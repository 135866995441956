import template from "modules/user/components/password-recovery/password-recovery.html?raw";
import "modules/user/components/password-recovery/password-recovery.scss";

import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";
import { Ui, UIButton, UITextfield } from "modules/core";
import ExternUserBO from "../../../core/business/ExternUserBO";
import mixins from "vue-typed-mixins";
import { PropType } from "vue";

export default mixins(CoreComponentMixin, LocaleMixin).extend({
    template,
    components: {
        "control-textfield": UITextfield,
        "mdk-button": UIButton,
    },
    props: {
        onSubmit: {
            required: true,
            type: Function as PropType<VoidFunction>,
            default: () => {},
        },
    },
    data() {
        return {
            currentUserCredentials: -1,
            localFormData: { username: "" } as Record<string, string>,
            typeInput: false,
        };
    },
    computed: {
        localeDescriptionRestorePass(): string {
            return this.localeText("access.descripcionRecuperarPassword");
        },

        localeDescriptionTitleRestorePass(): string {
            return this.localeText("change_password_forgotten_password_dialog");
        },

        navbarTitle(): string {
            return this.localeText("boton_atras");
        },
    },
    methods: {
        async submit() {
            await this.withPreloader(async () => {
                const response =
                    await ExternUserBO.getInstance().doForgotPassword(
                        this.localFormData.username,
                    );
                if (!("error" in response && response.error)) {
                    Ui.showToast(this.localeDescriptionRestorePass);
                    // @ts-ignore this is a function, but the type inferences fails when defining a default value
                    this.onSubmit();
                }
            });
        },
    },
});
