import _merge from "lodash/merge";
import _omitBy from "lodash/omitBy";
import _isNull from "lodash/isNull";
import { MessagesResponse } from "modules/core/model/messagesResponse";
import { MessagesRequest } from "modules/core/model/messagesRequest";
import { MessageResponse } from "modules/core/model/messageResponse";
import { MessageVO } from "modules/core/model/messageVO";
import GenericRemoteDAO from "./GenericRemoteDAO";

export default class MessagesRemoteDAO extends GenericRemoteDAO {
    async fetchMessages(
        offset?: number,
        count?: number,
        params: MessagesRequest = {},
    ): Promise<MessageVO[]> {
        const { messages }: MessagesResponse =
            await this.socket.sendServiceViaSocketWithPromise(
                "messages",
                _merge(_omitBy({ offset, count }, _isNull), params),
            );

        return messages || [];
    }

    async fetchMessage(params = {}): Promise<MessageVO> {
        const { message }: MessageResponse =
            await this.socket.sendServiceViaSocketWithPromise(
                "message",
                params,
            );

        return message || {};
    }
}
