import _merge from "lodash/merge";
import { ExternUserGroupListResponse } from "modules/core/model/externUserGroupListResponse";
import { ExternUserGroupVO } from "modules/core/model/externUserGroupVO";
import { ExternUserGroupListRequest } from "modules/core/model/externUserGroupListRequest";
import { RelationRequest } from "modules/core/model/relationRequest";
import { ExternUserGroupVORes } from "modules/core/model/externUserGroupVORes";
import { RelationResponse } from "modules/core/model/relationResponse";
import { ValuationResponse } from "modules/core/model/valuationResponse";
import { ValuationVO } from "modules/core/model/valuationVO";
import { ValuationRequest } from "modules/core/model/valuationRequest";
import GenericRemoteDAO from "./GenericRemoteDAO";

export default class ExternUserGroupRemoteDAO extends GenericRemoteDAO {
    async fetchExternUserGroupList(
        offset: number,
        count: number,
        params: ExternUserGroupListRequest = {},
    ): Promise<ExternUserGroupVO[]> {
        const response = await this.api.service<ExternUserGroupListResponse>(
            "externUserGroupList",
            _merge(this.basicParamsForPagination(offset, count), params),
        );

        if (response && response.externUserGroups) {
            return response.externUserGroups;
        }

        return [];
    }

    async fetchExternUserGroup(
        externUserGroupId: string,
        params: RelationRequest = {},
    ): Promise<ExternUserGroupVORes> {
        const response: RelationResponse =
            await this.socket.sendServiceViaSocketWithPromise(
                "relation",
                _merge({ relation: { externUserGroupId } }, params),
            );

        if (response && response.relation) {
            return response.relation;
        }

        return {};
    }

    async fetchValuation(params: ValuationRequest = {}): Promise<ValuationVO> {
        const response: ValuationResponse =
            await this.socket.sendServiceViaSocketWithPromise(
                "valuation",
                params,
            );

        if (response?.valuation) {
            return response.valuation;
        }

        return {};
    }
}
