import template from "modules/core/components/interface/textfield/textfield.html?raw";
import ControlTextField from "modules/core/mixins/ControlTextField";
import MdkMenu from "modules/core/components/interface/menu/Menu";
import { CoreComponentMixin } from "modules/core/mixins/ts";
import "modules/core/components/interface/textfield/textfield.scss";

import mixins from "vue-typed-mixins";

export type SupportingActionOptions = {
    id: string;
    name: string;
    leading: string;
};

export default mixins(CoreComponentMixin, ControlTextField).extend({
    template,
    components: {
        "mdk-ui-menu": MdkMenu,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        showInput: {
            type: Boolean,
            required: false,
            default: true,
        },
        supportingActionIcon: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: [String, Number],
            required: false,
            default: null,
        },
        supportingActionTypeButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        supportingActionTypeDropdown: {
            type: Boolean,
            required: false,
            default: false,
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false,
        },
        showSupportingAction: {
            type: Boolean,
            required: false,
            default: false,
        },
        onSupportingActionClick: {
            type: Function,
            required: false,
            default: () => {},
        },
        onActionTap: {
            type: Function,
            required: false,
            default: () => {},
        },
        supportingActionOptions: {
            type: Array,
            required: false,
            default: () => [],
        },
        theme: {
            type: String,
            required: false,
            default: "filled",
        },
        isLargeArea: {
            type: Boolean,
            required: false,
            default: false,
        },
        isLargeAreaFullHeight: {
            type: Boolean,
            required: false,
            default: false,
        },
        iconPrimaryColor: {
            type: Boolean,
            required: false,
            default: false,
        },
        supportingActionColorButton: {
            type: String,
            required: false,
            default: "#999",
        },
        showPlaceholderAsLabel: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasIconRight: {
            type: Boolean,
            required: false,
            default: true,
        },
        dataTestId: {
            type: [String, Boolean],
            required: false,
            default: false,
        }
    },
    data() {
        return {
            theValue: null as string | null | number,
        };
    },
    watch: {
        value(value: string | null) {
            this.theValue = value;
        },
        theValue(value: string | null) {
            this.$emit("input", value);
        },
    },
    computed: {
        setButtonClasses(): Record<string, boolean> {
            return {
                "mdk-ui-textfield__button-icon--primary": this.iconPrimaryColor,
            };
        },
        getStyleColorDefinitionButton() {
            return { color: this.supportingActionColorButton };
        },
        classDefinition(): string[] {
            const definition: string[] = [];

            if (this.theme === "outlined")
                definition.push("mdk-ui-textfield--outlined");
            if (this.icon != null)
                definition.push("mdk-ui-textfield--with-trailing-icon");
            if (this.supportingActionIcon != null && this.hasIconRight)
                definition.push("mdk-ui-textfield--with-button-icon");

            if (this.isLargeArea) definition.push("mdk-ui-textfield--textarea");

            if (this.showPlaceholderAsLabel)
                definition.push("mdk-ui-textfield--label");

            return definition;
        },
        supportingActionIsVisible(): boolean {
            return (
                this.showSupportingAction && this.supportingActionIcon != null
            );
        },
        isOutlined(): boolean {
            return this.theme === "outlined";
        },
        titleText(): string {
            return this.title || this.placeholder || "";
        },
        setTextareaClasses(): Record<string, boolean> {
            return {
                "mdk-ui-textfield--textarea--full-height":
                    this.isLargeAreaFullHeight,
            };
        },
        isSupportingActionTypeDropdown(): boolean {
            return (
                this.supportingActionTypeButton &&
                this.supportingActionTypeDropdown
            );
        },
        isSupportingActionTypeButton(): boolean {
            return (
                this.supportingActionTypeButton &&
                !this.supportingActionTypeDropdown
            );
        },
        getPlaceholder(): string {
            return !this.showPlaceholderAsLabel ? this.placeholder || "" : "";
        },
        getLabel(): string {
            return this.showPlaceholderAsLabel ? this.placeholder || "" : "";
        },
    },
    methods: {
        focusInput() {
            if ("textInput" in this.$refs) {
                // Safari on iOS prevents elements from receiving focus if no user interaction triggered the JavaScript code.
                this.$nextTick(() => {
                    setTimeout(() => {
                        if (this.$refs.textInput !== undefined) {
                            (this.$refs.textInput as HTMLElement).focus();
                        }
                    }, 500); // Hack
                });
            }
        },
    },
    mounted() {
        if (this.autofocus) {
            this.focusInput();
        }

        this.theValue = this.value;
    },
});
