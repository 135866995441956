import _map from "lodash/map";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _merge from "lodash/merge";
import _remove from "lodash/remove";
import _orderBy from "lodash/orderBy";
import _filter from "lodash/filter";
import _findLast from "lodash/findLast";
import _slice from "lodash/slice";
import _maxBy from "lodash/maxBy";
import _pull from "lodash/pull";
import _uniqBy from "lodash/uniqBy";
import GenericDAO from "./GenericDAO";

export default class MessagesDAO extends GenericDAO {
  constructor() {
    super();

    this.messagesByGroupId = {};
    this.itemById = {};

    this.idColumnName = "messageId";
    this.idColumnMessage = "groupId";
  }

  getMessageListByGroupId(groupId, offset, count) {
    return _slice(
      _orderBy(
        _filter(this.items, ["groupId", groupId]),
        (message) => {
          return message.deliveryDate;
        },
        ["desc"],
      ),
      offset,
      offset + count,
    );
  }

  getUnreadMessages() {
    return _filter(this.items, ["isRead", false]);
  }

  getUnreadMessagesByGroupId(groupId) {
    return _filter(this.items, { groupId, isRead: false });
  }

  addMessage(message, checkLastEdited = true) {
    const storedItem = this.findItemById(message[this.idColumnName]);
    if (storedItem === undefined) {
      if (message.isVisible) this.items.push(message);
    } else if (!message.isVisible) {
      this.removeMessage(message);
    } else {
      this.updateMessage(message);
    }
    this.items = _uniqBy(this.items, "messageId");

    return message;
  }

  updateMessage(message) {
    const index = _findIndex(this.items, ["messageId", message.messageId]);
    this.items[index] = message;
  }

  removeMessage(message) {
    this.items.splice(_findIndex(this.items, message), 1);
  }

  getMessageById(messageId) {
    return this.getItemById(messageId);
  }

  getLastMessageByGroupId(groupId) {
    return _maxBy(_filter(this.items, ["groupId", groupId]), "deliveryDate");
  }

  getNumberOfMessagesByGroupId(groupId) {
    return _filter(this.items, ["groupId", groupId]).length;
  }

  getExternUserGroupIdsOrderedByLastMessage(externUserGroupIds = []) {
    let list = [];
    const missMessageList = [];

    // let messageList = orderBy(_findLastIndex(this.items, "groupId"), )

    for (const groupId of externUserGroupIds) {
      if (this.getNumberOfMessagesByGroupId(groupId) > 0) {
        list.push(this.getLastMessageByGroupId(groupId));
      } else {
        missMessageList.push(groupId);
      }
    }

    list = _map(
      _orderBy(
        list,
        (lastMessage) => {
          return lastMessage.deliveryDate;
        },
        ["desc"],
      ),
      "groupId",
    );
    list.push(...missMessageList);

    return list;
  }

  getGroupIdsOrderedBylastMessage() {
    const groupIdList = _map(this.items, ["groupId"]);
    let lastMessageList = [];

    for (const groupId in groupIdList) {
      lastMessageList.push(this.getLastMessageByGroupId(groupId));
      lastMessageList = _orderBy(lastMessageList, ["lastEdited"], ["desc"]);
    }

    return _map(lastMessageList, ["groupId"]);
  }

  /*  getMyUnreadMessageCountById(groupId) {
        if (this.messagesByGroupId[groupId]) {
            let result = _filter(this.messagesByGroupId[groupId], message => { return !message.isRead });
            return result.length
        }
        return 0;

    } */

  /* updateMessage(message) {
        let index = _findIndex(this.messagesByGroupId[message.groupId], {"messageId": message.messageId})

        if (message.isVisible) {
            this.messagesByGroupId[message.groupId][index] = message
            this.itemById[message.messageId] = this.messagesByGroupId[message.groupId][index]
            return this.messagesByGroupId[message.groupId][index]
        } else {
            this.messagesByGroupId[message.groupId] = _remove(this.messagesByGroupId[message.groupId], { "messageId": message.messageId })
            delete this.itemById[message.messageId]
        }
    }

    getMessageById(messageId) {
        if (this.itemById[messageId])
            return this.itemById[messageId]
        return null
    } */

  getAllMyUnreadMessageCount(sourceId) {
    let myTotalUnreadMessageCount = 0;
    for (const groupId of Object.keys(this.messagesByGroupId)) {
      myTotalUnreadMessageCount += _filter(
        this.messagesByGroupId[groupId],
        (message) => {
          return !message.isRead && message.sourceId !== sourceId;
        },
      ).length;
    }

    return myTotalUnreadMessageCount;
  }

  getLastMessageByGroupIdAndExternUserId(groupId, externUserId) {
    let message = null;
    if (this.messagesByGroupId[groupId]) {
      message =
        _findLast(this.messagesByGroupId[groupId], (message) => {
          return message.sourceId === externUserId;
        }) || null;
    }

    return message;
  }

  removeAll() {
    this.messagesByGroupId = {};
    this.itemById = {};
  }
}
