import Enum from "enum";

const ExternUserGroupMemberRoleEnum = new Enum({
  OBSERVER: 0,
  OWNER: 1,
  ADMIN: 2,
  USER: 3,
  getStringRoleFromNum(number) {
    switch (number) {
      case 0:
        return "OBSERVER";
      case 1:
        return "OWNER";
      case 2:
        return "ADMIN";
      case 3:
        return "USER";
    }
  },
});

export default ExternUserGroupMemberRoleEnum;
