import Utils from "modules/core/lib/Util";

export default class Message {
  constructor(
    body = null,
    sourceId = null,
    groupId = null,
    type = null,
    isVisible = false,
    destinationId = null,
  ) {
    this.sourceId = sourceId;
    this.messageId = Utils.uniqueId();
    this.destinationId = destinationId;
    this.groupId = groupId;
    this.type = type;
    this.deliveryDate = null;
    this.sourceDate = new Date().getTime();
    this.body = body;
    this.isVisible = isVisible;
    this.readBy = null;
    this.receivedBy = null;
    this.sentTo = null;
    this.isRead = false;
    this.isReceived = false;
    this.readDate = null;
    this.receivedDate = null;
    this.lastEdited = null;
    this.sourceUid = null;
    this.number = null;
    this.title = null;
    this.subtitle = null;
  }
}
