import template from "modules/core/components/interface/loader/loader.html?raw";
import "modules/core/components/interface/loader/loader.scss";
import { svgToBase64 } from "../../../lib/Util";
import { QSpinner } from "quasar";

import vue from "vue";

export default vue.extend({
    template,
    components: {
        QSpinner,
    },
    props: {
        size: {
            type: Number,
            required: false,
            default: 35,
        },
        overlayed: {
            type: Boolean,
            required: false,
            default: false,
        },
        delaySpinner: {
            type: Boolean,
            required: false,
            default: false,
        },
        boxed: {
            type: Boolean,
            required: false,
            default: false,
        },
        padding: {
            type: Boolean,
            required: false,
            default: false,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            delaySpinnerTimeout: null as any,
            spinnerIsVisible: true,
        };
    },
    computed: {
        sizeWithUnit(): string {
            return `${this.size}px`;
        },
        url(): string {
            const logo =
                require("../../../../../app/static/img/loader2.svg?raw") as string;

            return svgToBase64(logo);
        },
        classes(): string[] {
            const list: string[] = [];

            if (this.overlayed) {
                list.push("mdk-ui-loader--overlayed");
            }

            if (!this.padding) {
                list.push("mdk-ui-loader--no-padding");
            }

            if (this.boxed) {
                list.push("mdk-ui-loader--boxed");
            }

            if (!this.spinnerIsVisible) {
                list.push("mdk-ui-loader--spinner-hidden");
            }

            return list;
        },
    },
    methods: {
        manageSpinnerVisibility() {
            if (this.delaySpinner) {
                this.spinnerIsVisible = false;

                this.delaySpinnerTimeout = setTimeout(() => {
                    this.spinnerIsVisible = true;
                }, 1500);
            } else {
                // this.spinnerIsVisible = true;
            }
        },
        stopTimeout() {
            if (this.delaySpinnerTimeout != null) {
                clearTimeout(this.delaySpinnerTimeout);
            }
        },
    },
    mounted() {
        // this.manageSpinnerVisibility();
    },
    destroyed() {
        this.stopTimeout();
    },
});
