import slugify from "voca/slugify";
import { Location } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import ExternUserGroupBO from "../business/ExternUserGroupBO";

export default class RouteGenerator {
    static instance?: RouteGenerator;

    readonly routeNameList = {
        session: "session",
        sessionResult: "sessionResult",
        patientDetail: "patientDetail",
        specialistDetail: "specialistDetail",
        chat: "chat",
        chats: "chats",
        chatbot: "chatbot",
        specialistPaymentDetail: "specialistPaymentDetail",
        specialties: "specialties",
        specialists: "specialists",
        sessionReportDownloadPdf: "sessionReportDownloadPdf",
        paymentConfirmation: "paymentConfirmation",
        home: "home",
        startSession: "startSession",
        checker: "checker",
        professionalNewImpersonatedSession:
            "professionalNewImpersonatedSession",
        profile: "profile",
        glossary: "glossary",
        glossaryDetail: "glossaryDetail",
        statementDetail: "statementDetail",
        profileActivity: "profileActivity",
        profileEdit: "profileEdit",
        externUserPrescriptionPdf: "externUserPrescriptionPdf",
        login: "login",
        register: "register",
        clientLinkList: "clientLinkList",
        clientLinkDetail: "clientLinkDetail",
        resetPassword: "resetPassword",
        profileHistory: "ProfileHistory",
        profileSettings: "profileSettings",
        schedule: "schedule",
        scheduleAppointment: "schedule-appointment",
        scheduleTimetable: "schedule-timetable",
        accessCode: "accessCode",
        notFound: "notFound",
        purchaseProduct: "purchaseProduct",
        specialistAdminList: "specialistAdminList",
        specialistAdminForm: "specialistAdminForm",
        specialistAdminDelete: "specialistAdminDelete",
        testCovid19: "testCovid19",
        actionsCovid: "actionsCovid",
        appPresets: "appPresets",
        dashboard: "dashboard",
        sessionStart: "sessionStart",
        hospitalSessions: "hospitalSessions",
        hospitalSessionHistory: "hospitalSessionHistory",
        hospitalUsers: "hospitalUsers",
        hospitalUserForm: "hospitalUserForm",
        hospitalAdminUserForm: "hospitalAdminUserForm",
        hospitalAdminUserDetails: "hospitalAdminUserDetails",
        hospitalAdminUserList: "hospitalAdminUserList",
        hospitalEvaluation: "hospitalEvaluation",
        hospitalUserDetails: "hospitalUserDetails",
        hospitalUserEdit: "hospitalUserEdit",
        hospitalSessionDetail: "hospitalSessionDetail",
        hospitalSessionDetailPrediagnostic:
            "hospitalSessionDetailPrediagnostic",
        hospitalSessionDetailReport: "hospitalSessionDetailReport",
        hospitalSessionBasicUser: "hospitalSessionBasicUser",
        cmd: "cmd",
        legalTerms: "legalTerms",
        privacyPolicy: "privacyPolicy",
        cookiePolicy: "cookiePolicy",
        validateEmail: "validateEmail",
        logout: "profileLogout",
        certificate: "certificate",
        pageHomeSelector: "pageHomeSelector",
        pageSymptomsSelector: "pageSymptomsSelector",
        pageDiseasesSelector: "pageDiseasesSelector",
        pageCommonDiseases: "pageCommonDiseases",
    };

    static getInstance() {
        if (!RouteGenerator.instance) {
            RouteGenerator.instance = new this();
        }

        return RouteGenerator.instance;
    }

    newSessionWithReasonForConsultation(
        reason: string | null = null,
        impersonate: boolean = false,
    ): Location {
        const query: Dictionary<string | string[]> = {};
        if (reason !== null) query.reason = reason;
        if (impersonate) query.impersonate = "1";

        return { name: this.routeNameList.checker, query };
    }

    session(sessionId: string, impersonate = false): Location {
        const query: Dictionary<string | string[]> = { sessionId };
        query.impersonate = impersonate === true ? "1" : "0";

        return { name: this.routeNameList.session, query };
    }

    sessionResult(sessionId: string): Location {
        return { name: this.routeNameList.sessionResult, query: { sessionId } };
    }

    patientDetail(externUserId: string): Location {
        return {
            name: this.routeNameList.patientDetail,
            query: { externUserId },
        };
    }

    specialistDetail(externUserName: string, externUserId: string): Location {
        return {
            name: this.routeNameList.specialistDetail,
            params: { externUserName: slugify(externUserName) },
            query: { externUserId },
        };
    }

    chat(externUserGroupId: string, status?: number): Location {
        const query: Dictionary<string | string[]> = { externUserGroupId };
        if (ExternUserGroupBO.getInstance().isValidGroupStatus(status))
            query.status = String(status);

        return { name: this.routeNameList.chat, query };
    }

    chats(): Location {
        return { name: this.routeNameList.chat };
    }

    specialistPaymentDetail(externUserId: string, productId: string): Location {
        return {
            name: this.routeNameList.chat,
            query: { externUserId, productId },
        };
    }

    purchase(
        productId: string,
        externUserId?: string,
        sessionId?: string,
    ): Location {
        const query: Dictionary<string | string[]> = { productId };
        if (sessionId) query.sessionId = sessionId;
        if (externUserId) query.externUserId = externUserId;

        return { name: this.routeNameList.purchaseProduct, query };
    }

    specialties(name: string, specialtyId: string): Location {
        return {
            name: this.routeNameList.specialties,
            params: { name },
            query: { specialtyId },
        };
    }

    specialists(categoryId: string): Location {
        return { name: this.routeNameList.specialists, params: { categoryId } };
    }

    sessionReportDownloadPdf(sessionId: string): Location {
        return {
            name: this.routeNameList.sessionReportDownloadPdf,
            query: { sessionId },
        };
    }

    paymentConfirmation(externUserId: string, productId: string): Location {
        return {
            name: this.routeNameList.paymentConfirmation,
            query: { externUserId, productId },
        };
    }

    newImpersonatedSession(): Location {
        return { name: this.routeNameList.professionalNewImpersonatedSession };
    }

    profile(sessionId?: string): Location {
        return {
            name: this.routeNameList.profile,
            query: { sessionId } as any,
        };
    }

    dashboard(): Location {
        return { name: this.routeNameList.dashboard };
    }

    certificate(): Location {
        return { name: this.routeNameList.certificate };
    }

    profileSettings(): Location {
        return { name: this.routeNameList.profileSettings };
    }

    profileActivity(): Location {
        return { name: this.routeNameList.profileActivity };
    }

    profileEdit(externUserId?: string): Location {
        return {
            name: this.routeNameList.profileEdit,
            query: { externUserId } as any,
        };
    }

    login(then?: string): Location {
        return { name: this.routeNameList.login, query: { then } as any };
    }

    register(then?: string): Location {
        return { name: this.routeNameList.register, query: { then } as any };
    }

    accessCode(): Location {
        return { name: this.routeNameList.accessCode };
    }

    notFound(): Location {
        return { name: this.routeNameList.notFound };
    }

    home(): Location {
        return { name: this.routeNameList.home };
    }

    checker(): Location {
        return { name: this.routeNameList.checker };
    }

    startSession(): Location {
        return { name: this.routeNameList.startSession };
    }

    chatbot(): Location {
        return { name: this.routeNameList.chatbot };
    }

    externUserPrescriptionPdf(
        externUserId: string,
        prescriptionId: string,
    ): Location {
        return {
            name: this.routeNameList.externUserPrescriptionPdf,
            query: {
                externUserId,
                prescriptionId,
            },
        };
    }

    scheduleAppointment(
        targetExternUserId: string,
        productId?: string,
        sessionId?: string,
    ): Location {
        return {
            name: this.routeNameList.scheduleAppointment,
            query: { targetExternUserId, productId, sessionId } as any,
        };
    }

    profileSchedule(): Location {
        return { name: this.routeNameList.schedule };
    }

    profileScheduleTimetable(): Location {
        return { name: this.routeNameList.scheduleTimetable };
    }

    specialistAdminList(): Location {
        return { name: this.routeNameList.specialistAdminList };
    }

    specialistAdminForm(externUserId?: string): Location {
        return {
            name: this.routeNameList.specialistAdminForm,
            query: { externUserId } as any,
        };
    }

    specialistAdminDelete(externUserId: string): Location {
        return {
            name: this.routeNameList.specialistAdminDelete,
            query: { externUserId },
        };
    }

    glossary(): Location {
        return { name: this.routeNameList.glossary };
    }

    glossaryDetail(
        conclusionId: string,
        conclusionDescription?: string,
    ): Location {
        return {
            name: this.routeNameList.glossaryDetail,
            query: { conclusionId },
            params: { conclusionDescription: conclusionDescription ?? "" },
        };
    }

    statementDetail(
        statementId: string,
        statementdescriptionShort?: string,
    ): Location {
        return {
            name: this.routeNameList.statementDetail,
            query: { statementId },
            params: {
                statementdescriptionShort: statementdescriptionShort ?? "",
            },
        };
    }

    testCovid19(): Location {
        return { name: this.routeNameList.testCovid19 };
    }

    conclusionActions(conclusionId: string, sessionId?: string): Location {
        return {
            name: this.routeNameList.actionsCovid,
            query: { conclusionId, sessionId } as any,
        };
    }

    appPresets(): Location {
        return { name: this.routeNameList.appPresets };
    }

    sessionStart(reason: string, externUserId?: string): Location {
        return {
            name: this.routeNameList.sessionStart,
            query: { reason, externUserId },
        };
    }

    /* hospitalSessions(): Location {
        return {name: this.routeNameList.hospitalSessions};
    }

    hospitalSessionHistory(): Location {
        return {name: this.routeNameList.hospitalSessionHistory};
    }

    hospitalUsers(): Location {
        return {name: this.routeNameList.hospitalUsers};
    }

    hospitalUserForm(): Location {
        return {name: this.routeNameList.hospitalUserForm};
    }

    hospitalAdminUserForm(externUserId?:string): Location {
        return {name: this.routeNameList.hospitalAdminUserForm, query: {externUserId}};
    }

    hospitalAdminUserDetails(externUserId?:string): Location {
        return {name: this.routeNameList.hospitalAdminUserDetails, query: {externUserId}};
    }

    hospitalAdminUserList(): Location {
        return {name: this.routeNameList.hospitalAdminUserList};
    }

    hospitalEvaluation(sessionId?:string): Location {
        return {name: this.routeNameList.hospitalEvaluation, query: {sessionId}};
    }

    hospitalUserDetails(externUserId:string): Location {
        return {name: this.routeNameList.hospitalUserDetails, query: {externUserId}};
    }

    hospitalUserEdit(externUserId:string, tab?:string): Location {
        return {name: this.routeNameList.hospitalUserEdit, query: {externUserId, tab}};
    }

    hospitalSessionDetail(sessionId:string): Location {
        return {name: this.routeNameList.hospitalSessionDetail, query: {sessionId}};
    }

    hospitalSessionDetailPrediagnostic(sessionId:string): Location {
        return {name: this.routeNameList.hospitalSessionDetailPrediagnostic, query: {sessionId}};
    }

    hospitalSessionDetailReport(sessionId:string): Location {
        return {name: this.routeNameList.hospitalSessionDetailReport, query: {sessionId}};
    }

    hospitalSessionBasicUser(): Location {
        return {name: this.routeNameList.hospitalSessionBasicUser};
    } */

    legalTerms(): Location {
        return { name: this.routeNameList.legalTerms };
    }

    privacyPolicy(): Location {
        return { name: this.routeNameList.privacyPolicy };
    }

    cookiePolicy(): Location {
        return { name: this.routeNameList.cookiePolicy };
    }

    resetPassword(validationId: string): Location {
        return {
            name: this.routeNameList.resetPassword,
            query: { validationId },
        };
    }

    validateEmail(validationId: string): Location {
        return {
            name: this.routeNameList.validateEmail,
            query: { validationId },
        };
    }

    cmd(cmd: string, query?: Dictionary<string | string[]>) {
        return { name: this.routeNameList.cmd, params: { cmd }, query };
    }

    logout(): Location {
        return { name: this.routeNameList.logout };
    }

    blank(): Location {
        return { name: "blank" };
    }

    pageHomeSelector(): Location {
        return { name: this.routeNameList.pageHomeSelector };
    }

    pageSymptomsSelector(): Location {
        return { name: this.routeNameList.pageSymptomsSelector };
    }

    pageDiseasesSelector(): Location {
        return { name: this.routeNameList.pageDiseasesSelector };
    }

    pageCommonDiseases(): Location {
        return { name: this.routeNameList.pageCommonDiseases };
    }
}
