import _slugify from "voca/slugify";
import _merge from "lodash/merge";
import { Location } from "vue-router";
import { ExternUserResponse } from "modules/core/model/externUserResponse";
import { BaseDeepLinking, CMD, IDeepLinking } from "./DeeplinkingManager";
import ConclusionBO from "../business/ConclusionBO";
import { Application } from "./Holder";
import RouteGenerator from "./RouteGenerator";
import Api from "./Api";

const isUndefined = (value: unknown): value is undefined => {
    return typeof value === "undefined";
};

export type AllowedActions =
    | "reasonForConsultation"
    | "sessionReasonForConsultation"
    | "login"
    | "register"
    | "profile"
    | "profileEdit"
    | "profileSettings"
    | "testCovid19"
    | "chatbot"
    | "glossary"
    | "specialtyGeneral"
    | "chat"
    | "chats"
    | "profileActivity"
    | "glossaryDetail"
    // | "statementDetail": not implemented
    | "specialistDetail"
    | "sessionResume"
    | "sessionReport"
    | "sessionConclusions"
    | "sessionStart"
    | "legalTerms"
    | "privacyPolicy"
    | "cookiePolicy"
    | "resetPassword"
    | "validateEmail"
    | "conclusionActions"
    | "awssaas"
    | "clientLinkDetail"
    | "clientLinkList"
    | "purchaseProduct"
    | "profileSchedule"
    | "profileScheduleTimetable"
    | "scheduleAppointment"
    | "specialistAdminList"
    | "specialistAdminAdd"
    | "specialistAdminEdit"
    | "homeSelector";

type AllowedActionsAliases = "home" | "sessionResult" | "conclusionDetail";

const ActionAliases: Record<AllowedActionsAliases, AllowedActions> = {
    home: "reasonForConsultation",
    sessionResult: "sessionConclusions",
    conclusionDetail: "glossaryDetail",
};

export type Commands = AllowedActions | AllowedActionsAliases;

export default class Deeplinking
    extends BaseDeepLinking<Commands>
    implements IDeepLinking<Location>
{
    // constructor(command: Commands, query: Record<string, any> | null = null) {
    //     this.command = command;
    //     this.query = query || getUrlQueryParamsAsObject(window.location.href);
    // }

    async handle(cmd: CMD<Commands>): Promise<void | Location> {
        this.setCMD(cmd);
        return this.manageRedirect();
    }

    commonQueryParams(params = {}) {
        return _merge({}, params);
    }

    protected async cmdToRoute(): Promise<Location | null> {
        const cmd: keyof typeof this.redirects =
            this.command && (ActionAliases[this.command] ?? this.command);
        const redirect = await this.redirects[cmd]();

        if (!redirect) throw new Error(`${cmd}: CMD does not exist!`);

        return redirect;
    }

    async manageRedirect(): Promise<Location> {
        const currentQuery = { ...this.query };
        const route = await this.cmdToRoute();
        if (route) {
            // @ts-ignore
            route.query = currentQuery;
            // route.query["fromCmd"] = "1";
        }
        // @ts-ignore
        return route;
    }

    redirects: Record<AllowedActions, () => Promise<Location>> = {
        reasonForConsultation: async () => {
            return RouteGenerator.getInstance().home();
        },
        sessionReasonForConsultation: async () => {
            return RouteGenerator.getInstance().newSessionWithReasonForConsultation();
        },
        login: async () => {
            return RouteGenerator.getInstance().login();
        },
        register: async () => {
            return RouteGenerator.getInstance().register();
        },
        profile: async () => {
            return RouteGenerator.getInstance().profile();
        },
        profileEdit: async () => {
            return RouteGenerator.getInstance().profileEdit();
        },
        profileSettings: async () => {
            return RouteGenerator.getInstance().profileSettings();
        },
        testCovid19: async () => {
            return RouteGenerator.getInstance().testCovid19();
        },
        chatbot: async () => {
            return RouteGenerator.getInstance().chatbot();
        },
        glossary: async () => {
            return RouteGenerator.getInstance().glossary();
        },
        specialtyGeneral: async () => {
            return {
                name: "specialists",
                query: {
                    categoryId:
                        Application.main.generalConfig()
                            .allSepecialistsCategoryId,
                } as any,
            };
        },
        chat: async () => {
            if (!this.checkRequiredQueryParams(["externUserGroupId"])) {
                return this.notFoundRoute();
            }

            return RouteGenerator.getInstance().chat(
                this.query?.externUserGroupId,
            );
        },
        chats: async () => {
            return RouteGenerator.getInstance().chats();
        },
        profileActivity: async () => {
            return RouteGenerator.getInstance().profileActivity();
        },
        glossaryDetail: async () => {
            if (!this.checkRequiredQueryParams(["conclusionId"])) {
                return this.notFoundRoute();
            }

            const conclusionDetail =
                await ConclusionBO.getInstance().fetchConclusionDetail(
                    this.query?.conclusionId,
                );
            if (conclusionDetail.conclusionId) {
                return RouteGenerator.getInstance().glossaryDetail(
                    conclusionDetail.conclusionId,
                    "conclusion",
                );
            }

            return this.notFoundRoute();
        },
        specialistDetail: async () => {
            if (
                !this.checkRequiredQueryParams(["specialistId"]) &&
                !this.checkRequiredQueryParams(["externUserId"])
            ) {
                return this.notFoundRoute();
            }

            const service = await Api.getInstance().service<ExternUserResponse>(
                "externUser",
                {
                    externUserId:
                        this.query?.specialistId || this.query?.externUserId,
                },
            );

            if (!service.externUser?.fullName) {
                throw new Error("User not found");
            }

            return RouteGenerator.getInstance().specialistDetail(
                service.externUser.fullName,
                service.externUser.externUserId!,
            );
        },
        sessionResume: async () => {
            if (!this.checkRequiredQueryParams(["sessionId"])) {
                return this.notFoundRoute();
            }

            return RouteGenerator.getInstance().session(this.query?.sessionId);
        },
        sessionReport: async () => {
            if (!this.checkRequiredQueryParams(["sessionId"])) {
                return this.notFoundRoute();
            }

            return RouteGenerator.getInstance().sessionReportDownloadPdf(
                this.query?.sessionId,
            );
        },
        sessionConclusions: async () => {
            if (!this.checkRequiredQueryParams(["sessionId"])) {
                return this.notFoundRoute();
            }

            return RouteGenerator.getInstance().sessionResult(
                this.query?.sessionId,
            );
        },
        sessionStart: async () => {
            if (
                isUndefined(this.query?.reason) &&
                isUndefined(this.query?.conclusion)
            ) {
                // this.checkRequiredQueryParams(["reason"])
                // this.checkRequiredQueryParams(["conclusion"])
                return this.notFoundRoute();
            }

            const param = this.query?.reason
                ? this.query?.reason
                : this.query?.conclusion;

            console.log(
                "Deeplinking::sdk::sessionstart",
                param,
                this.query?.externUserId,
            );

            return RouteGenerator.getInstance().sessionStart(
                param,
                this.query?.externUserId,
            );
        },
        legalTerms: async () => {
            return RouteGenerator.getInstance().legalTerms();
        },
        privacyPolicy: async () => {
            return RouteGenerator.getInstance().privacyPolicy();
        },
        cookiePolicy: async () => {
            return RouteGenerator.getInstance().cookiePolicy();
        },
        resetPassword: async () => {
            if (!this.checkRequiredQueryParams(["validationId"])) {
                return this.notFoundRoute();
            }

            return RouteGenerator.getInstance().resetPassword(
                this.query?.validationId,
            );
        },
        validateEmail: async () => {
            return RouteGenerator.getInstance().validateEmail(
                this.query?.validationId,
            );
        },
        conclusionActions: async () => {
            return {
                name: "actionsCovid",
                query: this.commonQueryParams({
                    conclusionId: this.query?.conclusionId,
                    sessionId: this.query?.sessionId,
                }),
            };
        },
        awssaas: async () => {
            if (
                !this.checkRequiredQueryParams([
                    "clientLinkType",
                    "clientLinkToken",
                ])
            ) {
                return this.notFoundRoute();
            }

            return {
                name: "awsLogin",
                query: {
                    clientLinkType: this.query?.clientLinkType,
                    clientLinkToken: this.query?.clientLinkToken,
                },
            };
        },
        clientLinkDetail: async () => {
            if (!this.checkRequiredQueryParams(["clientId"])) {
                return this.notFoundRoute();
            }

            return {
                name: "clientLinkDetail",
                query: {
                    clientId: this.query?.clientId,
                },
            };
        },
        clientLinkList: async () => {
            return {
                name: "clientLinkList",
            };
        },
        purchaseProduct: async () => {
            return RouteGenerator.getInstance().purchase(
                this.query?.productId,
                this.query?.specialistExternUserId,
                this.query?.sessionId,
            );
        },
        profileSchedule: async () => {
            return RouteGenerator.getInstance().profileSchedule();
        },
        profileScheduleTimetable: async () => {
            return RouteGenerator.getInstance().profileScheduleTimetable();
        },
        scheduleAppointment: async () => {
            return RouteGenerator.getInstance().scheduleAppointment(
                this.query?.targetExternUserId,
                this.query?.productId,
            );
        },
        specialistAdminList: async () => {
            return RouteGenerator.getInstance().specialistAdminList();
        },
        specialistAdminAdd: async () => {
            return RouteGenerator.getInstance().specialistAdminForm();
        },
        specialistAdminEdit: async () => {
            if (!this.checkRequiredQueryParams(["specialistId"])) {
                return this.notFoundRoute();
            }

            return RouteGenerator.getInstance().specialistAdminForm(
                this.query?.specialistId,
            );
        },
        homeSelector: async () => {
            return RouteGenerator.getInstance().pageHomeSelector();
        },
    };
}
