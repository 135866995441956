import App from "./App";

/**
 * @deprecated Please import App class directly
 */
export default class Holder {
    static app: App;

    /**
     * @deprecated
     */
    static setAppInstance(instance: App) {
        Holder.app = instance;
    }
}

/**
 * @deprecated Please import App class directly
 */
export class Application {
    static get main(): App {
        return Holder.app;
    }

    static getInstance(): App {
        return Holder.app;
    }
}
