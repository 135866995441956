import _includes from "lodash/includes";
import app from "modules/core/config/app";
import { LanguageVO } from "modules/core/model/languageVO";
import { LanguageListResponse } from "modules/core/model/languageListResponse";
import { LanguageDetailResponse } from "modules/core/model/languageDetailResponse";
import config from "modules/core/config/app";
import AppStorageManager from "../lib/AppStorageManager";
import EnvironmentConfig from "../lib/EnvironmentConfig";
import Storage from "../lib/Storage";
import Util from "../lib/Util";
import BusinessAbstract from "./BusinessAbstract";
import App from "../lib/App";

export default class LanguageBO extends BusinessAbstract {
    static instance?: LanguageBO;

    languageList: LanguageVO[] = [];

    languages: string[] = [];

    resolvedCurrentLanguageCode?: string;

    storage!: Storage;

    setStorage(storage: Storage) {
        this.storage = storage;
    }

    static getInstance(): LanguageBO {
        if (!LanguageBO.instance) {
            LanguageBO.instance = new this();
        }

        return LanguageBO.instance;
    }

    static userLanguage(): string {
        return Util.browserLanguage();
    }

    async fetchLanguageList() {
        const service = await this.api.service<LanguageListResponse>(
            "languageList",
            {
                useCache: 30 * 24,
            },
        );

        if (service && service.languageList) {
            // this.languageList = service.languageList.filter(item => item.isActive /*&& item.languageCode != "en_US"*/);
            this.languageList = service.languageList.filter((item) => {
                return (
                    item.isActive &&
                    this.enabledRouteForLanguage(
                        item.languageCode?.replace("_", "-") || "",
                    )
                );
            }) /* .map(item => {
                if(item.languageCode === "en_EN") item.languageCode = "en_GB";

                return item;
            }) */;
            const en = this.languageList.find(
                (item) => item.languageCode === "en_EN",
            );
            if (en) {
                const gb = { ...en };
                gb.languageCode = "en_GB";
                this.languageList.push(gb);
            }

            this.languages = this.languageList.map(
                (item) => item.languageCode || "",
            );
        }
    }

    async fetchLanguageDetail(
        lang?: string,
        useCache: boolean = false,
    ): Promise<LanguageVO | null> {
        try {
            const service: LanguageDetailResponse = await this.api.service(
                "languageDetail",
                {
                    useCache, // 15 days,
                    useDefault: false,
                    language: lang,
                },
            );

            return service.language!;
        } catch (e) {
            console.info(e);
        }

        return null;
    }

    static languageMatchDefinition(): RegExp {
        return /^[a-z]{2}(\-[a-z]{2})?$/;
    }

    static convertLanguageCodeToUrlCode(isoCode: string): string {
        let publicLanguage = isoCode.toLowerCase();
        const splitted = publicLanguage.split("-");

        if (splitted[0] === splitted[1]) {
            // Si es "es-es", usar solo "es"
            publicLanguage = splitted[0];
        }

        return publicLanguage;
    }

    getUserLanguageCorrespondenceCode(): string | null {
        let userLanguage = LanguageBO.userLanguage();

        // Two words languages should be converted to ISO (4 digits) to do the comparison
        if (userLanguage.length === 2) {
            userLanguage = `${userLanguage}-${userLanguage.toUpperCase()}`;
        }

        console.log("Browser language is:", userLanguage);

        if (!Util.isNullOrEmpty(userLanguage)) {
            for (const language of this.languageList) {
                const lang = language.languageCode!.replace("en_EN", "en_GB");

                if (lang == userLanguage.replace("-", "_")) {
                    return language.languageCode!.replace("_", "-");
                }
            }
        }

        return null;
    }

    resolveUrlLanguage(url?: string): any {
        // Teniendo en cuenta que el primer segmento despues del dominio+path es el idioma
        const urlPath =
            url ||
            (config.routerMode === "hash"
                ? window.location.hash.replace("#", "")
                : window.location.pathname);
        const urlSegmentLanguage = urlPath
            .replace(
                EnvironmentConfig.getValue("APP_BASE_PATH") || config.basePath,
                "",
            )
            .split("/");

        const parts = urlSegmentLanguage.filter((fragment) => {
            return fragment !== "#" && fragment !== "";
        });

        const candidate = parts[0];
        const splitChar = "-";
        const languageBlackList = ["cmd"];

        if (candidate) {
            if (
                candidate.match(LanguageBO.languageMatchDefinition()) &&
                !_includes(languageBlackList, candidate)
            ) {
                const split = candidate.split(splitChar);
                let result: string;

                // let's convert the language code to iso xx-XX
                if (split.length > 1) {
                    // Hay "-"
                    result = [split[0], split[1].toUpperCase()].join(splitChar);
                } else {
                    result = [split[0], split[0].toUpperCase()].join(splitChar);
                }

                return result;
            }
        }

        return undefined;
    }

    static convertFriendlyLanguageToIso(candidate: string): string {
        let result;
        const splitChar = "-";
        const split = candidate.split(splitChar);

        // let's convert the language code to iso xx-XX
        if (split.length > 1) {
            // Hay "-"
            result = [split[0], split[1].toUpperCase()].join(splitChar);
        } else {
            result = [split[0], split[0].toUpperCase()].join(splitChar);
        }

        return result;
    }

    enabledRouteForLanguage(language: string): boolean {
        return true;
    }

    async getResolvedCurrentLanguageCode(): Promise<string> {
        /* let urlLang = Util.urlQueryParam("lang");
        if (!Util.isNullOrEmpty(urlLang)) {
            let response = await this.fetchLanguageDetail(urlLang);
            if (response && response.languageCode && this.enabledRouteForLanguage(response.languageCode)) {
                return response.languageCode;
            }
        } */

        const urlLanguage = this.resolveUrlLanguage();

        if (
            !Util.isNullOrEmpty(urlLanguage) &&
            this.enabledRouteForLanguage(urlLanguage) &&
            this.languageIsAvailableInLanguagesList(urlLanguage)
        ) {
            return urlLanguage;
        }

        const storage = AppStorageManager.getInstance().miscStorage;
        const storageLanguage = storage.getByName("language");
        if (
            !Util.isNullOrEmpty(storageLanguage) &&
            this.enabledRouteForLanguage(storageLanguage) &&
            this.languageIsAvailableInLanguagesList(storageLanguage)
        ) {
            return storageLanguage;
        }

        if (config.detectUserLanguage) {
            const userLanguage = this.getUserLanguageCorrespondenceCode();
            if (
                !Util.isNullOrEmpty(userLanguage) &&
                this.enabledRouteForLanguage(userLanguage!) &&
                this.languageIsAvailableInLanguagesList(userLanguage!)
            ) {
                return userLanguage!;
            }
        }

        if (this.languageIsAvailableInLanguagesList(app.defaultLanguage)) {
            return app.defaultLanguage;
        }

        if (this.languages.length > 0) {
            console.log(
                "Default language not available. Falling back to first language in the list of languages.",
            );

            return this.languages[0].replace("_", "-");
        }

        console.log("Falling hard back to en-EN.");

        return "en-EN";
    }

    async resolveCurrentLanguageCode() {
        this.resolvedCurrentLanguageCode =
            await this.getResolvedCurrentLanguageCode();
    }

    currentLanguage(): LanguageVO {
        return this.languageList.filter(
            (item) =>
                item.languageCode ===
                this.currentLanguageCode().replace("-", "_"),
        )[0];
    }

    currentLanguageCode(): string {
        return this.resolvedCurrentLanguageCode || app.defaultLanguage; // FIXME: why resolvedCurrentLanguageCode is undefined somethimes?
    }

    languageIsAvailableInLanguagesList(language: string): boolean {
        return (
            this.languages.filter(
                (languageCode) => languageCode.replace("_", "-") === language,
            ).length > 0
        );
    }

    static convertToPublicLanguage(lang: string) {
        const language = lang.replace("_", "-");
        let publicLanguage = language.toLowerCase();
        const splitted = publicLanguage.split("-");

        if (splitted[0] == splitted[1]) {
            // Si es "es-es", usar solo "es"
            publicLanguage = splitted[0];
        }

        return publicLanguage;
    }

    currentLanguageCodeForApi(): string {
        return this.currentLanguageCode().replace("-", "_");
    }

    currentLanguageIsValid(): boolean {
        return _includes(this.getLanguages(), this.currentLanguageCode());
    }

    getLanguages(): string[] {
        return this.languages;
    }

    getLanguageList(includeDuplicates: boolean = true): LanguageVO[] {
        if (includeDuplicates) {
            return this.languageList;
        }

        return this.languageList.filter(
            (item) => item.languageCode !== "en_EN",
        );
    }

    static convertLowercaseLanguageToIso(language: string): string {
        let [lang, region] = language.split("-");
        if (!region) region = lang;

        return [lang, region.toUpperCase()].join("-");
    }

    static rareLanguageToOutputLanguage(language: string): string {
        if (
            language.match(/-GR$/) ||
            language.match(/-CN$/) ||
            language.match(/-AR$/)
        ) {
            return "en-EN";
        }

        return language;
    }
}
