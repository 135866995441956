import { Location } from "vue-router";
import { AnalyticsRequest } from "modules/core/model/analyticsRequest";
import { AnalyticsResponse } from "modules/core/model/analyticsResponse";
import BusinessAbstract from "./BusinessAbstract";
import UtmHandler from "../lib/UtmHandler";

/**
 * This class is used to send tracking data to mediktors servers
 */
export default class AnalyticsBO extends BusinessAbstract {
    private static instance?: AnalyticsBO;

    static getInstance(): AnalyticsBO {
        if (!AnalyticsBO.instance) {
            AnalyticsBO.instance = new this();
        }

        return AnalyticsBO.instance;
    }

    // eslint-disable-next-line class-methods-use-this
    private isJsonString(str: string): boolean {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    async sendAnalyticsToServer(
        params: AnalyticsRequest = {},
    ): Promise<AnalyticsResponse> {
        let extraParams = {};

        if (params && params.extra && this.isJsonString(params.extra)) {
            try {
                extraParams = JSON.parse(params.extra);
            } catch (error) {
                console.error('Error parsing extra params', error);
            }
        }

        let UTMParams = {};
        try {
            UTMParams = UtmHandler.getInstance().getParams();
        } catch (error) {
            console.error('Error getting UTM params', error);
        }

        const extra = JSON.stringify({
            ...extraParams,
            UTMParams,
        });

        const payload = { ...params, extra };


        return this.api.service("analytics", payload);

    }

    async sendPageView(route: Location): Promise<AnalyticsResponse> {
        const params = {
            param1: window.location.href,
            reason: route.name,
        };
        console.log("AnalyticsBO PageView", params);

        return this.sendAnalyticsToServer(params);
    }

    async sendEventAction(
        nameRoute: string,
        eventAction: string = "",
        eventValue: string = "",
        extra?: string,
    ): Promise<AnalyticsResponse> {
        const params = {
            extra,
            param1: eventValue,
            param2: nameRoute,
            reason: eventAction,
        };
        console.log("AnalyticsBO EventAction", params);

        return this.sendAnalyticsToServer(params);
    }
}