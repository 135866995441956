import _filter from "lodash/filter";
import _merge from "lodash/merge";
import _map from "lodash/map";
import GenericRepo from "./GenericRepo";
import MessagesDAO from "../dao/MessagesDAO";
import MessagesRemoteDAO from "../dao/MessagesRemoteDAO";
import Util from "../lib/Util";
import EventBusSingleton from "../lib/EventBusSingleton";

export default class MessagesRepo extends GenericRepo {
  constructor() {
    super();

    this.dao = new MessagesDAO();
    this.remoteDao = new MessagesRemoteDAO();
    this.storageName = "messages";
  }

  static getInstance() {
    if (!MessagesRepo.instance) {
      MessagesRepo.instance = new this();
    }

    return MessagesRepo.instance;
  }

  updateStorage() {}

  async fetch(offset = null, count = null, groupId = null, params = {}) {
    return await this.remoteDao.fetchMessages(
      offset,
      count,
      _merge({ groupId }, params),
    );
  }

  async fetchUnreadMessages(groupId = null, params = {}, iteration = true) {
    const unreadMessages = groupId
      ? this.getUnreadMessagesByGroupId(groupId)
      : this.getUnreadMessages();

    let fetchOffset = unreadMessages.length;
    const count = 30;
    let itemsFromServer = await this.fetch(fetchOffset, count, groupId, params);

    for (const item of itemsFromServer) {
      this.addMessage(item, true);
    }

    if (itemsFromServer !== undefined) {
      if (iteration) {
        while (itemsFromServer.length >= count) {
          fetchOffset += count;
          itemsFromServer = await this.fetch(
            fetchOffset,
            count,
            groupId,
            params,
          );

          for (const item of itemsFromServer) {
            this.addMessage(item, true);
          }
        }
      }
      // this.updateStorage();
    }
    // let filter = _filter(response.messages, ["isVisible", false]);
    // if (filter.length > 0) await this.readMessages(filter);
    EventBusSingleton.emit("userUpdateNotifications");

    return groupId
      ? this.getUnreadMessagesByGroupId(groupId)
      : this.getUnreadMessages();
  }

  async getMessageList(offset = 0, count = 50, groupId = null, params = {}) {
    if (groupId) return await this.getListById(offset, count, groupId, params);

    return await this.getList(offset, count, params);
  }

  async fetchMessage(params = {}) {
    const response = await this.remoteDao.fetchMessage(params);
    if (response !== undefined) {
      this.addOrUpdateMessage(response);

      return response;
    }
  }

  async getListById(offset = 0, count = 50, groupId = null, params = {}) {
    const items = this.getMessageListByGroupId(groupId, offset, count);
    const fetchCount = count - items.length;

    if (fetchCount > 0) {
      const fetchRequest = await this.getListByIdFromServer(
        offset + (count - fetchCount),
        fetchCount,
        groupId,
        params,
      );

      /* if(fetchRequest.length === 0){
                this.stopFetching = true;
            } */
    }

    return this.getMessageListByGroupId(groupId, offset, count);
  }

  async getListByIdFromServer(
    offset = null,
    count = null,
    groupId = null,
    params = {},
  ) {
    const maxDate = Util.currentTimestamp();
    const itemsFromServer = await this.fetch(offset, count, groupId, params);

    for (const item of itemsFromServer) {
      this.addMessage(item);
    }

    this.lastMaxDate = maxDate;

    /* if (itemsFromServer.length) {
            this.updateStorage();
        } */
    return itemsFromServer;
  }

  addOrUpdateMessage(message) {
    if (message) {
      const oldMessage = this.getMessageById(message.messageId);

      this.addMessage(message);
      if (
        !oldMessage ||
        oldMessage.isReceived !== message.isReceived ||
        oldMessage.isRead !== message.isRead
      ) {
        this.addMessage(message);
        EventBusSingleton.emit(`message:${message.groupId}`, message);
        EventBusSingleton.emit("userUpdateNotifications");
      }
      // this.updateStorage();
    } else console.log(message);

    return message;
  }

  async fetchLanguageCode(message) {
    const response = await this.fetchMessage(message);
    if (response !== undefined) {
      return response;
    }
  }

  async sendMessage(message) {
    const response = await this.fetchMessage(message);
    if (response !== undefined) {
      // EventBusSingleton.emit("message:" + response.groupId, response);
      EventBusSingleton.emit("relation");

      return response;
    }
  }

  async restartEvaluation(groupId, sourceId) {
    const data = {};
    data.message = {
      body: "RESTART",
      sourceId,
      groupId,
      type: "CHATBOT_COMMAND",
    };
    const response = await this.fetchMessage(data);
    if (response !== undefined) {
      return response;
    }
  }

  async readMessage(message = {}, isRead = false) {
    const data = {};
    data.message = this.markAsReadMessage(message, isRead);

    return await this.fetchMessage(data);
  }

  async readMessages(messages = []) {
    const messageList = messages.map((message) =>
      this.markAsReadMessage(message, true),
    );
    const itemsFromServer = await this.fetch(
      0,
      0,
      _map(messages, "groupId")[0],
      {
        messages: messageList /* , onlyReadable: true, onlyVisibles: true */,
      },
    );

    for (const item of itemsFromServer) {
      this.addOrUpdateMessage(item);
    }

    return itemsFromServer;
  }

  markAsReadMessage(message, isRead) {
    const data = {
      messageId: message.messageId,
      sourceId: message.sourceId,
      destinationId: message.destinationId,
      isReceived: true,
      receivedDate: message.isReceived
        ? message.receivedDate
        : new Date().getTime(),
      isRead,
      readDate: isRead ? new Date().getTime() : null,
    };

    return data;
  }

  addMessage(message) {
    return this.dao.addMessage(message);
  }

  getLastMessageByGroupId(groupId) {
    return this.dao.getLastMessageByGroupId(groupId);
  }

  getMessageById(messageId) {
    return this.dao.getMessageById(messageId);
  }

  getUnreadMessages() {
    return this.dao.getUnreadMessages();
  }

  getMessageListByGroupId(groupId, offset, count) {
    return this.dao.getMessageListByGroupId(groupId, offset, count);
  }

  getUnreadMessagesByGroupId(groupId) {
    return this.dao.getUnreadMessagesByGroupId(groupId);
  }

  getNumberOfMessagesByGroupId(groupId) {
    return this.dao.getNumberOfMessagesByGroupId(groupId);
  }

  getExternUserGroupIdsOrderedByLastMessage(groupIds = []) {
    return this.dao.getExternUserGroupIdsOrderedByLastMessage(groupIds);
  }
}
