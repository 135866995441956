// @ts-ignore
import $ from "cash-dom";
import Modal from "modules/core/components/interface/modal/Modal";
import Toast from "modules/core/components/interface/toast/Toast";
import Loader from "modules/core/components/interface/loader/Loader";
import Vue, { ComponentOptions, nextTick } from "vue";
import EventBusSingleton from "modules/core/lib/EventBusSingleton";
import Util, { emptyFn, isNullOrEmpty } from "modules/core/lib/Util";
import { Dialog, DialogChainObject, QDialog, QSpinner } from "quasar";
import { SimpleCallback } from "modules/core/types/misc";
//import LocalizationBO from "modules/core/business/LocalizationBO";
import App from "modules/core/lib/App";

export type ModalActionItem = {
    label: string;
    action: (el: HTMLElement, component: typeof Modal) => void;
    style?: string | Record<string, any>;
};

export type ModalCustomContent = {
    padding?: boolean;
    content?: string | Element;
};

export type InstanceTypes = "modal" | "alert" | "toast";

export type UiComponent = {
    getElement(): HTMLElement | Element;
    remove(): void;
    hide(): void;
};

class Ui {
    private dismissLabelText?: string;

    private rejectLabelText?: string;

    instances: { modal: Vue[]; alert: Vue[]; toast: Vue[] } = {
        modal: [],
        alert: [],
        toast: [],
    };

    openModal?: DialogChainObject;

    private app?: App;

    public setApp(app: App) {
        this.app = app;
        this.setDismissLabelText(this.app.localeText("ok") || "");
        this.setRejectLabelText(this.app.localeText("tmk245") || "");
    }

    public setDismissLabelText(value: string) {
        this.dismissLabelText = value;
    }

    public setRejectLabelText(value: string) {
        this.rejectLabelText = value;
    }

    showImageViewer(
        url: string,
        fromElement?: HTMLElement,
        copyrightExternal?: string,
    ) {
        let instance = this.mountBaseModal(
            "",
            url,
            "image",
            [],
            true,
            () => {},
            true,
            false,
            false,
            "",
            copyrightExternal,
        );

        this.saveInstance("modal", instance);

        if (fromElement != null)
            this.transformElementFromPosition(fromElement, instance);

        return instance;
    }

    showVideoViewer(
        content: string,
        fromElement?: HTMLElement,
        canClose: boolean = true,
        fixedContent: boolean = true,
    ) {
        let instance = this.mountBaseModal(
            "",
            content,
            "video",
            [],
            canClose,
            () => {},
            false,
        );

        this.saveInstance("modal", instance);

        if (fromElement != null)
            this.transformElementFromPosition(fromElement, instance);

        return instance;
    }

    // Experimental - use with caution!!!
    transformElementFromPosition(element: HTMLElement, instance: Vue) {
        let viewportOffset = element.getBoundingClientRect();
        let top = viewportOffset.top;
        let left = viewportOffset.left;

        let $element = $(instance.$el);
        $element.css({
            width: 0,
            height: 0,
            left: left + "px",
            top: top + "px",
            transition: "all .4s cubic-bezier(0, 0, 0.67, 0.99)",
        });
        setTimeout(() => {
            $element.css({ width: "100%", height: "100%", left: 0, top: 0 });
        }, 200);
    }

    showAlertWithPromise(
        message: string,
        title?: string,
        onClose?: () => void,
        closable: boolean = true,
    ) {
        return new Promise((resolve) => {
            this.showAlert(
                message,
                title,
                () => {
                    resolve(alert);
                },
                closable,
            );
        });
    }

    showAlert(
        message?: string,
        title?: string,
        onClose?: () => void,
        closable: boolean = true,
    ) {
        /*this.dismissAll("alert");

        let actions = [].concat(additionalActions);

        let instance = this.mountBaseModal(title, message, "alert", actions, true, onClose);

        this.saveInstance("alert", instance);

        return instance;*/

        if (message) {
            this.openModal = Dialog.create({
                message,
                title:
                    (title ? title : "‏‏‎ ‎") +
                    (closable
                        ? '<i id="closeBtnAlert" class="material-icons alert-dialog__close-icon">close</i>'
                        : ""),
                html: true,
                ok: false,
                persistent: !closable,
                color: "primary",
                class: "alert-dialog",
                transitionShow: "slide-up",
                transitionHide: "slide-down",
                //inception: false,
                //position: "top"
            }).onDismiss(() => {
                if (onClose) onClose();
            });

            // EVENT TO CLOSE POPUP ON THE CROSS ICON
            nextTick(() => {
                document
                    .getElementById("closeBtnAlert")
                    ?.addEventListener("click", () => {
                        this.hideAlert();
                    });
            });
        }
    }

    showAlertWithCover(
        cover: string,
        message?: string,
        title?: string,
        onClose?: () => void,
        closable: boolean = true,
    ) {
        const parsedMsg: string =
            (cover
                ? `<img src="${cover}" style="display: block; margin:0 auto; max-height:248px;max-width: 248px" /> <br>`
                : "") + (message ? message : "");

        if (parsedMsg) {
            this.openModal = Dialog.create({
                message: parsedMsg,
                title:
                    (title ? title : "‏‏‎ ‎") +
                    (closable
                        ? '<i id="closeBtnAlert" class="material-icons alert-with-cover-dialog__close-icon">close</i>'
                        : ""),
                html: true,
                persistent: !closable,
                color: "primary",
                class: "alert-with-cover-dialog",
                ok: false,
                transitionShow: "slide-up",
                transitionHide: "slide-down",
            }).onDismiss(() => {
                if (onClose) onClose();
            });

            // EVENT TO CLOSE POPUP ON THE CROSS ICON
            nextTick(() => {
                document
                    .getElementById("closeBtnAlert")
                    ?.addEventListener("click", () => {
                        this.hideAlert();
                    });
            });
        }
    }

    hideAlert() {
        if (this.openModal) {
            this.openModal.hide();
        }
    }

    confirmation(
        message: string,
        title?: string,
        onOk: SimpleCallback = emptyFn,
        onCancel: SimpleCallback = emptyFn,
    ) {
        Dialog.create({
            title,
            message,
            focus: "ok",
            ok: {
                rounded: true,
                dense: true,
                unelevated: true,
                noCaps: true,
                label: this.app?.localeText("tmk322"),
            },
            cancel: {
                outline: true,
                dense: true,
                rounded: true,
                unelevated: true,
                noCaps: true,
                label: this.app?.localeText("tmk323"),
            },
            persistent: true,
            class: "alert-dialog",
        })
            .onOk(onOk)
            .onCancel(onCancel);
    }

    showConfirmation(
        message: string,
        title?: string,
        onSuccess: () => void = emptyFn,
        onCancel: () => void = emptyFn,
        customActions?: ModalActionItem[],
    ): Vue {
        this.dismissAll("alert");

        let actions: ModalActionItem[] =
            customActions ||
            ([
                {
                    label: this.dismissLabelText,
                    action(el, component: any) {
                        component.$destroy(); // TODO: Revisar si no es .destroy()
                        onSuccess();
                    },
                },
                {
                    label: this.rejectLabelText,
                    action(el, component: any) {
                        component.$destroy(); // TODO: Revisar si no es .destroy()
                        onCancel();
                    },
                },
            ] as ModalActionItem[]);

        let instance = this.mountBaseModal(
            title ?? "",
            message,
            "alert",
            actions,
            true,
            onCancel,
        );

        this.saveInstance("alert", instance);

        return instance;
    }

    showModal(
        title: string,
        content: string | Element | ModalCustomContent,
        actions: ModalActionItem[] = [],
        canClose: boolean = true,
        onClose: () => void = () => {},
        buttonClose: boolean = true,
    ) {
        this.dismissAll("modal");

        let instance = this.mountBaseModal(
            title,
            content,
            "modal",
            actions,
            canClose,
            onClose,
            buttonClose,
        );

        this.saveInstance("modal", instance);

        return instance;
    }

    showFixedModal(
        title: string,
        content: string | Element | ModalCustomContent,
        actions: ModalActionItem[] = [],
        canClose: boolean = true,
        onClose: () => void = () => {},
        buttonClose: boolean = true,
    ) {
        this.dismissAll("modal");

        let instance = this.mountBaseModal(
            title,
            content,
            "modal",
            actions,
            canClose,
            onClose,
            buttonClose,
            true,
        );

        this.saveInstance("modal", instance);

        return instance;
    }

    mountComponent<T extends Vue = Vue>(
        componentObject: ComponentOptions<T>,
        propsData: Record<string, any> = {},
    ): Vue {
        // @ts-ignore
        return Util.mountComp(componentObject, propsData);
    }

    mountBaseModal(
        title: string,
        content?: string | Element | ModalCustomContent,
        type: string = "alert",
        actions: ModalActionItem[] = [],
        canClose: boolean = true,
        onClose: () => void = () => {},
        buttonClose: boolean = true,
        fixedContent: boolean = false,
        bigSizeWithIcon: boolean = false,
        icon: string = "",
        copyrightText: string = "",
    ) {
        let contentNoPadding = false;

        try {
            if (
                typeof content !== "string" &&
                (content as ModalCustomContent).content !== undefined
            ) {
                if ((content as ModalCustomContent).padding === false) {
                    contentNoPadding = true;
                }

                if ((content as ModalCustomContent).content) {
                    content = (content as ModalCustomContent).content;
                }
            }
        } catch (error) {
            content = "";
        }

        let instance = this.mountComponent(Modal as ComponentOptions<Vue>, {
            type,
            title,
            content,
            actions,
            canClose,
            buttonClose,
            fixedContent,
            contentNoPadding,
            icon,
            bigSizeWithIcon,
            copyrightText,
            onClose() {
                onClose();
            },
        });

        $(instance.$el).appendTo("body");

        return instance;
    }

    saveInstance(type: InstanceTypes, instance: Vue) {
        this.instances[type].push(instance);
    }

    dismissAll(type?: InstanceTypes) {
        if (type != null) {
            let types = this.instances[type];
            for (let index in types) {
                types[index].$destroy();
            }
        } else {
            for (let instanceType of this.instances.alert)
                instanceType.$destroy();
            for (let instanceType of this.instances.modal)
                instanceType.$destroy();
            for (let instanceType of this.instances.toast)
                instanceType.$destroy();
        }
    }

    showToast(
        text: string,
        delay: number = 4000,
        onDismiss: () => void = () => {},
    ) {
        let instance = this.mountComponent(Toast as ComponentOptions<Vue>, {
            text,
            delay,
            onDismiss,
        });

        let $element = $(instance.$el).appendTo("body");

        this.saveInstance("toast", instance);

        return $element;
    }

    appendPreloaderTo(
        parentElement: HTMLElement | Element,
        delaySpinner: boolean = false,
        boxed: boolean = false,
        size: number = 35,
        description?: string,
    ): UiComponent {
        let instance = this.mountComponent(Loader as ComponentOptions<Vue>, {
            overlayed: true,
            size,
            delaySpinner,
            boxed,
            description,
        });

        parentElement.appendChild(instance.$el);

        return {
            getElement() {
                return parentElement;
            },
            remove() {
                instance.$el.remove();
            },
            hide() {
                instance.$el.remove();
            },
        };
    }

    onElementVisible(
        target: HTMLElement,
        callback: (change: IntersectionObserverEntry) => void = () => {},
        callOnlyOnce: boolean = false,
        threshold: number = 1.0,
    ) {
        let called = false;

        if ("IntersectionObserver" in window) {
            let observer = new IntersectionObserver(
                (changes, observer) => {
                    changes.forEach((change) => {
                        if (
                            change.intersectionRatio > 0 &&
                            change.target == target
                        ) {
                            if (!callOnlyOnce || (callOnlyOnce && !called)) {
                                callback(change);

                                called = true;
                            }
                        }
                    });
                },
                {
                    root: null, // relative to document viewport
                    rootMargin: "0px", // margin around root. Values are similar to css property. Unitless values not allowed
                    threshold: threshold, // visible amount of item shown in relation to root
                },
            );
            if (typeof target !== "undefined" && target.nodeType)
                observer.observe(target);
        } else {
            // not supported
        }
    }

    isTouchDevice() {
        let prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
        let mq = function (query: string) {
            return window.matchMedia(query).matches;
        };

        // TODO: DocumentTpuch is not standar and not included in typescript
        if (
            "ontouchstart" in window ||
            // @ts-ignore
            (window.DocumentTouch && document instanceof window.DocumentTouch)
        ) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        let query = [
            "(",
            prefixes.join("touch-enabled),("),
            "heartz",
            ")",
        ].join("");

        return mq(query);
    }

    showMainProgress() {
        EventBusSingleton.emit("showProgress", true);
    }

    hideMainProgress() {
        EventBusSingleton.emit("showProgress", false);
    }

    showSpinner(
        message?: string,
        terminate: boolean = false,
    ): DialogChainObject {
        const dialog = Dialog.create({
            message,
            ok: false, //Ui.dismissLabelText,
            inception: false,
            progress: {
                // @ts-ignore
                spinner: QSpinner,
                color: "primary",
            },
        });

        if (terminate) {
            setTimeout(() => {
                dialog.hide();
            }, 4000);
        }

        return dialog;
    }
}

export default new Ui();
