import vue from "vue";
import LocalizationBO from "../../business/LocalizationBO";

export const LocaleMixin = vue.extend({
    methods: {
        localeText(
            name: string,
            params?: Record<string, any>,
            languageCode?: string,
            fallbackText?: string,
        ): string {
            let result = "";
            if (
                Object.keys(
                    this.app().generalConfig().customTmks as any,
                ).includes(name) &&
                (this.app().generalConfig().customTmks as any)[name][
                    this.app().language
                ]
            ) {
                result = (this.app().generalConfig().customTmks as any)[name][
                    this.app().language
                ];
            } else
                result = LocalizationBO.getInstance().localeText(
                    name,
                    params,
                    languageCode,
                    fallbackText,
                );
            if (this.app().generalConfig().enableShowTmksKeys) {
                return `${result} (${name})`;
            } else return result;
        },
    },
});
