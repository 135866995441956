import { Location } from "vue-router";
import ExternalAnalyticsManager from "modules/core/lib/ExternalAnalyticsManager";
import AnalyticsBO from "modules/core/business/AnalyticsBO";
import { AppEvents } from "modules/core/lib/events";
import { dispatchAnalyticsEvent } from "@mediktor-web/common/events/analytics";

export default class AnalyticsManager {
    static instance?: AnalyticsManager;

    static getInstance(): AnalyticsManager {
        if (!AnalyticsManager.instance) {
            AnalyticsManager.instance = new this();
        }

        return AnalyticsManager.instance;
    }

    setup() {
        new AppEvents.Analytics.Initialization().emit();

        ExternalAnalyticsManager.getInstance().setup();
    }

    async sendPageView(
        route: Location,
        title: string | null = null,
        enableExternTracking: boolean = true,
    ) {
        // @ts-ignore
        // new AppEvents.Analytics.TrackPage().emit({ url: route.path, title });
        title &&
            route.path &&
            dispatchAnalyticsEvent({
                name: "page",
                payload: { title, url: route.path },
            });

        const nameRouteMap =
            (route.name && this.mapRouteEvents()[route.name]) || route.name;

        if (enableExternTracking)
            ExternalAnalyticsManager.getInstance().sendPageAnalytics(
                nameRouteMap,
                title,
            );

        await AnalyticsBO.getInstance().sendPageView(route);
    }

    async trackCustomEvents(
        nameRoute: string | null = null,
        eventAction: string = "",
        eventValue: string = "",
        enableExternTracking: boolean = true,
        extra?: string,
    ) {
        new AppEvents.Analytics.TrackCustomEvents().emit({
            // @ts-ignore
            nameRoute,
            eventAction,
            eventValue,
            extra,
        });

        nameRoute &&
            dispatchAnalyticsEvent({
                name: "tracking",
                payload: {
                    route: nameRoute,
                    action: eventAction,
                    value: eventValue,
                    extra,
                },
            });

        const nameRouteMap =
            (nameRoute && this.mapRouteEvents()[nameRoute]) || nameRoute;

        if (enableExternTracking)
            ExternalAnalyticsManager.getInstance().trackCustomEvents(
                nameRouteMap,
                eventAction,
                eventValue,
            );

        await AnalyticsBO.getInstance().sendEventAction(
            // @ts-ignore
            nameRouteMap,
            eventAction,
            eventValue,
            extra,
        );
    }

    mapRouteEvents(): Record<string, string> {
        return {
            sessionReportDownloadPdf: "/DownloadReportPDF",
            home: "/Home",
            checker: "/SectionsHome", // FOR APPS CLASSIC MODE
            chatbot: "/Chatbot",
            profile: "/UserProfile",
            register: "/Register",
            login: "/UserLogin",
            profileEdit: "/UserProfileEdit",
            profileActivity: "/Activity",
            patientDetail: "/PatientDetail",
            profileMedical: "/ProfileMedical",
            profileLogout: "/Logout",
            profileSettings: "/UserProfileSettings",
            session: "/Session",
            sessionResult: "/SessionResult",
            glossary: "/Glossary",
            glossaryDetail: "/GlossaryDetail",
            specialists: "/Specialists",
            specialties: "/Specialties",
            specialistDetail: "SpecialistDetail",
            chat: "/RoomChat",
            interactionMode: "/InteractionMode",
            categories: "/Categories",
            privacyPolicy: "/PrivacyPolicy",
            legalTerms: "/LegalTerms",
            cookiePolicy: "/cookiePolicy",
            blog: "/Blog",
            corpTestimonials: "/CorpTestimonials",
            contact: "/Contact",
            corpAwards: "/CorpAwards",
            blogDetail: "/BlogDetail",
            corpScientific: "/CorpScientific",
            corpTeam: "/CorpTeam",
            press: "/Press",
        };
    }
}
