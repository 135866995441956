import ExternUserGroupStatusEnum from "modules/chat/enums/ExternUserGroupStatusEnum";
import ExternUserGroupMemberRoleEnum from "modules/chat/enums/ExternUserGroupMemberRoleEnum";
import { Application } from "modules/core/lib/Holder";
import { ProductListResponse } from "modules/core/model/productListResponse";
import { ProductVO } from "modules/core/model/productVO";
import { ChatLineResponse } from "modules/core/model/chatLineResponse";
import { ExternUserGroupMemberVO } from "modules/core/model/externUserGroupMemberVO";
import { ExternUserGroupVO } from "modules/core/model/externUserGroupVO";
import ExternUserGroupBO from "./ExternUserGroupBO";
import ProductDAO from "../dao/ProductDAO";
import BusinessAbstract from "./BusinessAbstract";

export default class ProductBO extends BusinessAbstract {
    static instance?: ProductBO;

    dao: ProductDAO;

    constructor() {
        super();

        this.dao = new ProductDAO();
    }

    static getInstance(): ProductBO {
        if (!ProductBO.instance) {
            ProductBO.instance = new this();
        }

        return ProductBO.instance;
    }

    async fetchProductList(): Promise<ProductVO[]> {
        const response = await this.api.service<ProductListResponse>(
            "productList",
            {
                useCache: 24,
            },
        );
        if (response && response.productList) {
            const list = response.productList;

            this.dao.setItems(list);

            return list;
        }

        return [];
    }

    // FIXME: DAO needs typings
    getProductList(): ProductVO[] {
        return this.dao.getList();
    }

    getProductById(productId: string): ProductVO {
        return this.dao.getItemById(productId);
    }

    isProductUpgrade(
        chatline: ChatLineResponse,
        externUserId: string,
    ): boolean {
        if (
            !Application.getInstance().generalConfig()
                .enableProductUpgradeModal ||
            !chatline
        )
            return false;
        if (
            !chatline.priceTier ||
            chatline.priceTier / (chatline?.priceTierShown ?? 1) === 0
        )
            return false;

        const activeGroups: ExternUserGroupVO[] =
            ExternUserGroupBO.getInstance().getExternUserGroupListByGroupStatus(
                ExternUserGroupStatusEnum.OPEN.value,
            );
        const isActiveGroupExists = activeGroups.filter(
            (group: ExternUserGroupVO) => {
                return group.externUserGroupMembers?.filter(
                    (member: ExternUserGroupMemberVO) => {
                        return (
                            member.externUserId === externUserId &&
                            member.userRole ===
                                ExternUserGroupMemberRoleEnum.ADMIN.value
                        );
                    },
                ).length;
            },
        );

        return isActiveGroupExists.length > 0;
    }
}
