import eventBus from "modules/core/lib/EventBusSingleton";

import RelationsBO from "modules/core/business/RelationsBO";
import ExternUserGroupBO from "modules/core/business/ExternUserGroupBO";
import MessagesBO from "modules/core/business/MessagesBO";
import Socket from "modules/chat/lib/Socket";
import EventBusSingleton from "../../core/lib/EventBusSingleton";
import { dispatchPublicEvent } from "@mediktor-web/common/events/public";

class SocketManager {
  constructor() {
    Socket.getInstance().onNewMessage((eventName, payload) => {
      switch (eventName) {
        case "message":
          this.message("message", payload);
          break;
        case "relation":
          this.relation("relation", payload);
          break;
        case "externUserStatus":
          this.externUserStatus("externUserStatus", payload);
          break;
        case "externUserGroupStatus":
          this.externUserGroupStatus("externUserGroupStatus", payload);
          break;
        case "connectionOnline":
          this.manageStateOfConnection("connectionOnline", payload);
          break;
        case "askDialRtc":
          this.askDialRtc("askDialRtc", payload);
          break;
        case "pickUpRtc":
          this.pickUpRtc("pickUpRtc", payload);
          break;
        case "hangUpRtc":
          this.hangUpRtc("hangUpRtc", payload);
          break;
        case "chatLine":
          eventBus.emit("chatLine", payload.value);
          break;
      }
    });
  }

  async message(eventType, payload) {
    const message = MessagesBO.getInstance().addOrUpdateMessage(
      payload.value.message,
    );
    eventBus.emit(
      `${eventType}:${payload.value.message.groupId}`,
      payload.value.message,
    );
    eventBus.emit(eventType, payload.value.message);
    dispatchPublicEvent({
      name: "socket:message_received",
      payload: {
        message: JSON.stringify(payload.value.message),
        type: eventType,
        chatId: payload.value.message.groupId,
      },
    });
  }

  relation(eventType, payload) {
    ExternUserGroupBO.getInstance().addOrUpdateExternUserGroup(
      payload.value.relation,
    );
    eventBus.emit(`${eventType}:${payload.value.relation.externUserGroupId}`);
    eventBus.emit(eventType, payload.value.relation);
  }

  externUserStatus(eventType, payload) {
    if (payload.externUserId !== payload.value.externUserId) {
      ExternUserGroupBO.getInstance().setExternUserStatus(payload.value);
      eventBus.emit(
        `${eventType}:${payload.value.externUserGroupId}`,
        payload.value,
      );
    }
  }

  externUserGroupStatus(eventType, payload) {
    eventBus.emit(`${eventType}:${payload.externUserId}`, payload);
  }

  async manageStateOfConnection(eventType, payload) {
    eventBus.emit(eventType, payload);
    // if (payload) await MessagesBO.fetchUnreadMessages();
  }

  askDialRtc(eventType, payload) {
    console.log(eventType, payload);
    eventBus.emit(eventType, payload.value);
  }

  pickUpRtc(eventType, payload) {
    console.log(eventType, payload);
    eventBus.emit(eventType, payload.value);
  }

  hangUpRtc(eventType, payload) {
    console.log(eventType, payload);
    eventBus.emit(eventType, payload.value);
  }
}
export default new SocketManager();
