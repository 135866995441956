import vue from "vue";
import Util from "modules/core/lib/Util";
import Ui from "modules/core/lib/Ui";

export default vue.extend({
  data() {
    return {
      layoutBreakpoint: null,
      viewportWidth: null,
    };
  },

  methods: {
    manageBreakpoints() {
      Util.layoutBreakpoint(
        "phone",
        () => {
          this.layoutBreakpoint = "phone";
        },
        () => {
          this.layoutBreakpoint = null;
        },
      );

      Util.layoutBreakpoint(
        "tablet",
        () => {
          this.layoutBreakpoint = "tablet";
        },
        () => {
          this.layoutBreakpoint = "phone";
        },
      );

      Util.layoutBreakpoint(
        "desktop",
        () => {
          this.layoutBreakpoint = "desktop";
        },
        () => {
          this.layoutBreakpoint = "tablet";
        },
      );
      Util.layoutBreakpoint(
        "bigDesktop",
        () => {
          this.layoutBreakpoint = "bigDesktop";
        },
        () => {
          this.layoutBreakpoint = "desktop";
        },
      );
    },
    manageWindowSize() {
      if (!this.enableWindowResizeDetection()) return;

      window.onresize = (e) => {
        this.setViewportWidth();
      };
    },
    setViewportWidth() {
      this.viewportWidth = document.documentElement.clientWidth;
    },
    enableWindowResizeDetection() {
      return false;
    },
  },

  computed: {
    isPhoneLayout() {
      return this.layoutBreakpoint == "phone";
    },
    isTabletLayout() {
      return this.layoutBreakpoint == "tablet";
    },
    isDesktopLayout() {
      return (
        this.layoutBreakpoint == "desktop" ||
        this.layoutBreakpoint == "bigDesktop"
      );
    },
    isBigDesktopLayout() {
      return this.layoutBreakpoint == "bigDesktop";
    },
    isMobileLayout() {
      return this.isPhoneLayout || this.isTabletLayout;
    },
    isTouchDevice() {
      return Ui.isTouchDevice();
    },
  },

  mounted() {
    this.manageBreakpoints();
    this.manageWindowSize();
    this.setViewportWidth();
  },
});
