import AppPresets, { PresetItem } from "../lib/AppPresets";
import defaults from "./defaults";
import type { DebugCapabilities, GeneralConfig } from "../types/misc";
import EnvironmentConfig from "../lib/EnvironmentConfig";
import { mergeDeep } from "../lib/util/objectUtil";

const loadConfigFromPreset = (
    preset: PresetItem | null,
    defaults: Partial<GeneralConfig>,
): Partial<GeneralConfig> => {
    const configTmp: PresetItem["config"] = {};
    if (preset) {
        Object.entries(preset.config as object).forEach((value) => {
            configTmp[value[0]] =
                typeof value[1] === "string" && value[1].includes("{")
                    ? JSON.parse(value[1])
                    : value[1];
        });
    }

    return mergeDeep<Partial<GeneralConfig>>(defaults, preset ? configTmp : {});
};

let config = loadConfigFromPreset(
    AppPresets.getInstance().getActivePreset(),
    defaults,
);

/*
 * DEBUG CAPABILITIES
 * The following overrides are meant to be used only for development purposes
 */
if (EnvironmentConfig.getValue("DEBUG_CAPABILITIES")) {
    const capabilities: DebugCapabilities = EnvironmentConfig.getValue(
        "DEBUG_CAPABILITIES",
    ) as DebugCapabilities;

    if (capabilities.fullFeatures !== undefined) {
        config.enableSocket = true;
        config.dashboardEnabled = true;
        config.scheduleEnabled = true;
        config.enableDerivation = true;
        config.enableSounds = true;
        config.enableToggleDarkMode = true;
    }

    if (capabilities.refreshTokenExpirationTime !== undefined) {
        config.refreshTokenExpirationTime =
            capabilities.refreshTokenExpirationTime;
    }

    if (capabilities.config !== undefined) {
        config = mergeDeep(config, capabilities.config);
    }
}

// Export function that loads app presets into current config
export const loadPresets = () => {
    config = loadConfigFromPreset(
        AppPresets.getInstance().getActivePreset(),
        defaults,
    );
};

export default config as GeneralConfig;
