import _merge from "lodash/merge";
import { ExternUserPrescriptionListResponse } from "modules/core/model/externUserPrescriptionListResponse";
import { ExternUserPrescriptionVO } from "modules/core/model/externUserPrescriptionVO";
import { ExternUserPrescriptionListRequest } from "modules/core/model/externUserPrescriptionListRequest";
import GenericRemoteDAO from "./GenericRemoteDAO";

export default class PrescriptionRemoteDAO extends GenericRemoteDAO {
    async fetchExternUserPrescriptionList(
        offset: number,
        count: number,
        params: ExternUserPrescriptionListRequest = {},
    ): Promise<ExternUserPrescriptionVO[]> {
        const { externUserPrescriptionList } =
            await this.api.service<ExternUserPrescriptionListResponse>(
                "externUserPrescriptionList",
                _merge(this.basicParamsForPagination(offset, count), params),
            );

        return externUserPrescriptionList || [];
    }
}
