import "./legal.scss";
import mixins from "vue-typed-mixins";
import { PropType } from "vue";
import FlexibleIframeContent from "modules/core/components/interface/flexible-iframe-content/FlexibleIframeContent";
import LocalizationBO from "modules/core/business/LocalizationBO";
import LanguageBO from "modules/core/business/LanguageBO";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import template from "./legal.html?raw";

type ContentType = "privacyPolicy" | "legalTerms" | "cookiePolicy";

export default mixins(CoreComponentMixin).extend({
    template,
    components: {
        FlexibleIframeContent,
    },
    props: {
        type: {
            required: true,
            type: String as PropType<ContentType>,
        },
    },
    data() {
        return {
            contentText: null as string | null,
        };
    },
    computed: {
        localeName(): string {
            switch (this.type) {
                case "legalTerms":
                    return this.appGeneralConfig.legalTermsTextLocaleId;
                case "privacyPolicy":
                    return this.appGeneralConfig.privacyPolicyTextLocaleId;
                case "cookiePolicy":
                    return this.appGeneralConfig.cookiesTextLocaleId;
                default:
                    return '';
            }
        },
        dataTestId(): string | null {
            switch (this.type) {
                case "legalTerms":
                    return 'legalTermsPage';
                case "privacyPolicy":
                    return 'privacyPolicyPage';
                default:
                    return null;
            }
        },
    },
    methods: {
        async fetchText() {
            const request =
                this.localeName &&
                (await LocalizationBO.getInstance().fetchLocalizationList(
                    LanguageBO.getInstance().currentLanguageCode(),
                    false,
                    [this.localeName],
                ));

            if (request && !request.error) {
                this.contentText =
                    (request.localizationList?.[0].description &&
                        (await LocalizationBO.getInstance().parseDeeplinkingForText(
                            request.localizationList[0].description,
                            (routeName, url) => {
                                return this.$router.resolve(routeName).href;
                            },
                        ))) ||
                    null;

                this.$emit("done", this.contentText);
            }
        },
    },
    created() {
        this.withPreloader(this.fetchText);
    },
});
