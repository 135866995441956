import _find from "lodash/find";
import _filter from "lodash/filter";
import _findIndex from "lodash/findIndex";
import _slice from "lodash/slice";
import _orderBy from "lodash/orderBy";
import { ExternUserGroupVO } from "modules/core/model/externUserGroupVO";
import { ExternUserGroupMemberVO } from "modules/core/model/externUserGroupMemberVO";
import ExternuserGroupStatusEnum from "../../chat/enums/ExternUserGroupStatusEnum";
import ExternUserGroupMemberRoleEnum from "../../chat/enums/ExternUserGroupMemberRoleEnum";
import GenericDAO from "./GenericDAO";

export default class ExternUserGroupDAO extends GenericDAO<
    "externUserGroupId",
    ExternUserGroupVO
> {
    protected readonly idColumnName: "externUserGroupId" = "externUserGroupId";

    hasFilters: boolean = true;

    getExternUserGroupListByGroupStatus(groupStatus: number) {
        return _filter(this.items, { groupStatus });
    }

    isMyExternUserGroup(
        externUserGroupId: string,
        externUserId: string,
    ): boolean {
        let userRole = false;
        const item = this.getItemById(externUserGroupId);

        if (item) {
            const externUserGroupMember = _find(item.externUserGroupMembers, {
                externUserId,
            });
            if (externUserGroupMember)
                userRole =
                    externUserGroupMember.userRole !==
                    ExternUserGroupMemberRoleEnum.OBSERVER.value;
        }

        return userRole;
    }

    getGroupStatusByExternUserGroupId(
        externUserGroupId: string,
    ): number | null {
        const item = this.getItemById(externUserGroupId);
        console.log("ExternUserGroupDAO::getGroupStatusByExternUserGroup", {
            item,
        });
        return item.groupStatus ?? null;
        // if (item && "groupStatus" in item)
        //     return this.getItemById(externUserGroupId).groupStatus || null;

        // return null;
    }

    getStatusByExternUserGroupId(
        externUserGroupId: string,
    ): ExternUserGroupVO.StatusEnum | null {
        const item = this.getItemById(externUserGroupId);
        if (item && "status" in item)
            // @ts-ignore
            return this.getItemById(externUserGroupId).status;

        return null;
    }

    getLastEditedByExternUserGroupId(externUserGroupId: string): number | null {
        const item = this.getItemById(externUserGroupId);
        if (item && "lastEdited" in item)
            // @ts-ignore
            return this.getItemById(externUserGroupId).lastEdited;

        return null;
    }

    // TODO: check _findIndex
    setExternUserStatus(externUserStatus: ExternUserGroupVO) {
        const index = _findIndex(this.items, {
            externUserGroupId: externUserStatus.externUserGroupId,
        });

        if (index) {
            // @ts-ignore
            if (externUserStatus?.writingInExternUserGroupId) {
                this.items[index].status = "WRITING";
            } else if ("externUserGroupId" in externUserStatus) {
                this.items[index].status = ExternUserGroupVO.StatusEnum.OPEN;
            }
        }
    }

    setHasMyReview(externUserGroupId: string) {
        const index = _findIndex(this.items, { externUserGroupId });

        if (index) this.items[index].hasMyReview = true;
    }

    // TODO: check first argument
    addExternUserGroup(
        externUserGroup: ExternUserGroupVO,
        checkLastEdited: boolean = true,
    ): ExternUserGroupVO {
        const storedItem = this.findItemById(
            externUserGroup.externUserGroupId!,
        );

        if (storedItem === undefined) {
            this.items.push(externUserGroup);
        } else if (
            !checkLastEdited ||
            (checkLastEdited &&
                externUserGroup.lastEdited !== undefined &&
                externUserGroup.lastEdited > storedItem.lastEdited!)
        ) {
            this.updateItem(externUserGroup);
        } else {
            this.updateExternUserGroup(externUserGroup);
        }

        return externUserGroup;
    }

    updateExternUserGroup(externUserGroup: ExternUserGroupVO) {
        const index = _findIndex(this.items, {
            externUserGroupId: externUserGroup.externUserGroupId,
        });
        this.items[index] = externUserGroup;
    }

    getUserRoleByExternUserGroupIdAndExternUserId(
        externUserGroupId: string,
        externUserId: string,
    ): number {
        let userRole: number | null = null;
        const item = this.getItemById(externUserGroupId);
        if (item) {
            const externUser = _find(item.externUserGroupMembers, {
                externUserId,
            });
            userRole = externUser ? externUser.userRole ?? null : 0;
        }

        return userRole ?? 0;
    }

    getExternUserByExternUserGroupIdAndExternUserId(
        externUserGroupId: string,
        externUserId: string,
    ): ExternUserGroupMemberVO | undefined {
        const item = this.getItemById(externUserGroupId);

        if (item) {
            return _find(item.externUserGroupMembers, { externUserId });
        }

        return undefined;
    }

    getExternUserGroupMembersByExternUserGroupId(
        externUserGroupId: string,
        externUserId?: string,
    ): ExternUserGroupMemberVO[] {
        let externUserGroupMembers: ExternUserGroupMemberVO[] = [];
        const item = this.getItemById(externUserGroupId);

        if (item)
            externUserGroupMembers = _filter(
                item.externUserGroupMembers,
                (externUserGroupMember) => {
                    return externUserGroupMember.externUserId !== externUserId;
                },
            );

        return externUserGroupMembers;
    }

    hasMyReviewByExternUserGroupId(externUserGroupId: string): boolean {
        let hasMyReview = false;
        const item = this.getItemById(externUserGroupId);

        if (item)
            hasMyReview =
                item.hasMyReview !== undefined ? item.hasMyReview : false;

        return hasMyReview;
    }

    isChatbotByExternUserGroupId(externUserGroupId: string): boolean {
        let isChatbot = false;
        const item = this.getItemById(externUserGroupId);

        if (item)
            isChatbot = item.isChatbot !== undefined ? item.isChatbot : false;

        return isChatbot;
    }

    getProductIdByExternUserGroupId(externUserGroupId: string): string | null {
        let productId: string | null = null;
        const item = this.getItemById(externUserGroupId);

        if (item) productId = item.productId ?? null;

        return productId;
    }

    getExternUserGroupListByGroupStatusPaginated(
        offset: number = 0,
        count: number = Infinity,
        groupStatus: number,
    ): ExternUserGroupVO[] {
        return _slice(
            _orderBy(
                _filter(this.items, { groupStatus }),
                (item) => {
                    return item.lastEdited;
                },
                ["desc"],
            ),
            offset,
            offset + count,
        );
    }

    getListOrderedByDate(
        order: "asc" | "desc" = "desc",
        offset: number = 0,
        count: number = Infinity,
        externUserId?: string,
    ): ExternUserGroupVO[] {
        const filteredItems = _filter(
            this.items,
            (item) =>
                item.groupStatus !== ExternuserGroupStatusEnum.HIDDEN.value,
        );

        if (externUserId) {
            return _slice(
                _orderBy(
                    _filter(filteredItems, (item) =>
                        this.isMyExternUserGroup(
                            item.externUserGroupId!,
                            externUserId,
                        ),
                    ),
                    ["date"],
                    order,
                ),
                offset,
                offset + count,
            );
        }

        return _slice(
            _orderBy(filteredItems, ["date"], order),
            offset,
            offset + count,
        );
    }

    getListOrderedByDateExternUserId(
        order: "asc" | "desc" = "desc",
        offset: number = 0,
        count: number = Infinity,
        externUserId?: string,
        params: Record<string, any> = {},
    ): ExternUserGroupVO[] {
        if (externUserId) {
            let filteredItems: ExternUserGroupVO[] = [];

            if (params.isOnlyClosedExternUserGroups)
                filteredItems = this.getExternUserExternUserGroupsByGroupStatus(
                    externUserId,
                    ExternuserGroupStatusEnum.CLOSED.value,
                );
            else {
                // @ts-ignore
                filteredItems = _filter(this.items, (item) => {
                    return item.externUserGroupMembers?.some(
                        (e) => e.externUserId === externUserId,
                    );
                });
            }

            return _slice(
                _orderBy(filteredItems, ["date"], order),
                offset,
                offset + count,
            );
        }
        return [];
    }

    getExternUserExternUserGroupsByGroupStatus(
        externUserId: string,
        groupStatus: number,
    ): ExternUserGroupVO[] {
        const filtered: ExternUserGroupVO[] = [];
        this.items.forEach((item) => {
            if (
                "externUserGroupId" in item &&
                item.groupStatus === groupStatus
            ) {
                if (
                    item.externUserGroupMembers &&
                    item.externUserGroupMembers.length <= 2
                ) {
                    item.externUserGroupMembers.forEach((externUser) => {
                        if (
                            externUser.externUserId === externUserId &&
                            externUser.userRole !== 0
                        )
                            filtered.push(item);
                    });
                }
            }
        });

        return filtered;
    }

    isValidGroupStatus(status: number): boolean {
        return (
            status === ExternuserGroupStatusEnum.OPEN.value ||
            status === ExternuserGroupStatusEnum.CLOSED.value
        );
    }
}
