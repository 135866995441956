import template from "./footer.html?raw";
import CoreComponent from "modules/core/mixins/CoreComponent";
import "./footer.scss";
import UIGridCell from "modules/core/components/interface/grid/GridCell";
import UIGridRow from "modules/core/components/interface/grid/GridRow";
import LanguageSelector from "app/components/layout/language-selector/LanguageSelector";
import logoImg from "app/static/img/logo-light.png";
// import darkModeToggle from "modules/core/components/dark-mode-toggle/DarkModeToggle";
import EnvironmentConfig from "modules/core/lib/EnvironmentConfig";
import RouteGenerator from "modules/core/lib/RouteGenerator";

export default {
    template,
    mixins: [CoreComponent],

    components: {
        "language-selector": LanguageSelector, // () => import("app/components/layout/language-selector/LanguageSelector"),
        "mdk-grid-cell": UIGridCell, // () => import("modules/core/components/interface/grid/GridCell"),
        "mdk-grid-row": UIGridRow, // () => import("modules/core/components/interface/grid/GridRow"),
        // "mdk-dark-mode-toggle": darkModeToggle,
    },

    props: {
        footerIsVisible: {
            type: Boolean,
            default: true,
        },
    },

    data () {
        return {};
    },

    computed: {
        certificateIsVisible () {
            return this.$app.generalConfig().showCertificate;
        },
        certificateTittleLink () {
            return this.localeText("tmk1932");
        },
        certificateLink () {
            return RouteGenerator.getInstance().certificate();
        },
        classes () {
            return {};
        },
        legalItems () {
            return [
                {
                    title: this.localeText("tmk406"),
                    dataQaTag: "legalTermsHome",
                    route: {
                        name: this.app().routeDefinitionName("legalTerms"),
                    },
                    dataTestId: "legalTermsSection",
                },
                {
                    title: this.localeText("tmk1122"),
                    dataQaTag: "privacyConditionsHome",
                    route: {
                        name: this.app().routeDefinitionName("privacyPolicy"),
                    },
                    dataTestId: "privacyPolicySection",
                },
                {
                    title: this.localeText("access.politicaCookies"),
                    dataQaTag: "cookiePoliciesHome",
                    route: {
                        name: this.app().routeDefinitionName("cookiePolicy"),
                    },
                },
            ];
        },
        copyrightText () {
            const year = new Date().getFullYear();

            return this.localeText("tmk1158", { CURRENT_YEAR: year });
        },
        versionDescription () {
            return this.app().appVersionDescription();
        },
        logoImg () {
            return logoImg;
        },
        languageSelectorIsVisible () {
            return this.$app.generalConfig().languageSelectorPosition === "bottom";
        },
        isDarkModeToggle () {
            return this.app().generalConfig().enableToggleDarkMode;
        },
        blockCellClasses () {
            return {
                "main-footer__block-cell--full": this.isDarkModeToggle,
            };
        },
        developmentTipIsVisible () {
            return (
                (EnvironmentConfig.getValue("APP_API_ENDPOINT").includes(
                    "int.mediktor.com",
                ) ||
                    EnvironmentConfig.getValue("APP_API_ENDPOINT").includes(
                        "dev.mediktor.com",
                    )) &&
                this.$app.generalConfig().showDevelopmentTip
            );
        },
    },
};
