import Vue, { ComponentOptions, ref } from "vue";
import _includes from "lodash/includes";
import VueRouter, {
    Location,
    NavigationGuard,
    RawLocation,
    Route,
} from "vue-router";
import ConnectionManager from "modules/chat/lib/ConnectionManager";
import Util, {
    cloneRouteLocation,
    isBot,
    lastChars,
    wait,
    removeQueryStringParamFromUrl,
} from "modules/core/lib/Util";
import Api, { ApiServerError } from "modules/core/lib/Api";
import eventBus from "modules/core/lib/EventBusSingleton";
import { ExternUserVO } from "modules/core/model/externUserVO";
import ErrorResponseCodeEnum from "modules/core/enums/ErrorResponseCodeEnum";
import { AppMiddleware } from "modules/core/middleware/AppMiddleware";
import Socket from "../../chat/lib/Socket";
import Ui, { UiComponent } from "./Ui";
import LanguageBO from "../business/LanguageBO";
import LocalizationBO from "../business/LocalizationBO";
import ExternUserBO from "../business/ExternUserBO";
import TermsBO from "../business/TermsBO";
import EnvironmentConfig, { runtimeConfig } from "./EnvironmentConfig";
import LoginBO from "../business/LoginBO";
import MessagesBO from "../business/MessagesBO";
import appConfig from "../config/app";

import PaymentBO, { ServiceTypes } from "../business/PaymentBO";
import Holder, { Application } from "./Holder";
import Router from "./Router";
import VideoConferenceManager from "./VideoConferenceManager";
// import ErrorCaptureManager from "./ErrorCaptureManager";
import AppStorageManager from "./AppStorageManager";
import ProductBO from "../business/ProductBO";
import ExternUserGroupBO from "../business/ExternUserGroupBO";
import ExternUserGroupStatusEnum from "../../chat/enums/ExternUserGroupStatusEnum";
import GlobalEventManager from "./GlobalEventManager";
import PermissionProfileEnum from "../enums/PermissionProfileEnum";
import RouteGenerator from "./RouteGenerator";
import "modules/chat/lib/SocketManager";
import EventBusSingleton from "./EventBusSingleton";
import { AppQueryContentManager } from "./AppQueryContentManager";
import SoundsManager from "./SoundsManager";
import DarkModeManager from "./DarkModeManager";
import AnalyticsManager from "./AnalyticsManager";
import CookiesAlertManager from "./CookiesAlertManager";
import QuasarManager from "modules/core/lib/QuasarManager";
import ClientLinkManager from "modules/core/lib/ClientLinkManager";
import EpicAuthManager from "modules/core/lib/EpicAuthManager";
import VueManager from "modules/core/lib/VueManager";
import AuthManager from "modules/core/lib/AuthManager";
import { MutexManager } from "modules/core/lib/Mutex";
import AppPresets from "modules/core/lib/AppPresets";
import { ChatLineResponse } from "../model/chatLineResponse";
import { LoginResponse } from "../model/loginResponse";
import type {
    EnvironmentConfigAllowedValues,
    GeneralConfig,
    MultiInstanceAuthSyncData,
    RefreshTokenExpiration,
    RouteResolve,
} from "../types/misc";
import { UserPermission } from "modules/core/lib/UserPermission";
import { TierPricingVO } from "modules/core/model/tierPricingVO";

// import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import config from "modules/core/config/app";
import { contentLocales, routeLocales } from "../../../locales";
import trim from "voca/trim";
import { AppEvents } from "modules/core/lib/events";
import { LegacyToken } from "modules/core/lib/LegacyToken";
import { ServerInfoResponse } from "modules/core/model/serverInfoResponse";
import GeolocalizationManager from "modules/core/lib/GeolocalizationManager";
import Deeplinking, { AllowedActions } from "modules/core/lib/Deeplinking";
import AppBaseData = AppEvents.AppBaseData;
import { MiddlewareInterface } from "modules/core/middleware/MiddlewareInterface";
import { AnimatedHeaderMiddleware } from "modules/core/middleware/AnimatedHeaderMiddleware";
import AppState from "modules/core/lib/AppState";
import UtmHandler from "modules/core/lib/UtmHandler";
import moment from "moment";
import NavigationTrackingMiddleware from "../middleware/NavigationTrackingMiddleware";
import NavigationQueryProcessingMiddleware from "../middleware/NavigationQueryProcessingMiddleware";
import DeepLinkingManager from "./DeeplinkingManager";
import { loadPresets } from "modules/core/config/app";
import AnalyticsBO from "../business/AnalyticsBO";

import * as Sentry from "@sentry/vue";
// import { init as initSentry } from "@sentry/vue";

import {
    confirmEvent,
    requestEventsChannel,
    dispatchResponseEvent,
    confirmationEventsChannel,
} from "@mediktor-web/common/events/awaited";
import {
    publicEventsChannel,
    dispatchPublicEvent,
} from "@mediktor-web/common/events/public";

import "@mdi/font/css/materialdesignicons.min.css";
import "material-icons/iconfont/material-icons.css";
import getLocaleDateFormat from "./util/getLocaleDateFormat";
import { LocalizationListResponse } from "../model/localizationListResponse";

// setup published events logging
if (import.meta.env.DEV) {
    window.addEventListener("message", (event) => {
        // filter: vue dev tools messages
        if ((event.data.source as string)?.startsWith("vue-devtools")) return;
        // filter: react dev tools messages
        if ((event.data.source as string)?.startsWith("react-devtools")) return;
        console.info(event.data);
    });
}

export const setupPublishPublicEvents = () => {
    publicEventsChannel.on("*", (_event, data) => {
        const canPost = appConfig.enablePostMessage;
        const targetDomain = appConfig.parentDomain ?? "*";
        canPost &&
            window.parent.postMessage(
                { channel: "public", ...data },
                targetDomain,
            );
    });
};

export const setupPublishConfirmationRequestEvents = () => {
    confirmationEventsChannel.on("*", (eventName, data) => {
        const canPost =
            appConfig.enablePostMessage &&
            eventName.startsWith("confirmation_request::");
        const targetDomain = appConfig.parentDomain ?? "*";
        const payload = {
            name: eventName,
            ...(typeof data === "object" && data),
        };
        canPost &&
            window.parent.postMessage(
                { channel: "confirmation_requests", ...payload },
                targetDomain,
            );
    });
};

export const setupPublishRequestEvents = () => {
    requestEventsChannel.on("*", (event, data) => {
        const canPost =
            appConfig.enablePostMessage && event.startsWith("request:");
        const targetDomain = appConfig.parentDomain ?? "*";
        canPost &&
            window.parent.postMessage(
                { channel: "requests", ...data },
                targetDomain,
            );
    });
};

export const setupAwaitedEvents = () => {
    // channel: confirmation_requests
    !appConfig.embeddedMode &&
        confirmationEventsChannel.on("*", (name, _event) => {
            const isConfirmationRequestEvent = name.startsWith(
                "confirmation_request::",
            );

            const confirmationResponseEventName = name.replace(
                "confirmation_request::",
                "",
            ) as Parameters<typeof confirmEvent>[0];
            // const confirmationResponseEventName = name.replace(
            //     "confirmation_request::",
            //     "confirmation_response::",
            // ) as Parameters<typeof confirmEvent>[0];

            isConfirmationRequestEvent &&
                confirmEvent(confirmationResponseEventName, true);
        });

    // channel: requests
    const isConfirmableEvent = (
        name: string,
    ): name is Parameters<typeof confirmEvent>[0] =>
        name.startsWith("request:");

    !appConfig.embeddedMode &&
        requestEventsChannel.on("*", (name, _event) => {
            isConfirmableEvent(name) && confirmEvent(name, false);
        });
};

export const setupAppEvents = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (App.getInstance().appEventsReady) return;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    App.getInstance().appEventsReady = true;
    setupPublishPublicEvents();
    setupPublishConfirmationRequestEvents();
    setupPublishRequestEvents();
    setupAwaitedEvents();
};

export type TokenData = {
    externUserId?: string;
    authToken?: string;
};

export default class App {
    appEventsReady: boolean = false;

    deepLinkingManager = new DeepLinkingManager<Location>(new Deeplinking());

    socket: Socket = Socket.getInstance();

    externUserId: string | null = null;

    externUser: ExternUserVO | null = null;

    externUserLinkList: any[] = [];

    deviceId: string | null = null;

    authToken: string | null = null;

    authTokenExpirationTime: number | null = null;

    api: Api | null = null; // Holds API class

    vueApp?: Vue;

    router?: VueRouter;

    serverInfo: ServerInfoResponse | null = null;

    termsComponent: Vue | null = null;

    resolvedCurrentLanguageCode: string | null = null;

    userCredentialsSetByUrlQuery: boolean = false;

    accessCode: string | null = null;

    layoutQueue: { (): void }[] = [];

    tierPricing?: TierPricingVO;

    appId: string = Util.uniqueId();

    isChangingLanguage: boolean = false;

    languageSetFromParam: boolean = false;

    mainLoader: UiComponent | null = null;

    static instance: App;

    authRenewalBlocked: boolean = false;

    appInForeground: boolean = true; //! document.hidden;

    language: string = this.generalConfig().defaultLanguage;

    $element?: HTMLElement;

    detectedBasePath: string | null = null;

    userFatalErrorHandlerIsProcessing: boolean = false;

    broadcastChannel?: BroadcastChannel;
    // broadcastChannel?: BroadcastChannel<MultiInstanceAuthSyncData>;

    // isLeader: boolean = isBot();

    legalTermsVisible = ref({ isVisible: false });

    constructor(el?: HTMLElement) {
        this.injectInstanceToSingletons();
        // this.setupExceptionCapture();
        this.detectUserInteraction();
        this.manageUtmParams();

        Vue.prototype.$app = this;
        Vue.prototype.app = () => {
            return this;
        };

        const targetAppContainer = el || document.getElementById("app");
        targetAppContainer && this.setElementContainer(targetAppContainer);

        console.info("App instance ID:", this.appId);
        console.info("Version:", this.appVersion());

        if (
            window.location &&
            config.enableDynamicBasePath &&
            config.allowedPaths
        ) {
            const paths = trim(window.location.pathname, "/").split("/");
            if (paths[0] && config.allowedPaths.includes(paths[0])) {
                this.detectedBasePath = paths[0];
            }
        }

        this.setupPostMessaging();

        return this;
    }

    static setEnvironmentConfig(
        config: Parameters<typeof EnvironmentConfig.setConfig>[0],
    ) {
        EnvironmentConfig.setConfig(config);
    }

    confirmSyncEvent(
        event: Parameters<typeof confirmEvent>[0],
        response: boolean,
    ) {
        confirmEvent(event, response);
    }

    respondToSyncEvent(event: Parameters<typeof dispatchResponseEvent>[0]) {
        dispatchResponseEvent(event);
    }

    setAuthTokenExpirationTime(time: number) {
        this.authTokenExpirationTime = time;
    }

    isAuthTokenExpired(): boolean {
        if (!this.externUser) return true;
        return (
            !!this.authTokenExpirationTime &&
            this.authTokenExpirationTime < Date.now()
        );
    }

    appEventData(): AppBaseData {
        return {
            authToken: this.authToken!,
            deviceId: this.deviceId!,
            externUser: this.externUser!,
            language: this.language,
            darkMode: DarkModeManager.getInstance().darkMode,
        };
    }

    printDebugInfo(modal: boolean = false) {
        const str: string[] = [];
        str.push(`Env: ${window.location.origin}`);
        str.push(`Version: ${this.appVersion()}`);
        str.push(`API version: ${Api.API_VERSION}`);
        str.push(`API Endpoint: ${Api.getInstance().endpoint}`);
        str.push(`Username: ${this.externUser?.username || "(Anonymous)"}`);
        str.push(`User ID: ${this.externUser?.externUserId}`);
        str.push(`Device ID: ${this.deviceId}`);
        str.push(
            `Preset: ${JSON.stringify(
                AppPresets.getInstance().getActivePreset(),
            )}`,
        );
        str.push(`Browser: ${window.navigator.userAgent}`);

        if (modal) {
            Ui.showAlert(
                `<div style="font-size:11px;">${str.join("<br />")}</div>`,
                "Debug info",
            );
        }

        return str;
    }

    injectInstanceToSingletons() {
        const self: any = this;
        Holder.setAppInstance(self);
        AuthManager.getInstance().setApp(self);
        Api.getInstance().setApp(self);
        Socket.getInstance().setApp(self);
        GlobalEventManager.getInstance().setApp(self);
        GeolocalizationManager.getInstance().setApp(self);
        Ui.setApp(self);
    }

    static getInstance(): App {
        if (!App.instance) {
            // @ts-ignore
            App.instance = new this();
        }

        return App.instance;
    }

    async dispatchOpeningAnalyticEvent() {
        const { origin } = AppQueryContentManager.getInstance();
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get("sessionId") ?? undefined;
        const { externUserId } = this;

        const getInferredRouteName = (path?: string) => {
            return path?.split("/").at(-1)?.split("?").at(0);
        };

        await AnalyticsBO.getInstance().sendAnalyticsToServer({
            reason: "opening",
            param1: window.location.href,
            param2:
                this.router?.currentRoute?.name ??
                getInferredRouteName(this.router?.currentRoute.path),
            extra: JSON.stringify({
                origin,
                externUserId,
                sessionId,
                UTMParams: UtmHandler.getInstance().getParams(),
            }),
        });
    }

    setupLocales() {
        this.generalConfig().dateFormat = getLocaleDateFormat();
    }

    async start(loadCallback = () => {}): Promise<void> {
        this.setupSentry();
        setupAppEvents();
        this.setupLocales();
        loadPresets();

        if (!this.checkMandatoryEnvironmentVars()) {
            throw new Error(
                "Mandatory environment variables are not being set.",
            );
        }

        // Restore previous language if set.

        this.setupContainer();
        this.showMainLoader();
        await this.setupUserStorage();
        this.manageBuildIdCheck();
        await this.manageDeviceId();
        this.setupAuthStorage();
        this.checkOnDemandApiCredentials();
        this.manageAppContent();

        this.setupVueConfiguration();
        await this.tabLock("multiInstanceFirstLoad", async (lock: any) => {
            this.retrieveSessionFromStorage();
            await this.loginByUrlQuery();
            await this.manageUserData();
        });
        await this.validateConfigurationLanguages();
        await this.manageLanguageChange();
        await this.updateUserRelatedInfo(false, false);
        await this.setupApp();
        this.setupAnalytics();
        this.manageComponentEvents();
        this.manageConnection();
        this.onSocketError();
        EpicAuthManager.getInstance().manage();
        this.manageRtc();
        this.startSoundsConfig();
        this.initDarkMode();
        // await this.syncUnreadMessages(); // already in onAfterLogin
        // AuthManager.getInstance().detectStorageUpdate();
        this.multiInstanceManager();
        // this.checkCookiesAlert();

        // new AppEvents.AppLoaded().emit(this.appEventData());
        dispatchPublicEvent({ name: "app:loaded" });
        // await this.dispatchOpeningAnalyticEvent();
        // this.emitEvent("component.loaded"); // Legacy event - some clients are still using it

        // const storedLanguage = AppStorageManager.getInstance().getLanguage();
        // storedLanguage && App.getInstance().setGlobalLanguage(storedLanguage);

        loadCallback();
    }

    checkMandatoryEnvironmentVars(): boolean {
        return (
            EnvironmentConfig.getValue("APP_API_ENDPOINT") &&
            EnvironmentConfig.getValue("APP_API_USER_ID") &&
            EnvironmentConfig.getValue("APP_API_AUTH_CODE") &&
            EnvironmentConfig.getValue("APP_WS_ENDPOINT")
        );
    }

    setupPostMessaging() {
        if (this.generalConfig().enablePostMessage) {
            this.catchAllEvents((eventName, data) => {
                if (!eventName)
                    throw new Error(
                        "App::setupPostMessaging --> Invalid event, name is undefined!",
                    );
                // try {
                //     Util.postMessage({
                //         method: eventName,
                //         value: data,
                //     });
                // } catch (e) {
                //     if (e instanceof Error)
                //         console.error(
                //             "Error postMessage",
                //             eventName,
                //             e.message,
                //         );
                // }
            });
        }
    }

    multiInstanceManager() {
        if (this.broadcastChannel) {
            this.broadcastChannel.onmessage = async (
                message: MessageEvent<MultiInstanceAuthSyncData>,
            ) => {
                const info = message.data;
                if (info.senderId !== this.appId) {
                    console.log("BROADCAST: multi instance event", info);
                    if (info.type === "renewal") {
                        if (this.authToken !== info.data?.authToken) {
                            const userHasChanged =
                                this.externUserId !== info.data?.externUserId;

                            if (!info.data?.authToken)
                                throw new Error("Auth Token is not defined");
                            if (!info.data?.externUserId)
                                throw new Error(
                                    "Extenr User ID is not defined",
                                );

                            this.setUserCredentials(
                                info.data?.authToken,
                                info.data?.externUserId,
                            );

                            this.externUser = info.data?.externUser ?? null;

                            EventBusSingleton.emit("authTokenRenewed");

                            if (userHasChanged) {
                                await this.updateUserRelatedInfo(
                                    true,
                                    true,
                                    false,
                                );
                            } else {
                                if (
                                    this.socket.isConnected() &&
                                    this.socket.WebSocket
                                ) {
                                    this.socket.WebSocket.send(
                                        this.socket.authorizationTokenForServer(),
                                    );
                                }

                                this.emitEvent("userUpdate", this.externUser);
                            }
                        }
                    }
                }
            };
        }
    }

    async handleUserFatalError(serverError: ApiServerError) {
        if (!this.userFatalErrorHandlerIsProcessing) {
            this.userFatalErrorHandlerIsProcessing = true;

            console.log(
                `${serverError.code}: proceeding to handle user fatal error...`,
                AuthManager.getInstance().getStoredExternUserId(),
                this.externUserId,
            );

            const errorMessage =
                serverError.message ||
                this.localeText(`error.${serverError.message}`);
            const currentUrl = location.href;
            // const expirationEvent = new AppEvents.AuthTokenRefreshExpired();

            if (serverError.code !== ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT) {
                Ui.showAlert(
                    errorMessage,
                    undefined,
                    undefined,
                    serverError.code !==
                        ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT_NO_USER_REGENERATION,
                );
            }

            if (
                serverError.code === ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT ||
                serverError.code ===
                    ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT_REGISTERED_USER
            ) {
                await this.tabLock("userReset", async (lock?: any) => {
                    if (
                        AuthManager.getInstance().getStoredExternUserId() ===
                        this.externUserId
                    ) {
                        console.log("Reseting user...");
                        await this.resetUser();
                    } else {
                        console.log(
                            "User reseted by leader tab, updating user based o local storage...",
                        );
                    }
                });

                this.userFatalErrorHandlerIsProcessing = false;

                dispatchPublicEvent({ name: "auth:token_expired" });

                // expirationEvent.global({
                //     url: currentUrl,
                //     externUser: this.externUser ?? undefined,
                //     errorCode: serverError.code,
                //     authToken: this.authToken || undefined,
                //     externUserId: this.externUserId || undefined,
                //     deviceId: this.deviceId || undefined,
                // });

                // Backwards compatible
                EventBusSingleton.emit("newExternUserLogin", {
                    url: currentUrl,
                    externUser: this.externUser,
                } as RefreshTokenExpiration);

                // await MutexManager.getInstance().release();
            } else if (
                serverError.code ===
                ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT_NO_USER_REGENERATION
            ) {
                dispatchPublicEvent({ name: "auth:token_expired" });

                // expirationEvent.global({
                //     url: currentUrl,
                //     externUser: this.externUser || undefined,
                //     errorCode: serverError.code,
                //     authToken: this.authToken || undefined,
                //     externUserId: this.externUserId || undefined,
                //     deviceId: this.deviceId || undefined,
                // });

                // Backwards compatible
                EventBusSingleton.emit("tokenExpired", {
                    url: currentUrl,
                } as RefreshTokenExpiration);

                // await MutexManager.getInstance().release();

                throw new Error("Anonymous user generation is disabled.");
            } // }
        }
    }

    async tabLock(
        name: string,
        action: (lock?: any) => Promise<void>,
    ): Promise<any> {
        if (isBot()) {
            await action();
        } else {
            try {
                // @ts-ignore
                return await navigator.locks.request(
                    name,
                    async (lock: any) => {
                        await action(lock);
                    },
                );
            } catch (e) {
                await action();
            }
        }
    }

    async resetUser() {
        // const spinner = this.showMainLoader(EnvironmentConfig.isLocal() ? "Logging in as a new user..." : null);

        // try {
        MutexManager.getInstance().clearQueue();

        this.closeSocketConnection();

        AuthManager.getInstance().resetAuthCredentialsFromStorage();

        const response = await this.loginGuestUser(false);

        dispatchPublicEvent({ name: "user:logout_done" });

        if (response && !response.error) {
            await this.updateUserRelatedInfo(true, true, true);
            await this.broadcastUserAuthData();

            console.info("App user has been re-generated.");
        } else {
            this.hideMainLoader();

            throw new Error(
                "There was a problem trying to create an anonymous user.",
            );
        }

        // }catch(error) {
        //    console.error("resetUser error:", error);
        // }finally {
        // spinner.remove();
        // }
    }

    /* async broadcastUserAuthPing(blocked:boolean): Promise<void> {
        const channel = new BroadcastChannel<MultiInstanceAuthSyncData>(this.deviceId);

        await channel.postMessage({
            type: "blocking",
            senderId: this.appId,
            data: {
                blocked
            }
        });

        console.log("BROADCAST: blocking to other tabs/windows");
    } */

    async broadcastUserAuthData(): Promise<void> {
        if (this.broadcastChannel !== null) {
            this.broadcastChannel?.postMessage({
                type: "renewal",
                senderId: this.appId,
                data: {
                    externUserId: this.externUserId || undefined,
                    authToken: this.authToken || undefined,
                    authTokenExpirationTime:
                        this.authTokenExpirationTime || undefined,
                    externUser: this.externUser || undefined,
                    deviceId: this.deviceId || undefined,
                },
            });

            console.log("SENT BROADCAST: send auth data to other tabs/windows");
        }
    }

    async updateUserRelatedInfo(
        emitUserUpdate: boolean = false,
        reloadLayout: boolean = false,
        updateStorage: boolean = true,
    ) {
        await this.manageLanguage();
        await this.fetchMasterData();

        await AppStorageManager.getInstance().whipeMessagesStorage();
        await this.onAfterLogin(updateStorage);

        if (this.externUser) {
            if (emitUserUpdate)
                EventBusSingleton.emit("userUpdate", this.externUser);

            if (this.generalConfig().hospitalMode) {
                Api.getInstance().setHospitalPath();
            } else {
                Api.getInstance().serviceBasePath = "";
            }
        }

        if (reloadLayout) {
            this.reloadLayout();

            if (this.router && this.router.currentRoute) {
                const to = this.router.currentRoute;

                for (const middlewareInstance of this.middlewares()) {
                    await middlewareInstance.handle(to, to, (to) => {
                        if (to) this.router?.replace(to as RawLocation);
                    });
                }
            }
        }

        if (this.router) {
            await LocalizationBO.getInstance().parseLocalizationListDeeplinkings(
                (routeName: Location, url: string) => {
                    if (!this.router) throw new Error("Router is not defined");

                    return this.router?.resolve(routeName).href;
                },
            );
        }
    }

    middlewares(): MiddlewareInterface[] {
        return [
            // @ts-ignore
            NavigationTrackingMiddleware,
            // @ts-ignore
            NavigationQueryProcessingMiddleware,
            // @ts-ignore
            new AppMiddleware(this),
            // @ts-ignore
            new AnimatedHeaderMiddleware(this),
        ];
    }

    reloadLayout() {
        EventBusSingleton.emit("mainContainerKey", Util.uniqueId());
    }

    initDarkMode() {
        DarkModeManager.getInstance().initDarkMode();
    }

    checkOnDemandApiCredentials() {
        if (
            Util.urlQueryParam("apiUsername") &&
            Util.urlQueryParam("apiPassword") &&
            Util.urlQueryParam("apiUserId")
        ) {
            AppStorageManager.getInstance().cleanContentStorage();
            AuthManager.getInstance().resetAuthCredentialsFromStorage();

            const id = `${Util.urlQueryParam(
                "apiUsername",
            )}--${Util.uniqueId()}`;

            AppPresets.getInstance().save({
                id,
                env: {
                    username: Util.urlQueryParam("apiUsername") || undefined,
                    password: Util.urlQueryParam("apiPassword") || undefined,
                    userId: Util.urlQueryParam("apiUserId") || undefined,
                },
                config: {},
            });
            AppPresets.getInstance().setActive(id);

            console.info("On demand API user was forced.");
        }
    }

    async syncUnreadMessages() {
        await MessagesBO.getInstance().fetchUnreadMessages();
    }

    manageAppContent() {
        AppQueryContentManager.getInstance().manageAll();
    }

    showMainLoader(message?: string): UiComponent {
        if (this.mainLoader) {
            this.mainLoader.hide();
        }

        const spinner = Ui.appendPreloaderTo(
            this.vueApp ? this.vueApp.$el : this.$element!,
            false,
            false,
            35,
            message,
        );

        this.mainLoader = spinner;

        return spinner;
    }

    manageComponentEvents() {
        GlobalEventManager.getInstance().listen();
    }

    async setupUserStorage() {
        await AppStorageManager.getInstance().setupUserStorage(
            this.appVisibleName(),
        );
    }

    async fetchMasterData() {
        // await this.manageLanguage();

        await Promise.all([
            this.setupLocalization(),
            PaymentBO.getInstance().pullTierPricing(),
            ProductBO.getInstance().fetchProductList(),
            ExternUserGroupBO.getInstance().fetchAllGroupsByStatus(
                ExternUserGroupStatusEnum.OPEN.value,
            ),
        ]).then(() => {
            console.log("master data DONE");
        });
    }

    manageRtc() {
        VideoConferenceManager.getInstance().manageRtcEvents();
    }

    async hideMainLoader() {
        if (this.mainLoader) {
            await this.mainLoader.remove();
        }
    }

    setupAuthStorage() {
        let suffix: string = "";
        const appStorage = AppStorageManager.getInstance();

        const devicesSessionId = Util.urlQueryParam("deviceSessionId");
        if (devicesSessionId) {
            appStorage.saveDeviceSessionId(devicesSessionId);
            suffix = devicesSessionId;

            removeQueryStringParamFromUrl(["deviceSessionId"]);
        } else {
            try {
                if (
                    sessionStorage &&
                    sessionStorage.getItem("deviceSessionId")
                ) {
                    suffix = sessionStorage.getDeviceSessionId();
                }
            } catch (err) {
                console.info(
                    "[setupAuthStorage] Session Storage disabled by your browser.",
                );
            }
        }

        AuthManager.getInstance().setupAuthStorage(
            suffix,
            !this.isUserRegistered() && appConfig.autodestroyMode,
        );
    }

    // TODO: We should not create deviceId anymore as the server always give it to us
    async manageDeviceId() {
        if (Util.isNullOrEmpty(this.deviceId)) {
            const urlDeviceId = Util.urlQueryParam("deviceId");
            const existingDeviceId =
                AppStorageManager.getInstance().getDeviceId();

            if (!Util.isNullOrEmpty(urlDeviceId)) {
                // URL
                this.deviceId = urlDeviceId;
            } else if (existingDeviceId) {
                // STORAGE
                this.deviceId = existingDeviceId;
            } else {
                // RANDOM
                this.deviceId = Util.uniqueId();
            }
        }

        try {
            if (!this.deviceId) throw new Error("Device ID is not defined");

            this.broadcastChannel = new BroadcastChannel(this.deviceId);
            // new BroadcastChannel<MultiInstanceAuthSyncData>(this.deviceId);
        } catch (e) {
            this.broadcastChannel = undefined;
        }

        if (!isBot()) await this.resolveLeader();

        this.deviceId &&
            AppStorageManager.getInstance().saveDeviceId(this.deviceId);
    }

    async resolveLeader() {
        if (this.broadcastChannel) {
            // const elector = createLeaderElection(this.broadcastChannel, {
            //     fallbackInterval: 2000, // optional configuration for how often will renegotiation for leader occur
            //     responseTime: 1000, // optional configuration for how long will instances have to respond
            // });
            // elector.awaitLeadership().then(() => {
            //     console.log("This tab is leader now", elector);
            //     // this.isLeader = true;
            // });
        }
        // if(this.isLeader) console.log("This tab is leader now");
    }

    /*
     * @deprecated Please use config.resolveLanguage instead
     * Keeping this for compatibility purposes.
     */
    resolveLanguage(): boolean {
        return this.generalConfig().resolveLanguage;
    }

    async manageLanguage() {
        console.log("App::manageLanguage");
        const storedLanguage = AppStorageManager.getInstance().getLanguage();

        const { forcedLanguage } = this.generalConfig();
        // if (
        //     forcedLanguage
        // ) {
        //     await this.setGlobalLanguage(forcedLanguage);
        //     return;
        // }

        const languageBO = LanguageBO.getInstance();

        await languageBO.fetchLanguageList();

        let language = forcedLanguage || this.generalConfig().defaultLanguage;

        console.log({ storedLanguage: language });

        const langUrlParam = Util.urlQueryParam("lang");
        if (window.location.href.match(/(cmd|c)\//) && langUrlParam) {
            language = langUrlParam;
        } else if (this.generalConfig().resolveLanguage) {
            await languageBO.resolveCurrentLanguageCode();
            language = await languageBO.getResolvedCurrentLanguageCode();
        }

        console.log({ storedLanguage, langUrlParam, language });
        // if (!langUrlParam && storedLanguage) {
        //     await this.setGlobalLanguage(storedLanguage);
        //     console.info("App restored to", language);
        //     return;
        // }
        await this.setGlobalLanguage(language);

        console.info("App language set to", language);
    }

    localeList(): { contentLocales: string[]; routeLocales: string[] } {
        return { contentLocales, routeLocales };
    }

    async setupLocalization() {
        await Promise.all([
            LocalizationBO.getInstance().setupLocalizationList(
                LanguageBO.getInstance().currentLanguageCode(),
                this.generalConfig().pullFullLocalizationList
                    ? []
                    : this.localeList().contentLocales,
            ),
            LocalizationBO.getInstance().fetchLocalizationMap(
                this.localeList().routeLocales,
            ),
        ]);

        Ui.setDismissLabelText(LocalizationBO.getInstance().localeText("ok"));
        Ui.setRejectLabelText(
            LocalizationBO.getInstance().localeText("tmk245"),
        );
    }

    async manageUserData() {
        if (!this.authToken) {
            const response = await this.loginGuestUser();

            if (!response) {
                this.hideMainLoader();

                throw new Error(
                    "There was a problem trying to create an anonymous user.",
                );
            }
        }

        if (!this.externUser) {
            await this.fetchUserData();
        }

        if (this.externUser) {
            if (this.generalConfig().hospitalMode) {
                Api.getInstance().setHospitalPath();
            }
        }

        console.log("manageUserData");
    }

    appVersionDescription(): string {
        const buildId = this.appBuildId();
        const appVersionRev = this.getGeneralConfigValue("appVersionRev");

        return `v${this.appVersion()}${buildId ? ` (${buildId})` : ``}${
            appVersionRev ? ` · ${appVersionRev}` : ``
        }`;
    }

    async loginGuestUser(
        dispatch: boolean = true,
        useBasicAuth: boolean = true,
    ): Promise<LoginResponse | null> {
        let response: LoginResponse;

        // @ts-ignore
        // await navigator.locks.request("tokenRenewal", async (lock:any) => {
        response = await LoginBO.getInstance().doLogin(
            {
                authTokenRefreshExpiresIn:
                    this.generalConfig().refreshTokenExpirationTime ||
                    undefined,
            },
            dispatch,
            useBasicAuth,
        );
        // });

        console.log("Login guest user...", response);

        if (response && !response.error) {
            this.setUserCredentials(
                response.authToken!,
                response.externUser?.externUserId!,
                response.deviceId,
            );

            this.externUser = response.externUser || null;
            this.authTokenExpirationTime =
                Date.now() + response.authTokenExpiresIn! * 1000;

            console.info("Logging in guest user...", this.externUserId);

            return response;
        }

        return null;
    }

    setElementContainer(el: HTMLElement) {
        this.$element = el;
    }

    generateDynamicMetas(): boolean {
        return this.generalConfig().enableGenerateDynamicMetas;
    }

    setupVueConfiguration() {
        VueManager.getInstance().setupVueConfiguration(
            this.useRouter(),
            this.generateDynamicMetas(),
        );
    }

    useRouter(): boolean {
        return true;
    }

    async manageLanguageChange() {
        /* await LanguageBO.getInstance().resolveCurrentLanguageCode();
        console.log(LanguageBO.getInstance().currentLanguageCode(), AppStorageManager.getInstance().getLanguage());
        if(LanguageBO.getInstance().currentLanguageCode() !== AppStorageManager.getInstance().getLanguage()) {
            AppStorageManager.getInstance().cleanContentStorage();

            console.info("Storage cleaned because language has changed.");
        } */
        const urlLanguage = LanguageBO.getInstance().resolveUrlLanguage();
        const storageLanguage = AppStorageManager.getInstance().getLanguage();

        const { forcedLanguage } = this.generalConfig();
        if (forcedLanguage && storageLanguage !== forcedLanguage) {
            console.info(
                "Storage cleaned because forced language has changed.",
            );
            AppStorageManager.getInstance().cleanContentStorage();
            return;
        }

        if (
            (Router.getInstance().abstractMode &&
                storageLanguage !== this.generalConfig().defaultLanguage) ||
            (urlLanguage &&
                !Router.getInstance().abstractMode &&
                storageLanguage !== urlLanguage)
        ) {
            AppStorageManager.getInstance().cleanContentStorage();

            console.info("Storage cleaned because language has changed.");
        }
    }

    manageBuildIdCheck() {
        if (this.appBuildId()) {
            if (
                AppStorageManager.getInstance().getBuildId() !==
                EnvironmentConfig.getValue("BUILD_ID")
            ) {
                console.info("Storage cleaned because build ID changed.");
                AppStorageManager.getInstance().cleanContentStorage();

                AppStorageManager.getInstance().saveBuildId(
                    EnvironmentConfig.getValue("BUILD_ID"),
                );
            }
        }
    }

    appVisibleName(): string {
        return "Mediktor";
    }

    startSoundsConfig() {
        const soundsManager = new SoundsManager();
        soundsManager.startSoundsConfig();
    }

    closeSocketConnection() {
        if (this.socket !== null) {
            this.socket.closeConnection();
        }
    }

    async setupApp() {
        console.log("Setup APP");
        await this.setupMainAppInstance();
        this.setDocumentRtlConfiguration();
    }

    setupSentry() {
        const dsn = EnvironmentConfig.getValue("SENTRY_DSN");
        if (!dsn) {
            console.error("can't setup sentry: DSN not provided");
            return;
        }
        const getEnvironment = () => {
            const { hostname } = window.location;
            if (hostname.includes("localhost")) return "localhost";
            if (hostname.includes(".dev.")) return "development";
            if (hostname.includes(".int.")) return "integration";
            return undefined;
        };

        const { deviceType, appId, encapsulatedDeviceType } =
            this.generalConfig();

        Sentry.setTags({
            mode: import.meta.env.VITE_APP_BUILD_MODE,
            deviceType,
            appId,
            ...(encapsulatedDeviceType ? { encapsulatedDeviceType } : {}),
        });

        Sentry.init({
            Vue,
            dsn,
            environment: getEnvironment(),
            // integrations: [
            //     ...(this.router
            //         ? [
            //               new Sentry.BrowserTracing({
            //                   routingInstrumentation:
            //                       Sentry.vueRouterInstrumentation(this.router),
            //               }),
            //           ]
            //         : []),
            //     new Sentry.Replay(),
            // ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            // tracesSampleRate: 0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: [
            //     "localhost",
            //     /^https:\/\/(.*)\.mediktor\.com/,
            // ],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            // replaysSessionSampleRate: 0.1,
            // replaysOnErrorSampleRate: 1.0,
        });
        console.info(
            `Sentry: initialized, env: ${getEnvironment() ?? "production"}`,
        );
    }

    setupContainer() {
        const html = this.useRouter()
            ? `<router-view class="mediktor-app" :key="mainContainerKey"></router-view>`
            : `<div class="mediktor-app" ref="mainContainer"></div>`;

        this.$element && (this.$element.innerHTML = html);
    }

    async setDocumentRtlConfiguration() {
        console.log("setDocumentRtlConfiguration");
        await QuasarManager.getInstance().setupQuasarLang();
        /*
        if (_includes(["ar-AR"], LanguageBO.getInstance().currentLanguageCode())) {
            document.body.setAttribute("dir", "rtl");
        } else {
            document.body.removeAttribute("dir");
        }
        */
    }

    starterComponents(): Record<string, Vue> {
        return {};
    }

    setupVueRouter() {
        const lang = LanguageBO.getInstance().currentLanguageCode();
        const routes = Router.getInstance().generateRouteList(lang);

        this.router = Router.getInstance().createRouter(
            routes,
            async (to, from, next) => {
                return this.onBeforeRoute(to, from, next as any);
            },
            (to, from) => {
                // TODO: review these castings
                this.onAfterRoute(to, from);
            },
            this.detectedBasePath || undefined,
        );

        this.router?.onError((error) => {
            console.error("Router error:", error);
        });

        return this.router;
    }

    async setupMainAppInstance() {
        if (!this.router) this.setupVueRouter();
        if (!this.router) throw new Error("Router is not defined");

        VideoConferenceManager.getInstance().setRouter(this.router);
        Router.getInstance().setRouter(this.router);

        await LocalizationBO.getInstance().parseLocalizationListDeeplinkings(
            (routeName: Location, url: string) => {
                if (!this.router) throw new Error("Router is not defined");

                return this.router.resolve(routeName).href;
            },
        );

        const params: ComponentOptions<Vue> = {
            el: this.$element,
            data() {
                return {
                    app: this,
                    events: {},
                    mainContainerKey: null,
                };
            },
            created() {
                EventBusSingleton.on("mainContainerKey", (key: string) => {
                    console.log("Main container key changed:", key);
                    // TODO: this.mainContainerKey is not a property of this class!!!
                    // @ts-ignore
                    this.mainContainerKey = key;
                });
            },
        };

        if (this.useRouter() && this.router != null) {
            params.router = this.router;
        }

        this.hideMainLoader();

        this.hideMainLoader();

        this.vueApp = new Vue(params);
    }

    async fetchUserData() {
        if (!Util.isNullOrEmpty(this.externUserId)) {
            const externUser = await ExternUserBO.getInstance().fetchExternUser(
                this.externUserId || undefined,
                true,
            );

            if (externUser) {
                this.externUser = externUser;

                ExternUserBO.getInstance().setExternUser(externUser);
            }
        }
    }

    isUserRegistered(): boolean {
        if (this.externUser) {
            if (this.externUser.isRegistered !== undefined) {
                return this.externUser.isRegistered === true;
            }

            return this.externUser.username !== undefined;
        }

        return false;
    }

    isUserLoggedIn(): boolean {
        return this.isUserRegistered();
    }

    /**
     * @deprecated Please use config param instead. This method will be removed in the future.
     */
    showCookiesAlert(): boolean {
        return this.generalConfig().showCookiesAlert;
    }

    async checkCookiesAlert() {
        if (!this.generalConfig().showCookiesAlert) {
            return;
        }

        await CookiesAlertManager.getInstance().mountAndShow();
    }

    /**
     * @deprecated Please use config param instead. This method will be removed in the future.
     */
    showLegalTerms(): boolean {
        return this.generalConfig()
            .userMustAcceptLegalTerms /* && _includes(["profile", "checker", "session", "chatbot"], this.currentRouteName()) */;
    }

    showModalStatusConnection(): boolean {
        return _includes(["chatbot", "chat"], this.currentRouteName());
    }

    dismissLegalTerms() {
        // @ts-ignore
        this.legalTermsVisible.isVisible = false;
    }

    async checkServerInfo() {
        // @ts-ignore
        this.legalTermsVisible.isVisible = false;

        const response = await TermsBO.getInstance().fetchServerInfo();

        if (response && !response.error) {
            this.serverInfo = response;
            // @ts-ignore
            this.legalTermsVisible.isVisible =
                TermsBO.legalTermsExpired(this.serverInfo) &&
                this.showLegalTerms();
        }
    }

    /**
     * @deprecated
     */
    pushRoute(
        name: string,
        params = {},
        language: string | null = null,
        query = {},
    ) {
        const definition = { name, params, query };

        this.router?.push(definition);
    }

    /**
     * @deprecated
     */
    replaceRoute(
        name: string,
        params = {},
        language: string | null = null,
        query = {},
    ) {
        const definition = { name, params, query };

        this.router?.replace(definition);
    }

    /**
     * @deprecated
     */
    resolveRoute(
        name: string,
        params = {},
        language: string | null = null,
        query = {},
    ): any {
        return this.router?.resolve({ name, params, query });
    }

    /**
     * @deprecated
     */
    routeDefinitionName(name: string, language: string | null = null): string {
        return name;
    }

    /**
     * @deprecated
     */
    currentRouteName(): string | null {
        try {
            const name = this.router?.currentRoute.name;

            return name?.replace(/^[a-zA-Z\-]\-\-/, "") || null;
        } catch (e) {
            console.info("Cannot retrieve current route name.");
        }

        return null;
    }

    /**
     * @deprecated Please use event based analytics
     */
    setupAnalytics() {
        try {
            AnalyticsManager.getInstance().setup();
        } catch (error) {
            console.error("Error setting up Deprecated Analytics Manager");
        }
    }

    localeText(
        name: string,
        params: any = {},
        languageCode: string | null = null,
        localeList: string | null = null,
    ): string {
        return LocalizationBO.getInstance().localeText(
            name,
            params,
            languageCode || undefined,
            localeList || undefined,
        );
    }

    /**
     * @deprecated Please use EnvironmentConfig class instead
     */
    config(name: EnvironmentConfigAllowedValues): string | boolean {
        return EnvironmentConfig.getValue(name);
    }

    // setupExceptionCapture() {
    //     if (
    //         EnvironmentConfig.getValue("ENABLE_ERROR_REPORTING") &&
    //         EnvironmentConfig.getValue("SENTRY_DSN")
    //     ) {
    //         ErrorCaptureManager.getInstance().setup();
    //     }
    // }

    // setupUserCapture() {
    //     if (
    //         EnvironmentConfig.getValue("ENABLE_ERROR_REPORTING") &&
    //         EnvironmentConfig.getValue("SENTRY_DSN") &&
    //         this.externUser
    //     ) {
    //         ErrorCaptureManager.getInstance().captureUser(
    //             this.externUser.externUserId!,
    //             this.externUser.username!,
    //         );
    //     }
    // }

    // eslint-disable-next-line consistent-return
    async setToken(authToken?: NonNullable<TokenData["authToken"]>): Promise<void> {
        if (!authToken) {
            try {
                await this.logout(true, false);
            } catch (error) {
                console.error(error);
            }
            removeQueryStringParamFromUrl(["authToken"]);
            return AuthManager.getInstance().resetAuthCredentialsFromStorage();
        }
        await this.loginWithToken({ authToken }, false);
    }

    async login(token?: string) {
        if (token) {
            await this.loginWithToken({ authToken: token });
            return;
        }

        if (this.authToken) {
            await this.loginWithToken({
                authToken: this.authToken,
            });
            return;
        }
        throw new Error(
            "APP::login -> Token has not been not defined or provided",
        );
    }

    async removeToken() {
        try {
            if (this.authToken) {
                this.authToken && (await this.logout(true, false));
            } else {
                localStorage.removeItem("mdk-auth");
                localStorage.removeItem("mdk-mdkGuest");
            }
        } catch (error) {
            console.error(error);
        }
        !config.embeddedMode && removeQueryStringParamFromUrl(["authToken"]);
        AuthManager.getInstance().resetAuthCredentialsFromStorage();
    }

    async loginWithToken(loginData: TokenData, reuseToken = false) {
        if (!loginData.authToken) throw new Error("AuthToken must be provided");
        if (loginData.authToken === "null") {
            await this.removeToken();
            return;
        }
        // @ts-ignore
        const legacy = new LegacyToken(this);

        const credentials = await legacy.renew(
            loginData.authToken,
            // loginData.externUserId,
        );
        const externUserId = credentials.externUser?.externUserId;
        const token = reuseToken ? loginData.authToken : credentials.authToken;

        if (credentials.error || !externUserId || !token)
            throw new Error("Error renewing token");

        AuthManager.getInstance().addCredentialsToStorage(token, externUserId);

        this.setUserCredentials(token, externUserId);

        this.userCredentialsSetByUrlQuery = true;
    }

    async loginByUrlQuery() {
        const loginData: TokenData = {
            // externUserId: Util.urlQueryParam("externUserId") || undefined,
            authToken: Util.urlQueryParam("authToken") || undefined,
        };

        // let externUserId: string | undefined =
        //     Util.urlQueryParam("externUserId") || undefined;
        // let authToken: string | undefined =
        //     Util.urlQueryParam("authToken") || undefined;
        if (!loginData.authToken) return;
        if (loginData.authToken === "null") {
            this.removeToken();
            return;
        }

        await this.loginWithToken(loginData);

        removeQueryStringParamFromUrl([
            "authToken",
            // "externUserId",
            // "deviceId",
        ]);
    }

    /**
     * @deprecated Move to another class
     */
    geolocationEnabled(): boolean {
        if (Util.urlQueryParam("isGeolocationEnabled")) {
            return (
                (Util.urlQueryParam("isGeolocationEnabled") as string) === "1"
            );
        }

        return this.generalConfig().geolocationEnabled;
    }

    /* setGeolocationCoordsByQuery() {
        if (!this.geolocationEnabled() && Util.urlQueryParam("geolocationLatitude") && Util.urlQueryParam("geolocationLongitude")) {
            Api.getInstance().setCoords(parseInt(Util.urlQueryParam("geolocationLatitude")), parseInt(Util.urlQueryParam("geolocationLongitude")));
        }
    } */

    destroy() {
        console.log("Destroying app...");

        this.externUserId = null;
        this.externUser = null;
        this.authToken = null;

        this.$element && (this.$element.innerHTML = "");

        this.vueApp?.$destroy();
    }

    emitEvent(name: string, value?: any) {
        eventBus.emit(name, value);
    }

    catchEvent(name: string, callback: { (data?: any): void }) {
        eventBus.on(name, callback);
    }

    catchAllEvents(callback: (eventName: string, eventData: any) => void) {
        eventBus.onAll(callback);
    }

    /**
     * @deprecated
     */
    removeEvent(name: string) {
        if (this.vueApp != null) {
            this.vueApp.$off(name);
        }
    }

    async setGlobalLanguage(language: string) {
        this.language = language; // retrocompatibilidad

        LanguageBO.getInstance().resolvedCurrentLanguageCode = language;
        AppStorageManager.getInstance().saveLanguage(language);
        moment.locale(language);
        console.log("setGlobalLanguage:", language);
        await QuasarManager.getInstance().setupQuasarLang(language as any);
    }

    async changeAppLanguageForRoute(
        route: Route,
        language: string,
        interfaceFeedback: boolean = true,
    ) {
        this.isChangingLanguage = true;

        // if(interfaceFeedback) this.showMainLoader();

        // @ts-ignore
        const r = cloneRouteLocation(route);
        r?.query?.lang && delete r.query.lang;

        if (r && r.name?.includes("__")) {
            r.name = r.name.split("__")[0];
        }

        const newRoute = await this.translateRouteIntoNewLanguage(r, language);

        await this.changeAppLanguage(Util.convertServerLanguageToIso(language));

        await this.router?.replace(newRoute.route.fullPath);
        await this.setDocumentRtlConfiguration();

        this.reloadLayout();

        // if(interfaceFeedback) this.hideMainLoader();

        this.isChangingLanguage = false;

        console.log("Changed app language", language, "for route", route.name);
    }

    async changeAppLanguage(language: string) {
        await this.setGlobalLanguage(language);

        AppStorageManager.getInstance().cleanContentStorage();

        await this.fetchMasterData();

        const routes = Router.getInstance().generateRouteList(language);
        const newRouter = Router.getInstance().createRouter(
            routes,
            async (to, from, next) => {
                await this.onBeforeRoute(to, from, next as any);
            },
            this.onAfterRoute,
            this.detectedBasePath || undefined,
        );
        // @ts-ignore
        this.router.matcher = newRouter.matcher;

        this.router && Router.getInstance().setRouter(this.router);

        await LocalizationBO.getInstance().parseLocalizationListDeeplinkings(
            (routeName: Location, url: string) => {
                if (!this.router) throw new Error("Router is not defined");

                return this.router.resolve(routeName).href;
            },
        );

        // new AppEvents.LanguageChange().emit({ language });
        dispatchPublicEvent({
            name: "app:language_changed",
            payload: { language },
        });
    }

    onSocketError() {
        this.catchEvent("onSocketErrorTimeout", () => {
            // Ui.showAlert(this.localeText("error_web_services"), null, () => {
            // }, []);
            // TODO: timeout en una peticion.
            Ui.showToast(
                `${this.localeText("error_web_services")} (Socket error)`,
            );
        });
    }

    manageConnection() {
        this.socket.onConnected(() => {
            ConnectionManager.closeModalReconnecting();
        });
        this.socket.onDisconnected(() => {
            if (this.showModalStatusConnection())
                ConnectionManager.openModalReconnecting(
                    this.localeText("no_connection"),
                );
        });
    }

    appVersion(): string | null {
        return this.generalConfig().appVersion;
    }

    appBuildId(): string {
        return this.config("BUILD_ID") as string;
    }

    defaultLanguage(): string {
        return this.generalConfig().defaultLanguage;
    }

    async logout(logoutFromServer = true, exitRoute = false) {
        console.info("Logging user out...");
        if (
            VideoConferenceManager.getInstance()
                .currentVideoConferenceExternUserGroupId
        ) {
            VideoConferenceManager.getInstance().closeVideoConferenceComponent();
        }

        await AppStorageManager.getInstance().whipeUserStorage();

        this.closeSocketConnection();

        if (logoutFromServer) {
            try {
                await ExternUserBO.getInstance().doLogout();
            } catch (error) {
                console.log("network error:", { error });
            }
        }

        ClientLinkManager.getInstance().clearClientLinkListFromStorage();

        const guestExternUserId =
            AuthManager.getInstance().getStoredGuestExternUserId();
        const guestAuthToken =
            AuthManager.getInstance().getStoredGuestAuthToken();
        const guestAuthTokenExpirationTime =
            AuthManager.getInstance().getStoredGuestAuthTokenExpirationTime();

        // TODO: review this process
        if (
            true
            // !guestExternUserId ||
            // guestExternUserId ===
            //     AuthManager.getInstance().getStoredExternUserId()
        ) {
            console.info(
                "Guest user not found or is the same as the main user, proceeding to create new user...",
            );
            await this.resetUser();
        }

        // new AppEvents.Logout().emit(this.appEventData());
        if (this.generalConfig().embeddedMode) {
            this.router?.back();
            return;
        }
        if (exitRoute) {
            await wait(2000);
            this.router
                ?.push(RouteGenerator.getInstance().home())
                .catch((e) => {
                    // TODO: handle navigation error
                });
        }
    }

    async onAfterLogin(updateStorage: boolean = true) {
        EventBusSingleton.emit("userUpdate", this.externUser);

        if (
            updateStorage &&
            this.authToken &&
            this.externUserId &&
            this.authTokenExpirationTime
        ) {
            AuthManager.getInstance().addCredentialsToStorage(
                this.authToken,
                this.externUserId,
                this.authTokenExpirationTime,
            );
            if (!AuthManager.getInstance().getStoredGuestAuthToken()) {
                AuthManager.getInstance().addGuestCredentialsToStorage(
                    this.authToken,
                    this.externUserId,
                    this.authTokenExpirationTime,
                );
            }
        }

        this.initWebSocket();
        // this.setupUserCapture();

        await this.manageOrigin();
    }

    async manageOrigin() {
        const { origin } = AppQueryContentManager.getInstance();

        if (origin) {
            // const route: Location = { name: "origin" };
            // await AnalyticsManager.getInstance().sendPageView(route, origin);

            await ExternUserBO.getInstance().doExternUser({
                externUser: {
                    externUserId: this.externUserId || undefined,
                    extra: JSON.stringify({
                        origin,
                    }),
                },
            });
        }
    }

    initWebSocket() {
        // this.socket.setCredentials(this.authToken, this.externUserId);
        this.socket.setLanguage(
            LanguageBO.getInstance().currentLanguageCodeForApi(),
        );

        if (
            this.generalConfig().enableSocket &&
            this.appInForeground &&
            !this.generalConfig().hospitalMode
        ) {
            if (this.socket.isConnected()) {
                this.socket.closeConnection();
            }
            if (this.isUserRegistered()) {
                this.socket.startWebSocket(() => {
                    this.syncUnreadMessages();
                });
            }
        }
    }

    retrieveSessionFromStorage() {
        if (AuthManager.getInstance().hasAuthStorage()) {
            if (Util.isNullOrEmpty(this.deviceId))
                this.deviceId = AppStorageManager.getInstance().getDeviceId();
            if (Util.isNullOrEmpty(this.authToken))
                this.authToken = AuthManager.getInstance().getStoredAuthToken();
            if (Util.isNullOrEmpty(this.authTokenExpirationTime))
                this.authTokenExpirationTime =
                    AuthManager.getInstance().getStoredGuestAuthTokenExpirationTime();
            if (Util.isNullOrEmpty(this.externUserId))
                this.externUserId =
                    AuthManager.getInstance().getStoredExternUserId();
        }
    }

    setUserCredentials(
        authToken: string,
        externUserId: string,
        deviceId?: string,
    ) {
        this.authToken = authToken;
        this.externUserId = externUserId;

        if (deviceId !== undefined && !Util.isNullOrEmpty(deviceId)) {
            this.deviceId = deviceId;
        }
    }

    async translateRouteIntoNewLanguage(
        route: Location,
        language: string,
    ): Promise<RouteResolve> {
        const localizationBO = new LocalizationBO();

        if (this.generalConfig().rareLanguageFallsBackToEnglish) {
            language = "en-EN";
        }

        await localizationBO.setupLocalizationList(
            language,
            this.localeList().routeLocales,
            false,
        );

        const appRouter = new Router(localizationBO); // FIXME: Router is a singleton!
        const router = new VueRouter({
            mode: config.routerMode,
            base:
                this.detectedBasePath ||
                EnvironmentConfig.getValue("APP_BASE_PATH") ||
                config.basePath,
            routes: appRouter.generateRouteList(language),
        });

        return router.resolve({
            name: route.name,
            params: route.params,
            query: route.query,
        });
    }

    async saveLoginData(response: LoginResponse) {
        if (!response || (response && response.error)) return;

        this.closeSocketConnection();

        const expirationTime = Date.now() + response.authTokenExpiresIn! * 1000;

        this.authToken = response.authToken!;
        this.authTokenExpirationTime = expirationTime!;
        this.externUserId = response.externUser?.externUserId!;
        this.externUser = response.externUser!;

        if (this.generalConfig().hospitalMode) {
            Api.getInstance().setHospitalPath();
        }

        ExternUserBO.getInstance().setExternUser(response.externUser!);

        AuthManager.getInstance().addCredentialsToStorage(
            response.authToken!,
            response.externUser?.externUserId!,
            expirationTime,
        );

        this.setUserCredentials(
            response.authToken!,
            response.externUser?.externUserId!,
        );

        await this.onAfterLogin(false);

        try {
            if (!this.generalConfig().hospitalMode) {
                await ClientLinkManager.getInstance().manageClientLink();
            }
        } catch (err) {}

        await this.broadcastUserAuthData();
    }

    currentExternUserPermission(): number | undefined {
        return this.externUser?.permissionProfile;
    }

    currentExternUserIsPartner(): boolean {
        return (
            this.currentExternUserPermission() ===
            PermissionProfileEnum.PARTNER_EXTERNAL.value
        );
    }

    async onBeforeRoute(
        to: Route,
        from: Route,
        next: NavigationGuard,
    ): Promise<void> {
        for (const middlewareInstance of this.middlewares()) {
            const finish = await middlewareInstance.handle(to, from, next);
            if (finish) break;
        }
    }

    registeredUsersOnlyRoute(): Location {
        return RouteGenerator.getInstance().login();
    }

    currentLanguageCode(): string {
        return LanguageBO.getInstance().currentLanguageCode();
    }

    // FIXME Dear god this has to be improved and simplified using only route segment change
    async manageRoutingLanguageChange(to: Route, from: Route) {
        const toParts = trim(to.path, "/").split("/");
        const fromParts = trim(from.path, "/").split("/");

        const [supposedLanguage] = toParts;
        const [prevSupposedLanguage] = fromParts;

        const listOfLangs = LanguageBO.getInstance().languages.map((lang) =>
            lang.toLowerCase(),
        );

        if (
            to.query.lang &&
            this.language !== to.query.lang &&
            from?.name !== "cmd"
        ) {
            console.log("Setting language because lang param", to);
            await this.changeAppLanguageForRoute(to, to.query.lang as string);
        } else if (
            !this.isChangingLanguage &&
            prevSupposedLanguage &&
            listOfLangs.includes(prevSupposedLanguage) &&
            supposedLanguage !== prevSupposedLanguage &&
            listOfLangs.includes(supposedLanguage)
        ) {
            console.log(
                "Setting languange because lang path",
                supposedLanguage,
                prevSupposedLanguage,
            );
            await this.changeAppLanguageForRoute(
                to,
                LanguageBO.convertFriendlyLanguageToIso(supposedLanguage),
            );
        }
    }

    async changeGlobalLanguage(language: string) {
        const to = this.router?.currentRoute;

        to && (await this.changeAppLanguageForRoute(to, language));
    }

    manageUtmParams() {
        UtmHandler.getInstance().handleUtmParams();
    }

    detectUserInteraction() {
        const handleInteraction = () => {
            AppState.userHasInteracted = true;
        };

        document.body.addEventListener("click", handleInteraction);
        document.body.addEventListener("touchstart", handleInteraction);
    }

    isFirstNavigation = true;

    async onAfterRoute(to: Route, from: Route) {
        if (this.isFirstNavigation) {
            this.isFirstNavigation = false;
            console.log("FIRST NAVIGATION: TRIGGERING OPENING ANALYTIC EVENT", {
                to,
                from,
            });
            await this.dispatchOpeningAnalyticEvent();
        }

        Ui.hideMainProgress();

        this.manageRoutingLanguageChange(to, from);

        // TODO: For some reason, the logout service is called BEFORE analytics service even tho
        // the latter is called before in the execution timeline.
        if (
            this.authToken &&
            to.name !== RouteGenerator.getInstance().routeNameList.logout
        ) {
            console.log("sendPageView");
            // @ts-ignore
            AnalyticsManager.getInstance().sendPageView(to);
        }
    }

    async validateEmail(): Promise<Location> {
        const validationId = Util.urlQueryParam("validationId");
        if (!validationId) {
            return RouteGenerator.getInstance().home();
        }

        try {
            const service =
                await ExternUserBO.getInstance().doExternUserValidation(
                    validationId,
                );

            if (service.externUser) {
                await this.saveLoginData(service);

                Ui.showAlert(
                    LocalizationBO.getInstance().localeText(
                        "access.emailValidado",
                    ),
                );
            }
        } catch (e) {
            return RouteGenerator.getInstance().home();
        }

        return RouteGenerator.getInstance().profile();
    }

    onLayoutLoad(callback: { (): void }) {
        this.layoutQueue.push(callback);
    }

    executeLayoutQueue() {
        for (const action of this.layoutQueue) {
            action();
        }

        this.layoutQueue = [];
    }

    /**
     * @deprecated Please use generalConfig() because passing name as string can lead to undefined
     */
    getGeneralConfigValue(name: string): any {
        const generalConfig: Record<string, any> = this.generalConfig();

        if (generalConfig[name] !== undefined) {
            return generalConfig[name];
        }

        return null;
    }

    generalConfig(): GeneralConfig {
        return appConfig;
    }

    processFinalPayment(
        domElement: HTMLElement | null = null,
        paymentData: any,
        onChatLine: { (chatLine: ChatLineResponse): void } | null = null,
        successURL: string | null = null,
        errorURL: string | null = null,
        serviceType: ServiceTypes = "takeAppointment",
        serviceTypeData: { [key: string]: any } = {},
    ) {
        const paymentService = PaymentBO.getInstance();
        paymentService.setIsTest(!this.config("REDSYS_LIVE"));

        if (
            this.generalConfig().paymentGatewayType === "redsys" &&
            this.config("REDSYS_KEY")
        ) {
            paymentService.processRedsys(
                "sessionId" in paymentData ? paymentData.sessionId : null,
                paymentData.product,
                "externUser" in paymentData ? paymentData.externUser : null,
                "externUserId" in paymentData ? paymentData.externUserId : null,
                paymentData.productPrice,
                (chatLine: any) => {
                    if (onChatLine != null) {
                        onChatLine(chatLine);
                    } else {
                        this.pushRoute("chat", {}, this.language, {
                            externUserGroupId:
                                chatLine.externUserGroup.externUserGroupId,
                        });
                    }
                },
                {
                    secretKey: this.config("REDSYS_KEY"),
                    successURL:
                        successURL ||
                        this.config("APP_DOMAIN") +
                            this.resolveRoute("paymentConfirmation", {
                                externUserId:
                                    "externUser" in paymentData
                                        ? paymentData.externUser.externUserId
                                        : null,
                                productId: paymentData.product.productId,
                            }).href,
                    errorURL:
                        errorURL ||
                        `${
                            this.config("APP_DOMAIN") +
                            this.router!.currentRoute.fullPath
                        }&?error=1`,
                    merchantCode: this.config("REDSYS_MERCHANT_CODE"),
                    merchantUrl: this.config("APP_DOMAIN"),
                    notificationUrl: `${this.config(
                        "APP_API_ENDPOINT",
                    )}/uploads/Payment.Upload?userId=${this.config(
                        "APP_API_USER_ID",
                    )}`,
                },
                true,
                serviceType,
            );
        } else if (
            this.generalConfig().paymentGatewayType === "stripe" &&
            this.config("STRIPE_KEY") &&
            domElement
        ) {
            console.log("processFinalPayment::processStripe");
            paymentService.processStripe(
                domElement,
                "sessionId" in paymentData ? paymentData.sessionId : null,
                paymentData.product,
                "externUser" in paymentData ? paymentData.externUser : null,
                (chatLine: any) => {
                    // FIXME: fix typing
                    if (onChatLine != null) {
                        onChatLine(chatLine);
                    } else {
                        this.pushRoute("chat", {}, this.language, {
                            externUserGroupId:
                                chatLine.externUserGroup.externUserGroupId,
                        });
                    }
                },
                {
                    secretKey: this.config("STRIPE_KEY"),
                    merchantUrl: this.config("APP_DOMAIN"),
                },
                true,
                serviceType,
                serviceTypeData,
            );
        }
    }

    async processPayment(
        paymentData: any,
        onChatLine: { (chatLine: ChatLineResponse): void } | null = null,
        successURL: string | null = null,
        errorURL: string | null = null,
        serviceType: ServiceTypes = "chatLine",
        serviceTypeData = {},
    ) {
        if (this.isUserLoggedIn()) {
            this.processFinalPayment(
                null,
                paymentData,
                onChatLine,
                successURL,
                errorURL,
                serviceType,
                serviceTypeData,
            );
        } else {
            const selector = await import(
                "modules/user/components/selector/Selector"
            );

            await LoginBO.getInstance().showLoginModal(
                selector.default as ComponentOptions<Vue>,
                (response: LoginResponse) => {
                    this.saveLoginData(response);

                    EventBusSingleton.emit("refreshRouteComponent"); // FIXME: is this needed?

                    this.processFinalPayment(
                        null,
                        paymentData,
                        onChatLine,
                        successURL,
                        errorURL,
                        serviceType,
                        serviceTypeData,
                    );
                },
            );
        }
    }

    userTierPricing(): TierPricingVO | undefined {
        return PaymentBO.getInstance().tierPricing;
    }

    /**
     * @deprecated
     */
    isDemo() {
        return false;
    }

    registeredUsersOnly() {
        return false;
    }

    hasAccessCode() {
        return this.accessCode !== null;
    }

    setHasAccessCode(accessCode: string) {
        this.accessCode = accessCode;
    }

    isUserAdmin() {
        if (this.externUser && this.externUser.permissions) {
            // return this.externUser.permissionProfile === PermissionProfileEnum.ADMINISTRATOR.value;
            return (
                ((1 << PermissionProfileEnum.ADMINISTRATOR.value) &
                    this.externUser.permissions) >
                0
            );
        }

        return false;
    }

    environment() {
        return EnvironmentConfig.getValue("APP_ENV");
    }

    async renewAuthenticationToken(
        acquire: boolean = true,
        sendTokenViaSocket: boolean = true,
    ): Promise<void> {
        if (acquire) MutexManager.getInstance().acquire();

        EventBusSingleton.emit("authTokenRenewing");

        // @ts-ignore
        await this.tabLock("tokenRenewal", async (lock: any) => {
            let authToken: string;
            let authTokenExpirationTime: number;

            const proceed = true;

            if (
                // (AuthManager.getInstance().getStoredAuthToken() ===
                // this.authToken)
                proceed
            ) {
                const response = await LoginBO.getInstance().doLogin(
                    {
                        authTokenRefreshExpiresIn:
                            this.generalConfig().refreshTokenExpirationTime ||
                            undefined,
                    },
                    false,
                );

                console.log("Renewing token...", response);

                if (response && !response.error) {
                    authToken = response.authToken!;
                    authTokenExpirationTime =
                        Date.now() + response.authTokenExpiresIn! * 1000;

                    AuthManager.getInstance().addCredentialsToStorage(
                        authToken,
                        this.externUserId!,
                        authTokenExpirationTime,
                    );

                    if (!Application.getInstance().isUserLoggedIn()) {
                        AuthManager.getInstance().addGuestCredentialsToStorage(
                            authToken,
                            this.externUserId!,
                            authTokenExpirationTime,
                        );
                    }

                    this.authToken = authToken;
                    this.authTokenExpirationTime = authTokenExpirationTime;

                    await this.broadcastUserAuthData();

                    if (
                        sendTokenViaSocket &&
                        this.socket.isConnected() &&
                        this.socket.WebSocket
                    ) {
                        console.log(
                            "Sending token via socket... (from App)",
                            lastChars(this.authToken, 4),
                        );
                        this.socket.WebSocket.send(
                            this.socket.authorizationTokenForServer(),
                        );
                    }

                    EventBusSingleton.emit("userUpdate", this.externUser);

                    // new AppEvents.AuthTokenRenewed().emit({
                    //     authToken: this.authToken,
                    //     externUserId: this.externUserId!,
                    //     deviceId: this.deviceId!,
                    //     externUser: this.externUser!,
                    // });

                    dispatchPublicEvent({ name: "auth:token_renewed" });

                    console.info("Token renewed", lastChars(authToken, 4));
                }
            } /* else{
                authToken = AuthManager.getInstance().getStoredAuthToken();
                authTokenExpirationTime = AuthManager.getInstance().getStoredAuthTokenExpirationTime();

                console.info("Token retrieved", lastChars(authToken, 4));
            } */

            /* if(this.generalConfig().enableSocket) {
                Socket.getInstance().reconnectWebSocket(() => {
                    this.syncUnreadMessages();
                });
            } */
        });

        if (acquire) MutexManager.getInstance().release();
    }

    /*
     * @deprecated This is ported from Hospital and should be removed. Please use UserPermission class.
     */
    currentUserCanDo(permissionName: string): boolean {
        const permission = new UserPermission(this.externUser!);

        return permission.userHospitalCanDo(permissionName);
    }

    async openView(name: AllowedActions, query: Record<string, any> = {}) {
        // @ts-ignore
        if (name === "blank") {
            this.router?.push({ name: "blank" });
            return;
        }

        const location = { name, query };

        // const deepLinking = new Deeplinking(name, query);
        // const route = await deepLinking.manageRedirections();
        const route = await this.deepLinkingManager.getRedirectionForCMD({
            command: name,
            query,
        });

        this.router?.push(route ?? location);
    }

    async replaceView(name: AllowedActions, query: Record<string, any> = {}) {
        // @ts-ignore
        if (name === "blank") {
            this.router?.push({ name: "blank" });
            return;
        }

        const location = { name, query };

        // const deepLinking = new Deeplinking(name, query);
        // const route = await deepLinking.manageRedirections();
        const route = await this.deepLinkingManager.getRedirectionForCMD({
            command: name,
            query,
        });
        this.router?.replace(route ?? location);
    }

    shareLocation(locations: GeolocationCoordinates[]) {
        new AppEvents.LocationShare().emit(locations);
    }

    changeDarkMode(value: boolean) {
        DarkModeManager.getInstance().updateIsDarkMode(value);
    }

    dateMask(): string {
        return getLocaleDateFormat();
        // const language = this.currentLanguageCode();

        // if (language === "ja-JP" || language === "zh-CN") {
        //     return "YYYY/MM/DD";
        // }
        // if (
        //     language === "en-US" ||
        //     language === "en-GB" ||
        //     language === "en-EN"
        // ) {
        //     return "MM/DD/YYYY";
        // }
    }

    setGlobalStyles(styles: Record<string, string>) {
        const root = document.documentElement;
        for (const prop in styles) {
            root.style.setProperty(`--mdk-${prop}`, styles[prop]);
        }
    }

    setEnvironment(
        vars: Record<EnvironmentConfigAllowedValues, string | boolean | number>,
    ) {
        for (const prop in vars) {
            switch (prop) {
                case "APP_API_AUTH_CODE":
                case "APP_API_USER_ID":
                case "APP_API_ENDPOINT":
                case "APP_WS_ENDPOINT":
                    runtimeConfig[prop] = vars[prop];
                    break;
            }
        }
    }

    async validateConfigurationLanguages() {
        // console.info("**********************************************");
        // console.info("**********************************************");

        const { defaultLanguage, forcedLanguage } = this.generalConfig();
        const api = Api.getInstance();

        const validateLanguage = async (language: string) => {
            const validatedLanguage =
                await api.service<LocalizationListResponse>(
                    "localizationList",
                    {
                        language,
                        rowIdList: ["error.ME0"],
                    },
                );
            // console.info("returned language:", validatedLanguage.language);
            return validatedLanguage.language?.replace("_", "-") ?? language;
        };

        this.generalConfig().defaultLanguage =
            await validateLanguage(defaultLanguage);
        if (forcedLanguage) {
            this.generalConfig().forcedLanguage =
                await validateLanguage(forcedLanguage);
        }

        // console.info(
        //     "validated config languages,",
        //     this.generalConfig().defaultLanguage,
        //     this.generalConfig().forcedLanguage,
        // );
    }
}
