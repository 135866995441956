import template from "app/components/layout/layout.html?raw";
import "app/components/layout/layout.scss";
import Progress from "nprogress";
import CoreComponent from "modules/core/mixins/CoreComponent";
import _includes from "lodash/includes";
import Menu from "app/components/layout/menu/Menu";
import Footer from "app/components/layout/footer/Footer";
import LanguageSelector from "app/components/layout/language-selector/LanguageSelector";
import { AppQueryContentManager } from "modules/core/lib/AppQueryContentManager";
import { Application } from "modules/core/lib/Holder";
import AppPresets from "modules/core/lib/AppPresets";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import EnvironmentConfig from "modules/core/lib/EnvironmentConfig";
import Cookies from "modules/user/components/cookies/Cookies";
import CookiesAlertManager from "modules/core/lib/CookiesAlertManager";
import Terms from "modules/user/components/terms/Terms";
import { QBtn } from "quasar";
import { computed } from "vue";

import mixins from "vue-typed-mixins";
import { navigationState } from "modules/core/middleware/NavigationTrackingMiddleware";
import EventBusSingleton from "modules/core/lib/EventBusSingleton";
import { subscribeToPrivateEvent } from "@mediktor-web/common/events/private";

const footerVisibilityBlackList = [
  "session",
  "chatbot",
  "chat",
  "chatDetail",
  "clientLinkDetail",
  "awsLogin",
  "session",
];

export default mixins(CoreComponent).extend({
  template,
  components: {
    "header-menu": Menu, // () => import("app/components/layout/menu/Menu"),
    "footer-menu": Footer, // () => import("app/components/layout/footer/Footer"),
    "language-selector": LanguageSelector, // () => import("app/components/layout/language-selector/LanguageSelector"),
    Cookies,
    Terms,
    QBtn,
  },
  watch: {
    debuggerKeyCounter() {
      if (this.debuggerKeyCounter === 3) {
        this.debuggerKeyCounter = 0;

        this.debuggerVisible = !this.debuggerVisible;
      }
    },
    $route: {
      handler() {
        this.generalConfig = { ...this.$app.generalConfig() };
        this.getNavigationState = navigationState.history.length;
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      timeout: null,
      progressDelayTime: 0,
      layoutLandingMode: false,
      layoutBreakpoint: null,
      layoutKey: null,
      currRoute: null,
      debuggerVisible: false,
      userData: { externUserId: null, authToken: null, deviceId: null },
      debuggerKeyTimeout: null,
      debuggerKeyCounter: 0,
      avatarVisibility: true,
      avatarThumbnailImage: '/src/app/static/img/avatar-face.png',
      getNavigationState: 0,
      generalConfig: { ...this.$app.generalConfig() },
    };
  },

  provide() {
    return {
      avatarVisibility: computed(() => this.avatarVisibility),

      setVisibility: (value) => (this.avatarVisibility = value),

      headerIsVisible: computed(() => this.headerIsVisible),

        avatarThumbnail: computed(() => this.avatarThumbnailImage),

        setAvatarThumbnail: (value) => (this.avatarThumbnailImage = value)
    };
  },

  computed: {
    miniNavIcon() {
      const os = getMobileOperatingSystem();
      return os === "Android" ? "arrow_back" : "arrow_back_ios_new";
    },
    usingGlobalConfig() {
      return (
        !AppQueryContentManager.getInstance().isAppContent &&
        AppPresets.getInstance().hasActive() &&
        this.app().generalConfig().showActivePresetName
      );
    },
    globalConfigMessage() {
      return AppPresets.getInstance().message();
    },
    progressElementId() {
      return "mdk-main-layout"; // return "progress-" + Util.uniqueId();
    },
    scrollElementId() {
      return "mdk-main-scroll";
    },
    socket() {
      return this.app().socket;
    },
    classes() {
      const definition = [];

      if (this.layoutLandingMode) {
        definition.push("main-layout--landing-mode");
      }

      return definition;
    },
    headerIsVisible() {
      const blackList = ["awsLogin", "clientLinkDetail"];

      if (
        this.$route.name ===
          RouteGenerator.getInstance().routeNameList.session &&
        this.$route.query.prev !== "activity" &&
        !this.app().generalConfig().forceHeaderVisible
      ) {
        blackList.push("session");
      }

      const isIncludedInBlacklist = _includes(
        blackList,
        this.$router.currentRoute.name,
      );

      return (
        !isIncludedInBlacklist &&
        !this.shouldHideHeader &&
        this.$app.generalConfig().header.enabled
      );
    },
    miniFooterIsVisible() {
      return (
        !_includes(footerVisibilityBlackList, this.$route.name) &&
        !this.shouldHideFooter &&
        this.$app.generalConfig().footer.enabled &&
        this.$app.generalConfig().footer.mini
      );
    },
    versionDescription() {
      return this.$app.appVersionDescription();
    },
    footerIsVisible() {
      return (
        !_includes(footerVisibilityBlackList, this.$route.name) &&
        !this.shouldHideFooter &&
        this.$app.generalConfig().footer.enabled &&
        !this.$app.generalConfig().footer.mini
      );
    },
    isAppContent() {
      return (
        AppQueryContentManager.getInstance().isAppContent ||
        !Application.getInstance().generalConfig().mainVisualNavigationEnabled
      );
    },
    shouldHideHeader() {
      return (
        AppQueryContentManager.getInstance().hideHeader || this.isAppContent
      );
    },
    shouldHideFooter() {
      return (
        AppQueryContentManager.getInstance().hideFooter || this.isAppContent
      );
    },
    theUser() {
      try {
        const length = 5;

        return `
                    User ID: ${this.userData.externUserId.substr(
                      this.userData.externUserId.length - length,
                      length,
                    )}<br />
                    User name: ${
                      this.userData.externUser
                        ? this.userData.externUser.fullName
                        : "-"
                    }<br />
                    Token: ${this.userData.authToken.substr(
                      this.userData.authToken.length - length,
                      length,
                    )}<br />
                    Device ID: ${this.userData.deviceId.substr(
                      this.userData.deviceId.length - length,
                      length,
                    )}<br />
                    Token exp. time: ${
                      this.$app.generalConfig().refreshTokenExpirationTime ||
                      "Server"
                    }<br />
                    Leader: ${/*this.$app.isLeader*/ false}<br />
                    DB: ${
                      atob(
                        EnvironmentConfig.getValue("APP_API_AUTH_CODE"),
                      ).split(":")[0]
                    }<br />
                    Endpoint: ${EnvironmentConfig.getValue("APP_API_ENDPOINT")}
                `;
      } catch (e) {
        return "User info fetch error.";
      }
    },
    debuggerIsVisible() {
      return (
        this.debuggerVisible &&
        this.$app.generalConfig().enableUserDebugger &&
        EnvironmentConfig.getValue("USER_DEBUGGER")
      );
    },
    cookiesIsVisible() {
      return (
        CookiesAlertManager.getInstance().shouldBeVisible() &&
        this.$app.generalConfig().showCookiesAlert
      );
    },
    termsIsVisible() {
      // @ts-ignore
      return this.$app.legalTermsVisible.isVisible;
    },
    miniNavIsVisible() {
      // Fullscreen pages
      const blackList = [
        RouteGenerator.getInstance().routeNameList.home,
        RouteGenerator.getInstance().routeNameList.pageHomeSelector,
        RouteGenerator.getInstance().routeNameList.session,
        RouteGenerator.getInstance().routeNameList.glossary,
        RouteGenerator.getInstance().routeNameList.profileSettings,
        RouteGenerator.getInstance().routeNameList.profile,
        RouteGenerator.getInstance().routeNameList.profileActivity,
      ];

      // if (!window.trackedHistory.length) return false;
      console.log({
        minNavIsVisible: this.generalConfig.enableMiniNav,
        shouldBe: this.$app.generalConfig().enableMiniNav,
      });
      return (
        this.generalConfig.enableMiniNav &&
        (!blackList.includes(this.$route.name) ||
          (this.$route.name ===
            RouteGenerator.getInstance().routeNameList.session &&
            this.$route.query.prev === "activity"))
      );
    },
  },

  methods: {
    onTermsProceed() {},
    keyPressFn(e) {
      if (e.key === "d") {
        if (this.debuggerKeyTimeout) clearTimeout(this.debuggerKeyTimeout);

        this.debuggerKeyCounter++;

        this.debuggerKeyTimeout = setTimeout(() => {
          this.debuggerKeyCounter = 0;
        }, 500);
      }
    },
    showProgress(progress = null) {
      this.timeout = setTimeout(() => {
        if (progress != null) {
          Progress.set(progress);
        } else {
          Progress.start();
        }
      }, this.progressDelayTime);
    },
    finishProgress() {
      setTimeout(() => {
        Progress.done();
      }, 500);

      if (this.timeout) clearTimeout(this.timeout);
    },
    manageEvents() {
      this.catchEvent("showProgress", (status) => {
        if (status) {
          this.showProgress();
        } else {
          this.finishProgress();
        }
      });

      this.catchEvent("setProgressValue", (progress) => {
        this.showProgress(progress);
      });

      this.catchEvent("layoutLandingMode", (status) => {
        this.layoutLandingMode = status;
      });

      this.catchEvent("userUpdate", (externUser) => {
        this.updateAppUser();
      });

      // this.catchEvent("layoutFooterVisible", (status) => {
      //    this.footerIsVisible = status;
      // });
    },
    testResetUser() {
      if (confirm("Are you sure?")) {
        this.$app.resetUser(true);
      }
    },
    closeUserDebugger() {
      this.debuggerVisible = false;
    },
    updateAppUser() {
      this.userData = {
        externUser: this.$app.externUser,
        externUserId: this.$app.externUserId,
        authToken: this.$app.authToken,
        deviceId: this.$app.deviceId,
      };
    },
    setupDebugger() {
      document.addEventListener("keypress", this.keyPressFn, true);
    },
    onCookiesDismiss() {
      CookiesAlertManager.getInstance().dismiss();
    },
    onCookiesReject() {
      CookiesAlertManager.getInstance().reject();
    },
    goBack() {
      if (navigationState.history.length === 1) {
        EventBusSingleton.emit("navigation:back:onRoot");
      }
      this.$router.back();
    },
  },

  destroyed() {
    document.removeEventListener("keypress", this.keyPressFn, true);
  },

  mounted() {
    Progress.configure({ parent: `#${this.progressElementId}` });

    this.manageEvents();
    // this.manageLayoutLandingMode();

    this.app().executeLayoutQueue();

    this.setupDebugger();

    subscribeToPrivateEvent("app:config_changed", (config) => {
      this.generalConfig = { ...config };
    });
  },

  created() {
    this.updateAppUser();
  },
});

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {string}
 */
function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}
