import moment, { MomentInput } from "moment";
import enquire from "enquire.js";
import Vue, { ComponentOptions, PropOptions } from "vue";
import queryString from "query-string";
import _includes from "lodash/includes";
import slugify from "voca/slugify";
import _findIndex from "lodash/findIndex";
import { clone, isUndefined, omitBy } from "lodash";
import { AxiosResponse } from "axios";
import { Location, Route } from "vue-router";
import isbot from "isbot";
import { ConclusionVO } from "modules/core/model/conclusionVO";
import { PostMessageEvent, RouteLocation } from "modules/core/types/misc";
import config from "modules/core/config/app";
import EventBusSingleton from "modules/core/lib/EventBusSingleton";
import getLocaleDateFormat from "modules/core/lib/util/getLocaleDateFormat";
import EnvironmentConfig from "./EnvironmentConfig";
import { StatementVO } from "../model/statementVO";
import appConfig from "../config/app";

export default class Util {
    static isNullOrEmpty(str: any): boolean {
        return str === null || str === "" || JSON.stringify(str) == "{}";
    }

    static uniqueId(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    }

    static randomNumber(max: number): number {
        return Math.floor(Math.random() * max);
    }

    static nl2br(str: string, isXhtml: boolean = true): string {
        if (typeof str === "undefined" || str === null) {
            return "";
        }
        const breakTag =
            isXhtml || typeof isXhtml === "undefined" ? "<br />" : "<br>";

        return `${str}`.replace(
            /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
            `$1${breakTag}$2`,
        );
    }

    static getContentBodyFromHTMLString(htmlString: string): string {
        const div = document.createElement("div");
        div.innerHTML = htmlString;
        console.log((div.firstChild as HTMLAnchorElement).href);
        // return div.innerHTML;

        return div.textContent || div.innerText || "";
    }

    static documentIsFocused(): boolean {
        const iframe = document.getElementById("iframe") as HTMLIFrameElement;
        return (
            document.hasFocus() || !!iframe.contentWindow?.document.hasFocus()
        );
    }

    static browserLanguage(): string {
        // @ts-ignore // TODO: userLanguage no existe tipo en TS - mirar bien!
        const definition = navigator.language || window.navigator.userLanguage;

        return definition /* .substr(0, 2).toLowerCase() */;
    }

    static replaceStringParams(
        text: string,
        params: Record<string, any> = {},
    ): string {
        if (text != null) {
            for (const i in params) {
                text = text.split(`%${i}%`).join(params[i]);
                // text = text.replaceAll("％" + i + "％", params[i]); // TODO: esto es para chino/griego, ya que se han traducido con este caracter
            }
        }

        return text;
    }

    static propDefinition(
        type: any,
        required: boolean = false,
        defaultValue: any = null,
    ): PropOptions {
        return { type, required, default: defaultValue };
    }

    static loadScript(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const head = document.getElementsByTagName("head")[0];
            const script = document.createElement(
                "script",
            ) as HTMLScriptElement;
            const done = false; // Handle Script loading

            script.src = url;
            script.onload = /* script.onreadystatechange = */ () => {
                // Attach handlers for all browsers
                resolve();
                script.onload = null; // Handle memory leak in IE
            };
            script.onerror = () => {
                reject();
            };

            head.appendChild(script);

            return undefined; // We handle everything using the script element injection
        });
    }

    static dateDescription(
        date: MomentInput,
        dateFormat: string = config.dateFormat,
    ) {
        return moment(date).format(dateFormat);
    }

    static layoutBreakpoint(
        breakpointName: "bigDesktop" | "desktop" | "tablet" | "phone",
        match: () => void = () => {},
        unmatch: () => void = () => {},
    ): void {
        const breakpoints = {
            // based on material scss layout/_variables
            bigDesktop: 1200,
            desktop: 840,
            tablet: 480,
            phone: 0,
        };
        const width = breakpoints[breakpointName];

        enquire.register(`screen and (min-width: ${width}px)`, {
            match,
            unmatch,
        });
    }

    /**
     * @deprecated
     */
    static async mountComponent(
        module: ComponentOptions<Vue>,
        propsData: Record<string, any> = {},
        element?: HTMLElement,
    ): Promise<Vue> {
        const component = Vue.extend(module);

        const instance = new component({
            propsData,
        });
        if (!element) {
            try {
                instance.$mount();
            } catch (err) {}
        } else {
            try {
                instance.$mount(element);
            } catch (err) {}
        }

        return instance;
    }

    static mountComp(
        module: ComponentOptions<Vue>,
        propsData: Record<string, any> = {},
        element?: HTMLElement,
    ): Vue {
        const component = Vue.extend(module);

        const instance = new component({
            propsData,
        });
        if (element == null) {
            try {
                instance.$mount();
            } catch (err) {}
        } else {
            try {
                instance.$mount(element);
            } catch (err) {}
        }

        return instance;
    }

    static urlQueryParam(name: string): string | null {
        let urlParams: URLSearchParams | undefined;

        if (window.location.search) {
            urlParams = new URLSearchParams(window.location.search);
        } else if (window.location.hash && window.location.hash.match(/\?/)) {
            urlParams = new URLSearchParams(
                `?${window.location.hash.split("?")[1]}`,
            );
        }
        if (!urlParams) return null;
        return urlParams?.get(name) || null;
    }

    static reloadPage(withQuery: boolean = true) {
        if (withQuery) {
            location.reload();
        } else {
            window.location.href = window.location.href.split("?")[0];
        }
    }

    static commandUrlMatcher(): RegExp {
        return /https?\:\/\/.+\.mediktor\.com\/cmd\/([a-zA-Z0-9]+)\/\??[&#=\-a-zA-Z0-9]+/gi;
    }

    static parseCmdFromText(text: string): string | null {
        const reg = Util.commandUrlMatcher();
        const matches = text.match(reg);

        if (matches) {
            return matches[1];
        }

        return null;
    }

    static msieVersion(): number | null {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer, return version number
            return msie;
        }

        return null;
    }

    static convertServerLanguageToIso(language: string): string {
        return language.replace("_", "-");
    }

    /* static convertServerLocalesToAssocArray(locales:LocalizationVO[], filter:(item:LocalizationVO) => boolean = item => true): Record<string, string> {
        return locales.filter(filter).reduce((result, {rowId, description}) => {
            result[rowId] = description;

            return result;
        }, {});
    } */

    /**
     * @deprecated
     * @param data postMessage payload
     */
    static postMessage(data: PostMessageEvent) {
        console.info("Utils::postMessage is @deprecated");
        if (!config.enablePostMessage) {
            console.info("postMessage not enabled");
        } else {
            if (!data.method)
                throw new Error("postMessage: data.method is undefined");

            // const canPost = publicEvents.includes(
            //     data.method as (typeof publicEvents)[number],
            // );
            const canPost = false;

            const targetDomain = appConfig.parentDomain ?? "*";
            canPost && window.parent.postMessage(data, targetDomain);

            // sendMessageToWebkit(data);
        }
    }

    static fileDownloadUrl(
        params: Record<string, any> = {},
        baseDomain: string = EnvironmentConfig.getValue("APP_API_ENDPOINT"),
    ): string {
        const query = queryString.stringify(params, { encode: true });

        if (baseDomain) return `${baseDomain}/downloads/File.Download?${query}`;

        return `${EnvironmentConfig.getValue(
            "APP_DOMAIN",
        )}/downloads/File.Download?${query}`;
    }

    static currentTimestamp(): number {
        return +new Date();
    }

    static async openBrowseFile(
        accept: string | null = null,
        returnFirstFile: boolean = false,
    ): Promise<FileList | File> {
        return new Promise((resolve, reject) => {
            const input = document.createElement("input");
            // @ts-ignore
            input.setAttribute("style", "position:fixed; left:-10000px;");
            input.type = "file";
            accept && (input.accept = accept);

            input.onchange = (e) => {
                const { files } = e.target as HTMLInputElement;

                files &&
                    resolve(
                        returnFirstFile && files.length > 0 ? files[0] : files,
                    );
            };

            input.click();
        });
    }

    static async getImageData(file: Blob): Promise<string> {
        return new Response(file).text();
    }

    static getImageBase64(file: Blob): Promise<string | ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => reader.result && resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    static urltoFile(
        url: string,
        filename: string,
        mimeType: string,
    ): Promise<void | File> {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];

        return fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, { type: mimeType });
            });
    }

    static epToRT(date: number): string {
        if (date !== null) {
            const startDay = moment()
                .startOf("day")
                .valueOf(); /* start of the day 00:00 */
            if (date - startDay > 0) {
                return moment(date).format("HH:mm");
            }
            const startOfWeek =
                moment().subtract(6, "days").startOf("day").unix() * 1000;
            // startOfWeek = moment(startOfWeek).unix() * 1000;
            if (startOfWeek < date) {
                const thisDate = moment(date);
                thisDate.locale("es");

                return thisDate.format("dddd");
            }
            return moment(date).format(getLocaleDateFormat());
        }

        return "";
    }

    static getMessageTypeFromMimeType(mimeType: string): string | null {
        const typesPhoto = [
            "image/png",
            "image/jpeg",
            "image/jpeg",
            "image/jpeg",
            "image/gif",
            "image/bmp",
            "image/vnd.microsoft.icon",
            "image/tiff",
            "image/tiff",
            "image/svg+xml",
            "image/svg+xml",
        ];
        const typesVideo = [
            "video/mp4",
            "video/ogg",
            "video/webm",
            "video/quicktime",
            "video/quicktime",
        ];
        const typesAudio = [
            "audio/mpeg",
            "audio/aac",
            "audio/mp4",
            "audio/mp3",
            "audio/ogg",
            "audio/wav",
            "audio/webm",
        ];
        const typesAttachment = [
            "text/plain",
            "text/html",
            "text/html",
            "text/html",
            "text/css",
            "application/javascript",
            "application/json",
            "application/xml",
            "application/x-shockwave-flash",
            "video/x-flv",

            "application/pdf",
            "image/vnd.adobe.photoshop",
            "application/postscript",
            "application/postscript",
            "application/postscript",

            "application/zip",
            "application/x-rar-compressed",
            "application/x-msdownload",
            "application/x-msdownload",
            "application/vnd.ms-cab-compressed",

            "application/msword",
            "application/rtf",
            "application/vnd.ms-excel",
            "application/vnd.ms-powerpoint",

            "application/vnd.oasis.opendocument.text",
            "application/vnd.oasis.opendocument.spreadsheet",

            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];

        if (
            _findIndex(typesPhoto, (type) => {
                return type === mimeType;
            }) > -1
        ) {
            return "PHOTO";
        }
        if (
            _findIndex(typesVideo, (type) => {
                return type === mimeType;
            }) > -1
        ) {
            return "VIDEO";
        }
        if (
            _findIndex(typesAudio, (type) => {
                return type === mimeType;
            }) > -1
        ) {
            return "AUDIO";
        }
        if (
            _findIndex(typesAttachment, (type) => {
                return type === mimeType;
            }) > -1
        ) {
            return "ATTACHMENT";
        }

        return null;
    }

    /* static switchExtensionToThumb(url:string) {

    } */
}

export function svgToBase64(svg: string): string {
    return `data:image/svg+xml;base64,${window.btoa(svg)}`;
}

export function getFileSize(
    url: string,
    onSuccess?: (response: string) => void,
): XMLHttpRequest {
    const fileSize = "";
    const http = new XMLHttpRequest();
    http.onreadystatechange = function () {
        if (http.readyState === 4 && http.status === 200) {
            const header = http.getResponseHeader("content-length");
            if (onSuccess && header) onSuccess(header);
        }
    };
    http.open("HEAD", url, false);
    http.send(null);
    /* if (http.status === 200) {
        fileSize = http.getResponseHeader('content-length');
    } */

    return http;
}

export function getAlphabetBasedOnLang(lang: string): string[] {
    if (lang === "el-GR") {
        return "Α Β Γ Δ Ε Ζ Η Θ Ι Κ Λ Μ Ν Ξ Ο Π Ρ Σ Τ Υ Φ Χ Ψ Ω".split(" ");
    }

    return "a b c d e f g h i j k l m n o p q r s t v w x y z"
        .toUpperCase()
        .split(" ");
}

/* export function sortObjectByPropFunction(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return -1;
        }
        if (a[prop] > b[prop]) {
            return 1;
        }

        return 0;
    };
} */

// FIXME: strictly coupled to ConclusionVO - must fix!
export function sortObjectByKey(
    object: Record<string, ConclusionVO[]>,
    prop: string,
    languageCode: string,
): Record<string, ConclusionVO[] | StatementVO[]> {
    const ordered: Record<string, ConclusionVO[]> = {};

    Object.keys(object)
        .sort((a: string, b: string) =>
            a.localeCompare(b, languageCode.split("-")[0]),
        )
        .forEach(function (key) {
            const innerList = object[key];

            ordered[key] = innerList.sort(function (a: any, b: any) {
                return a[prop].localeCompare(
                    b[prop],
                    languageCode.split("-")[0],
                );
            });
        });

    return ordered;
}

// Better than voca/latinize -> does not broke anything
export function latinize(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/* export function getDatesBetween(startDate:MomentInput, stopDate:MomentInput, intervalAmount = 1, intervalUnit = "days") {
    let dateArray = moment.Moment[];
    let currentDate = moment(startDate);
    let stopDateMomented = moment(stopDate);

    while (currentDate < stopDateMomented) {
        dateArray.push(moment(currentDate));
        currentDate = moment(currentDate).add(intervalAmount, intervalUnit);
    }

    return dateArray;
} */

export function getParameterByName(name: string, url: string): string | null {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function matchUrlInString(text: string): RegExpMatchArray | null {
    return text.match(
        /https?:\/\/[a-zA-Z0-9]+\.?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    );
}

export function urlMatcherRegex(): RegExp {
    return /https?:\/\/[a-zA-Z0-9]+\.?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
}

export function getUrlQueryParamsAsObject(url: string): Record<string, string> {
    const object: Record<string, string> = {};
    const params = new URL(url).searchParams;

    for (const p of params) {
        object[p[0]] = p[1];
    }

    return object;
}

export function basicSlugify(text: string) {
    if (text !== null) {
        return (
            text
                .toString()
                .toLowerCase()
                .trim()
                .replace(/\s+/g, "-") // Replace spaces with -
                // .replace(/&/g, "-and-") // Replace & with 'and'
                // .replace(/[^\w\-]+/g, "") // Remove all non-word chars
                .replace(/\--+/g, "-") // Replace multiple - with single -
                .replace(/^-+/, "") // Trim - from start of text
                .replace(/-+$/, "")
        ); // Trim - from end of text
    }

    return "";
}

export function slugifyTextForLanguage(
    text: string,
    languageCode: string,
): string {
    const publicLanguage = languageCode.includes("_")
        ? languageCode.replace("_", "-")
        : languageCode;

    const blackList = ["zh-CN", "el-GR", "ar-AR", "ja-JP", "ko-KR"]; // TODO: cogido con pinzas!!!

    if (!_includes(blackList, publicLanguage)) {
        return slugify(text);
    }

    return text
        ? basicSlugify(
              publicLanguage === "ko-KR" ? text.normalize("NFC") : text,
          )
        : "_undef_"; // TODO: add text.toLowerCase().replace(" ", "-");
}

export function escapeHtml(unsafeString: string): string {
    return unsafeString
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export function lastChars(str: string, length: number): string {
    return str.substr(str.length - length, length);
}

export function wait(time: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, time);
    });
}

export function scrollTo(
    element: HTMLElement,
    targetY?: number,
    duration: number = 500,
) {
    const y = targetY || element.scrollHeight;

    /*     if(document.documentElement.style.scrollBehavior !== undefined) {
        element.scrollTop = y;

        return;
    } */

    const initialY = element.scrollTop;
    const baseY = (initialY + y) * 0.5;
    const difference = initialY - baseY;
    const startTime = performance.now();

    function step() {
        let normalizedTime = (performance.now() - startTime) / duration;
        if (normalizedTime > 1) normalizedTime = 1;

        element.scrollTo(
            0,
            baseY + difference * Math.cos(normalizedTime * Math.PI),
        );
        if (normalizedTime < 1) window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);
}

export function getAgeFromDate(date: number): number {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export function dateIsFuture(date: MomentInput) {
    const dateToBeCompared = moment(date);

    return dateToBeCompared.isAfter(moment());
}

export function objectToQueryString(
    data: Record<string, any>,
    includeSign: boolean = true,
): string {
    const obj = omitBy(data, isUndefined);

    return (
        (includeSign ? "?" : "") +
        Object.keys(obj)
            .reduce(function (a, k) {
                a.push(`${k}=${encodeURIComponent(obj[k])}`);

                return a;
            }, [] as string[])
            .join("&")
    );
}
export function toDecimals(
    number: number = 0,
    numberOfDecimals: number | null = null,
): number {
    const decimals: number =
        numberOfDecimals || config.maps.mapLocationPrecisionDecimals;

    return Number(number.toFixed(decimals));
}

export function getAxiosResponseFromError(error: any): AxiosResponse {
    const { response } = error;

    return response as AxiosResponse;
}

export function globalEvent(method: string, data?: any) {
    EventBusSingleton.emit(method, data);
    Util.postMessage({
        method,
        value: data,
    });
}

export function moveElementInArray(array: any[], from: number, to: number) {
    if (to === from) return array;

    const target = array[from];
    const increment = to < from ? -1 : 1;

    for (let k = from; k != to; k += increment) {
        array[k] = array[k + increment];
    }
    array[to] = target;

    return array;
}

export const { isNullOrEmpty } = Util;

export function isUUID(str: string): boolean {
    const regexExp =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str); // true
}

export function removeQueryStringParamFromUrl(params: string[]) {
    const url = new URL(location.href);

    if (config.routerMode === "history") {
        for (const param of params) {
            url.searchParams.delete(param);
        }

        window.history.pushState({ path: url.toString() }, "", url.toString());
    } else if (config.routerMode === "hash") {
        const fakeUrl = url.origin + url.hash.replace(/^#/, "");
        const newUrl = new URL(fakeUrl.toString());

        for (const param of params) {
            newUrl.searchParams.delete(param);
        }

        window.history.pushState(
            null,
            "",
            `#${newUrl.pathname}${newUrl.search}`,
        );
    }
}

export function cloneRouteLocation(location: Route): Location {
    return {
        name: clone(location.name) ?? undefined,
        params: clone(location.params),
        query: clone(location.query),
    };
}

export function isBot() {
    return isbot(navigator.userAgent);
}

export const { dateDescription } = Util;

export const emptyFn = () => {};

export function convertDefaultFormatDateToUTC(
    input: MomentInput,
    zeroTime: boolean = false,
) {
    const m = moment.utc(input, config.dateFormat, true);

    if (zeroTime) {
        m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    return m.valueOf();
}

export function sendMessageToWebkit(
    data: PostMessageEvent,
    handler: string = "mdk",
) {
    console.log("Webkit msg", handler, data);
    if (window.webkit !== undefined) {
        window.webkit.messageHandlers[handler].postMessage(data);
    }
}

export function convertRouteToLocation(route: Route): RouteLocation {
    return {
        name: route.name || undefined,
        path: route.path,
        query: route.query,
        params: route.params,
        fullPath: route.fullPath,
    };
}

export function convertTimeToLegibleTime(milliseconds: number): string {
    const seconds = Math.floor(milliseconds / 1000);

    if (seconds < 60) {
        return `${seconds}s`;
    }

    const days = Math.floor(moment.duration(milliseconds).asDays());
    const hours = Math.abs(moment.duration(milliseconds).hours());
    const min = Math.abs(moment.duration(milliseconds).minutes());

    if (days === 0 && hours === 0 && min <= 59) return `${min}m`;
    if (days === 0 && hours <= 24) return `${hours}h, ${min}m`;
    return `${days}d, ${hours >= 1 ? `${hours}h, ` : ""}${min}m`;
}
