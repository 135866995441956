import _merge from "lodash/merge";
import _includes from "lodash/includes";
import VueRouter, { Location, Route } from "vue-router";
import { NewSessionResponse } from "modules/core/model/newSessionResponse";
import { NewSessionRequest } from "modules/core/model/newSessionRequest";
import { SessionInfoRequest } from "modules/core/model/sessionInfoRequest";
import { SessionInfoResponse } from "modules/core/model/sessionInfoResponse";
import { SessionLinkRequest } from "modules/core/model/sessionLinkRequest";
import { ValuationResponse } from "modules/core/model/valuationResponse";
import { ValuationRequest } from "modules/core/model/valuationRequest";
import { AnswerStatementRequest } from "modules/core/model/answerStatementRequest";
import { AnswerStatementResponse } from "modules/core/model/answerStatementResponse";
import { RevertStatementRequest } from "modules/core/model/revertStatementRequest";
import { StatementResponseVO } from "modules/core/model/statementResponseVO";
import { StatementResponseVOReq } from "modules/core/model/statementResponseVOReq";
import { SessionVO } from "modules/core/model/sessionVO";
import { StatementVO } from "modules/core/model/statementVO";
import { SessionConclusionVO } from "modules/core/model/sessionConclusionVO";
import { SessionExternUserGroupListResponse } from "modules/core/model/sessionExternUserGroupListResponse";
import { SessionExternUserGroupListRequest } from "modules/core/model/sessionExternUserGroupListRequest";
import { objectToQueryString } from "modules/core/lib/Util";
import { SessionInfoHtmlRequest } from "modules/core/model/sessionInfoHtmlRequest";
import { GenericResponse } from "modules/core/model/genericResponse";
import app from "modules/core/config/app";
import { SessionEventListResponse } from "modules/core/model/sessionEventListResponse";
import { SessionEventListRequest } from "modules/core/model/sessionEventListRequest";
import { SessionEventCreateRequest } from "modules/core/model/sessionEventCreateRequest";
import { SessionEventResponse } from "modules/core/model/sessionEventResponse";
import { SessionPhaseRequest } from "modules/core/model/sessionPhaseRequest";
import { SessionActionResponse } from "modules/core/model/sessionActionResponse";
import { SessionActionRequest } from "modules/core/model/sessionActionRequest";
import { SessionStateEnum } from "modules/core/enums/SessionStateEnum";
import SessionPhaseEnum from "modules/core/enums/SessionPhaseEnum";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import Api from "../lib/Api";
import QuestionTypeEnum from "../enums/QuestionTypeEnum";
import AnswerSourceEnum from "../enums/AnswerSourceEnum";
import BusinessAbstract from "./BusinessAbstract";

export enum SessionEventIdType {
    SESSION_TRIAGE = "SESSION_TRIAGE",
    SESSION_INPUT_VALIDATION = "SESSION_INPUT_VALIDATION",
    SESSION_DISCHARGE = "SESSION_DISCHARGE",
    SESSION_ADMISSION_START = "SESSION_ADMISSION_START",
    SESSION_TRIAGE_START = "SESSION_TRIAGE_START",
}

export interface SessionInfoHtmlResponse extends GenericResponse {
    css?: string;
    fileName?: string;
    html?: string;
}

export default class SessionBO extends BusinessAbstract {
    static instance?: SessionBO;

    static getInstance(): SessionBO {
        if (!SessionBO.instance) {
            SessionBO.instance = new this();
        }

        return SessionBO.instance;
    }

    async doNewSessionForReason(reason: string): Promise<NewSessionResponse> {
        return this.api.service<NewSessionResponse>("newSession", {
            reason,
            fullInfo: true,
        } as NewSessionRequest);
    }

    // TODO: model needed - commented because is not used
    async doSessionNotification(
        sessionId: string,
    ): Promise<SessionInfoResponse> {
        return this.api.service<SessionInfoResponse>("sessionNotification", {
            sessionId,
        } as SessionInfoRequest);
    }

    /*
     * @deprecated Please use doSessionInfo()
     */
    async fetchSessionInfo(
        sessionId: string,
        includeExtendedInfo: boolean = false,
    ): Promise<SessionInfoResponse> {
        const request = SessionBO.sessionRequestBasicParams<SessionInfoRequest>(
            {
                sessionId,
            },
            includeExtendedInfo,
        );

        const response = await this.api.service<SessionInfoResponse>(
            "sessionInfo",
            request,
        );

        return response;
    }

    async doSessionInfo(
        data?: SessionInfoRequest,
    ): Promise<SessionInfoResponse> {
        return this.api.service<SessionInfoResponse>("sessionInfo", data);
    }

    async doSessionLink(
        sessionId: string,
        organizationId: string,
    ): Promise<any> {
        await this.api.service<SessionLinkRequest>("sessionLink", {
            sessionId,
            organizationId,
        });
    }

    // FIXME: add typings
    async doSessionOfferingList(sessionId: string): Promise<any> {
        const response = await this.api.service<any>("sessionOfferingList", {
            sessionId,
        });

        return response.sessionOfferingList !== undefined
            ? response.sessionOfferingList
            : [];
    }

    async doSessionOfferingGroupList(sessionId: string): Promise<any> {
        const response = await this.api.service<any>("sessionOfferingGroupList", {
            sessionId,
        });
        
        return response.sessionOfferingGroupList !== undefined
            ? response.sessionOfferingGroupList
            : [];
    }

    async doValuation(
        sessionId: string,
        valuation: number,
        description?: string,
    ): Promise<ValuationResponse> {
        const response = await this.api.service<ValuationResponse>(
            "valuation",
            {
                sessionId,
                valuation,
                description,
            } as ValuationRequest,
        );

        return response;
    }

    async doNewSession(
        params: NewSessionRequest = {},
        impersonatedExternUserId?: string,
    ): Promise<NewSessionResponse> {
        const response = await this.api.service<
            NewSessionResponse,
            NewSessionRequest
        >(
            "newSession",
            Object.assign(params, {
                __querystring: { impersonatedExternUserId },
            }),
        );

        return response;
    }

    // async doNewSessionForReason(reason) {
    //    return await this.doNewSession(reason);
    // }

    async doAnswerStatement(
        sessionId: string,
        params: AnswerStatementRequest = {},
    ): Promise<AnswerStatementResponse> {
        const response = await this.api.service<AnswerStatementResponse>(
            "answerStatement",
            _merge(
                {
                    sessionId,
                },
                params,
            ),
        );

        return response;
    }

    async doRevertStatement(
        sessionId: string,
        impersonatedExternUserId?: string,
    ): Promise<any> {
        // const self = this;

        const request =
            SessionBO.sessionRequestBasicParams<RevertStatementRequest>(
                {
                    sessionId,
                },
                true,
            );

        // if (impersonatedExternUserId != null) {
        //    request.impersonatedExternUserId = impersonatedExternUserId;
        // }

        const response = await this.api.service("revertStatement", request);

        return response;
    }

    async answerStatements(
        statements: Record<string, string>,
        sessionId: string,
        parentId?: string,
        recalculateResults: boolean = false,
        source: number = AnswerSourceEnum.REASON.value,
    ): Promise<AnswerStatementResponse> {
        const answers: StatementResponseVOReq[] = [];

        if (statements != null) {
            for (const statementId in statements) {
                const answer: StatementResponseVOReq = {
                    statementId,
                    answerSource: source,
                    response: statements[statementId],
                };
                if (parentId != null) answer.responsibleStatementId = parentId;
                answers.push(answer);
            }
        }

        const request =
            SessionBO.sessionRequestBasicParams<AnswerStatementRequest>(
                {
                    sessionId,
                    responses: answers,
                    recalculateResults,
                    includeExternUser: true,
                    includePartnerExternUser: true,
                    includeConclusions: true,
                    inputValidation: true,
                    includeHospitalRecommendations: true,
                } as AnswerStatementRequest,
                true,
            );

        const response = await this.api.service<AnswerStatementResponse>(
            "answerStatement",
            request,
        );

        return response;
    }

    async answerQuestion(
        sessionId: string,
        statementId: string,
        questionType: number,
        responseLiteral: string,
        control?: any,
        childrenStatements?: Record<string, string>,
    ): Promise<AnswerStatementResponse> {
        const request =
            SessionBO.sessionRequestBasicParams<AnswerStatementRequest>(
                {
                    sessionId,
                },
                true,
            );
        const responses: {
            statementId: string;
            response: string;
            responsibleStatementId?: string;
            answerSource: number;
        }[] = [];

        if (!_includes(SessionBO.questionMultiTypes(), questionType)) {
            responses.push({
                statementId,
                response: responseLiteral,
                answerSource: 0,
            });
        } else {
            responses.push({
                statementId,
                response: "YES",
                answerSource: 0,
            });

            if (childrenStatements != null) {
                for (const childrenStatementId in childrenStatements) {
                    responses.push({
                        statementId: childrenStatementId,
                        response: childrenStatements[childrenStatementId],
                        responsibleStatementId: statementId,
                        answerSource: 0,
                    });
                }
            }
        }

        request.responses = responses;

        const response = await this.api.service<AnswerStatementResponse>(
            "answerStatement",
            request,
        );

        return response;
    }

    static sessionRequestBasicParams<T>(
        params: Record<string, any> = {},
        includeExtendedInfo: boolean = false,
    ): T {
        return _merge(params, {
            includeConclusions: includeExtendedInfo,
            includeActions: includeExtendedInfo,
            includeExternUser: includeExtendedInfo, // TODO: Hospital only
            includePartnerExternUser: app.hospitalMode, // TODO: Hospital only
            fullInfo: true,
            inputValidation: true,
        }) as T;
    }

    static getSessionAnswers(
        session: SessionVO,
        parentStatementId?: string,
    ): Record<string, string> {
        const collection: Record<string, string> = {};
        const { answers } = session;

        // console.log(4, session);

        for (const i in answers) {
            /* if (answers[i].statementId == parentStatementId) { // FIXME was this.parentStatementId
                if (answers[i].innerStatementList != null) {
                    let innerList = answers[i].innerStatementList;
                    for (let j in innerList) {
                        collection[innerList[j].statementId] = innerList[j].response;
                    }
                }
            } */
            if (answers[i].response) {
                collection[answers[i].statementId] = answers[i].response;
            }
        }

        const inputs =
            session.inputRecommendation &&
            session.inputRecognition?.concat(session.inputRecommendation);

        // console.log(2, inputs);

        for (const i in inputs) {
            if (typeof inputs[i].response !== "undefined") {
                if (inputs[i].response) {
                    collection[inputs[i].statementId] = inputs[i].response;
                }

                if (inputs[i].innerStatementList) {
                    const childList = inputs[i].innerStatementList;
                    for (const j in childList) {
                        if (childList[j].response) {
                            collection[childList[j].statementId] =
                                childList[j].response;
                        }
                    }
                }
            }
        }

        return collection;
    }

    static getInnerStatementListByParentId(
        session: SessionVO,
        statementId: string,
    ): StatementResponseVO[] {
        const inputs =
            session.inputRecommendation &&
            session.inputRecognition?.concat(session.inputRecommendation);

        for (const i in inputs) {
            if (inputs[i].statementId == statementId) {
                if (inputs[i].innerStatementList != null) {
                    return inputs[i].innerStatementList.filter(
                        (item) => item.isActive,
                    );
                }
            }
        }

        return [];
    }

    static getAllSessionStatementChilds(
        session: SessionVO,
        statement: StatementVO,
    ): StatementResponseVO[] {
        let affecteds: StatementVO[] = [];

        if (statement.affectedsList != null) {
            affecteds = statement.affectedsList;
        }

        return SessionBO.getInnerStatementListByParentId(
            session,
            statement.statementId!,
        ).concat(affecteds);
    }

    static questionMultiTypes() {
        return [
            QuestionTypeEnum.MULTI.value,
            QuestionTypeEnum.MULTI_TEXT.value,
            QuestionTypeEnum.MULTI_IMAGE.value,
            QuestionTypeEnum.MULTI_IMAGE_TEXT.value,
        ];
    }

    getActionListForSession(
        session: SessionVO,
        conclusionId: string,
    ): SessionConclusionVO[] {
        return (
            session.sessionConclusions?.sessionConclusions?.filter(
                (conclusion) => conclusion?.conclusionId === conclusionId,
            )[0].actionList || []
        );
    }

    async fetchSessionExternUserGroupList(
        externUserGroupId: string,
    ): Promise<SessionExternUserGroupListResponse> {
        return this.api.service<SessionExternUserGroupListResponse>(
            "sessionExternUserGroupList",
            { externUserGroupId } as SessionExternUserGroupListRequest,
        );
    }

    async doSessionInfoHtml(
        data: SessionInfoHtmlRequest,
    ): Promise<SessionInfoHtmlResponse> {
        return this.api.service<SessionInfoHtmlResponse>(
            "sessionInfoHtml",
            data,
        );
    }

    // FIXME: needs model!
    async doSessionExternUserCreate(data: {
        sessionExternUser: { sessionId: string };
    }): Promise<GenericResponse> {
        return this.api.service("sessionExternUserCreate", data);
    }

    async doSessionEventList(
        data: SessionEventListRequest & { __alertOnError?: boolean },
    ): Promise<SessionEventListResponse> {
        return this.api.service<SessionEventListResponse>(
            "sessionEventList",
            data,
        );
    }

    async doSessionEventCreate(
        data: SessionEventCreateRequest,
    ): Promise<SessionEventResponse> {
        return this.api.service<SessionEventResponse>(
            "sessionEventCreate",
            data,
        );
    }

    async doSessionAction(
        data: SessionActionRequest,
    ): Promise<SessionActionResponse> {
        return this.api.service<SessionActionResponse>("sessionAction", data);
    }

    // TODO: SessionPhaseResponse
    async doSessionPhase(
        data: SessionPhaseRequest,
    ): Promise<SessionInfoResponse> {
        return this.api.service<SessionInfoResponse>("sessionPhase", data);
    }

    downloadSessionSummaryXls(language: string) {
        const params = JSON.stringify({
            childrenRelationTypeList: [3],
            includeTargetSessions: false,
            language,
        });

        window.open(
            Api.getInstance().fileDownloadUrl({
                type: "method",
                method: "sessionValidationXlsx",
                params: encodeURI(params),
            }),
            "_blank",
        );
    }

    /* async manageSessionState(session:SessionVO, redirectTo:(route:Location) => void): Promise<SessionVO> {
        if(session.state === SessionStateEnum.TEST_RECEIVED) {
            redirectTo(RouteGenerator.getInstance().hospitalEvaluation(session.sessionId));
        }else if (session.state === SessionStateEnum.PRE_DIAGNOSTIC) {
            redirectTo(RouteGenerator.getInstance().hospitalSessionDetailPrediagnostic(session.sessionId));
        } else if (session.state === SessionStateEnum.TRIAGE_GUIDANCE) {
            redirectTo(RouteGenerator.getInstance().hospitalSessionDetail(session.sessionId));
        }

        return null;
    } */
}
