import defaults from "modules/core/config/defaults";
import config from "modules/core/config/app";
import { NavigationGuard } from "vue-router";
import { dispatchPrivateEvent } from "@mediktor-web/common/events/private";
import { GeneralConfig } from "../types/misc";

const parse = (value: string) => {
    try {
        const parsedValue = JSON.parse(value);
        return parsedValue;
    } catch (_error) {
        return value;
    }
};

const strategies = ["disableMiniNav"] as const;
type strategy = (typeof strategies)[number];

let configsBuffer: DeepPartial<GeneralConfig> = {};

// TODO: only shallow path aliased are supported with this typing.
const storeConfigInBuffer = (
    key: keyof typeof configsBuffer,
    value?: (typeof configsBuffer)[typeof key],
) => {
    // @ts-ignore
    value === undefined && (configsBuffer[key] = config[key]);
};

const restoreConfigFromBuffer = (key: keyof typeof configsBuffer) => {
    const { [key]: item, ...rest } = configsBuffer;
    // @ts-ignore
    config[key] = item ?? defaults[key];
    configsBuffer = rest;
};

const mutationStrategies: Record<strategy, VoidFunction> = {
    disableMiniNav: () => {
        storeConfigInBuffer("enableMiniNav");
        config.enableMiniNav = false;
    },
};

const restorationStrategies: Record<strategy, VoidFunction> = {
    disableMiniNav: () => {
        restoreConfigFromBuffer("enableMiniNav");
    },
};

const mutateConfig: typeof storeConfigInBuffer = (key, value) => {
    storeConfigInBuffer(key, value);
    // @ts-ignore
    value && (config[key] = parse(value));
};

const isStrategy = (key: string): key is strategy =>
    strategies.includes(key as strategy);

const isConfigurationParam = (key: string): key is keyof GeneralConfig => {
    // console.log(({isConfigurationParam: {[key]: config.hasOwnProperty(key)}}))
    // return config.hasOwnProperty(key);
    return Object.hasOwn(config, key);
};

const NavigationQueryProcessingMiddleware: { handle: NavigationGuard } = {
    handle(to, from, next) {
        if (to === from) return;

        // console.log({
        //     to: to.query
        // })

        Object.keys(from.query).forEach((key) => {
            isStrategy(key) && restorationStrategies[key]();
            isConfigurationParam(key) && restoreConfigFromBuffer(key);
        });

        Object.entries(to.query).forEach(([key, value]) => {
            isStrategy(key) && mutationStrategies[key]();
            isConfigurationParam(key) && mutateConfig(key, value);
        });

        // console.log({configsBuffer})

        dispatchPrivateEvent({
            name: "app:config_changed",
            payload: { config: { ...config } },
        });

        // next();
    },
};

export default NavigationQueryProcessingMiddleware;
