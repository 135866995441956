import { Location } from "vue-router";
import vue from "vue";
import LanguageBO from "modules/core/business/LanguageBO";
import { cloneRouteLocation } from "modules/core/lib/Util";
import { dispatchPublicEvent } from "@mediktor-web/common/events/public";

type generateMetaLinkCallback = (
    route: Location,
    language: string,
) => Location | null;

export const PageMixin = vue.extend({
    metaInfo(): any {
        const parentTitle: string = this.$app.appVisibleName();
        const title: string = this.pageTitle!;
        const link: Location[] = [];

        const meta = [
            { name: "keywords", content: this.pageKeywords },
            {
                name: "apple-itunes-app",
                content: this.$app.generalConfig().marketAppleApp,
            },
            {
                name: "google-play-app",
                content: this.$app.generalConfig().marketGoogleApp,
            },
            { property: "og:url", content: location.href },
            { property: "og:description", content: this.pageDescription },
            { property: "og:image", content: this.pageMainImage },
        ];

        if (title) {
            meta.push({
                property: "og:title",
                content: `${parentTitle}${title ? ` - ${title}` : ""}`,
            });
        }

        if (this.pageDescription) {
            meta.push({ name: "description", content: this.pageDescription });
        }

        if (this.enableDefaultMetaLinks) {
            link.push(...this.generateMetaLink());
        }

        link.push(...this.metaLinks);

        return {
            title: parentTitle,
            titleTemplate: `%s${title ? ` - ${title}` : ""}`,
            htmlAttrs: {
                lang: this.$app.language,
            },
            meta,
            link,
        };
    },
    computed: {
        enableDefaultMetaLinks(): boolean {
            return true;
        },

        metaLinks(): Array<Location> {
            return [];
        },

        pageTitle(): string | null {
            return null;
        },

        pageDescription(): string {
            return this.pageDescriptionDefault;
        },

        pageDescriptionDefault(): string {
            return this.$app.localeText("evaluator.msgMetaDescription");
        },

        pageKeywords(): string {
            return this.pageKeywordsDefault;
        },

        pageKeywordsDefault(): string {
            return this.$app.localeText("evaluator.msgKeywords");
        },

        pageMainImage(): string | null {
            return this.app().generalConfig().mainLogo;
        },

        mainTitle(): string {
            return "";
        },

        mainTitleIsVisible(): boolean {
            return this.$app.generalConfig().enablePageHeaders;
        },
    },
    methods: {
        generateMetaLink(
            callback: generateMetaLinkCallback = (
                route: Location,
                language: string,
            ) => route,
        ): Array<Record<string, string>> {
            const link: any[] = [];

            for (const language of LanguageBO.getInstance().getLanguageList(
                false,
            )) {
                if (language.isActive) {
                    const lang = language.languageCode?.replace("_", "-");

                    if (lang !== this.$app.language) {
                        const route = cloneRouteLocation(this.$route);
                        route.name = `${route.name}__${lang}`;

                        const modifiedRoute = lang && callback(route, lang);
                        if (modifiedRoute) {
                            const resolvedRoute =
                                this.$router.resolve(modifiedRoute).resolved;

                            link.push({
                                rel: "alternate",
                                hreflang: lang,
                                href:
                                    window.location.origin +
                                    resolvedRoute.fullPath,
                            });
                        }
                    }
                }
            }

            return link;
        },
    },
    created() {
        dispatchPublicEvent({
            name: "page:loaded",
            payload: {
                name: this.$route?.name || "",
                title: this.mainTitle,
                url: this.$route?.fullPath,
            },
        });
        //     new AppEvents.PageLoaded().emit({
        //         // TODO: check fallback values
        //         title: this.mainTitle || "default",
        //         name: this.$route?.name || undefined,
        //         url: this.$route?.fullPath,
        //     });
        // },
    },
});
