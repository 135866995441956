import eventBus from "modules/core/lib/EventBusSingleton";
import VueRouter, {
    Location,
    NavigationGuard,
    RawLocation,
    Route,
    RouteConfig,
} from "vue-router";
import { Dictionary } from "vue-router/types/router";
import { slugifyTextForLanguage } from "modules/core/lib/Util";
import layout from "modules/core/routes/layout";
import old from "modules/core/routes/old";
import generics from "modules/core/routes/generics";
import authNeeded from "modules/core/routes/authNeeded";
import config from "modules/core/config/app";
import EnvironmentConfig from "./EnvironmentConfig";
import LayoutComponent from "../../../app/components/layout/Layout";
import LocalizationBO from "../business/LocalizationBO";
import LanguageBO from "../business/LanguageBO";

type LocationQueryDictionary = Dictionary<
    string | (string | null)[] | null | undefined
>;
type LocationParamsDictionary = Dictionary<string>;

export default class Router {
    static instance?: Router;

    localizationBO: LocalizationBO;

    router?: VueRouter;

    abstractMode: boolean = config.routerMode === "abstract";

    constructor(localizationBO: LocalizationBO) {
        this.localizationBO = localizationBO;
    }

    static getInstance(): Router {
        if (!Router.instance) {
            Router.instance = new this(LocalizationBO.getInstance());
        }

        return Router.instance;
    }

    generateRouteList(
        lang?: string,
        includeNoLangRoutes: boolean = true,
    ): RouteConfig[] {
        const language = lang || LanguageBO.getInstance().currentLanguageCode();
        const publicLanguage = LanguageBO.convertToPublicLanguage(language);
        const routes: RouteConfig[] = [];

        if (includeNoLangRoutes) {
            routes.push(...generics(language));
        }

        routes.push({
            name: "layout",
            path: `/${publicLanguage}`,
            component: LayoutComponent,
            children: this.layoutRoutes(
                [
                    // @ts-ignore
                    ...old(language, this.localizationBO),
                    // @ts-ignore
                    ...layout(language, this.localizationBO),
                    // ... config.hospitalMode ? hospital(language) : []
                ],
                language,
            ),
        });

        routes.push({
            name: "notFoundLayout",
            path: `*`,
            component: LayoutComponent,
            children: [
                {
                    name: "notFound",
                    path: "*",
                    component: () => import("app/components/layout/404/404"),
                },
            ],
        });
        console.info("ROUTES:", { routes });

        return routes;
    }

    /**
     * @deprecated
     */
    routeDefinitionName(name: string, language?: string) {
        return name;
    }

    /**
     * @deprecated
     */
    urlSegmentDefinition(name: string, languageCode: string) {
        const languageCodeConverted = languageCode.replace("_", "-");
        const locale = this.localizationBO.localeText(name);

        return slugifyTextForLanguage(locale, languageCodeConverted);
    }

    layoutRoutes(routes: RouteConfig[], language: string) {
        return routes;
    }

    async pushRoute(
        nameOrObject: RawLocation,
        params: LocationParamsDictionary = {},
        query: LocationQueryDictionary = {},
    ) {
        if (
            typeof nameOrObject === "object" ||
            (typeof nameOrObject === "string" && nameOrObject.match("^http"))
        ) {
            this.router?.push(nameOrObject);
        } else {
            this.router?.push({
                name: nameOrObject,
                params,
                query: query as any,
            });
        }
    }

    replaceRoute(...args: any) {
        // @ts-ignore
        return this.router.replace.apply(args);
    }

    currentRoute(): Route {
        return this.router!.currentRoute;
    }

    getRouter(): VueRouter {
        return this.router!;
    }

    setRouter(router: VueRouter) {
        this.router = router;
    }

    createRouter(
        routes: RouteConfig[],
        onBefore: NavigationGuard = () => {},
        onAfter: (to: Route, from: Route) => any = () => {},
        path?: string,
    ) {
        const router = new VueRouter({
            mode: this.abstractMode ? "abstract" : config.routerMode,
            base:
                path ||
                EnvironmentConfig.getValue("APP_BASE_PATH") ||
                config.basePath,
            routes,
            scrollBehavior(to, from, savedPosition) {
                return { x: 0, y: 0 };
            },
        });

        // const originalRouterBack = router.back;
        // router.back = function () {
        //     eventBus.emit("navigation:back");
        //     return originalRouterBack();
        // };

        // history.back = function () {
        //     eventBus.emit("navigation:back");
        //     return originalRouterBack();
        // };

        router.beforeEach(onBefore);
        router.afterEach(onAfter);

        return router;
    }

    routeNeedsLogin(to: Location): boolean {
        return !!(to.name && authNeeded.includes(to.name));
    }

    goToDeeplink(commandName: string, params: LocationParamsDictionary = {}) {
        this.pushRoute("cmd", { cmd: commandName }, params);
    }
}
