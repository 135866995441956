import _isEmpty from "lodash/isEmpty";
import vue from "vue";
import UI from "modules/core/lib/Ui";
import Util from "modules/core/lib/Util";
import eventBus from "../lib/EventBusSingleton";
import DarkModeManager from "../lib/DarkModeManager";

export default vue.extend({
  data() {
    return {
      isLoading: false,
      mainIsVisible: true,
      eventId: Util.uniqueId(),
      preloader: null,
      events: [],
      isDarkMode: false,
      dataTheme: "light",
    };
  },

  props: {
    appInstance: {
      type: Object,
      required: false,
      default: null,
    },
    onLoading: {
      type: Function,
      required: false,
      default: () => {},
    },
    onActionEvent: {
      type: Function,
      required: false,
      default: (type, data) => {},
    },
    enableGlobalEvents: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {
    /* app() {
            // Calls the main Vue instance where all components are loaded.
            //return this.$root.appInstance || this.appInstance || this.$root.$data.app;
            return Holder.app;
        }, */
    prop(type = String, required = false, def = null) {
      return Util.propDefinition(type, required, def);
    },
    currentRouteParam(name) {
      try {
        return this.$router.currentRoute.params[name];
      } catch (e) {
        return null;
      }
    },
    currentRouteQueryParam(name) {
      try {
        return this.$router.currentRoute.query[name];
      } catch (e) {
        return null;
      }
    },
    currentRouteQuery(name) {
      try {
        return this.$router.currentRoute.query[name];
      } catch (e) {
        return null;
      }
    },
    pushRoute(name, params = {}, query = {}) {
      this.$router.push({ name, params, query });
    },
    emitEvent(name, value) {
      if (this.enableGlobalEvents) {
        eventBus.emit(name, value);
      }
    },
    catchEvent(name, callback) {
      if (this.enableGlobalEvents) {
        const listener = eventBus.on(name, callback);

        this.events.push(listener);
      }
    },
    removeEvents() {
      if (this.enableGlobalEvents) {
        for (const event of this.events) {
          eventBus.off(event);
        }
      }
    },
    mainContentProp() {
      return null;
    },
    mainContentIsReady() {
      if (this.mainContentProp() == null) return false;

      return !_isEmpty(this.mainContentProp());
    },
    placeholderedContent(content, viewHeight = null) {
      if (this.mainContentIsReady()) {
        return content();
      }

      return this.viewPlaceholderTemplate(viewHeight);
    },
    viewPlaceholderTemplate(height = null) {
      const h = height == null ? "100%" : `${height}px`;

      return `<div style="width:80%; min-height:${h}; background-color:#f2f2f2; margin:5px auto;"></div>`;
    },
    actionEvent(name, data = null) {
      this.onActionEvent(name, data);
      this.emitEvent(name, data);
    },
    destroy() {
      this.mainIsVisible = false;
    },
    init() {
      // Method to be called on mounted. Every component should implement this method.
    },
    showPreloader(delaySpinner = false, boxed = false, size = 35) {
      this.hidePreloader();

      this.$nextTick(() => {
        this.preloader = UI.appendPreloaderTo(
          this.$el,
          delaySpinner,
          boxed,
          size,
        );
      });
    },
    hidePreloader() {
      if (this.preloader) {
        this.preloader.remove();
      }
    },
    async withPreloader(callbackAction, callbackErr = (err) => {}) {
      this.showPreloader();

      try {
        await callbackAction();
      } catch (err) {
        callbackErr(err);
      } finally {
        this.hidePreloader();
      }
    },
    setColorMode() {
      this.isDarkMode = DarkModeManager.getInstance().darkMode;
      this.dataTheme = DarkModeManager.getInstance().dataTheme;
    },
  },

  computed: {
    componentId() {
      return Util.uniqueId();
    },
    $module() {
      return `mdk-${this.componentId}`;
    },
    /* $app() {
            return Holder.app;
        }, */
    commonInputAttributes() {
      return [{ rounded: true, outlined: true, dense: true }];
    },
    commonButtonAttributes() {
      return [{ rounded: true, noCaps: true, color: "primary" }];
    },
    appConfig() {
      return this.$app.generalConfig();
    },
  },

  mounted() {
    // this.emitEvent("component.loaded", this);
    this.setColorMode();
    this.catchEvent("dark-mode-toggle", (value) => {
      this.isDarkMode = value;
      this.dataTheme = DarkModeManager.getInstance().dataTheme;
    });

    /* (new AppEvents.DarkModeChange()).catch((value) => {
            this.isDarkMode = value;
            this.dataTheme = DarkModeManager.getInstance().dataTheme;
        }); */

    this.init();
  },

  destroyed() {
    this.removeEvents();
  },
});
