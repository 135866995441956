import BusinessAbstract from "./BusinessAbstract";
import firebase from "firebase/app";
import appConfig from "modules/core/config/app";
import "firebase/auth";
import EventBusSingleton from "modules/core/lib/EventBusSingleton";
import { RegisterResponse } from "modules/core/model/registerResponse";

/**
 * Use this class to register or login a user using the firebase tools,
 * To make it more flexible, you will have to create the provider externally
 * and set it with the setFirebaseProvider method.
 * This class use mediktors config by defult, you can add your own config in
 * modules/core/config/defaults --> firebaseConfig
 *  * @param provider = <Provider>
 */
export default class FirebaseSignInBO extends BusinessAbstract {
    provider: any;

    constructor(provider: any) {
        super();

        if (provider === null) {
            throw new Error("[FirebaseSignInBO] - Enter a valid provider");
        }
        this.provider = provider;

        this.firebaseInitializeApp();
    }

    async doRegisterForFirebase(token: string): Promise<RegisterResponse> {
        const response = await this.api.service<RegisterResponse>("register", {
            firebaseUserId: token,
        });

        return response;
    }

    setFirebaseProvider(provider: any) {
        this.provider = provider;
    }

    async cleanUserFromFirebase() {
        const auth = await firebase.auth();
        if ("currentUser" in auth) await firebase.auth()?.currentUser?.delete();
    }

    async startSignInProccess(mode: string = "redirect"): Promise<void> {
        if (!this.provider) return;

        try {
            switch (mode) {
                case "redirect":
                    await this.signInWithRedirect();
                    break;

                case "popup":
                    const result = await this.signInWithPopup();
                    if (!result.user) return;

                    const token = await firebase
                        .auth()
                        .currentUser?.getIdToken();
                    const response = await this.doRegisterForFirebase(token!);
                    EventBusSingleton.emit("apple-sign-in", response);
                    await this.cleanUserFromFirebase();
                    break;

                default:
                    await this.signInWithRedirect();
                    break;
            }
        } catch (error) {
            throw new Error(`"FirebaseSignInBO error:" ${error}`);
        }
    }

    async signInWithRedirect() {
        if (!this.provider) return;

        return firebase.auth().signInWithRedirect(this.provider);
    }

    async signInWithPopup(): Promise<firebase.auth.UserCredential> {
        // if(!this.provider) return null;

        return firebase.auth().signInWithPopup(this.provider);
    }

    async getRedirectResult() {
        const result = await firebase.auth().getRedirectResult();
        if (!result.user) return;

        const token = await firebase.auth().currentUser?.getIdToken();
        const response = await this.doRegisterForFirebase(token!);
        EventBusSingleton.emit("apple-sign-in", response);
        await this.cleanUserFromFirebase();
    }

    firebaseInitializeApp() {
        if (firebase.apps.length === 0 && appConfig.firebaseConfig) {
            firebase.initializeApp(appConfig.firebaseConfig);
        }
    }
}
