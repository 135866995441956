import template from "modules/core/components/interface/radio/radio.html?raw";
import { MDCFormField } from "@material/form-field";
import { MDCRadio } from "@material/radio";
import "modules/core/components/interface/radio/radio.scss";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,
    model: {
        prop: "modelValue",
        event: "input",
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "radio-id-default",
        },
        name: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: String,
            required: false,
            default: null,
        },
        className: {
            type: String,
            required: false,
            default: null,
        },
        checked: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        theme: {
            type: String,
            required: false,
            default: null,
        },
        color: {
            type: String,
            required: false,
            default: null,
        },
        modelValue: {
            type: String,
            required: false,
            default: "",
        },
    },
    computed: {
        state(): boolean {
            if (!this.modelValue) {
                return this.checked;
            }

            return this.modelValue === this.value;
        },
        classes(): string[] {
            const list: string[] = [];
            if (this.theme) {
                list.push(`mdk-ui-radio--${this.theme}`);
            }
            if (this.color) {
                list.push(`mdk-ui-radio--color-${this.color}`);
            }

            return list;
        },
    },
    methods: {
        onChange() {
            this.toggle();
        },
        toggle() {
            this.$emit("input", this.state ? "" : this.value);
        },
        setup() {
            const checkbox: MDCRadio = new MDCRadio(
                this.$refs.radio as HTMLElement,
            );
            const formField: MDCFormField = new MDCFormField(this.$el);

            formField.input = checkbox;
        },
    },
    watch: {
        checked(newValue: boolean) {
            if (newValue !== this.state) {
                this.toggle();
            }
        },
    },
    mounted() {
        this.setup();

        if (this.checked && !this.state) {
            this.toggle();
        }
    },
});
