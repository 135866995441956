import { Application } from "./Holder";
import MessagesBO from "../business/MessagesBO";

export default class NotificationsManager {
    static instance?: NotificationsManager;

    unreadMessagesNotifications: number = 0;

    static getInstance() {
        if (!NotificationsManager.instance) {
            NotificationsManager.instance = new this();
        }

        return NotificationsManager.instance;
    }

    setNotifications(groups: string[]) {
        groups.forEach((groupId) => {
            this.unreadMessagesNotifications +=
                MessagesBO.getInstance().getUnreadMessagesByGroupId(
                    groupId,
                ).length;
        });
    }

    updateNotifications(groups: string[]) {
        let unreadMessages = 0;
        groups.forEach((groupId) => {
            unreadMessages +=
                MessagesBO.getInstance().getUnreadMessagesByGroupId(
                    groupId,
                ).length;
        });
        this.unreadMessagesNotifications = unreadMessages;
    }

    resetNotifications() {
        this.unreadMessagesNotifications = 0;
    }
}
