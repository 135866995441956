import { dispatchPublicEvent } from "@mediktor-web/common/events/public";
import { merge } from "lodash";
import "./redirector";
import App, { setupAppEvents } from "modules/core/lib/App";
import "./sass/styles.scss";
import EnvironmentConfig from "modules/core/lib/EnvironmentConfig";
import { exposeGlobalMethods } from "./exposed";
import devConfig from "./dev.config";

import "./polyfills";

if (!window.global) {
    window.global = window;
}

App.setEnvironmentConfig({
    APP_API_AUTH_USERNAME: import.meta.env.VITE_APP_API_AUTH_USERNAME,
    APP_API_AUTH_PASSWORD: import.meta.env.VITE_APP_API_AUTH_PASSWORD,
    APP_API_USER_ID: import.meta.env.VITE_APP_API_USER_ID,
    APP_API_AUTH_CODE: import.meta.env.VITE_APP_API_AUTH_CODE,
    APP_API_ENDPOINT: import.meta.env.VITE_APP_API_ENDPOINT,
    APP_WS_ENDPOINT: import.meta.env.VITE_APP_WS_ENDPOINT,
    APP_DOMAIN: import.meta.env.VITE_APP_DOMAIN,
    APP_BASE_PATH: import.meta.env.VITE_APP_BASE_PATH,
    REDSYS_LIVE: import.meta.env.VITE_APP_REDSYS_LIVE === "true",
    REDSYS_KEY: import.meta.env.VITE_APP_REDSYS_KEY,
    REDSYS_MERCHANT_CODE: import.meta.env.VITE_APP_REDSYS_MERCHANT_CODE,
    ENABLE_ERROR_REPORTING:
        import.meta.env.VITE_APP_ENABLE_ERROR_REPORTING === "true",
    DEBUG_CAPABILITIES: import.meta.env.VITE_APP_DEBUG_CAPABILITIES,
    FORCE_DOMAIN: import.meta.env.VITE_APP_FORCE_DOMAIN,
    RUNTIME_MODE: import.meta.env.VITE_APP_RUNTIME_MODE === "true",
    STRIPE_KEY: import.meta.env.VITE_APP_STRIPE_KEY,
    SENTRY_DSN: import.meta.env.VITE_APP_SENTRY_DSN,
    USER_DEBUGGER: import.meta.env.VITE_APP_USER_DEBUGGER === "true",
});

console.log({ environment: EnvironmentConfig.getAll() });

const app = App.getInstance();

const runtimeMode = EnvironmentConfig.getValue("RUNTIME_MODE");
const config = app.generalConfig();

config.appId = "@mediktor-web/sdk";

// checkerSkin: "conversation",
// // mainVisualNavigationEnabled: false,
// enableMiniNav: true,
// embeddedMode: true,
// enableBreadCrumb: true,
config.enableSpecialistListing = true;
config.appVersion = import.meta.env.VITE_APP_VERSION;
config.defaultLanguage = "en-EN";
config.googleSignInId = null;
config.showBrandingElements = false;
config.classicViewSpecialists = false;
config.scheduleEnabled = true;
config.prescriptionsEnabled = true;
config.valuationEnabled = true;
config.dashboardEnabled = true;
config.dashboardRedirectEnabled = true;
config.enableSounds = true;
config.enableSocket = true;
config.enableToggleDarkMode = true;
config.paymentGatewayType = "stripe";
config.offerings.moduleLayout = 3;

if (import.meta.env.DEV) {
    merge(config, devConfig);
}

window.mdkApp = app;

window.mdkVersion = app.appVersionDescription();
window.mdkDebugInfo = (modal: boolean) => app.printDebugInfo(modal);

if (config.enableGlobalFunctions) {
    exposeGlobalMethods(app);
}

// new AppEvents.AppReady().emit();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const embedded = urlParams.get("embedded");
const isEmbedded = typeof embedded === "string";

isEmbedded && (config.embeddedMode = true);

if (runtimeMode) {
    console.info("=== MEDIKTOR BROWSER VERSION 😎 ===");

    // config.routerMode = "hash";
    config.enableGlobalFunctions = false;
    config.mainVisualNavigationEnabled = true;
    config.enableUserDebugger = true;
    config.presentDefaultView = false;

    window.Mediktor = {};
    window.Mediktor.app = app;
}

setupAppEvents();

dispatchPublicEvent({ name: "app:ready" });

if (!runtimeMode && !isEmbedded) {
    app.start();
}
