/* global GeolocationPosition */
/* global GeolocationCoordinates */
/* global GeolocationPositionError */

import { Location } from "vue-router";
import EventBusSingleton from "modules/core/lib/EventBusSingleton";
import Util, { sendMessageToWebkit } from "modules/core/lib/Util";
import { LoginResponse } from "modules/core/model/loginResponse";
import { RegisterResponse } from "modules/core/model/registerResponse";
import { SessionVO } from "modules/core/model/sessionVO";
import {
    CredentialsGroup,
    RefreshTokenExpiration,
    RouteLocation,
} from "modules/core/types/misc";
import { ExternUserVO } from "modules/core/model/externUserVO";
import { ErrorResponse } from "modules/core/model/errorResponse";
import { ProductVO } from "../model/productVO";
import config from "../config/app";

export module AppEvents {
    export interface AppEventInterface<T = undefined> {
        emit(data?: T): void;
        catch(callback: (data?: T) => void): void;
        postMessage(data: T): void;
        global(data: T): void;
        getEventName(): string;
    }

    export abstract class Base<T = undefined> implements AppEventInterface<T> {
        protected eventName?: string;

        protected catched?: typeof EventBusSingleton.on;

        emit(data?: T) {
            // console.log("EVENT", this.eventName, data);

            /* if(config.enableMessageToWebkit) {
                sendMessageToWebkit({
                    name: this.getEventName(),
                    data,
                    error: false
                }, this.getEventName());
            } */

            EventBusSingleton.emit(this.eventName, data);
        }

        catch(callback: (data?: T) => void) {
            // @ts-ignore
            this.catched = EventBusSingleton.on(this.eventName, callback);
        }

        remove() {
            if (this.catched) {
                EventBusSingleton.off(this.catched);
            }
        }

        postMessage(data: T) {
            Util.postMessage({
                method: this.eventName || "",
                value: data,
            });
        }

        global(data: T) {
            this.emit(data);
            this.postMessage(data);
        }

        getEventName(): string {
            return this.eventName || "";
        }
    }

    // List of events

    export type PageLoadedEvent = {
        title: string;
        name?: string;
        url?: string;
    };
    export type ElementTapEvent = {
        context: string;
        element?: string;
        action?: string;
        data: any;
    };
    export type LanguageChangeEvent = { language: string };
    export type EditDataChangeEvent = {
        weightUnit: string;
        heightUnit: string;
        temperatureUnit: string;
    };
    export type TrackPageEvent = { url: string; title: string };
    export type TrackCustomEventsEvent = {
        nameRoute: string;
        eventAction: string;
        eventValue: string;
    };
    export type LocationShareEvent = GeolocationCoordinates[];
    export type AppBaseData = {
        authToken: string;
        deviceId: string;
        externUser: ExternUserVO;
        language: string;
        darkMode: boolean;
    };
    export type SessionOfferingActionEvent = {
        product: ProductVO;
        sessionInfo: SessionVO;
    };
    export type PageRedirectionEvent = {
        from: RouteLocation;
        to: RouteLocation;
    };

    export class RequestError extends Base<{
        service: string;
        error: ErrorResponse;
    }> {
        protected eventName = "requestError";
    }

    export class Logout extends Base<AppBaseData> {
        protected eventName = "logout";
    }

    export class AppReady extends Base {
        protected eventName = "appReady";
    }

    export class AppLoaded extends Base<AppBaseData> {
        protected eventName = "appLoaded";
    }

    export class PageLoaded extends Base<PageLoadedEvent> {
        protected eventName = "pageLoaded";
    }

    export class ElementAction extends Base<ElementTapEvent> {
        protected eventName = "elementAction";
    }

    export class LanguageChange extends Base<LanguageChangeEvent> {
        protected eventName = "languageChange";
    }

    export class DarkModeChange extends Base<boolean> {
        protected eventName = "darkModeChange";
    }

    export class AuthTokenRefreshExpired extends Base<RefreshTokenExpiration> {
        protected eventName = "authTokenRefreshExpired";
    }

    export class AuthTokenRenewed extends Base<CredentialsGroup> {
        protected eventName = "authTokenRenewed";
    }

    export class AuthenticationRequired extends Base<Location> {
        protected eventName = "authenticationRequired";
    }

    export class LocationRequest extends Base<void> {
        protected eventName = "locationRequest";
    }

    export class LocationShare extends Base<LocationShareEvent> {
        protected eventName = "locationShare";
    }

    export class PageRedirection extends Base<PageRedirectionEvent> {
        protected eventName = "pageRedirection";
    }

    export module Login {
        export class FormSubmit extends Base<LoginResponse> {
            protected eventName = "loginSubmit";
        }
    }

    export module Register {
        export class FormSubmit extends Base<RegisterResponse> {
            protected eventName = "registerSubmit";
        }
    }

    export module Checker {
        export class ReasonInputNoResult extends Base<SessionVO> {
            protected eventName = "checkerReasonInputNoResult";
        }

        export class ReasonInputSuccess extends Base<SessionVO> {
            protected eventName = "checkerReasonInputSuccess";
        }

        export class SessionOfferingAction extends Base<SessionOfferingActionEvent> {
            protected eventName = "sessionOfferingAction";
        }
    }

    export module UserProfile {
        /**
         * Event handler used when the user toggles any of the units (temperature, height and weight)
         * before submitting it to server
         */
        export class EditDataChange extends Base<EditDataChangeEvent> {
            protected eventName = "userProfileEditDataChange";
        }

        /**
         * Event handler used when the user toggles any of the units (temperature, height and weight)
         * and submits them to server
         */
        export class UnitsSave extends Base<ExternUserVO> {
            protected eventName = "userProfileUnitsSave";
        }

        /**
         * Event handler used when the user toggles sounds
         */
        export class SoundsToggle extends Base<boolean> {
            protected eventName = "userProfileSoundsToggle";
        }
    }

    export module Analytics {
        export class TrackPage extends Base<TrackPageEvent> {
            protected eventName = "sendPageAnalytics";
        }

        export class TrackCustomEvents extends Base<TrackCustomEventsEvent> {
            protected eventName = "trackCustomEvents";
        }

        export class Initialization extends Base {
            protected eventName = "initAnalytics";
        }
    }
}
