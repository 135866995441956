import LocalizationBO from "../business/LocalizationBO";

export default {
  data() {
    return {
      localeList: [],
    };
  },

  computed: {
    locales() {
      return [];
    },
  },

  methods: {
    localeText(name, params = {}, fallbackText = null, matcherCallback = null) {
      let result = "";
      if (
        Object.keys(this.app().generalConfig().customTmks).includes(name) &&
        this.app().generalConfig().customTmks[name][this.app().language]
      ) {
        result =
          this.app().generalConfig().customTmks[name][this.app().language];
      } else
        result = LocalizationBO.getInstance().localeText(
          name,
          params,
          fallbackText,
        );
      if (this.app().generalConfig().enableShowTmksKeys) {
        return `${result} (${name})`;
      } else return result;
    },
    onLocalizationLoaded(service) {
      // Deprecated
    },
  },

  mounted() {
    this.onLocalizationLoaded();
  },
};
