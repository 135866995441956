/**
 * Mediktor API
 * Description
 *
 * OpenAPI spec version: 4.0
 * Contact: info@mediktor.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExternUserGroupMemberVO } from "./externUserGroupMemberVO";
import { MessageVO } from "./messageVO";

export interface ExternUserGroupVO {
    autoDatabaseId?: string;
    autoDatabaseSchema?: ExternUserGroupVO.AutoDatabaseSchemaEnum;
    closeDate?: number;
    date?: number;
    externUserGroupId?: string;
    externUserGroupMembers?: Array<ExternUserGroupMemberVO>;
    groupStatus?: number;
    hasMyReview?: boolean;
    imgBadges?: Array<string>;
    info?: string;
    isActive?: boolean;
    isChatbot?: boolean;
    languageCode?: string;
    lastActivityInfo?: string;
    lastEdited?: number;
    lastMessage?: MessageVO;
    lastStatusInfo?: string;
    maxActivityDate?: number;
    myDescription?: string;
    myImage?: string;
    myPlaceholderSexImage?: number;
    myRole?: number;
    myUnreadMessageCount?: number;
    myValuationId?: string;
    payment?: { [key: string]: string };
    priceTier?: number;
    productId?: string;
    progressBarPercentage?: number;
    status?: ExternUserGroupVO.StatusEnum;
    tintColor?: string;
}
export namespace ExternUserGroupVO {
    export type AutoDatabaseSchemaEnum =
        | "AUDIT"
        | "AUDIT_RUNTIME"
        | "HISTORY"
        | "LOCATION"
        | "MASTERDATA"
        | "REPORT"
        | "RESEARCH"
        | "SERVER"
        | "USER"
        | "USER_ARCHIVE";
    export const AutoDatabaseSchemaEnum = {
        AUDIT: "AUDIT" as AutoDatabaseSchemaEnum,
        AUDITRUNTIME: "AUDIT_RUNTIME" as AutoDatabaseSchemaEnum,
        HISTORY: "HISTORY" as AutoDatabaseSchemaEnum,
        LOCATION: "LOCATION" as AutoDatabaseSchemaEnum,
        MASTERDATA: "MASTERDATA" as AutoDatabaseSchemaEnum,
        REPORT: "REPORT" as AutoDatabaseSchemaEnum,
        RESEARCH: "RESEARCH" as AutoDatabaseSchemaEnum,
        SERVER: "SERVER" as AutoDatabaseSchemaEnum,
        USER: "USER" as AutoDatabaseSchemaEnum,
        USERARCHIVE: "USER_ARCHIVE" as AutoDatabaseSchemaEnum,
    };
    export type StatusEnum = "CLOSED" | "HIDDEN" | "OPEN" | "WRITING";
    export const StatusEnum = {
        CLOSED: "CLOSED" as StatusEnum,
        HIDDEN: "HIDDEN" as StatusEnum,
        OPEN: "OPEN" as StatusEnum,
        WRITING: "WRITING" as StatusEnum, // TODO: not in swagger
    };
}
