// @ts-ignore
// TODO: Move to TS ENUM!
import Enum from "enum";

const ProductTypeListEnum = new Enum({
    CHAT: 0,
    TWILIO: 1,
    CHATBOT: 2,
    MEDIQUO: 3,
    DOCLINE: 4,
    EXPRESS: 5,
    DELAYED: 6,
    EXTERNAL_CALL: 7,
    URL: 8,
});

export default ProductTypeListEnum;
