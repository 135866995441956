// @ts-ignore
// TODO: Move to TS ENUM!
import Enum from "enum";

const PaymentMethodEnum = new Enum({
    NONE: 0,
    APPLE_IN_APP_PURCHASE: 1,
    GOOGLE_IN_APP_PURCHASE: 2,
    OGONE: 3,
    REDSYS: 4,
    STRIPE: 6,
});

export default PaymentMethodEnum;
