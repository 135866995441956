import template from "modules/core/components/interface/button/button.html?raw";
import "modules/core/components/interface/button/button.scss";
import { MDCRipple } from "@material/ripple"; // @material/ripple/dist/mdc.ripple //Poniendo solo @material/ripple devuelve error en tiempo de ejecución: Reason: TypeError: Object prototype may only be an Object or null: undefined

import vue from "vue";

export default vue.extend({
    template,
    props: {
        onDismiss: {
            type: Function,
            required: false,
            default: () => {},
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        theme: {
            type: [String, Array],
            required: false,
            default: null,
        },
        simple: {
            type: Boolean,
            required: false,
            default: false,
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        light: {
            type: Boolean,
            required: false,
            default: false,
        },
        upper: {
            type: Boolean,
            required: false,
            default: false,
        },
        filled: {
            type: Boolean,
            required: false,
            default: false,
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false,
        },
        tag: {
            type: String,
            required: false,
            default: "button",
        },
        iconButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        badge: {
            type: Number,
            required: false,
            default: null,
        },
    },
    computed: {
        classDefinition(): string[] {
            const definition: string[] = [];

            if (this.light) {
                definition.push("mdk-ui-button--light");
            }

            if (this.outlined) {
                definition.push("mdc-button--outlined");
            }

            if (this.upper) {
                definition.push("mdk-ui-button--upper");
            }

            if (this.filled) {
                definition.push("mdk-ui-button--filled");
            }

            if (this.iconButton) {
                definition.push("mdc-icon-button material-icons");
                definition.push("mdk-ui-button--icon-button");

                if (this.theme === "variation1") {
                    definition.push("mdk-ui-button--variation1");
                }

                return definition;
            }
            definition.push("mdk-ui-button mdc-button");

            const themeDefinition: string[] = [];

            if (this.theme != null) {
                if (typeof this.theme === "string") {
                    if (this.theme === "variation1") {
                        definition.push("mdk-ui-button--variation1");
                    } else {
                        themeDefinition.push(this.theme);
                    }
                } else if (typeof this.theme === "object") {
                    themeDefinition.push(...(this.theme as any));
                }
            } else {
                if (!this.simple) {
                    themeDefinition.push("raised");
                }

                if (this.dense) {
                    themeDefinition.push("dense");
                }
            }

            definition.push(
                ...themeDefinition.map((style): string => {
                    return `mdc-button--${style}`;
                }),
            );

            return definition;
        },
    },
    mounted() {
        this.setup();
    },

    methods: {
        setup() {
            if (typeof MDCRipple !== "undefined") {
                const buttonRipple: MDCRipple = new MDCRipple(
                    this.$refs.target as Element,
                );

                if (this.iconButton) {
                    buttonRipple.unbounded = true;
                }
            }
        },
    },
});
