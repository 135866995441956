import template from "./cookies.html?raw";
import "./cookies.scss";
import {
    UIGridCell,
    UIGridRow,
    UIButton,
} from "modules/core/components/interface";
import { CoreComponentMixin, LocaleMixin } from "modules/core/mixins/ts";
import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin, LocaleMixin).extend({
    template,
    components: {
        "mdk-button": UIButton,
        "mdk-grid-cell": UIGridCell, // () => import("modules/core/components/interface/grid/GridCell"),
        "mdk-grid-row": UIGridRow,
    },
    data() {
        return {
            isVisible: true,
        };
    },
    computed: {
        description(): string {
            return this.localeText("tmk1637");
        },

        buttonText(): string {
            return this.localeText("tmk1639");
        },

        buttonRejectText(): string {
            return this.localeText("tmk1590");
        },
    },
    methods: {
        dismiss() {
            this.$emit("dismiss");

            this.destroy();
        },

        reject() {
            this.$emit("reject");

            this.destroy();
        },

        destroy() {
            this.isVisible = false;
        },
    },
});
