import { NavigationGuard, Route } from "vue-router";
import { MiddlewareInterface } from "modules/core/middleware/MiddlewareInterface";
import App from "modules/core/lib/App";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import AppState from "modules/core/lib/AppState";

export class AnimatedHeaderMiddleware implements MiddlewareInterface {
    private app: App;

    constructor(app: App) {
        this.app = app;
    }

    async handle(to: Route, from: Route, next: NavigationGuard): Promise<void> {
        setTimeout(() => {
            if (
                this.app.generalConfig().checker.enableAnimatedHeader &&
                !AppState.userHasInteracted &&
                !to.query.preventRedirection &&
                (to.name === RouteGenerator.getInstance().routeNameList.home ||
                    to.name ===
                        RouteGenerator.getInstance().routeNameList.checker)
            ) {
                // @ts-ignore
                next(RouteGenerator.getInstance().pageHomeSelector());
            }
        }, 100);
    }
}
