import vue from "vue";

export default vue.extend({
    template: `<div class="mdc-layout-grid__inner" :style="style"><slot></slot></div>`,
    props: {
        noGap: {
            type: Boolean,
            required: false,
            default: false,
        },
        widthGrid: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        style(): Record<string, string> {
            return {
                ...(this.widthGrid !== null && { width: this.widthGrid }),
                ...(this.noGap && { gap: "0" }),
            };
        },
    },
});
