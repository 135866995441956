import _merge from "lodash/merge";
import CryptoJS from "crypto-js";
import Vue, { ComponentOptions } from "vue";
import UI from "modules/core/lib/Ui";
import { PaymentCreateRequest } from "modules/core/model/paymentCreateRequest";
import { PaymentCreateResponse } from "modules/core/model/paymentCreateResponse";
import { PaymentVO } from "modules/core/model/paymentVO";
import { PaymentCheckResponse } from "modules/core/model/paymentCheckResponse";
import { PaymentCheckRequest } from "modules/core/model/paymentCheckRequest";
import { PaymentMethodCreateResponse } from "modules/core/model/paymentMethodCreateResponse";
import { PaymentMethodCreateRequest } from "modules/core/model/paymentMethodCreateRequest";
import { TakeAppointmentResponse } from "modules/core/model/takeAppointmentResponse";
import { ChatLineResponse } from "modules/core/model/chatLineResponse";
import { TakeAppointmentRequest } from "modules/core/model/takeAppointmentRequest";
import { ProductVO } from "modules/core/model/productVO";
import { ExternUserVO } from "modules/core/model/externUserVO";
import ProductPrice from "modules/core/lib/ProductPrice";
import { TierPricingVO } from "modules/core/model/tierPricingVO";
import { ChatLineRequest } from "modules/core/model/chatLineRequest";
import { ErrorResponse } from "modules/core/model/errorResponse";
import { MessageResponse } from "modules/core/model/messageResponse";
import { CheckTierPricingResponse } from "modules/core/model/checkTierPricingResponse";
import { Application } from "../lib/Holder";
import ProductBO from "./ProductBO";
import ChatLineBO from "./ChatLineBO";
import LocalizationBO from "./LocalizationBO";
import BusinessAbstract from "./BusinessAbstract";
import Message from "../../chat/models/params/Message";
import PaymentMethodEnum from "../enums/PaymentMethodEnum";
import Util from "../lib/Util";

export type ServiceTypes = "chatLine" | "takeAppointment";

export default class PaymentBO extends BusinessAbstract {
    static instance?: PaymentBO;

    isTest: boolean = true;

    baseDomain?: string;

    locales: Record<string, string> = {}; // [String rowId: String description]

    serviceType: string;

    tierPricing?: TierPricingVO;

    constructor(serviceType: string = "chatline") {
        super();

        this.serviceType = serviceType;
    }

    static getInstance(): PaymentBO {
        if (!PaymentBO.instance) {
            PaymentBO.instance = new this();
        }

        return PaymentBO.instance;
    }

    setIsTest(isTest: boolean) {
        this.isTest = isTest;
    }

    async createPaymentReference(
        externUserId: string,
        productId: string,
        quantity: number,
        totalPrice: number,
        paymentMethod: number,
    ): Promise<PaymentVO | null> {
        const paymentService = await this.api.service<PaymentCreateResponse>(
            "paymentCreate",
            {
                externUserId,
                productId,
                paymentMethod,
                quantity,
                totalPrice,
            } as PaymentCreateRequest,
        );

        if (paymentService && paymentService.payment) {
            return paymentService.payment;
        }

        return null;
    }

    async checkPayment(
        paymentId: string,
        paymentMethod: number,
        quantity: number,
    ): Promise<PaymentCheckResponse> {
        const paymentService = await this.api.service<PaymentCheckResponse>(
            "paymentCheck",
            {
                payment: {
                    paymentId,
                    paymentMethod,
                    quantity,
                },
            } as PaymentCheckRequest,
        );

        return paymentService;
    }

    async createPaymentMethod(
        id: string,
        type: number = 6,
    ): Promise<PaymentMethodCreateResponse> {
        const service = await this.api.service<PaymentMethodCreateResponse>(
            "paymentMethodCreate",
            {
                paymentMethod: {
                    id,
                    type,
                },
            } as PaymentMethodCreateRequest,
        );

        return service;
    }

    async createChatLine(
        externUserId: string,
        productId: string,
        createChatLine: boolean = true,
        sessionId?: string,
        serviceType: ServiceTypes = "chatLine",
        serviceTypeData: TakeAppointmentRequest | ChatLineRequest = {},
    ): Promise<TakeAppointmentResponse | ChatLineResponse | null> {
        if (serviceType === "chatLine") {
            return ChatLineBO.getInstance().doChatLine(
                externUserId,
                productId,
                createChatLine,
                sessionId,
            );
        }
        if (serviceType === "takeAppointment") {
            const options: TakeAppointmentRequest = _merge(
                {
                    productId,
                    sessionId,
                    createChatLine,
                    fullInfo: true,
                },
                serviceTypeData,
            );

            if (externUserId) options.externUserId = externUserId;

            const service = await this.api.service<TakeAppointmentResponse>(
                "takeAppointment",
                options,
            );

            return service;
        }

        return null;
    }

    async sendPaymentDataToRedsys(params: Record<string, any>) {
        const merchantParameters = {
            DS_MERCHANT_AMOUNT: params.amount,
            DS_MERCHANT_ORDER: params.orderReference,
            DS_MERCHANT_MERCHANTCODE: params.merchantCode,
            DS_MERCHANT_CURRENCY: params.currency,
            DS_MERCHANT_TRANSACTIONTYPE: "0",
            DS_MERCHANT_TERMINAL: "1",
            DS_MERCHANT_PAYMETHODS: "C",
            DS_MERCHANT_MERCHANTURL: params.notificationUrl,
            DS_MERCHANT_URLOK: params.successURL,
            DS_MERCHANT_URLKO: params.errorURL,
            DS_MERCHANT_MERCHANTDATA: params.paymentId,
        };

        const merchantParametersBase64 = CryptoJS.enc.Utf8.parse(
            JSON.stringify(merchantParameters),
        ).toString(CryptoJS.enc.Base64);
        const key = CryptoJS.enc.Base64.parse(params.secretKey);
        const iv = CryptoJS.enc.Hex.parse("0000000000000000");
        const encrypted = CryptoJS.TripleDES.encrypt(
            merchantParameters.DS_MERCHANT_ORDER,
            key,
            {
                iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.ZeroPadding,
            },
        );
        const signature = CryptoJS.HmacSHA256(
            merchantParametersBase64,
            encrypted.ciphertext,
        );
        const signatureBase64 = signature.toString(CryptoJS.enc.Base64);

        const formParams: Record<string, any> = {
            Ds_MerchantParameters: merchantParametersBase64,
            Ds_Signature: signatureBase64,
            Ds_SignatureVersion: "HMAC_SHA256_V1",
        };

        const endpoint = this.isTest
            ? "https://sis-t.redsys.es:25443/sis/realizarPago"
            : "https://sis.redsys.es/sis/realizarPago";

        const form = document.createElement("form");
        form.setAttribute("action", endpoint);
        form.setAttribute("method", "POST");
        form.setAttribute("style", "display: none");
        document.body.appendChild(form);

        for (const key in formParams) {
            const field = document.createElement("input");
            field.setAttribute("type", "hidden");
            field.setAttribute("name", key);
            field.setAttribute("value", formParams[key]);
            form.appendChild(field);
        }

        form.submit();
    }

    async processRedsys(
        sessionId: string,
        product: ProductVO,
        externUser: ExternUserVO,
        targetExternUserId?: string,
        productPriceInstance?: ProductPrice,
        onSuccess: (chatline: ChatLineResponse) => void = (chatLine) => {},
        gatewayParams: Record<string, any> = {},
        createChatline: boolean = false,
        serviceType: ServiceTypes = "takeAppointment",
    ) {
        // const self = this;

        try {
            let chatLine = await this.createChatLine(
                // @ts-ignore
                targetExternUserId ||
                    (externUser && externUser.externUserId
                        ? externUser.externUserId
                        : null),
                product.productId,
                createChatline,
                sessionId,
                serviceType,
            );
            chatLine =
                serviceType === "chatLine"
                    ? (chatLine as ChatLineResponse)
                    : (chatLine as TakeAppointmentResponse);

            if (!chatLine) return;
            if (chatLine && chatLine.error) return;

            if ("externUserGroup" in chatLine) {
                onSuccess(chatLine);

                return;
            }
            if (chatLine.priceTier == 0) {
                chatLine = (await this.createChatLine(
                    targetExternUserId || "",
                    product.productId || "",
                    true,
                    sessionId,
                    serviceType,
                )) as ChatLineResponse;

                onSuccess(chatLine);

                return;
            }

            const totalPrice =
                (chatLine.priceTier || 0) *
                (PaymentBO.getInstance().tierPricing?.totalPrice || 0); // productPriceInstance.totalPriceInCents();
            const paymentReference = await this.createPaymentReference(
                externUser.externUserId!,
                product.productId!,
                chatLine.priceTier!,
                totalPrice,
                PaymentMethodEnum.REDSYS.value,
            );

            const params = _merge(gatewayParams, {
                amount: totalPrice,
                orderReference:
                    paymentReference!
                        .externalReference /* + (new Date().getUTCMilliseconds()) */, // paymentReference.referenceForRedsys
                payerName: externUser.fullName,
                sellerName: "Mediktor",
                paymentId: paymentReference!.paymentId,
                currency: 978,
            });

            this.sendPaymentDataToRedsys(params);
        } catch (error) {
            console.info(error);
            this.managePaymentErrors(error as ErrorResponse, () => {
                this.processRedsys(
                    sessionId,
                    product,
                    externUser,
                    targetExternUserId,
                    productPriceInstance,
                    onSuccess,
                    gatewayParams,
                    true,
                );
            });
        }
    }

    async processStripe(
        domElement?: HTMLElement,
        sessionId?: string,
        product?: ProductVO,
        externUser?: ExternUserVO,
        onSuccess: (
            chatline: ChatLineResponse | TakeAppointmentResponse,
        ) => void = (chatLine) => {},
        gatewayParams: Record<string, any> = {},
        createChatline: boolean = false,
        serviceType: ServiceTypes = "takeAppointment",
        serviceTypeData: TakeAppointmentRequest | ChatLineRequest = {},
    ) {
        const preloader = UI.appendPreloaderTo(document.body);

        let chatLine = await this.createChatLine(
            externUser?.externUserId!,
            product!.productId!,
            createChatline,
            sessionId,
            serviceType,
            serviceTypeData,
        );
        chatLine =
            serviceType === "chatLine"
                ? (chatLine as ChatLineResponse)
                : (chatLine as TakeAppointmentResponse);

        if (
            (serviceType === "chatLine" &&
                (chatLine as ChatLineResponse).externUserGroup) ||
            (serviceType === "takeAppointment" &&
                (chatLine as TakeAppointmentResponse).appointment)
        ) {
            preloader.remove();

            onSuccess(chatLine);
        } else {
            const totalPrice =
                (chatLine.priceTier || 0) *
                (PaymentBO.getInstance().tierPricing?.totalPrice || 0);
            const paymentReference = await this.createPaymentReference(
                externUser?.externUserId!,
                product?.productId!,
                chatLine.priceTier!,
                totalPrice,
                PaymentMethodEnum.STRIPE.value,
            );
            const productObject = ProductBO.getInstance().getProductById(
                product?.productId!,
            );

            await this.showCardModal(
                async (token: string) => {
                    const payment = await this.createPaymentMethod(
                        token,
                        PaymentMethodEnum.STRIPE.value,
                    );
                    const preloader = UI.appendPreloaderTo(document.body);

                    const checkPayment = await this.checkPayment(
                        paymentReference?.paymentId!,
                        PaymentMethodEnum.STRIPE.value,
                        (chatLine as ChatLineResponse).priceTier!,
                    );
                    if (checkPayment) {
                        const checkChatline = await this.createChatLine(
                            externUser?.externUserId!,
                            product?.productId!,
                            createChatline,
                            sessionId,
                            serviceType,
                            serviceTypeData,
                        );

                        checkChatline && onSuccess(checkChatline);
                    }

                    preloader.remove();
                },
                productObject?.name!,
                paymentReference!,
                false,
                domElement,
            );

            preloader.remove();
            // } else {
            //    this.checkPayment(paymentReference.paymentId, PaymentMethodEnum.STRIPE.value, this.paymentQuantity);
            // }*/
        }
    }

    async managePaymentErrors(error: ErrorResponse, callback = () => {}) {
        const self = this;

        if (error.code == "ME307") {
            UI.dismissAll("alert"); // default alert

            UI.showModal("", error.description as string, [
                {
                    label: LocalizationBO.getInstance().localeText("Yes"),
                    async action(el, component: any) {
                        component.$destroy();

                        callback();
                    },
                },
                {
                    label: LocalizationBO.getInstance().localeText("No"),
                    action(el, component: any) {
                        component.$destroy();
                    },
                },
            ]);
        }
    }

    async showCardModal(
        onSelection: (token: string) => void,
        modalTitle: string,
        paymentReference: PaymentVO,
        extendedInfo: boolean = false,
        domElement?: HTMLElement,
    ): Promise<{ modal: Vue; paymentComponent: Vue } | null> {
        const Stripe = await import("modules/payments/stripe/Stripe");
        let modal: Vue;

        const paymentComponent = Util.mountComp(
            Stripe.default as ComponentOptions<Vue>,
            {
                paymentReference,
                extendedInfo,
            },
        );
        paymentComponent.$on("done", (token: string) => {
            if (modal) modal.$destroy();

            onSelection(token);
        });

        if (domElement) {
            domElement.append(paymentComponent.$el);
        } else {
            modal = UI.showModal(
                modalTitle /* LocalizationBO.getInstance().localeText("payment.iniciarProcesoPago") */,
                paymentComponent.$el,
            );

            return { modal, paymentComponent };
        }

        return null;
    }

    // pasar a chat
    // TODO: falta typeing de la request
    async sendMessage(
        productId: string,
        externUser: ExternUserVO,
        externUserId: string,
        externUserGroupId: string,
    ) {
        await this.api.service<MessageResponse>("message", {
            // @ts-ignore
            message: new Message(
                // @ts-ignore
                JSON.stringify({
                    relation: {
                        productId,
                        myDescription: "Chatear con %FULLNAME%",
                        externUserGroupMembers: [
                            {
                                externUserId: externUser.externUserId,
                                externUser: {
                                    externUserId: externUser.externUserId,
                                    fullName: externUser.fullName,
                                    imageFace: externUser.imageFace,
                                },
                            },
                        ],
                    },
                }),
                // @ts-ignore
                externUserId,
                // @ts-ignore
                externUserGroupId,
                // @ts-ignore
                "RELATION",
                // @ts-ignore
                true,
            ),
        });
    }

    async openAddCreditView(success: () => void = () => {}) {
        const module = await import(
            "modules/user/components/add-credit/AddCredit"
        );
        const component = Util.mountComp(module.default as unknown as Vue);

        UI.showModal(
            LocalizationBO.getInstance().localeText("tmk1598"),
            component.$el,
        );

        component.$on("submit", async (amount: number) => {
            // const amount = (parseInt(amount) * 1) / this.app().generalConfig().productUnitPrice;
            const { externUser } = Application.getInstance();
            const paymentBO = PaymentBO.getInstance();

            const totalPrice =
                amount * (PaymentBO.getInstance().tierPricing?.totalPrice ?? 0);

            const paymentReference = await paymentBO.createPaymentReference(
                externUser?.externUserId!,
                // @ts-ignore
                null,
                amount,
                totalPrice,
                PaymentMethodEnum.STRIPE.value,
            );

            const paymentModal = await paymentBO.showCardModal(
                async (token) => {
                    await paymentBO.createPaymentMethod(
                        token,
                        PaymentMethodEnum.STRIPE.value,
                    );

                    const preloader = UI.appendPreloaderTo(document.body);

                    try {
                        await paymentBO.checkPayment(
                            paymentReference?.paymentId!,
                            PaymentMethodEnum.STRIPE.value,
                            amount,
                        );

                        await Application.getInstance().fetchUserData();

                        success();
                    } catch (e) {}

                    preloader.remove();
                },
                LocalizationBO.getInstance().localeText("info_buyticket"),
                paymentReference!,
                true,
            );

            // @ts-ignore
            paymentModal.paymentComponent.$on("cancel", () => {
                this.openAddCreditView();
            });
        });
    }

    async doCheckTierPricing(): Promise<CheckTierPricingResponse> {
        return this.api.service<CheckTierPricingResponse>("checkTierPricing");
    }

    async pullTierPricing() {
        const service = await this.doCheckTierPricing();

        if (service && service.tierPricing) {
            this.tierPricing = service.tierPricing;
        }
    }
}
