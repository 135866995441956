// @ts-ignore
import template from "modules/specialists/components/specialist-card/specialist-card-overview.html?raw";
import "modules/specialists/components/specialist-card/specialist-card-overview.scss";
import { CoreComponentMixin, LocaleMixin } from "modules/core/mixins/ts";
import UIRating from "modules/core/components/interface/rating/Rating";
import ProfileImage from "modules/user/components/profile/profile-image/ProfileImage";
import { ExternUserVO } from "modules/core/model/externUserVO";
import ProductPrice from "../../../core/lib/ProductPrice";
import { UIIcon, UIButton } from "../../../core/components/interface";
import PaymentBO from "../../../core/business/PaymentBO";
import mixins from "vue-typed-mixins";

export default mixins(LocaleMixin, CoreComponentMixin).extend({
    template,
    components: {
        UIRating,
        ProfileImage,
        UIIcon,
        UIButton,
    },
    props: {
        externUser: { type: Object, required: true, default: null },
        showRating: { type: Boolean, required: false, default: true },
        specialtyBadgeImage: { type: String, required: false, default: null },
        editable: { type: Boolean, required: false, default: false },
    },

    computed: {
        title(): string {
            return this.placeholderedContent(
                () => this.externUser.fullName ?? "",
                20,
            );
        },

        ratingCount(): number {
            if (!this.mainContentIsReady()) return 0;

            return this.externUser.valuationCount ?? 0;
        },

        rating(): number {
            if (!this.mainContentIsReady()) return 0;

            return this.externUser.valuation ?? 0;
        },

        categoryName(): string | null {
            if (!this.mainContentIsReady()) return null;

            if (this.externUser?.externUserSpecialties) {
                if (this.externUser.externUserSpecialties.length > 0) {
                    return (
                        this.externUser.externUserSpecialties[0].name ?? null
                    );
                }
            }

            return null;
        },

        availableBalanceText(): string {
            return this.localeText("tmk1596");
        },

        ticketsDescription(): string | null {
            if (this.appGeneralConfig.showUserAvailableTickets) {
                let priceDescription = "";

                if (this.$app.externUser?.tickets) {
                    const productPrice = new ProductPrice(
                        null,
                        this.$app.externUser.tickets,
                    );

                    priceDescription = productPrice.description();
                } else {
                    const tier = PaymentBO.getInstance().tierPricing;

                    priceDescription = `0 ${tier?.currency ?? ""}`;
                }

                return priceDescription;
            }

            return "";
        },

        addCreditIsVisible(): boolean {
            return (
                this.appGeneralConfig.showUserAvailableTickets &&
                this.appGeneralConfig.adddCreditToAccountEnabled &&
                this.appGeneralConfig.paymentGatewayType === "stripe"
            );
        },

        addCreditText(): string {
            return this.localeText("tmk1597");
        },

        ratingIsVisible(): boolean {
            return (
                this.showRating &&
                this.appGeneralConfig.userValuationsVisibilityEnabled
            );
        },

        creditsIsVisible(): boolean {
            if (this.$app.externUser) {
                const userIsPartner = this.$app.externUser.isPartner;

                return (
                    !userIsPartner ||
                    (userIsPartner &&
                        this.appGeneralConfig.creditVisibleForPartner)
                );
            }

            return false;
        },
    },

    methods: {
        mainContentProp(): ExternUserVO {
            return this.externUser;
        },

        onImageUpdate() {},

        async addCredit(): Promise<void> {
            await PaymentBO.getInstance().openAddCreditView();
        },
    },
});
