import vue from "vue";
import Api from "modules/core/lib/Api";

export const ApiMixin = vue.extend({
    data() {
        return {
            api: Api.getInstance() as Api,
        };
    },
    methods: {
        /**
         * @deprecated
         */
        authUserId(): string {
            return Api.getInstance().userId;
        },
    },
});
