import mixins from "vue-typed-mixins";
import template from "./legal-images.html?raw";
import "./legal-images.scss";
import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";

export default mixins(LocaleMixin, CoreComponentMixin).extend({
    template,
    computed: {
        imagesList() {
            return [
                require("./img/icon-ce-dark.png"),
                require("./img/icon-gdpr-dark.png"),
                require("./img/icon-hippa-dark.png"),
                require("./img/icon-validation-dark.png"),
            ];
        },
    },
});
