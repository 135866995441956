import _findIndex from "lodash/findIndex";
import _orderBy from "lodash/orderBy";
import _slice from "lodash/slice";
import _filter from "lodash/filter";

type GenericFoo<KeyT extends PropertyKey, ValueT> = {
    [Key in KeyT]?: { [Key2 in Key]?: ValueT };
}[KeyT];

type Item<T extends string> = GenericFoo<T, string> & {
    lastEdited?: number;
    externUserId?: string;
};

type OrderTypes = "desc" | "asc";

export default abstract class GenericDAO<T extends string, K extends Item<T>> {
    items: K[] = [];

    protected readonly idColumnName!: T;

    addItems(items: K[], checkLastEdited: boolean = true) {
        for (const item of items) {
            this.addItem(item, checkLastEdited);
        }
    }

    // Alias of addItems()
    setItems(items: K[], checkLastEdited: boolean = true) {
        this.addItems(items, checkLastEdited);
    }

    addItem(item: K, checkLastEdited: boolean = true) {
        const storedItem = this.findItemById(item[this.idColumnName]!);

        if (storedItem === undefined) {
            this.items.push(item);
        } else if (
            !checkLastEdited ||
            (checkLastEdited &&
                item.lastEdited !== undefined &&
                item.lastEdited > storedItem.lastEdited!)
        ) {
            this.updateItem(item);
        }
    }

    // Alias of addItem()
    setItem(item: K, checkLastEdited = true) {
        return this.addItem(item, checkLastEdited);
    }

    removeItemById(id: string) {
        this.items = this.items.filter(
            // @ts-ignore
            (item) => item[this.idColumnName] !== id,
        );
    }

    // Alias of getItemById()
    findItemById(id: string): K {
        // @ts-ignore
        return this.items.find((item) => item[this.idColumnName] === id);
    }

    getItemById(id: string): K {
        return this.findItemById(id);
    }

    updateItem(item: K) {
        const index = _findIndex(
            this.items,
            // @ts-ignore
            (element) => element[this.idColumnName] === item[this.idColumnName],
        );

        this.items.splice(index, 1, item);
    }

    getListIds(): string[] {
        // @ts-ignore
        return this.items.map((item) => item[this.idColumnName]);
    }

    getList(offset = 0, count = Infinity): K[] {
        return _slice(this.items, offset, offset + count);
    }

    getListOrderedByDate(
        order: OrderTypes = "desc",
        offset: number = 0,
        count: number = Infinity,
    ): K[] {
        return _slice(
            _orderBy(this.items, ["date"], order),
            offset,
            offset + count,
        );
    }

    getListOrderedByDateExternUserId(
        order: OrderTypes = "desc",
        offset: number = 0,
        count: number = Infinity,
        externUserId: string | null = null,
    ): K[] {
        if (externUserId) {
            const filteredItems = _filter(
                this.items,
                (item) => item.externUserId === externUserId,
            );

            return _slice(
                _orderBy(filteredItems, ["date"], order),
                offset,
                offset + count,
            );
        }

        return [];
    }

    cleanAll() {
        this.items = [];
    }
}
