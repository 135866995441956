import template from "modules/user/components/login/mychart-button/mychart-button.html?raw";
import "modules/user/components/login/mychart-button/mychart-button.scss";

import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";
import OrganizationBO from "modules/core/business/OrganizationBO";
import { Ui } from "modules/core";
import { OrganizationEndpointVO } from "modules/core/model/organizationEndpointVO";
import ModalComponent from "modules/core/components/interface/modal/Modal";
import CoreUtil from "../../../../core/lib/Util";
import mixins from "vue-typed-mixins";

import icon from "./img/mychart-icon.png";

type Modal = typeof ModalComponent;

export default mixins(LocaleMixin, CoreComponentMixin).extend({
    template,
    props: {
        organizationId: { required: false, type: String, default: null },
        epicMode: { default: null, required: false, type: Number },
    },
    data() {
        return {
            organizationListModal: null as Modal | null,
        };
    },
    computed: {
        myChartImg(): string {
            return icon;
        },
        myChartText(): string {
            return this.localeText("tmk1421", {
                METHOD: this.localeText("tmk1423"),
            });
        },
    },
    methods: {
        async tapAction() {
            const self = this;

            if (!CoreUtil.isNullOrEmpty(this.organizationId)) {
                this.onOrganizationListSelect(
                    this.organizationId,
                    this.epicMode,
                );

                return;
            }

            const module = await import(
                "modules/user/components/login/organization-selector/OrganizationSelector"
            );

            const component = await CoreUtil.mountComponent(
                module.default as unknown as Vue,
                {
                    appInstance: this.$app,
                    onSelect(item: OrganizationEndpointVO) {
                        item.organizationId &&
                            self.onOrganizationListSelect(
                                item.organizationId,
                                self.epicMode,
                            );
                    },
                },
            );

            const title = `<img src="${
                this.myChartImg
            }" style="width:30px; height:auto; float:left; margin-right:5px; position:relative" /> ${this.localeText(
                "tmk1424",
            )}`;

            this.organizationListModal = Ui.showModal(
                title,
                component.$el,
            ) as any;
            // this.organizationListModal = Ui.showModal(title, component.$el) as Modal;
        },

        onOrganizationListSelect(organizationId: string, epicMode?: number) {
            if (this.organizationListModal) {
                (this.organizationListModal as any).destroy();
            }

            this.withPreloader(async () => {
                OrganizationBO.getInstance().doOrganizationEndpoint(
                    organizationId,
                    epicMode,
                );
            });
        },
    },
});
