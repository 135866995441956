import Util from "@mediktor-web/sdk/src/modules/core/lib/Util";
import vue from "vue";

export default vue.extend({
    props: [
        "type",
        "placeholder",
        "min",
        "max",
        "disabled",
        "val",
        "required",
        "autofocus",
        "value",
        "checked",
        "name",
        "maxlength",
        "step",
        "iconText",
        "pattern",
    ],

    computed: {
        fieldId() {
            return Util.uniqueId();
        },
    },

    methods: {
        onInput() {
            this.$emit("input", this.value);
        },
    },
});
