import template from "./icon-state.html?raw";
import "./icon-state.scss";
import UIIcon from "modules/core/components/interface/icon/Icon";

import vue from "vue";

export default vue.extend({
    name: "IconState",
    template,
    components: {
        UIIcon,
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: true,
        },
        icon: {
            type: String,
            required: true,
            default: "",
        },
        stateIcon: {
            type: String,
            required: false,
            default: "lock",
        },
    },
    computed: {
        classes(): Record<string, boolean> {
            return {
                "mdk-ui-icon-state--disabled": !this.active,
            };
        },
    },
});
