import GoogleSignInBO from "modules/core/business/GoogleSignInBO";

export default class Utils {
    static async startGoogleSignin(
        elementTrigger: HTMLElement,
        googleSigninId: string,
        onResponse: Function,
    ): Promise<void> {
        const googleSignIn = new GoogleSignInBO(googleSigninId);
        await googleSignIn.startGoogleSignin(elementTrigger, async (token) => {
            const response = await googleSignIn.doRegisterForGoogleSSO(
                token.credential,
            );

            if (response) {
                onResponse(response);
            }
        });
    }
}
