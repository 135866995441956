// @ts-nocheck
import template from "modules/core/components/interface/modal/modal.html?raw";
import "modules/core/components/interface/modal/modal.scss";
import { QCard, QCardActions, QCardSection, QBtn, QDialog } from "quasar";
import Button from "modules/core/components/interface/button/Button";

import vue from "vue";

type Component = any;

export type ModalActionItem = {
    label: string;
    action: (el: HTMLElement, component: Component) => void;
    style?: string | Record<string, any>;
};

export default vue.extend({
    template,
    components: {
        MdkUiButton: Button,
        QCard,
        QCardActions,
        QCardSection,
        QBtn,
        QDialog,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        canClose: {
            type: Boolean,
            required: false,
            default: true,
        },
        buttonClose: {
            type: Boolean,
            required: false,
            default: true,
        },
        overlay: {
            type: Boolean,
            required: false,
            default: true,
        },
        actions: {
            type: Array,
            required: false,
            default: () => [],
        },
        onClose: {
            type: Function,
            required: false,
            default: () => {},
        },
        type: {
            type: String,
            required: false,
            default: "alert",
        },
        background: {
            type: Boolean,
            required: false,
            default: true,
        },
        mainClass: {
            type: String,
            required: false,
            default: "",
        },
        contentNoPadding: {
            type: Boolean,
            required: false,
            default: false,
        },
        fixedContent: {
            type: Boolean,
            required: false,
            default: true,
        },
        bigSize: {
            type: Boolean,
            required: false,
            default: false,
        },
        bigSizeWithIcon: {
            type: Boolean,
            required: false,
            default: true,
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
        content: {
            type: [String, Element],
            required: false,
            default: "",
        },
        preventClick: {
            type: Boolean,
            required: false,
            default: false,
        },
        copyrightText: {
            type: String,
            required: false,
            default: "",
        }
    },
    data() {
        return {
            isVisible: true,
            isOpen: false,
        };
    },
    computed: {
        getDefinitionContainerClass(): Record<string, boolean> {
            return {
                "mdk-ui-modal__container--fixed": this.fixedContent,
            };
        },
        getDefinitionMediaClass(): Record<string, boolean> {
            return {
                "mdk-ui-modal__image": this.isImage,
                "mdk-ui-modal__video": this.isVideo,
            };
        },
        isModal(): boolean {
            return this.type === "modal" || this.isAlert;
        },
        isAlert(): boolean {
            return this.type === "alert";
        },
        isImage(): boolean {
            return this.type === "image";
        },
        isVideo(): boolean {
            return this.type === "video";
        },
        bodyContent(): string {
            if (typeof this.content === "string") {
                return this.content;
            }
            return "";
        },
        imageStyle(): Record<string, string> {
            return {
                backgroundImage: `url(${this.content})`,
            };
        },
        hasActions(): boolean {
            return this.parsedActions.length > 0;
        },
        buttonCloseIsVisible(): boolean {
            return this.buttonClose && this.canClose && !this.isAlert;
        },
        parsedActions(): ModalActionItem[] {
            const actions: ModalActionItem[] = [];

            if (this.actions && this.actions.length > 0) {
                actions.push(...this.actions);
            }

            return actions;
        },
        classDefinition(): string {
            return `mdk-modal--type-${this.type} ${this.mainClass}`;
        },
        getDefinitionClassContent(): Record<string, boolean> {
            return {
                "mdk-ui-modal__surface--fixed": this.fixedContent,
                "mdk-ui-modal__surface--big-with-icon": this.bigSizeWithIcon,
            };
        },
        locales(): string[] {
            return ["ok", "tmk167"];
        },
        classes(): Record<string, boolean> {
            return {
                "mdc-dialog--open": this.isOpen,
                "mdk-ui-modal--content-no-padding": this.contentNoPadding,
                "mdk-ui-modal--type-alert": this.isAlert,
            };
        },
    },
    methods: {
        close() {
            if (!this.canClose) return;

            this.onClose(this.$el, this);

            this.destroy();
        },
        dontClose(e: UIEvent) {
            e.stopPropagation();
            e.preventDefault();
        },
        destroy() {
            this.isVisible = false;
        },
        executeAction(action: (el: Element, component: Component) => {}) {
            action(this.$el, this);
        },
    },
    mounted() {
        if (typeof this.content !== "string") {
            (this.$refs.content as HTMLElement).appendChild(this.content);
        }
        this.isOpen = true;
    },
});
