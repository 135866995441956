import template from "modules/core/components/interface/checkbox/checkbox.html?raw";
import { MDCFormField } from "@material/form-field";
import { MDCCheckbox } from "@material/checkbox";
import "modules/core/components/interface/checkbox/checkbox.scss";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,
    model: {
        prop: "model",
        event: "change",
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "checkbox-id-default",
        },
        name: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: String,
            required: false,
            default: null,
        },
        checked: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
            default: null,
        },
        light: {
            type: Boolean,
            required: false,
            default: false,
        },
        model: {
            type: [String, Array, Boolean],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            uniqueId: "",
        };
    },

    watch: {
        checked(newValue: boolean) {
            if (newValue !== this.checkboxState) {
                this.toggle();
            }
        },
    },

    computed: {
        checkboxState(): boolean {
            if (!this.model) return this.checked;
            if (Array.isArray(this.model))
                return this.model.indexOf(this.value as string) !== -1;

            return !!this.model;
        },
        classDefinition(): string[] {
            const definition: string[] = [];

            if (this.light) {
                definition.push("mdk-ui-checkbox--light");
            }

            if (this.color) {
                definition.push(`mdk-ui-checkbox--color-${this.color}`);
            }

            return definition;
        },
    },
    methods: {
        setup() {
            const checkbox = new MDCCheckbox(
                this.$refs.checkbox as HTMLElement,
            );
            const formField = new MDCFormField(this.$el);

            formField.input = checkbox;
        },
        genId() {
            if (!this.id)
                this.uniqueId = `m-checkbox--${Math.random()
                    .toString(36)
                    .substring(2, 10)}`;
            else this.uniqueId = this.id;
        },
        toggle(): boolean | string[] | unknown {
            if (!this.disabled) {
                let value = this.model;
                if (Array.isArray(value) && typeof this.value === "string") {
                    value = value.slice();
                    const i = value.indexOf(this.value);
                    if (i === -1) value.push(this.value);
                    else value.splice(i, 1);
                } else value = !this.checkboxState;

                return this.$emit("change", value);
            } else return this.$emit("change", this.checked);
        },
    },
    mounted() {
        this.setup();
        this.genId();

        if (this.checked && !this.checkboxState) {
            this.toggle();
        }
    },
});
