import _merge from "lodash/merge";
import { ConclusionListResponse } from "modules/core/model/conclusionListResponse";
import { ConclusionVO } from "modules/core/model/conclusionVO";
import { ConclusionDetailRequest } from "modules/core/model/conclusionDetailRequest";
import { ConclusionDetailResponse } from "modules/core/model/conclusionDetailResponse";
import { SessionConclusionDetailResponse } from "modules/core/model/sessionConclusionDetailResponse";
import { SessionConclusionDetailRequest } from "modules/core/model/sessionConclusionDetailRequest";
import { ActionSearchRequest } from "modules/core/model/actionSearchRequest";
import { ActionSearchResponse } from "modules/core/model/actionSearchResponse";
import { ConclusionSearchRequest } from "modules/core/model/conclusionSearchRequest";
import { ConclusionSearchResponse } from "modules/core/model/conclusionSearchResponse";
import BusinessAbstract from "./BusinessAbstract";

export default class ConclusionBO extends BusinessAbstract {
    static instance?: ConclusionBO;

    static getInstance(): ConclusionBO {
        if (!ConclusionBO.instance) {
            ConclusionBO.instance = new this();
        }

        return ConclusionBO.instance;
    }

    async fetchConclusionList(): Promise<ConclusionVO[]> {
        const response: ConclusionListResponse = await this.api.service(
            "conclusionList",
            { useCache: 24 * 7 },
        );

        if (response && !response.error) {
            return response.conclusions || [];
        }

        return [];
    }

    async fetchConclusionDetail(
        conclusionId: string,
        conclusionTemplate: string = "conclusion_description_body",
        params: ConclusionDetailRequest = {},
    ): Promise<ConclusionVO> {
        const response: ConclusionDetailResponse = await this.api.service(
            "conclusionDetail",
            _merge(
                {
                    conclusionId,
                    conclusionTemplate,
                },
                params,
            ),
        );

        if (response?.conclusion && !response.error) {
            return response.conclusion;
        }

        return {};
    }

    async fetchSessionConclusionDetail(
        sessionId: string,
        conclusionId: string,
        params: SessionConclusionDetailRequest = {},
    ): Promise<SessionConclusionDetailResponse> {
        return this.api.service(
            "sessionConclusionDetail",
            _merge({ conclusionId, sessionId }, params),
        );
    }

    async doConclusionSearch(
        data: ConclusionSearchRequest,
    ): Promise<ConclusionSearchResponse> {
        return this.api.service<ConclusionSearchResponse>(
            "conclusionSearch",
            data,
        );
    }
}
