/* global environment */

import { environment } from "modules/core/lib/EnvironmentConfig";
// Domain handling
try {
    if (typeof environment !== undefined) {
        if (environment.APP_DOMAIN && environment.FORCE_DOMAIN) {
            if (!window.location.origin.match(environment.APP_DOMAIN)) {
                window.location.href =
                    environment.APP_DOMAIN +
                    window.location.href.substr(window.location.origin.length);
            }
        }
    }
} catch (error) {
    console.info(error);
}

export {};
