import _merge from "lodash/merge";

export default class ExternUserDAO {
  constructor() {
    this.externUserList = {};
  }

  setExternUser(externUser) {
    this.externUserList[externUser.externUserId] = externUser;
  }

  getExternUserById(externUserId) {
    if (this.externUserList[externUserId])
      return this.externUserList[externUserId];

    return null;
  }

  updateExternUser(externUser) {
    this.externUserList[externUser.externUserId] = _merge(
      this.externUserList[externUser.externUserId],
      externUser,
    );
  }

  getImageFaceById(externUserId) {
    if (this.externUserList[externUserId]) {
      return this.externUserList[externUserId].imageFace;
    }

    return null;
  }

  getExternUserList() {
    // TODO: map d'aquells usuaris que siguin especialistes.
    return null;
  }

  getExternUserListIds() {
    return Object.keys(this.externUserList);
  }

  getUserNameById(externUserId) {
    if (this.externUserList[externUserId]) {
      return this.externUserList[externUserId].fullName;
    }

    return null;
  }
}
