import template from "modules/core/components/interface/pagination-button/pagination-button.html?raw";
import "modules/core/components/interface/pagination-button/pagination-button.scss";
import UILoader from "modules/core/components/interface/loader/Loader";
import UIButton from "modules/core/components/interface/button/Button";
import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(LocaleMixin, CoreComponentMixin).extend({
    template,
    components: {
        "mdk-ui-loader": UILoader,
        "mdk-ui-button": UIButton,
    },
    props: {
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
        onSubmit: {
            type: Function,
            required: false,
            default: (page: string) => {},
        },
        simple: {
            type: Boolean,
            required: false,
            default: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        count: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    computed: {
        locales(): string[] {
            return ["tmk1372"];
        },
        placeholderText() {
            const text =
                this.placeholder ||
                this.localeText(
                    "tmk1372",
                    { NUM: "" /* this.count != null ? this.count : "" */ },
                    "Load %NUM% more",
                );

            return `${text}`;
        },
    },
});
