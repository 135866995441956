import mixins from "vue-typed-mixins";
import template from "modules/core/components/interface/tabs/tabs.html?raw";
import "modules/core/components/interface/tabs/tabs.scss";
import { MDCTabBar } from "@material/tab-bar";
import { CoreComponentMixin } from "modules/core/mixins/ts";

export default mixins(CoreComponentMixin).extend({
    template,
    props: {
        tabs: {
            type: Array,
            required: true,
            default: [],
        },
        onSelection: {
            type: Function,
            required: false,
            default: (index: string) => {},
        },
        selected: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selectedTab: 0,
        };
    },
    watch: {
        selected(newVal: number, oldVal: number) {
            this.selectedTab = newVal;
        },
    },
    methods: {
        setup() {
            const tabBar = new MDCTabBar(this.$el);
        },
        selectTab(index: number) {
            this.selectedTab = index;
            this.onSelection?.(index);
        },
    },
    mounted() {
        this.setup();
        this.selectedTab = this.selected;
    },
});
