import Vue from "vue";
import Util from "./Util";
import AppStorageManager from "./AppStorageManager";
import { AppQueryContentManager } from "./AppQueryContentManager";
import { Application } from "./Holder";
import EventBusSingleton from "./EventBusSingleton";

export default class CookiesAlertManager {
    static instance?: CookiesAlertManager;

    component: Vue | null = null;

    rejected: boolean = false;

    static getInstance() {
        if (!CookiesAlertManager.instance) {
            CookiesAlertManager.instance = new this();
        }

        return CookiesAlertManager.instance;
    }

    isAccepted(): boolean {
        return AppStorageManager.getInstance().getCookies();
    }

    shouldBeVisible(): boolean {
        return !(
            AppQueryContentManager.getInstance().isAppContent ||
            AppStorageManager.getInstance().getCookies()
        );
    }

    /*
     * @deprecated The component now mounts in layout component
     */
    async mountAndShow() {
        if (!this.shouldBeVisible()) {
            return;
        }

        const component = await import(
            "modules/user/components/cookies/Cookies"
        );

        const mountedComponent = await Util.mountComponent(
            component.default as unknown as Vue,
        );
        mountedComponent.$on("dismiss", () => {
            this.dismiss();
        });
        mountedComponent.$on("reject", () => {
            this.reject();
        });

        this.component = mountedComponent;

        document.body.appendChild(mountedComponent.$el);
    }

    dismiss() {
        AppStorageManager.getInstance().saveCookies(true);

        EventBusSingleton.emit("cookiesAccepted");

        this.rejected = false;
    }

    reject() {
        this.rejected = true;
    }

    close() {
        if (this.component) {
            this.component.$destroy();
        }

        AppStorageManager.getInstance().saveCookies(true);
    }

    async update() {
        if (this.component && !this.rejected) {
            this.component.$destroy();
            await this.mountAndShow();
        }
        if (!this.component) {
            await this.mountAndShow();
        }
    }
}
