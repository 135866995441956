// @ts-nocheck
import _isEmpty from "lodash/isEmpty";
import vue, { PropType } from "vue";
import UI, { UiComponent } from "modules/core/lib/Ui";
import Util from "modules/core/lib/Util";
import { GeneralConfig } from "modules/core/types/misc";
import { ExternUserVO } from "modules/core/model/externUserVO";
import eventBus from "../../lib/EventBusSingleton";
import DarkModeManager from "../../lib/DarkModeManager";

export const CoreComponentMixin = vue.extend({
    data() {
        return {
            isLoading: false,
            mainIsVisible: true,
            preloader: undefined as UiComponent,
            events: [] as any[],
            isDarkMode: false,
            dataTheme: "light",
        };
    },

    props: {
        onLoading: {
            required: false,
            default: () => {},
            type: Function,
        },

        onActionEvent: { required: false, type: Function },

        customGeneralConfig: {
            required: false,
            default: null,
            type: Object as PropType<GeneralConfig>,
        },
    },

    methods: {
        /**
         * @deprecated
         */
        prop(type: any = String, required: boolean = false, def: any = null) {
            return Util.propDefinition(type, required, def);
        },

        /**
         * @deprecated
         */
        currentRouteParam(name: string): string | string[] {
            try {
                return this.$router.currentRoute.params[name];
            } catch (e) {
                return null;
            }
        },

        /**
         * @deprecated
         */
        currentRouteQueryParam(name: string): string | string[] {
            try {
                return this.$router.currentRoute.query[name];
            } catch (e) {
                return null;
            }
        },

        /**
         * @deprecated
         */
        currentRouteQuery(name: string): string | string[] {
            try {
                return this.$router.currentRoute.query[name];
            } catch (e) {
                return null;
            }
        },

        emitEvent(name: string, value?: any) {
            eventBus.emit(name, value);
        },

        catchEvent(name: string, callback: (...args: any[]) => void) {
            const listener = eventBus.on(name, callback);

            this.events?.push(listener);
        },

        removeEvents() {
            for (const event of this.events) {
                eventBus.off(event);
            }
        },

        mainContentProp(): ExternUserVO {
            return null;
        },

        mainContentIsReady(): boolean {
            if (this.mainContentProp() == null) return false;

            return !_isEmpty(this.mainContentProp());
        },

        placeholderedContent(
            content: () => string,
            viewHeight: number = null,
        ): string {
            if (this.mainContentIsReady()) {
                return content();
            }

            return this.viewPlaceholderTemplate(viewHeight);
        },

        viewPlaceholderTemplate(height: number = null): string {
            const h = height == null ? "100%" : `${height}px`;

            return `<div style="width:80%; min-height:${h}; background-color:#f2f2f2; margin:5px auto;"></div>`;
        },

        actionEvent(name: string, data: any = null) {
            this.onActionEvent(name, data);
            this.emitEvent(name, data);
        },

        destroy() {
            this.mainIsVisible = false;
        },

        console(...params: any[]) {
            try {
                console.log.apply(console, [
                    ...([`[${this.constructor.name}]`] || []),
                    ...params,
                ]);
            } catch (e) {}
        },

        init() {
            // Method to be called on mounted. Every component should implement this method.
        },

        showPreloader(
            delaySpinner: boolean = false,
            boxed: boolean = false,
            size: number = 35,
        ) {
            this.hidePreloader();

            this.$nextTick(() => {
                this.preloader = UI.appendPreloaderTo(
                    this.$el,
                    delaySpinner,
                    boxed,
                    size,
                );
            });
        },

        hidePreloader() {
            if (this.preloader) {
                this.preloader.remove();
            }
        },

        async withPreloader(
            callbackAction: () => Promise<void>,
            callbackErr = (err: Error) => {},
        ) {
            this.showPreloader();

            try {
                await callbackAction();
            } catch (err) {
                callbackErr(err);
            } finally {
                this.$nextTick(() => this.hidePreloader());
            }
        },

        setColorMode() {
            this.isDarkMode = DarkModeManager.getInstance().darkMode;
            this.dataTheme = DarkModeManager.getInstance().dataTheme;
        },
    },
    computed: {
        commonInputAttributes(): Record<string, any>[] {
            return [this.appGeneralConfig.interface.inputAttributes];
        },
        commonButtonAttributes(): Record<string, any>[] {
            return [this.appGeneralConfig.interface.buttonAttributes];
        },
        componentId(): string {
            return Util.uniqueId();
        },
        $module(): string {
            return `mdk-${this.componentId}`;
        },
        appGeneralConfig(): GeneralConfig {
            return this.customGeneralConfig
                ? this.customGeneralConfig
                : this.$app.generalConfig();
        },
        appConfig(): GeneralConfig {
            return this.appGeneralConfig;
        },
    },

    mounted() {
        // this.emitEvent("component.loaded", this);
        this.setColorMode();
        this.catchEvent("dark-mode-toggle", (value: boolean) => {
            this.isDarkMode = value;
            this.dataTheme = DarkModeManager.getInstance().dataTheme;
        });

        this.init();
    },

    destroyed() {
        this.removeEvents();
    },
});
