import { PropType } from "vue";
// ignore-storybook
import Util, { dateDescription } from "modules/core/lib/Util";
import "./terms.scss";
import TermsBO from "modules/core/business/TermsBO";
import {
    CoreComponentMixin,
    LayoutMixin,
    LocaleMixin,
} from "modules/core/mixins/ts";
import { ServerInfoResponse } from "modules/core/model/serverInfoResponse";
import { CheckboxCustomItem } from "modules/core/types/misc";
import { ExternUserVO } from "modules/core/model/externUserVO";
import Legal from "modules/user/components/legal/Legal";
import CardHeader from "modules/core/components/interface/card-header/CardHeader";

import { QBtn, QCheckbox, QDialog, QCard, QCardSection } from "quasar";

import mixins from "vue-typed-mixins";
import {
    ServerInfoBO,
    AgreeLegalTermsBO,
    ExternUserBO,
} from "../../../core/business";

import template from "./terms.html?raw";

export default mixins(LocaleMixin, CoreComponentMixin, LayoutMixin).extend({
    template,
    components: {
        QBtn,
        QCheckbox,
        QDialog,
        QCard,
        Legal,
        CardHeader,
        QCardSection, // LanguageSelector
    },
    props: {
        onFinish: {
            required: false,
            type: Function as PropType<VoidFunction>,
            default: () => {},
        },
        onProceed: {
            required: false,
            type: Function as PropType<
                (serverInfo: ServerInfoResponse) => void
            >,
            default: () => {},
        },
        isChecked: { required: false, type: Boolean, default: false },
    },
    data() {
        return {
            isLoading: false,
            isVisible: true,
            contentIsVisible: false,
            checksChecked: [] as CheckboxCustomItem[],
            scrollAidVisible: false,
            scrollPushed: false,
            dialogContentType: null as string | null,
            dialogContentVisible: false,
        };
    },
    computed: {
        scrollableElement(): HTMLDivElement {
            return this.$refs.scrollable as HTMLDivElement;
        },

        titleIsVisible(): boolean {
            return this.appGeneralConfig.legalTerms.title;
        },

        imagesContentIsVisible(): boolean {
            return this.appGeneralConfig.legalTerms.supportingImages;
        },

        imagesIsVisible(): boolean {
            return this.appGeneralConfig.legalTerms.infoSupportingImages;
        },

        title(): string {
            return Util.nl2br(this.localeText("tmk896"));
        },

        disclaimer(): string {
            return Util.nl2br(this.localeText("tmk845"));
        },

        texts(): Record<string, string> {
            return {
                privacyPolicy: this.localeText(
                    this.appGeneralConfig.privacyPolicyTextLocaleId,
                ),
                legalTerms: this.localeText(
                    this.appGeneralConfig.legalTermsTextLocaleId,
                ),
            };
        },

        checksList(): CheckboxCustomItem[] {
            let list: CheckboxCustomItem[] = [];
            const customItems =
                this.appGeneralConfig.legalTerms.checkboxCustomItems;

            if (!this.appGeneralConfig.legalTerms.checkboxCustomItemsOnly) {
                if (this.appGeneralConfig.legalTerms.enableCheckPrivacyPolicy) {
                    list.push({
                        title: this.localeText("tmk1345"),
                        name: "privacyPolicy",
                        required: true,
                        dataQaTa: "privacyCheckBox",
                        dataTestId: "privacyCheckBox",
                    });
                }

                list.push({
                    title: this.localeText("tmk1344"),
                    name: "legalTerms",
                    required: true,
                    dataQaTa: "legalTermsCheckBox",
                    dataTestId: "legalTermsCheckBox",
                });
            }

            if (customItems.length > 0) list = [...list, ...customItems];

            return list;
        },

        buttonLabel(): string {
            return this.localeText("tmk357");
        },

        paperImg(): string {
            return require("./img/paper.png");
        },

        getCEImageUrl(): string {
            if (!this.isDarkMode)
                return require("app/static/img/icon-ce-dark.png");
            return require("app/static/img/icon-ce.png");
        },

        getGDPRImageUrl(): string {
            if (!this.isDarkMode)
                return require("app/static/img/icon-gdpr-dark.png");
            return require("./img/gdpr.png");
        },

        getHIPPAImageUrl(): string {
            if (!this.isDarkMode)
                return require("app/static/img/icon-hippa-dark.png");
            return require("app/static/img/icon-hippa.png");
        },

        requiredChecksList(): CheckboxCustomItem[] {
            return this.checksList.filter((check) => check.required);
        },

        requiredChecksChecked(): CheckboxCustomItem[] {
            return this.requiredChecksList.filter((check) =>
                this.checksChecked.includes(check),
            );
        },

        isButtonContinueEnabled(): boolean {
            return (
                this.requiredChecksList.length ===
                this.requiredChecksChecked.length
            );
        },

        classes(): Record<string, any> {
            return {
                "mdk-terms-conditions--content-visible": this.contentIsVisible,
            };
        },

        mainContentIsVisible(): boolean {
            return this.titleIsVisible || this.imagesIsVisible;
        },

        dataProtectionTitle(): string {
            return this.localeText("tmk1863");
        },

        dataProtectionList(): Record<string, string> {
            const data: Record<string, string> = {};
            data[this.localeText("tmk1846")] = Util.nl2br(
                this.localeText("tmk1853"),
            );
            data[this.localeText("tmk1847")] = Util.nl2br(
                this.localeText("tmk1854"),
            );
            data[this.localeText("tmk1849")] = Util.nl2br(
                this.localeText("tmk1855"),
            );
            data[this.localeText("tmk1850")] = Util.nl2br(
                this.localeText("tmk1856"),
            );
            data[this.localeText("tmk1851")] = Util.nl2br(
                this.localeText("tmk1857"),
            );
            data[this.localeText("tmk1852")] = Util.nl2br(
                this.localeText("tmk1858"),
            );
            data[this.localeText("tmk1848")] = Util.nl2br(
                this.localeText("tmk1859"),
            );

            return data;
        },

        dataProtectionIsVisible(): boolean {
            return this.appGeneralConfig.legalTerms.dataProtectionModule;
        },

        lastTimeUpdated(): string | null {
            if (this.$app.serverInfo?.legalTermsDate) {
                return `${this.localeText("tmk1865")} ${dateDescription(
                    this.$app.serverInfo.legalTermsDate,
                )}`;
            }

            return null;
        },

        lastTimeAccepted(): string | null {
            if (this.$app.externUser?.legalTermsDate) {
                return `${this.localeText("tmk1866")} ${dateDescription(
                    this.$app.externUser.legalTermsDate,
                )}`;
            }

            return null;
        },
    },
    methods: {
        handleScroll() {
            this.scrollAidVisible = false;
        },

        handleResize() {
            this.scrollAidVisible = this.hasScroll();
        },

        setCheckedByDefault() {
            if (this.isChecked) {
                this.checksList.forEach((item) =>
                    this.checksChecked.push(item),
                );
            }
            this.checksList.forEach((check) => {
                if ("checked" in check && check.checked)
                    this.checksChecked.push(check);
            });
        },

        parseAnchors() {
            for (const element of this.$refs.parseable as HTMLIFrameElement[]) {
                const anchors = element.getElementsByTagName("a");

                if (anchors.length > 0) {
                    for (const anchorElement of anchors) {
                        const path = anchorElement.href.replace(
                            /^.*\/\/[^\/]+/,
                            "",
                        );
                        const resolvedRoute = this.$app.router?.resolve(path);
                        const type =
                            resolvedRoute?.route.name === "cmd"
                                ? resolvedRoute?.route.params.cmd
                                : resolvedRoute?.route.name;

                        if (resolvedRoute && type) {
                            anchorElement.setAttribute("data-type", type);
                            anchorElement.setAttribute("href", "#");
                            anchorElement.addEventListener(
                                "click",
                                async (e) => {
                                    if (resolvedRoute) {
                                        e.preventDefault();

                                        const type = (
                                            e.target as HTMLAnchorElement
                                        ).getAttribute("data-type");

                                        /* let component = await import("../../../core/components/interface/flexible-iframe-content/FlexibleIframeContent");
                                let content = this.texts[(e.tar,as HTMLAnchorElement).getAttribute("data-type")];

                                let iframe = Util.mountComp(component.default as ComponentOptions<Vue>, {
                                    contentText: content
                                });
                                Ui.showFixedModal(null, iframe.$el); */
                                        this.dialogContentType = type || "";
                                        this.dialogContentVisible = true;
                                    }
                                },
                            );
                        }
                    }
                }
            }
        },

        async proceed() {
            if (this.isLoading) return;

            this.isLoading = true;

            this.showPreloader();

            await AgreeLegalTermsBO.getInstance().doAgreeLegalTerms();

            const serverInfo =
                await ServerInfoBO.getInstance().fetchServerInfo();

            if (
                serverInfo &&
                !serverInfo.error &&
                !TermsBO.legalTermsExpired(serverInfo)
            ) {
                this.$app.serverInfo = serverInfo;
                // @ts-ignore
                this.$app.legalTermsVisible.isVisible = false;

                // @ts-ignore this is a function, but the type inferences fails when defining a default value
                this.onProceed(serverInfo);

                this.emitEvent("userTermsAccepted", serverInfo);

                // if(serverInfo.externUser) await this.updateExternUserInfo(serverInfo.externUser);
            }

            this.emitEvent("userTermsProceed", serverInfo);

            this.hidePreloader();

            this.contentIsVisible = false;

            // setTimeout(() => {
            this.destroy();
            // }, 1000);
        },

        destroy() {
            this.isVisible = false;

            document.documentElement.classList.remove("non-scrollable");
            document.body.classList.remove("non-scrollable");

            // this.scrollableElement?.removeEventListener("scroll", this.handleScroll);
            // window.removeEventListener("resize", this.handleResize);

            // @ts-ignore this is a function, but the type inferences fails when defining a default value
            this.onFinish();
        },

        async updateExternUserInfo(user: ExternUserVO) {
            const newUser = await ExternUserBO.getInstance().doExternUser({
                externUser: user,
            });

            if (newUser) {
                this.$app.externUser = newUser;
            }
        },

        pushScroll() {
            this.scrollPushed = true;
            this.scrollableElement.scrollTo(
                0,
                this.scrollableElement.scrollTop + 100,
            );
        },

        hasScroll() {
            return (
                this.scrollableElement.scrollHeight - 20 >
                this.scrollableElement.clientHeight
            );
        },
    },

    mounted() {
        document.documentElement.classList.add("non-scrollable");
        document.body.classList.add("non-scrollable");

        this.contentIsVisible = true;

        if (this.appGeneralConfig.legalTerms.showContentInModal) {
            this.parseAnchors();
        }

        // this.setCheckedByDefault();

        // setTimeout(() => {
        //    this.scrollAidVisible = this.hasScroll();
        // }, 500);

        // this.scrollableElement.addEventListener("scroll", this.handleScroll);
        // window.addEventListener("resize", this.handleResize);
    },
});
