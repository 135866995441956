import $ from "cash-dom";
import Util from "modules/core/lib/Util";
import Ui from "modules/core/lib/Ui";
import Loader from "modules/core/components/interface/loader/Loader";

class ConnectionManager {
  constructor() {
    this.isConnected = false;
    this.reconnectingComponent = null;
  }

  async openModalReconnecting(noConnectionText = "") {
    if (this.reconnectingComponent === null) {
      const loader = await Util.mountComponent(Loader, {
        delaySpinner: false,
        size: 35,
        boxed: false,
        description: noConnectionText,
        overlayed: false,
      });
      this.reconnectingComponent = Ui.showModal(null, loader.$el, [], false);
    }
  }

  closeModalReconnecting() {
    if (!Util.isNullOrEmpty(this.reconnectingComponent)) {
      $(this.reconnectingComponent.$el).remove();
      this.reconnectingComponent = null;
    }
  }

  updateState(payload) {
    this.isConnected = payload.value;

    if (this.isConnected) this.closeModalReconnecting();
    else this.openModalReconnecting();
  }
}
export default new ConnectionManager();
