import { Dialog } from "quasar";
import Vue from "vue";
import { ExternUserVO } from "modules/core/model/externUserVO";
import Ui from "modules/core/lib/Ui";
import LocalizationBO from "modules/core/business/LocalizationBO";
import Util from "../../../../core/lib/Util";

export async function openProfileImageEditModal(
    parent: Vue,
    externUser: ExternUserVO,
    defaultImage: Blob | string,
    onSelect = (image: Blob, response: string) => {},
) {
    const service = await import(
        "modules/user/components/profile/profile-image-edit/ProfileImageEdit"
    );
    let defImage: string | ArrayBuffer;

    if (typeof defaultImage !== "string") {
        defImage = await Util.getImageBase64(defaultImage);
    } else {
        defImage = defaultImage;
    }

    Dialog.create({
        component: service.default,
        parent,
        externUser,
        defaultImage: defImage,
    }).onOk((image: Blob, response: string) => {
        onSelect(image, response);
    });

    /* let component = await Util.mountComponent(service.default, {
        externUser,
        defaultImage: defImage
    });

    component.$on("finish", (image:Blob, response:string) => {
        onSelect(image, response);

        // @ts-ignore
        modal.destroy();
    });

    let modal = Ui.showModal(LocalizationBO.getInstance().localeText("tmk1479"), {
        content: component.$el,
        padding: false
    }); */

    // return component;
}
