import axios, { AxiosRequestConfig } from "axios";
import { Api } from "modules/core";
import App from "modules/core/lib/App";
import { LoginResponse } from "modules/core/model/loginResponse";
import { isUUID } from "./Util";
import EnvironmentConfig from "./EnvironmentConfig";
import axiosRetry from "axios-retry";

// Exponential back-off retry delay between requests
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay });

export class LegacyToken {
    protected app: App;

    constructor(app: App) {
        this.app = app;
    }

    static check(token: string) {
        return isUUID(token);
    }

    generateReplaceableUrl(response: LoginResponse): string {
        return `${window.location.href.split("?")[0]}?authToken=${
            response.authToken
        }&externUserId=${response.externUser?.externUserId}&deviceId=${
            response.deviceId
        }`;
    }

    async renew(
        authToken: string,
        _externUserId?: string,
    ): Promise<LoginResponse> {
        const config: AxiosRequestConfig = {
            headers: {
                Authorization: authToken
                    ? `Bearer ${authToken}`
                    : `Basic ${EnvironmentConfig.getValue(
                          "APP_API_AUTH_CODE",
                      )}`,
            },
        };

        const url = `${EnvironmentConfig.getValue(
            "APP_API_ENDPOINT",
        )}/services/login`;

        const response = await axios.post<LoginResponse>(
            url,
            {
                apiVersion: Api.API_VERSION,
                appVersion: this.app.generalConfig().appVersion,
                appId: this.app.generalConfig().appId,
                deviceType: this.app.generalConfig().deviceType,
                language: this.app.language,
                deviceId: this.app.deviceId,
            },
            config,
        );
        return response.data;
    }
}
