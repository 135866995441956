import CoreComponent from "modules/core/mixins/CoreComponent";
import template from "./profile-image.html?raw";
import "./profile-image.scss";
import Util from "modules/core/lib/Util";
import _find from "lodash/find";
import UIButton from "modules/core/components/interface/button/Button";
import UIIcon from "modules/core/components/interface/icon/Icon";
import { openProfileImageEditModal } from "modules/user/components/profile/profile-image/util";
import mixins from "vue-typed-mixins";
import { Ui } from "modules/core";

export default mixins(CoreComponent).extend({
  template,
  components: {
    UIButton,
    UIIcon,
  },

  props: {
    externUser: {
      type: Object,
      required: true,
      default: null,
    },
    specialtyBadgeImage: {
      type: String,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    alternativeName: {
      type: String,
      required: false,
      default: null,
    },
    specialImageSize: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      mainImageUrl: null,
      imageIsUploading: false,
    };
  },
  watch: {
    externUser: {
      handler(newExternUserObject, oldExternUserObject) {
        this.mainImageUrl = newExternUserObject.imageFace;
      },
      deep: true,
    },
  },

  computed: {
    isEditable() {
      return (
        this.editable && this.app().generalConfig().settings.enableImagePicker
      );
    },
    classes() {
      return {
        "mdk-specialist-image-overview--loading": this.imageIsUploading,
      };
    },
    avatarDescription() {
      if (this.mainImageUrl) return null;
      if (this.alternativeName) return this.alternativeName[0];
      if (this.externUser && "fullName" in this.externUser)
        return this.externUser.fullName[0];
    },
    imageForegroundStyle() {
      // if(this.avatarDescription) return;

      return {
        backgroundImage: `url(${this.mainImageUrl})`,
      };
    },
    setSpecialImageSize() {
      if (this.specialImageSize === "") return false;
      const imageSizes = {
        "special-small": "special-small",
      };

      return imageSizes[this.specialImageSize];
    },
    getDefinitionClassSize() {
      return {
        "mdk-specialist-image-overview__image--tablet":
          this.isPhoneLayout || this.isTabletLayout,
        "mdk-specialist-image-overview__image--desktop": this.isDesktopLayout,
        "mdk-specialist-image-overview__image--special-small":
          this.setSpecialImageSize === "special-small",
      };
    },
    getDefinitionClassCategorySize() {
      return {
        "mdk-specialist-image-overview__category-image--phone":
          this.isPhoneLayout,
        "mdk-specialist-image-overview__category-image--tablet":
          this.isTabletLayout,
        "mdk-specialist-image-overview__category-image--desktop":
          this.isDesktopLayout,
        "mdk-specialist-image-overview__category-image--special-small":
          this.setSpecialImageSize === "special-small",
      };
    },
    categoryImageStyle() {
      return {
        backgroundImage: `url(${this.categoryImageUrl})`,
      };
    },
    imageSize() {
      if (this.isDesktopLayout) return 125;
      if (this.isPhoneLayout) return 75;
      if (this.isMobileLayout) return 100;
    },
    categoryImageUrl() {
      let categoryId = null;

      if ("externUserSpecialties" in this.externUser) {
        if (this.externUser.externUserSpecialties.length > 0) {
          categoryId = this.externUser.externUserSpecialties[0].categoryId;
        }
      }

      if (categoryId != null) {
        const item = _find(this.categoryList, { categoryId });
        if (typeof item !== "undefined") {
          return item.image;
        }
      }

      return this.specialtyBadgeImage;
    },
    attachImageButtonIcon() {
      return !this.mainImageUrl ? "camera_alt" : "edit";
    },
  },

  methods: {
    mainContentProp() {
      return this.externUser;
    },
    async attachImage() {
      if (this.mainImageUrl) {
        this.openEditor(this.mainImageUrl);
      } else {
        this.browseImage();
      }
    },
    async browseImage() {
      // const files = await Util.openBrowseFile("image/*");
      const files = await Util.openBrowseFile("image/jpg,image/jpeg,image/png");

      if (files && files.length > 0) {
        /**
         * @type {File}
         */
        const file = files[0];

        console.log({ file });

        const isValid = /^image\/(jpg|jpe?g|png)/.test(file.type);

        if (!isValid) {
          const invalidMessage =
            this.localeText(
              "tmk2702",
              undefined,
              undefined,
              "Only jpg/jpeg and png files are allowed!",
            ) || "Only jpg/jpeg and png files are allowed!";
          Ui.showAlert(invalidMessage);
          return;
        }

        this.openEditor(file);
      }
    },
    async openEditor(file) {
      const component = await openProfileImageEditModal(
        this,
        this.externUser,
        file,
        async (resultFile, response) => {
          this.mainImageUrl = resultFile
            ? await Util.getImageBase64(resultFile)
            : resultFile;

          this.$emit("update", response);

          this.emitEvent("userProfileImageChange", response);
        },
      );
    },
  },

  created() {
    if (
      this.externUser &&
      "imageFace" in this.externUser &&
      this.externUser.imageFace !== undefined
    ) {
      this.mainImageUrl = this.externUser.imageFace;
    }
  },
});
