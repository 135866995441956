// Locales used across all the app. Make sure this list is updated!
import interfaceLocales from "./interfaceLocales";

const extraLocales = ["tmk1611", "tmk1880"];

export const contentLocales = [...interfaceLocales, ...extraLocales];

export const routeLocales = [
    "routes.login",
    "routes.register",
    "routes.profile",
    "routes.edit-profile",
    "routes.specialists",
    "routes.diccionario",
    "routes.enfermedad",
    "routes.section",
    "routes.chat",
    "routes.paymentRedirection",
    "routes.paymentConfirmation",
    "tmk267",
    "routes.logout",
    "routes.aviso-legal",
    "access.politicaPrivacidad",
    "routes.contact",
    "tmk1137",
    "tmk1173",
    "tmk1138",
    "tmk1139",
    "routes.results",
    "tmk1139",
    "routes.recover-password",
    "routes.reset-password",
    "routes.testimonials",
    "evaluator.sintomas",
    "tmk373",
    "tmk547",
    "tmk374",
];
