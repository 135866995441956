// @ts-ignore
// TODO: Move to TS ENUM!
import Enum from "enum";

const QuestionTypeEnum = new Enum({
    DEFAULT_YES_PYES_UNK_PNO: 0,
    DEFAULT_YES_NO: 1,
    MULTI: 2,
    MULTI_IMAGE: 3,
    MULTI_IMAGE_TEXT: 4,
    MULTI_TEXT: 5,
    INPUT_TEXT: 6,
    INPUT_NUMBER: 7,
    INPUT_NUMBER_VITAL_CONSTANT: 8,
    INPUT_DATE: 9,
    INPUT_IMAGE_UPLOAD: 10,
    MULTI_DINAMIC: 11,
    INPUT_AUDIO_UPLOAD: 12,
    MULTI_AUDIO_TEXT: 13,
    INFO: 14,
    GEOLOCATION: 15,
    TEXT: 16,
    REASON_INPUT: 17,
    GEOLOCATION_OWN: 19,
});

export default QuestionTypeEnum;
