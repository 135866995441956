import { Route, NavigationGuard } from "vue-router";
import eventBus from "modules/core/lib/EventBusSingleton";
import { dispatchConfirmationRequestEvent } from "@mediktor-web/common/events/awaited";
import config from "../config/app";

interface State {
    history: Route[];
}

type NavigationType = "forward" | "backward";

const getDialogRef = () =>
    document.querySelector("div[role='dialog']") ||
    document.querySelector("div[role='alertdialog']");

const isDialogMounted = () => !!getDialogRef();
const closeDialog = () => {
    const dialog = getDialogRef();
    dialog && dialog.parentElement?.removeChild(dialog);
};
const shouldHandleDialog = (navigationType: NavigationType) =>
    config.embeddedMode && navigationType === "backward" && isDialogMounted();

type NavigationStrategy = (state: State, to: Route, from: Route) => void;

const navigationStrategies: Record<NavigationType, NavigationStrategy> = {
    backward: (state) => state.history.pop(),
    forward: (state, to) => state.history.push(to),
};

export const navigationState: State = {
    history: [],
};

const toCloneableObject = ({ path, fullPath, query, params }: Route) => {
    return {
        path,
        fullPath,
        query,
        params,
    };
};

// const getAt = <T>(array: T[], position: number): T | undefined => {
//     // ToInteger() abstract op
//     position = Math.trunc(position) || 0;
//     // Allow negative indexing from the end
//     if (position < 0) position += array.length;
//     // OOB access is guaranteed to return undefined
//     if (position < 0 || position >= array.length) return undefined;
//     // Otherwise, this is just normal property access
//     return array[position];
// };

const serializeRoute = (route: Route) => {
    return JSON.parse(
        JSON.stringify({
            path: route.path,
            name: route.name,
            hash: route.hash,
            params: route.params,
            query: route.query,
            fullPath: route.fullPath,
        }),
    );
};

const NavigationTrackingMiddleware: { handle: NavigationGuard } = {
    async handle(to, from, next) {
        const navigationType: NavigationType =
            to.fullPath === navigationState.history.at(-2)?.fullPath
                ? // to.fullPath === getAt(navigationState.history, -2)?.fullPath
                  "backward"
                : "forward";

        const proceed = await dispatchConfirmationRequestEvent({
            name: "navigation:before",
            payload: {
                to: serializeRoute(to),
                from: serializeRoute(from),
                direction: navigationType,
                // query: to.query as Record<string, string>,
            },
        });

        if (!proceed) return true;

        if (to === from) return false;

        if (shouldHandleDialog(navigationType)) {
            closeDialog();
            next(false);
            return true;
        }

        navigationStrategies[navigationType](navigationState, to, from);

        eventBus.emit("navigation", {
            navigationType,
            data: {
                historyLength: navigationState.history.length,
                to: toCloneableObject(to),
                from: toCloneableObject(from),
            },
        });

        return false;
    },
};

export default NavigationTrackingMiddleware;
