import _orderBy from "lodash/orderBy";
import Util from "modules/core/lib/Util";
import BusinessAbstract from "modules/core/business/BusinessAbstract";
import { OrganizationEndpointListResponse } from "modules/core/model/organizationEndpointListResponse";
import { OrganizationEndpointVO } from "modules/core/model/organizationEndpointVO";
import { SmartLaunchpadRequest } from "modules/core/model/smartLaunchpadRequest";
import { SmartLaunchpadResponse } from "modules/core/model/smartLaunchpadResponse";

export default class OrganizationBO extends BusinessAbstract {
    static instance?: OrganizationBO;

    static getInstance(): OrganizationBO {
        if (!OrganizationBO.instance) {
            OrganizationBO.instance = new this();
        }

        return OrganizationBO.instance;
    }

    async fetchMainOrganizationId(): Promise<string> {
        const service =
            await this.api.service<OrganizationEndpointListResponse>(
                "organizationEndpointList",
            );

        if (service && service.organizationEndpointList) {
            const selecteds = service.organizationEndpointList.filter((item) =>
                item?.name?.match(/Open\.Epic/),
            );

            return selecteds[0].organizationId || "";
        }

        return "";
    }

    async fetchOrganizations(): Promise<OrganizationEndpointVO[]> {
        const service =
            await this.api.service<OrganizationEndpointListResponse>(
                "organizationEndpointList",
                {
                    useCache: 24 * 7,
                },
            );

        if (service && service.organizationEndpointList) {
            const list = _orderBy(
                service.organizationEndpointList,
                [(item) => item.name?.toLowerCase()],
                ["asc"],
            );

            return service.organizationEndpointList;
        }

        return [];
    }

    async doOrganizationEndpoint(organizationId: string, epicMode?: number) {
        const data: SmartLaunchpadRequest = {
            organizationId,
            redirectUri: location.href,
        };

        if (!Util.isNullOrEmpty(epicMode)) {
            data.epicMode = epicMode;
        }

        const service = await this.api.service<SmartLaunchpadResponse>(
            "epicLaunchpad",
            data,
        );

        if (service && service.authorizeUrl) {
            window.location.href = service.authorizeUrl;
        } else {
            alert("No Authorize URL found.");
        }
    }

    async doAutoLoginAndRedirect(
        launch: string,
        iss: string,
        epicMode?: number,
    ) {
        const data: SmartLaunchpadRequest = {
            launch,
            iss,
            redirectUri: location.href,
        };

        if (!Util.isNullOrEmpty(epicMode)) {
            data.epicMode = epicMode;
        }

        const service = await this.api.service<SmartLaunchpadResponse>(
            "epicLaunchpad",
            data,
        );

        if (service && service.authorizeUrl) {
            window.location.href = service.authorizeUrl;
        }
    }
}
