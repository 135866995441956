/* global google */

import Util from "modules/core/lib/Util";
import { RegisterResponse } from "modules/core/model/registerResponse";
import { RegisterRequest } from "modules/core/model/registerRequest";
import BusinessAbstract from "./BusinessAbstract";

const GOOGLE_ENDPOINT_URL = "https://accounts.google.com/gsi/client";

export default class GoogleSignInBO extends BusinessAbstract {
    googleSigninId: string;

    /**
     * Create an instance for signing in or logging in by Google authentication
     * @param googleSigninId = string // google key
     */
    constructor(googleSigninId: string) {
        // if(googleSigninId === null) {
        //    throw new Error("[GoogleSignIn] - googleSigninId is missing");
        // }
        super();

        this.googleSigninId = googleSigninId;
    }

    async doRegisterForGoogleSSO(token: string): Promise<RegisterResponse> {
        const response = await this.api.service<RegisterResponse>("register", {
            googleUserId: token,
        } as RegisterRequest);

        return response;
    }

    async startGoogleSignin(
        domElement: HTMLElement,
        onGoogleSign: (token: any) => void,
    ) {
        if (typeof google === "undefined") {
            await Util.loadScript(GOOGLE_ENDPOINT_URL);
        }

        google.accounts.id.initialize({
            client_id: this.googleSigninId,
            callback: onGoogleSign,
        });

        google.accounts.id.renderButton(
            domElement,
            { theme: "outline", shape: "pill", type: "icon", size: "large" }, // customization attributes
        );
    }
}
