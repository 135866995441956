import template from "modules/core/components/interface/rating/rating.html?raw";
import "modules/core/components/interface/rating/rating.scss";
import Util from "modules/core/lib/Util";

import vue from "vue";

export type IconsOptions = Record<string, string>;

export default vue.extend({
    template,
    props: {
        rating: {
            type: Number,
            required: false,
            default: 0,
        },
        description: {
            type: [String, Number],
            required: false,
            default: null,
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
        isBigStars: {
            type: Boolean,
            required: false,
            default: false,
        },
        iconStyle: {
            type: String,
            required: false,
            default: "star",
        },
        onSelect: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            selected: 0,
        };
    },
    computed: {
        starsList(): number[] {
            return [1, 2, 3, 4, 5];
        },
        classes(): string[] {
            const classes: string[] = [];

            if (this.rating > 0 || this.editable) {
                classes.push("mdk-ui-rating--active");
            }
            if (this.editable) classes.push("mdk-ui-rating--cursor");
            if (this.isBigStars) classes.push("mdk-ui-rating__star--big");
            if (this.iconStyle === "circle")
                classes.push("mdk-ui-rating__star--circle");

            return classes;
        },
        isDescriptionVisible(): boolean {
            return !Util.isNullOrEmpty(this.description);
        },
        filledIcon(): string {
            const filledIcons: IconsOptions = {
                star: "star",
                circle: "lens",
            };

            return filledIcons[this.iconStyle];
        },
        borderIcon(): string {
            const borderIcons: IconsOptions = {
                star: "star_border",
                circle: "panorama_fish_eye",
            };

            return borderIcons[this.iconStyle];
        },
    },
    methods: {
        setValueStars(index: number) {
            this.selected = index;
        },
        starIcon(index: number): string {
            if (!this.editable) {
                if (index <= this.rating) return this.filledIcon;

                return this.borderIcon;
            }
            if (index <= this.selected) return this.filledIcon;

            return this.borderIcon;
        },
        select(index: number) {
            // @ts-ignore this is a function, but the type inferences fails when defining a default value
            if (this.editable) this.onSelect(index);
        },
    },
});
