import _merge from "lodash/merge";
import { SessionListRequest } from "modules/core/model/sessionListRequest";
import { SessionListResponse } from "modules/core/model/sessionListResponse";
import { SessionVO } from "modules/core/model/sessionVO";
import GenericRemoteDAO from "./GenericRemoteDAO";

export default class SessionRemoteDAO extends GenericRemoteDAO {
    async fetchSessionList(
        offset: number,
        count: number,
        params: SessionListRequest = {},
    ): Promise<SessionVO[]> {
        const { sessionList } = await this.api.service<SessionListResponse>(
            "sessionList",
            _merge(this.basicParamsForPagination(offset, count), params),
        );

        return sessionList || [];
    }
}
