import Ui from "modules/core/lib/Ui";
import AppStorageManager from "modules/core/lib/AppStorageManager";
import OrganizationBO from "modules/core/business/OrganizationBO";
import LanguageBO from "modules/core/business/LanguageBO";
import LocalizationBO from "modules/core/business/LocalizationBO";
import Util from "./Util";

export default class EpicAuthManager {
    epicMode: number | null = null;

    static instance?: EpicAuthManager;

    static getInstance(): EpicAuthManager {
        if (!EpicAuthManager.instance) EpicAuthManager.instance = new this();

        return EpicAuthManager.instance;
    }

    async manage() {
        const params = {
            showMessage: Util.urlQueryParam("showMessage"),
            epicMode: Util.urlQueryParam("epicMode"),
            launch: Util.urlQueryParam("launch"),
            iss: Util.urlQueryParam("iss"),
        };
        if (params.showMessage) {
            Ui.showAlert(
                LocalizationBO.getInstance().localeText(params.showMessage),
            );
        }

        if (params.epicMode) {
            AppStorageManager.getInstance().saveEpicMode(
                parseInt(params.epicMode),
            );
        }

        this.epicMode = AppStorageManager.getInstance().getEpicMode();

        if (params.launch && params.iss) {
            await OrganizationBO.getInstance().doAutoLoginAndRedirect(
                params.launch,
                params.iss,
                this.epicMode,
            );
        }
    }

    isEpicEnabled(): boolean {
        const currentLanguage = LanguageBO.getInstance().currentLanguageCode();

        return (
            (this.epicMode === 1 || this.epicMode === 0) &&
            (currentLanguage === "es-ES" ||
                currentLanguage === "en-EN" ||
                currentLanguage === "en-US")
        );
    }
}
