import { dispatchPublicEvent } from "@mediktor-web/common/events/public";
import template from "./login.html?raw";
import "./login.scss";

import { Location } from "vue-router";
import mixins from "vue-typed-mixins";
import LegalImages from "app/components/user/legal-images/LegalImages";
import { LoginResponse } from "modules/core/model/loginResponse";
import { AppEvents } from "modules/core/lib/events";
import EpicAuthManager from "modules/core/lib/EpicAuthManager";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import ExternUserGroupBO from "modules/core/business/ExternUserGroupBO";
import ExternUserGroupStatusEnum from "modules/chat/enums/ExternUserGroupStatusEnum";
import LoginCompoenent from "modules/user/components/login/Login";
import { PageMixin } from "app/lib/ts/PageMixin";
import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";

export default mixins(LocaleMixin, CoreComponentMixin, PageMixin).extend({
    template,
    components: {
        login: LoginCompoenent,
        "legal-images": LegalImages,
    },

    props: {
        onLogin: { type: Function, required: false, default: () => {} },
        redirect: { type: Boolean, required: false, default: true },
    },

    computed: {
        bottomActionRoute(): Location {
            return RouteGenerator.getInstance().register(
                this.$route.query.then as string,
            );
        },

        bottomDesriptionText(): string {
            return this.localeText("access.noTieneCuenta");
        },

        bottomLinkText(): string {
            return this.localeText("access.registreseAhora");
        },

        locales(): Array<string> {
            return [
                "access.noTieneCuenta",
                "access.registreseAhora",
                "access.botonLogin",
            ];
        },

        pageTitle(): string {
            return this.localeText("access.botonLogin");
        },

        mychartSSOIsEnabled(): boolean {
            return EpicAuthManager.getInstance().isEpicEnabled();
        },

        epicMode(): number | null {
            return EpicAuthManager.getInstance().epicMode;
        },

        bottomActionsIsVisible(): boolean {
            return this.appGeneralConfig.login.bottomActions;
        },

        registrationIsEnabled(): boolean {
            return (
                this.appGeneralConfig.register.enabled &&
                !this.appGeneralConfig.hospitalMode
            );
        },

        dashboardRedirect(): boolean {
            return !!(
                this.appGeneralConfig.dashboardRedirectEnabled &&
                this.appGeneralConfig.dashboardEnabled &&
                this.app().externUser?.isPartner
            );
        },

        mainTitle(): string {
            return this.localeText("tmk347");
        },
    },

    methods: {
        async onSubmit(response: LoginResponse) {
            // new AppEvents.Login.FormSubmit().emit(response);

            await this.withPreloader(async () => {
                await this.app().saveLoginData(response);
            });

            dispatchPublicEvent({ name: "user:login_form_submit" });

            if (this.redirect) {
                this.actionAfterLogin();
            }
            // @ts-ignore this is a function, but the type inferences fails when defining a default value
            this.onLogin();

            ExternUserGroupBO.getInstance().fetchAllGroupsByStatus(
                ExternUserGroupStatusEnum.OPEN.value,
            );

            this.emitEvent("refresh-drawer-links");
        },

        actionAfterLogin() {
            const handleError = (error: unknown) => {
                // eslint-disable-next-line no-console
                // console.error("Imperative Routing error:", error);
            };
            if (this.$route.query.then) {
                this.$router
                    .replace(this.$route.query.then as string)
                    .catch(handleError);
            } else if (this.dashboardRedirect) {
                this.$router
                    .replace(RouteGenerator.getInstance().dashboard())
                    .catch(handleError);
            } else {
                this.$router
                    .replace(RouteGenerator.getInstance().profile())
                    .catch(handleError);
            }
        },
    },
});
