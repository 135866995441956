import _includes from "lodash/includes";
import { Location, NavigationGuard, Route } from "vue-router";

import { AllowedActions } from "modules/core/lib/Deeplinking";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import Router from "modules/core/lib/Router";
import app from "modules/core/config/app";

import App from "modules/core/lib/App";
import { MiddlewareInterface } from "modules/core/middleware/MiddlewareInterface";
import { dispatchPublicEvent } from "@mediktor-web/common/events/public";
import LanguageBO from "../business/LanguageBO";
import Util from "../lib/Util";

export class AppMiddleware implements MiddlewareInterface {
    protected app: App;

    constructor(app: App) {
        this.app = app;
    }

    async handle(to: Route, from: Route, next: NavigationGuard): Promise<void> {
        /* if(!this.app.isChangingLanguage) {
            const fromLang = LanguageBO.getInstance().resolveUrlLanguage(from.fullPath);
            const toLang = LanguageBO.getInstance().resolveUrlLanguage(to.fullPath);

            if (fromLang && toLang && fromLang !== toLang) {
                await this.app.changeAppLanguage(toLang);
                this.app.reloadLayout();
                //Util.reloadPage(false);

                //return;
            }
        } */

        if (!this.app.generalConfig().enableRedirections && from.path !== "/") {
            // new AppEvents.PageRedirection().emit({
            //     from: convertRouteToLocation(from),
            //     to: convertRouteToLocation(to),
            // });

            dispatchPublicEvent({
                name: "page:redirection",
                payload: { to: to.path, from: from.path },
            });

            return;
        }

        if (to.query?.then !== undefined) {
            const currentLang = LanguageBO.convertToPublicLanguage(
                LanguageBO.getInstance().currentLanguageCode(),
            );
            const thenLang = (to.query.then as string).split("/", 2)[1];

            if (thenLang !== currentLang) {
                to.query.then = (to.query.then as string).replace(
                    thenLang,
                    currentLang,
                );
            }
        }

        // if (to.params.cmd !== undefined) {
            // const redirection =
            //     await this.app.deepLinkingManager.getRedirectionForCMD({
            //         command: to.params.cmd as AllowedActions,
            //         query: to.query,
            //     });
            // console.log({ redirection });
            // // const cmd = new Deeplinking(
            // //     to.params.cmd as AllowedActions,
            // //     to.query,
            // // );
            // // const redirection = await cmd.manageRedirections();
            // if (redirection) {
            //     // @ts-ignore
            //     next(redirection);
            //     return;
            // }
            // // @ts-ignore
            // next(
            //     app.redirectToHomeIfCmdNotFound
            //         ? RouteGenerator.getInstance().home()
            //         : RouteGenerator.getInstance().notFound(),
            // );
            // return;
        // }

        if (
            Router.getInstance().routeNeedsLogin(to as unknown as Location) &&
            !this.app.isUserLoggedIn()
        ) {
            if (
                this.app.generalConfig().presentLoginWhenRequiringAuthentication
            ) {
                // @ts-ignore
                next(RouteGenerator.getInstance().login(to.fullPath));
            }

            // new AppEvents.AuthenticationRequired().emit(
            //     omit(to as unknown as Location, "matched"),
            // );

            dispatchPublicEvent({
                name: "auth:auth_required",
                payload: { route: to.name! },
            });

            return;
        }

        if (this.app.registeredUsersOnly()) {
            if (
                !this.app.isUserLoggedIn() &&
                !_includes(
                    [
                        "login",
                        "register",
                        "privacyPolicy",
                        "legalTerms",
                        "accessCode",
                        "cookiePolicy",
                    ],
                    to.name,
                )
            ) {
                // @ts-ignore
                next(this.app.registeredUsersOnlyRoute());

                return;
            }

            if (this.app.isUserLoggedIn() && to.name === "register") {
                // @ts-ignore
                next(RouteGenerator.getInstance().home());

                return;
            }
        }

        if (
            this.app.isUserLoggedIn() &&
            (to.name === "login" ||
                to.name === "register" ||
                to.name === "accessCode")
        ) {
            // @ts-ignore
            next(RouteGenerator.getInstance().profile());

            return;
        }

        if (
            app.enableAccessCode &&
            !this.app.isUserLoggedIn() &&
            to.name === "register" &&
            !this.app.hasAccessCode() &&
            !Util.urlQueryParam("ignoreAccessCodeRestriction")
        ) {
            // @ts-ignore
            next(RouteGenerator.getInstance().accessCode());

            return;
        }

        if (
            (to.name === "schedule-timetable" ||
                to.name === "schedule-appointment-confirmation" ||
                to.name === "schedule-appointment" ||
                to.name === "schedule") &&
            !app.scheduleEnabled
        ) {
            // @ts-ignore
            next(RouteGenerator.getInstance().notFound());

            return;
        }

        if (to.name === "dashboard") {
            // to check if it is an specialist user, is there other way instead of isPartner??
            if (
                !this.app.isUserLoggedIn() ||
                (this.app.isUserLoggedIn() &&
                    !this.app.externUser?.isPartner) ||
                !app.dashboardEnabled
            ) {
                // @ts-ignore
                next(RouteGenerator.getInstance().notFound());

                return;
            }
        }

        if (
            to.matched.some((record) => record.meta.needsAdminPermission) &&
            !this.app.isUserAdmin()
        ) {
            // @ts-ignore
            next(RouteGenerator.getInstance().notFound());

            return;
        }

        // const userPermission = new UserPermission(application.externUser);
        // ====> Ported to Pro repo
        /* if(this.app.externUser && to.name === RouteGenerator.getInstance().routeNameList.home && this.app.generalConfig().hospitalMode) {
            if(this.app.generalConfig().hospital.researchMode) {
                next(RouteGenerator.getInstance().hospitalSessionBasicUser());
            }else{
                next(RouteGenerator.getInstance().hospitalSessions());
            }

            return;
        } */
        // @ts-ignore
        next();
    }
}
