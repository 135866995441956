import { ExternUserVO } from "modules/core/model/externUserVO";
import { ExternUserResponse } from "modules/core/model/externUserResponse";
import GenericRemoteDAO from "./GenericRemoteDAO";

export default class ExternUserRemoteDAO extends GenericRemoteDAO {
    async fetchExternUser(externUserId: string): Promise<ExternUserVO> {
        const { externUser } = await this.api.service<ExternUserResponse>(
            "externUser",
            { externUserId },
        );

        return externUser || {};
    }
}
