import moment from "moment/min/moment-with-locales";
import defaults from "modules/core/config/defaults";

const getLocaleDateFormat = () => {
    const localMoment = moment();
    localMoment.locale(navigator.language);

    return localMoment.localeData().longDateFormat("L") || defaults.dateFormat;
};

export default getLocaleDateFormat;
