// @ts-ignore
import template from "modules/core/components/interface/progress-bar/progress-bar.html?raw";
import "modules/core/components/interface/progress-bar/progress-bar.scss";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,

    props: {
        value: {
            type: Number,
            required: false,
            default: 0,
        },
        animated: {
            type: Boolean,
            required: false,
            default: true,
        },
        barColor: {
            type: String,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            width: 0,
            timeOut: null,
        };
    },

    watch: {
        value() {
            this.width = this.value;
        },
    },

    computed: {
        styleDefinition(): string {
            const definition: string[] = [];

            definition.push(`width: ${this.width}%`);

            if (this.barColor != null) {
                definition.push(`background-color: ${this.barColor}`);
            }

            return definition.join(";");
        },

        tooltip(): string {
            return `${this.width}%`;
        },
    },

    mounted() {
        if (this.animated) {
            (this as any).timeOut = setTimeout(() => {
                this.width = this.value;
            }, 800);
        } else {
            this.width = this.value;
        }
    },

    destroyed() {
        if (this.timeOut != null) clearTimeout(this.timeOut);
    },
});
