import AppStorageManager from "./AppStorageManager";
import { Application } from "./Holder";

export default class SoundsManager {
    startSoundsConfig() {
        if (!Application.getInstance().generalConfig().enableSounds)
            return this.saveIsSoundsEnabled(false);
        if (this.getIsSoundsEnabled() === null) this.saveIsSoundsEnabled(true);
        else this.saveIsSoundsEnabled(this.getIsSoundsEnabled());
    }

    isSoundsEnabled(): boolean {
        if (!Application.getInstance().generalConfig().enableSounds)
            return false;
        if (this.getIsSoundsEnabled() === null) return true;

        return this.getIsSoundsEnabled();
    }

    saveIsSoundsEnabled(isSoundsEnabled: boolean) {
        AppStorageManager.getInstance().saveIsSoundsEnabled(isSoundsEnabled);
    }

    getIsSoundsEnabled(): boolean {
        return AppStorageManager.getInstance().getIsSoundsEnabled();
    }
}
