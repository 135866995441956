import Socket from "modules/chat/lib/Socket";
import Api from "../lib/Api";

interface BasicParams {
    offset?: number;
    count?: number;
    maxDate?: number;
    useLastEdited?: boolean;
}

export default abstract class GenericRemoteDAO {
    maxDate?: number;

    protected api: Api = Api.getInstance();

    protected socket: Socket = Socket.getInstance();

    setMaxDate(maxDate: number) {
        this.maxDate = maxDate;
    }

    basicParamsForPagination(offset: number, count: number): BasicParams {
        return {
            offset,
            count,
            maxDate: this.maxDate,
            useLastEdited: false,
        };
    }
}
