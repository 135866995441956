// @ts-nocheck
import { Route } from "vue-router";

import vue from "vue";

export const QueryRouteMixin = vue.extend({
    methods: {
        async addQueryToRoute(
            params: Record<string, string | string[]>,
        ): Promise<Route> {
            const query = JSON.parse(JSON.stringify(this.$route.query));

            return this.$router.replace({
                query: Object.assign(query, params),
            }) as any;
        },

        async removeQueryFromRoute(name: string): Promise<Route> {
            const query = JSON.parse(JSON.stringify(this.$route.query));

            delete query[name];

            return this.$router.replace({ query }) as any;
        },
    },
});
