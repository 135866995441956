export default class AudioController {
    protected audio?: HTMLAudioElement;
    protected isFirstAudio?: boolean = true;
    protected isGrantedAudio: boolean = false;

    protected static instance?: AudioController;

    public static getInstance(): AudioController {
        if (!AudioController.instance) {
            AudioController.instance = new this();
        }

        return AudioController.instance;
    }

    public setAudio(file: string): void {
        this.audio = new Audio(file);
    }

    public setAudioBySrc(file: string): void {
        if (this.audio) {
            this.isFirstAudio = false;
            this.audio.src = file;
        } else {
            this.setAudio(file);
        }
    }

    public getAudio(): HTMLAudioElement {
        // if (!this.audio) throw new Error("Audio is not set");

        return this.audio!;
    }

    public getIsFirstAudio(): boolean {
        return !!this.isFirstAudio;
    }

    public getIsGrantedAudio(): boolean {
        return this.isGrantedAudio;
    }

    public setIsGrantedAudio(isGranted: boolean): void {
        this.isGrantedAudio = isGranted;
    }

    public destroyInstance(): void {
        AudioController.instance = undefined;
    }
}
