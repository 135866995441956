import mitt from "mitt";

export type EventNames = "app:config_changed";

type PrivateEvent<
    Name extends EventNames,
    Payload = undefined,
> = Payload extends undefined
    ? {
          name: Name;
          payload?: undefined;
      }
    : {
          name: Name;
          payload: Payload;
      };

export type PrivateEvents = PrivateEvent<
    "app:config_changed",
    { config?: any }
>;

type PrivateEventChannel = {
    [key in EventNames]: Extract<PrivateEvents, { name: key }>;
};

export const emitter = mitt<PrivateEventChannel>();

export const dispatch = (event: PrivateEvents) => {
    emitter.emit(event.name, event);
};

export const subscribe = <T extends EventNames>(
    event: T,
    handler: Parameters<typeof emitter.on<T>>[1],
) => {
    emitter.on(event, handler);
};

export const unsubscribe = <T extends EventNames>(
    event: T,
    handler: Parameters<typeof emitter.on<T>>[1],
) => {
    emitter.off(event, handler);
};
