import mitt from "mitt";
import { type EventNames, type PublicEvents } from "../public/channel";
import { type RequestEvents, type RequestEventNames } from "./requestsChannel";

type ConfirmablePublicEventsNames =
    | Extract<
          EventNames,
          | "user:logout"
          | "session:profile_enrichment_closed"
          | "session:from_activity_closed"
          | "session:closed"
          | "session:offering_action"
      >
    | "videocall:connect"
    | "navigation:before";

type ConfirmableEventsNames = RequestEventNames | ConfirmablePublicEventsNames;

type RemovePayload<T extends { name: string }, U> = Pick<
    Extract<T, U>,
    "name"
> & { payload?: undefined };

type SerializedRoute = {
    path: string;
    name?: string | null;
    hash: string;
    params: Record<string, string>;
    query: Record<string, string>;
    fullPath: string;
};

export type ConfirmableEvents =
    | RemovePayload<RequestEvents, { name: "request:location" }>
    | Extract<PublicEvents, { name: ConfirmablePublicEventsNames }>
    | {
          name: "videocall:connect";
          payload: {
              token: string;
              roomName: string;
              userId: string;
          };
      }
    | {
          name: "navigation:before";
          payload: {
              to: SerializedRoute;
              from: SerializedRoute;
              direction: "forward" | "backward";
              //   query?: Record<string, string>;
          };
      };

export type ConfirmationRequestEventNames =
    `confirmation_request::${ConfirmableEventsNames}`;
type ConfirmationResponseEventNames =
    `confirmation_response::${ConfirmableEventsNames}`;

type ExtractEventName<TEventName extends ConfirmationRequestEventNames> =
    TEventName extends `confirmation_request::${infer EventName}`
        ? EventName
        : never;

type ConfirmationEventChannel = {
    [key in
        | ConfirmationRequestEventNames
        | ConfirmationResponseEventNames]: key extends ConfirmationRequestEventNames
        ? Extract<ConfirmableEvents, { name: ExtractEventName<key> }>
        : boolean;
};

export const confirmationEventsChannel = mitt<ConfirmationEventChannel>();

export const confirmEvent = (
    event: ConfirmableEventsNames,
    confirmation: boolean,
) => {
    confirmationEventsChannel.emit(
        `confirmation_response::${event}`,
        confirmation,
    );
};

type ExtractPublicEventPayload<T extends ConfirmableEventsNames> = Extract<
    ConfirmableEvents,
    { name: T }
>["payload"];

export const useAwaitedConfirmationEvent = <T extends ConfirmableEventsNames>(
    event: T,
    callback: VoidFunction,
) => {
    type Payload = ExtractPublicEventPayload<T>;
    type Dispatcher = (data?: Payload) => void;

    // const eventName: ConfirmationEventNames = `response::${event}`;
    const confirmationRequestEvent: ConfirmationRequestEventNames = `confirmation_request::${event}`;
    const confirmationResponseEvent: ConfirmationResponseEventNames = `confirmation_response::${event}`;

    const emit: Dispatcher = (data) => {
        const handler = (proceed: boolean) => {
            confirmationEventsChannel.off(confirmationResponseEvent, handler);
            proceed && callback();
        };

        confirmationEventsChannel.on(confirmationResponseEvent, handler);

        const eventToBeDispatched = {
            name: event,
            payload: data,
        } as ConfirmableEvents;

        confirmationEventsChannel.emit(
            confirmationRequestEvent,
            eventToBeDispatched,
        );
    };
    return { dispatch: emit };
};

export const dispatchConfirmationRequestEvent = (event: ConfirmableEvents) => {
    const confirmationRequestEvent: ConfirmationRequestEventNames = `confirmation_request::${event.name}`;
    const confirmationResponseEvent: ConfirmationResponseEventNames = `confirmation_response::${event.name}`;

    return new Promise<boolean>((resolve) => {
        const handler = (response: boolean) => {
            console.log("Confrimation to event!", { event, response });
            confirmationEventsChannel.off(confirmationResponseEvent, handler);
            resolve(response);
        };

        confirmationEventsChannel.on(confirmationResponseEvent, handler);

        confirmationEventsChannel.emit(confirmationRequestEvent, event);
    });
};

export default {
    useAwaitedConfirmationEvent,
    dispatchConfirmationRequestEvent,
    confirmEvent,
    confirmationEventsChannel,
};

// Awaited event list:
// "user:logout"
// "session:profile_enrichment_closed"
// "session:from_activity_closed",
// "session:closed",
