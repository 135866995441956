import BusinessAbstract from "./BusinessAbstract";

export default class AgreeLegalTermsBO extends BusinessAbstract {
    static instance?: AgreeLegalTermsBO;

    static getInstance(): AgreeLegalTermsBO {
        if (!AgreeLegalTermsBO.instance) {
            AgreeLegalTermsBO.instance = new this();
        }

        return AgreeLegalTermsBO.instance;
    }

    async doAgreeLegalTerms() {
        await this.api.service("agreeLegalTerms");
    }
}
