import { ExternUserProductVO } from "modules/core/model/externUserProductVO";
import { Application } from "./Holder";

export default class ProductPrice {
    externUserAvailableTicket: number;

    productUnitPrice: number;

    hasDiscount: boolean;

    totalPrice: number;

    totalPriceWithDiscount: number;

    totalTicketsPrice: number;

    currency?: string;

    priceTier: number;

    priceTierShown: number;

    constructor(
        product: ExternUserProductVO | null = null,
        priceTier: number | null = null,
        priceTierShown: number | null = null,
    ) {
        if (product === null && priceTier === null && priceTierShown === null) {
            throw new Error(
                "No object with tiers, neither priceTier or priceTierShown passed as arguments",
            );
        }

        const app = Application.getInstance();
        const tierPricing = app.userTierPricing();

        this.externUserAvailableTicket = app?.externUser?.tickets ?? 0;
        this.productUnitPrice = tierPricing?.totalPrice ?? 0 / 100;
        this.hasDiscount = false;
        this.totalPrice = 0.0;
        this.totalPriceWithDiscount = 0.0;
        this.totalTicketsPrice = 0.0;
        this.currency = tierPricing?.currency;

        this.priceTier = priceTier || product?.priceTier || 0;
        this.priceTierShown =
            priceTierShown || priceTier || product?.priceTierShown || 0;

        this.calculatePrice();
    }

    calculatePrice() {
        if (!this.priceTier) return;
        const { priceTier } = this;
        let priceTierShown = priceTier;

        if (this.priceTierShown != null) {
            priceTierShown = this.priceTierShown;
        }
        // priceTier [sempre es mostra]
        this.totalPrice = ProductPrice.formatPrice(
            priceTierShown * this.productUnitPrice,
        );
        this.totalPriceWithDiscount = ProductPrice.formatPrice(
            priceTier * this.productUnitPrice,
        );
        this.totalTicketsPrice = ProductPrice.formatPrice(
            this.externUserAvailableTicket * this.productUnitPrice,
        );

        this.hasDiscount =
            this
                .totalPriceWithDiscount /* - parseFloat(this.totalTicketsPrice) */ <
            this.totalPrice;
    }

    description(
        className: string = "",
        freeDescriptionText: string = "Free",
        isExtendedDescription: boolean = false,
    ): string {
        if (this.isFree()) {
            return freeDescriptionText;
        }
        if (this.hasDiscount && isExtendedDescription) {
            const totalDiscount =
                this.totalPriceWithDiscount - this.totalTicketsPrice;
            const discountMessage =
                totalDiscount > 0
                    ? `${totalDiscount} ${this.currency}`
                    : freeDescriptionText;

            return `${discountMessage} <span class='${className}'>${this.totalPrice} ${this.currency}</span>`;
        }

        // if(this.hasDiscount){
        //     return `<span class='${className}'>${this.totalPrice} ${this.currency}</span> ${this.totalPriceWithDiscount} ${this.currency} `;
        // }

        return `${this.totalPriceWithDiscount} ${this.currency}`;
    }

    // FIXME: hay que revisar esto con cariño!!!
    static formatPrice(price: number): number {
        return parseFloat(price.toFixed(2));
    }

    totalPriceInCents(): number {
        return this.totalPriceWithDiscount * 100;
    }

    finalPrice(): number {
        return this.totalPriceWithDiscount;
    }

    isFree(): boolean {
        return this.totalPriceWithDiscount <= 0 || this.totalPrice == 0;
    }
}
