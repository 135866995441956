import localforage from "localforage";
import moment from "moment";
import _every from "lodash/every";
import Api from "../lib/Api";
import { Application } from "../lib/Holder";
import GenericDAO from "../dao/GenericDAO";
import GenericRemoteDAO from "../dao/GenericRemoteDAO";
import Util from "../lib/Util";

export default class GenericRepo {
  constructor() {
    this.api = Api.getInstance();
    this.dao = new GenericDAO();
    this.remoteDao = new GenericRemoteDAO();
    this.lastMaxDate = null;
    this.lastSinceDate = null;
    this.noMoreItems = false;
    this.storageName = "generic";
    this.expirationMaxDays = 7;
  }

  async setupDriver(externUserId = null) {
    if (externUserId) {
      this.storageInstance = localforage.createInstance({
        name: Application.getInstance().appVisibleName(),
        storeName: `master=${externUserId}`,
      });
    } else {
      this.storageInstance = localforage; /* .createInstance({
                name: Holder.app.externUserId
            }); */
    }
    const defaults = {
      items: [],
      lastMaxDate: null,
      lastSinceDate: null,
    };

    let data = defaults;

    if (!(await this.storageInstance.getItem(this.storageName))) {
      await this.storageInstance.setItem(this.storageName, defaults);
    } else {
      data = await this.storageInstance.getItem(this.storageName);
    }

    this.dao.addItems(data.items);

    this.lastMaxDate = data.lastMaxDate; // lastSyncDate
    this.lastSinceDate = data.lastSinceDate; // oldestCreationDate
    this.noMoreItems = data.noMoreItems;
    // this.checkPendingData();
  }

  storageHasExpired(date) {
    const pastDays = moment().diff(moment(date), "days");

    return pastDays > this.expirationMaxDays;
  }

  whipeData() {
    if (!this.storageInstance) return;
    this.dao.cleanAll();

    this.lastMaxDate = null;
    this.lastSinceDate = null;
    this.noMoreItems = false;

    this.updateStorage();
  }

  getMaxDate() {
    return this.lastMaxDate;
  }

  async getStorageData() {
    return await this.storageInstance.getItem(this.storageName);
  }

  async fetch(
    offset = null,
    count = null,
    maxDate = null,
    params = {},
    externUserId,
  ) {
    return [];
  }

  async fetchPending(
    offset,
    count,
    sinceDate,
    maxDate,
    useLastEdited,
    externUserId,
  ) {
    return [];
  }

  filterItemsByExternUserIdFromStoreName() {
    const storeNameExternUserId = this.storageInstance._config.storeName
      .split("=")
      .slice(-1)[0];

    return this.dao.getList().filter((item) => {
      if ("externUserId" in item) {
        return item.externUserId === storeNameExternUserId;
      }
      if ("externUserGroupId" in item) {
        return item.externUserGroupMembers.some(
          (externUser) => externUser.externUserId === storeNameExternUserId,
        );
      }
    });
  }

  async updateStorage(externUserId = null) {
    await this.storageInstance.setItem(this.storageName, {
      // when an ExternUserId is sent , it filters the items list by the storeName
      items: externUserId
        ? this.filterItemsByExternUserIdFromStoreName()
        : this.dao.getList(),
      lastMaxDate: this.lastMaxDate,
      lastSinceDate: this.lastSinceDate,
      noMoreItems: this.noMoreItems,
    });
  }

  async getListFromServer(
    offset = null,
    count = null,
    params = {},
    externUserId = null,
  ) {
    const maxDate = this.lastMaxDate ? this.lastMaxDate : this.lastSinceDate;
    // let maxDate = Util.currentTimestamp();
    let itemsFromServer = await this.fetch(
      0,
      count,
      maxDate,
      params,
      externUserId,
    );

    if (
      itemsFromServer.length > 0 &&
      this.lastMaxDate === itemsFromServer[itemsFromServer.length - 1].date
    ) {
      itemsFromServer = await this.fetch(
        offset,
        count,
        maxDate,
        params,
        externUserId,
      );
    }

    if (itemsFromServer.length) {
      for (const item of itemsFromServer) {
        this.dao.addItem(item, false);
      }

      this.lastMaxDate = itemsFromServer[itemsFromServer.length - 1].date;
    }

    this.updateStorage(externUserId);

    return itemsFromServer;
  }

  createExternUserStore(externUserId = null) {
    if (!externUserId) return;
    this.setupDriver(externUserId);
  }

  async checkPendingData(externUserId = null, params = {}) {
    // The flow change if an ExternUserId is sent, starting creating an independent store,
    // otherwise it will remain the regular flow
    this.createExternUserStore(externUserId);

    if (this.lastSinceDate !== null) {
      if (this.storageHasExpired(this.lastSinceDate)) {
        this.whipeData();

        console.info(
          `${this.storageName} storage whiped because some days passed from last sync.`,
        );
      } else {
        return await this.getPendingListFromServer(
          this.lastSinceDate,
          Util.currentTimestamp(),
          externUserId,
          params,
        );
      }
    } else {
      const sinceDate = moment().subtract(this.expirationMaxDays, "days");

      const response = await this.getPendingListFromServer(
        sinceDate.valueOf(),
        Util.currentTimestamp(),
        externUserId,
        params,
      );
      console.info(
        "Fetch pending from server since ",
        sinceDate.format("DD-MM-YYYY HH:mm"),
      );
      return response;
    }
  }

  /**
   * Fetches pending list of items from server since a specified date
   * @param sinceDate
   * @param maxDate
   * @returns {Promise<[]>}
   */
  async getPendingListFromServer(
    sinceDate,
    maxDate = null,
    externUserId = null,
    params,
  ) {
    let offset = 0;
    const count = 10;
    let resultCount = 10;
    let itemsFromServer = [];

    while (count <= resultCount) {
      itemsFromServer = await this.fetchPending(
        offset,
        count,
        sinceDate,
        maxDate,
        true,
        externUserId,
      );
      resultCount = itemsFromServer.length;
      offset += count;

      for (const item of itemsFromServer) {
        this.dao.addItem(item, true);
      }
    }

    this.lastSinceDate = maxDate;
    if (!this.lastMaxDate) this.lastMaxDate = sinceDate;

    return this.whichListOrderedByDate("desc", 0, count, externUserId, params);
  }

  /**
   * Retrieves a list of items and fetches more from server if necessary depending on offset and count
   * @param offset
   * @param count
   * @returns void
   */
  async manageList(offset, count, params = {}, externUserId = null) {
    let items = this.whichListOrderedByDate(
      "desc",
      offset,
      count,
      externUserId,
      params,
    );
    let fetchCount = count - items.length;

    while (fetchCount > 0 && !this.noMoreItems) {
      const fetchRequest = await this.getListFromServer(
        offset + (count - fetchCount),
        count,
        params,
        externUserId,
      );

      if (fetchRequest.length < count) {
        this.noMoreItems = true;
      }

      items = this.whichListOrderedByDate(
        "desc",
        offset,
        count,
        externUserId,
        params,
      );
      fetchCount = count - items.length;

      this.updateStorage(externUserId);
    }
  }

  whichListOrderedByDate(type, offset, count, externUserId, params) {
    if (externUserId) {
      return this.dao.getListOrderedByDateExternUserId(
        type,
        offset,
        count,
        externUserId,
        params,
      );
    }
    return this.dao.getListOrderedByDate(
      type,
      offset,
      count,
      Api.getInstance().externUserId,
    );
  }

  async getList(offset, count, params = {}, externUserId = null) {
    await this.manageList(offset, count, params, externUserId);

    return this.whichListOrderedByDate(
      "desc",
      offset,
      count,
      externUserId,
      params,
    );
  }

  async getListOrderedByDate(offset, count, params = {}, externUserId = null) {
    await this.manageList(offset, count, params, externUserId);

    return this.whichListOrderedByDate(
      "desc",
      offset,
      count,
      externUserId,
      params,
    );
  }
}
