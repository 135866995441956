import vue from "vue";
import ApiMixin from "modules/core/mixins/ApiMixin";
import LocaleMixin from "modules/core/mixins/LocaleMixin";
import CoreComponentMixin from "modules/core/mixins/CoreComponentMixin";
import LayoutMixin from "modules/core/mixins/LayoutMixin";

// TODO: Should be removed! This is the old CoreComponent which will eventually be replaced by CoreComponentMixin.

export default vue.extend({
  mixins: [CoreComponentMixin, ApiMixin, LayoutMixin, LocaleMixin],
});
