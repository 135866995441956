import template from "modules/core/components/interface/datepicker/datepicker.html?raw";
import "modules/core/components/interface/datepicker/datepicker.scss";
import Flatpickr from "flatpickr";
import moment from "moment";
import { CustomLocale } from "flatpickr/dist/types/locale";
import { DateOption } from "flatpickr/dist/types/options";
import { Instance as FlatpickrInstance } from "flatpickr/dist/types/instance";

import vue, { inject } from "vue";
import UITextfield from "modules/core/components/interface/textfield/Textfield";
import CheckerSkinEnum from "modules/core/enums/CheckerSkinEnum";
import LanguageBO from "../../../business/LanguageBO";

const localeList = import.meta.glob<any>(
    "../../../../node_modules/flatpickr/dist/l10n/(es|pt|fi|sv|it|default|de|fr|zh|gr|cat|ar|ro).js",
    {
        eager: true,
    },
);

const flatpickrLangMap = {
    "es-ES": ["es", "Spanish"],
    "pt-PT": ["pt", "Portuguese"],
    "pt-BR": ["pt", "Portuguese"],
    "fi-FI": ["fi", "Finnish"],
    "sv-SW": ["sv", "Swedish"],
    "it-IT": ["it", "Italian"],
    "en-US": ["default", "english"],
    "de-DE": ["de", "German"],
    "en-EN": ["default", "english"],
    "es-CO": ["es", "Spanish"],
    "es-MX": ["es", "Spanish"],
    "fr-FR": ["fr", "French"],
    "zh-CN": ["zh", "Mandarin"],
    "el-GR": ["gr", "Greek"],
    "ca-ES": ["cat", "Catalan"],
    "ar-AR": ["ar", "Arabic"],
    "ro-RO": ["ro", "Romanian"],
};

type FlatpickrLocale = keyof typeof flatpickrLangMap;

export default vue.extend({
    template,
    components: {
        "mdk-textfield": UITextfield,
    },
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            picker: null as FlatpickrInstance | null,
            pickerLanguage: null as CustomLocale | null,
        };
    },
    setup() {
        const avatarVisibility = inject("avatarVisibility") as Boolean;

        return { avatarVisibility };
    },
    computed: {
        flatpickrLangMap() {
            return flatpickrLangMap;
        },
        showPlaceholderAsLabel(): Boolean {
            return (
                this.app().generalConfig().checker.inputTextLabel &&
                (this.app().generalConfig().checkerSkin ===
                    CheckerSkinEnum.ASSISTANT ||
                    (this.app().generalConfig().checkerSkin ===
                        CheckerSkinEnum.AVATAR &&
                        this.avatarVisibility))
            );
        },
        nativeInput(): HTMLFormElement {
            return (this.$refs.textInput as HTMLElement | any).$refs.textInput;
        },
    },
    watch: {
        value(newValue: DateOption) {
            if (newValue === this.nativeInput.value) return;
            this.picker && this.picker.setDate(newValue, true);
        },
    },
    methods: {
        async setup() {
            let pickerFormat = "d/m/Y";

            try {
                const language =
                    await LanguageBO.getInstance().getResolvedCurrentLanguageCode();
                if (language === "en-US") {
                    pickerFormat = "m/d/Y";
                } else if (language === "ja-JP") {
                    pickerFormat = "Y/m/d";
                }
            } catch (e) {
                console.log("DatePicker Setup error ->", e);
            }

            await this.getFlatpickrLang();

            setTimeout(() => {
                // @ts-ignore
                this.picker = Flatpickr(this.nativeInput, {
                    locale: this.pickerLanguage,
                    dateFormat: pickerFormat,
                    onChange: (selectedDates) => {
                        this.$emit("input", moment(selectedDates[0]).valueOf());
                    },
                });
                if (this.value)
                    this.picker.setDate(moment(this.value).valueOf());

                // this.$refs.textInput.setupField();
            }, 1000);
        },
        async getFlatpickrLang() {
            const language =
                (await LanguageBO.getInstance().getResolvedCurrentLanguageCode()) as
                    | FlatpickrLocale
                    | undefined;
            if (!language) return;

            try {
                // await import(
                //   // We need this magic commit (webpackInclude) from webpack, otherwise all the available language
                //   // packs will be bundled, resulting in an increase in the compilation time and the bundle size
                //   // more info https://webpack.js.org/api/module-methods/#magic-comments

                //   /* webpackInclude: /(es|pt|fi|sv|it|default|de|fr|zh|gr|cat|ar|ro)\.js$/ */
                //   localeList`flatpickr/dist/l10n/${this.flatpickrLangMap[language][0]}.js`
                // ).then((lang) => {

                localeList[
                    `../../../../node_modules/flatpickr/dist/l10n/${this.flatpickrLangMap[language][0]}.js`
                ]().then((lang: any) => {
                    this.pickerLanguage =
                        lang[this.flatpickrLangMap[language][1]];
                });
            } catch (err) {
                console.log("set Flatpickr language error", err);
            }
        },
    },
    mounted() {
        this.setup();
    },
});
