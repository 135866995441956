import template from "modules/core/components/interface/toast/toast.html?raw";
import "modules/core/components/interface/toast/toast.scss";
import anime from "animejs";

import vue from "vue";

export default vue.extend({
    name: "Toast",
    template,
    props: {
        text: {
            type: String,
            required: false,
            default: null,
        },
        delay: {
            type: Number,
            required: false,
            default: 4000,
        },
        fixed: {
            type: Boolean,
            required: false,
            default: true,
        },
        onDismiss: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            isVisible: true,
        };
    },
    computed: {
        classes(): Record<string, boolean> {
            return {
                "mdk-ui-toast--fixed": this.fixed,
            };
        },
    },
    methods: {
        destroy() {
            this.animateOut(() => {
                this.isVisible = false;
                // @ts-ignore this is a function, but the type inferences fails when defining a default value
                this.onDismiss();
            });
        },
        animateIn() {
            const tl = anime.timeline({
                easing: "easeOutExpo",
            });

            tl.add({
                targets: this.$el,
                translateY: -10,
                duration: 500,
            });
        },
        animateOut(endCallback = () => {}) {
            const tl = anime.timeline({
                easing: "easeInExpo",
            });

            tl.add({
                targets: this.$el,
                translateY: 10,
                duration: 500,
                opacity: 0,
                complete(anim: any) {
                    endCallback();
                },
            });
        },
        setup() {
            this.animateIn();
            setTimeout(() => this.destroy(), this.delay);
        },
    },
    mounted() {
        this.setup();
    },
});
