import { getUrlQueryParamsAsObject } from "modules/core/lib/Util";
import app from "modules/core/config/app";

type HandlerParam = Record<string, string>;

export default class UtmHandler {
    static instance?: UtmHandler;

    private utmParams: HandlerParam = {};

    static getInstance(): UtmHandler {
        if (!UtmHandler.instance) UtmHandler.instance = new this();

        return UtmHandler.instance;
    }

    constructor() {
        this.utmParams = {};
        const storedUtmParams = localStorage.getItem("utm");
        if (storedUtmParams) {
            try {
                this.utmParams = JSON.parse(storedUtmParams);
            } catch (error) {
                console.error("Error restoring utmParams:", error);
            }
        }
    }

    handleUtmParams() {
        const query = getUrlQueryParamsAsObject(location.href);
        const utmMap = {};
        if (query) {
            for (const key in query) {
                if (key.substr(0, 4) === "utm_") {
                    utmMap[key] = query[key];
                }
            }
        }
        if (Object.keys(utmMap).length !== 0) {
            this.utmParams = utmMap;
        }

        if (window.localStorage) {
            window.localStorage.setItem("utm", JSON.stringify(this.utmParams));
            const storedUTMs = window.localStorage.getItem("utm");
            if (storedUTMs) {
                this.utmParams = JSON.parse(storedUTMs);
            }
        }
    }

    getParams(): HandlerParam {
        return this.utmParams;
    }

    getValueFor(name: string): string {
        return this.utmParams[`utm_${name}`];
    }

    hasData(): boolean {
        return Object.keys(this.utmParams).length > 0;
    }

    transformUrl(url: string) {
        if (url && url[0] === "/") {
            url = `${location.protocol}//${location.host}${url}`;
        }

        const urlWithoutParams = url.split("?")[0];
        const newUrl = new URL(url);

        const params = new URLSearchParams(newUrl.searchParams);
        params.delete("utm_source");
        params.delete("utm_campaign");
        params.delete("utm_medium");
        params.delete("utm_content");
        params.delete("utm_terms");

        if (this.hasData()) {
            if (this.getValueFor("source"))
                params.append("utm_source", this.getValueFor("source"));
            if (this.getValueFor("campaign"))
                params.append("utm_campaign", this.getValueFor("campaign"));
            if (this.getValueFor("medium"))
                params.append("utm_medium", this.getValueFor("medium"));
            if (this.getValueFor("content"))
                params.append("utm_content", this.getValueFor("content"));
            if (this.getValueFor("terms"))
                params.append("utm_terms", this.getValueFor("utm_terms"));
        } else {
            // params.append("utm_source", app.utmParams.source);
            // params.append("utm_campaign", app.utmParams.campaign);
            // params.append("utm_medium", app.utmParams.medium);
            Object.entries(app.utmParams).forEach(([key, value]) => {
                params.append(`utm_${key}`, value);
            });
        }

        return decodeURIComponent(`${urlWithoutParams}?${params.toString()}`);
    }
}
