import Quasar, { QuasarLanguage } from "quasar";

const localeList = import.meta.glob(
    // "../../../../../../../node_modules/quasar/lang/*.js"
    "../../../../../../../node_modules/quasar/lang/(es|pt|pt-br|fi|sv|it|en-us|de|en-gb|fr|zh-hans|el|ca|ar|ro|ja).js",
);
const quasarLangMap = {
    "es-ES": "es",
    "pt-PT": "pt",
    "pt-BR": "pt-br",
    "fi-FI": "fi",
    "sv-SW": "sv",
    "it-IT": "it",
    "en-US": "en-us",
    "de-DE": "de",
    "en-EN": "en-gb",
    "es-CO": "es",
    "es-MX": "es",
    "fr-FR": "fr",
    "zh-CN": "zh-hans",
    "el-GR": "el",
    "ca-ES": "ca",
    "ar-AR": "ar",
    "ro-RO": "ro",
    "nl-BE": "nl",
    "fr-BE": "fr",
    "en-GB": "en-gb",
    "ja-JP": "ja",
};

type QuasarLocale = keyof typeof quasarLangMap;

export default class QuasarManager {
    static instance?: QuasarManager;

    language?: QuasarLocale;

    constructor() {}

    static getInstance(): QuasarManager {
        if (!QuasarManager.instance) {
            QuasarManager.instance = new this();
        }

        return QuasarManager.instance;
    }

    async setupQuasarLang(
        language?: QuasarLocale,
        callback?: (lang: QuasarLanguage) => void,
    ) {
        if (1) return;
        if (!language && !this.language) return;

        const locale = (language || this.language) as QuasarLocale;
        console.log({
            language,
            locale,
            key: `../../../../../../../node_modules/quasar/lang/${quasarLangMap[locale]}.js`,
        });
        try {
            const lang = (await localeList[
                `../../../../../../../node_modules/quasar/lang/${quasarLangMap[locale]}.js`
            ]()) as QuasarLanguage;
            // .then((lang) => {
            //   console.log({ lang });
            //   Quasar.lang.set(lang as QuasarLanguage);
            // });
            console.log({ lang });
            Quasar.lang.set(lang as QuasarLanguage);
            console.log({ QuasarLocale: Quasar.lang.getLocale() });
            callback?.(lang);
        } catch (err) {
            console.log("set quasar language error", err);
        }
    }
}
