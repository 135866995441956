import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template: `<iframe ref="frame" frameborder="0"></iframe>`,
    props: {
        contentText: {
            type: String,
            required: false,
            default: null,
        },
    },
    watch: {
        contentText() {
            this.setupFrame();
        },
        isDarkMode() {
            this.setupFrame();
        },
    },
    methods: {
        setupFrame() {
            this.$nextTick(() => {
                try {
                    const frame = this.$refs.frame as HTMLIFrameElement;
                    if (frame.contentWindow) {
                        frame.contentWindow.document.open();
                        this.contentText &&
                            frame.contentWindow.document.write(
                                this.contentText,
                            );
                        frame.contentWindow.document.close();
                        frame.style.width = "100%";
                        const lang = this.$app.language;
                        if (lang === "ar-AR")
                            frame.contentWindow.document.documentElement.style.direction =
                                "rtl";
                        setTimeout(() => {
                            frame.style.height = `${
                                // @ts-ignore
                                frame.contentWindow.document.body.offsetHeight +
                                100
                            }px`;
                        }, 300);
                        frame.contentWindow.document.body.style.overflow =
                            "hidden";
                        if (this.isDarkMode)
                            frame.contentWindow.document.body.style.color =
                                "#FFF";
                        else
                            frame.contentWindow.document.body.style.color =
                                "#4a4a4a";
                    }
                } catch (e) {
                    console.log("FlexibleIframeContent setup error ->", e);
                }
            });
        },
    },
    mounted() {
        this.setupFrame();
    },
});
