<template>
    <section></section>
</template>
<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
    console.info("Navigated to BLANK view!");
});
</script>
