import Util, { removeQueryStringParamFromUrl } from "./Util";
import AppStorageManager from "./AppStorageManager";

export class AppQueryContentManager {
    static instance?: AppQueryContentManager;

    isAppContent: boolean = false;

    hideHeader: boolean = false;

    hideFooter: boolean = false;

    origin?: string;

    static getInstance(): AppQueryContentManager {
        if (!AppQueryContentManager.instance) {
            AppQueryContentManager.instance = new this();
        }

        return AppQueryContentManager.instance;
    }

    manageAll() {
        this.manageIsAppContent();
        this.manageHideHeader();
        this.manageHideFooter();
        this.manageOrigin();
    }

    manageIsAppContent() {
        const passedValue = Util.urlQueryParam("isAppContent");

        if (!Util.isNullOrEmpty(passedValue)) {
            const value = !!passedValue;

            this.isAppContent = value;

            AppStorageManager.getInstance().saveIsAppContent(value);

            removeQueryStringParamFromUrl(["isAppContent"]);
        } else {
            const existingValue =
                AppStorageManager.getInstance().getIsAppContent();
            if (existingValue) {
                this.isAppContent = existingValue;
            }
        }
    }

    manageHideHeader() {
        const passedValue = Util.urlQueryParam("hideHeader");

        if (!Util.isNullOrEmpty(passedValue)) {
            const value = !!passedValue;

            this.hideHeader = value;

            AppStorageManager.getInstance().saveHideHeader(value);

            removeQueryStringParamFromUrl(["hideHeader"]);
        } else {
            const existingValue =
                AppStorageManager.getInstance().getHideHeader();
            if (existingValue) {
                this.hideHeader = existingValue;
            }
        }
    }

    manageHideFooter() {
        const passedValue = Util.urlQueryParam("hideFooter");

        if (!Util.isNullOrEmpty(passedValue)) {
            const value = !!passedValue;

            this.hideFooter = value;

            AppStorageManager.getInstance().saveHideFooter(value);

            removeQueryStringParamFromUrl(["hideFooter"]);
        } else {
            const existingValue =
                AppStorageManager.getInstance().getHideFooter();
            if (existingValue) {
                this.hideFooter = existingValue;
            }
        }
    }

    manageOrigin() {
        const passedValue = Util.urlQueryParam("origin");

        if (!Util.isNullOrEmpty(passedValue) && passedValue) {
            this.origin = passedValue;

            AppStorageManager.getInstance().saveOrigin(passedValue);

            removeQueryStringParamFromUrl(["origin"]);
        } else {
            const existingValue = AppStorageManager.getInstance().getOrigin();
            if (existingValue) {
                this.origin = existingValue;
            }
        }
    }
}
