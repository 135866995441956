import moment from "moment";
import Storage from "modules/core/lib/Storage";
import defaults from "modules/core/config/defaults";
import { GeneralConfig } from "modules/core/types/misc";

export type PresetItem = {
    config?: Partial<GeneralConfig>;
    date?: number;
    env?: {
        username?: string;
        password?: string;
        userId?: string;
        endpoint?: string;
        wsEndpoint?: string;
        analyticsId?: string;
        stripeId?: string;
    };
    id?: string;
};

export type AppPresetsStoreValue = {
    active: string | null;
    list: PresetItem[];
};

export type ConfigParamListItem = {
    name: string;
    label: string;
    type: string;
    defaultValue: string;
};

export default class AppPresets {
    static instance?: AppPresets;

    storage: Storage;

    presetList: PresetItem[] = [];

    constructor() {
        this.storage = new Storage("appPresets", null);
        this.presetList = this.storage.getByName("list") || [];
    }

    getPresetList(): PresetItem[] {
        return this.presetList.sort((a, b) => a.id!.localeCompare(b.id!));
    }

    static getInstance(): AppPresets {
        if (!AppPresets.instance) AppPresets.instance = new this();

        return AppPresets.instance;
    }

    getActivePreset(): PresetItem | null {
        return (
            this.presetList.find((preset) => {
                return preset.id === this.getActiveName();
            }) || null
        );
    }

    getPresetByName(presetName: string): PresetItem | null {
        return (
            this.presetList.find((preset) => {
                return preset.id === presetName;
            }) || null
        );
    }

    getActiveName(): string | null {
        return this.storage.getByName("active");
    }

    setActive(presetName: string) {
        this.storage.save("active", presetName);
    }

    hasActive(): boolean {
        return this.getActiveName() !== null && this.getActiveName() !== "";
    }

    save(data: PresetItem) {
        data.date = moment().valueOf();

        this.presetList.push(data);

        this.storage.save("list", this.presetList);
    }

    saveActive(presetName: string) {
        this.storage.save("active", presetName);
    }

    removeItem(presetName: string, handleActive: boolean = true) {
        const list = this.presetList.filter((item) => item.id !== presetName);

        this.storage.save("list", list);

        this.presetList = list;

        if (handleActive && this.getActiveName() === presetName)
            this.setActive("");
    }

    getConfigParamsList(): ConfigParamListItem[] {
        const def = defaults as Record<string, any>;

        return Object.keys(def).map((configKey) => {
            return {
                name: configKey,
                label: configKey,
                type: typeof def[configKey],
                defaultValue: def[configKey],
            };
        });
    }

    message(): string {
        return `<a href="/app-presets" style="background-color: #ce1339; text-decoration:none; position:fixed; display:block; bottom:0; left:0; border-top-right-radius: 10px; font-size:11px; font-weight:bold; color:white; padding:5px 10px; z-index:99999; text-align:center;">Preset ${this.getActiveName()} is active</a>`;
    }

    static downloadFile(filename: string, text: string) {
        const element = document.createElement("a");
        element.setAttribute(
            "href",
            `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`,
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    generateExportFileForId(idList: string[]) {
        const content = JSON.stringify(
            idList.map((id) => this.getPresetByName(id)),
        );

        AppPresets.downloadFile(`presets-${moment().valueOf()}`, content);
    }

    importDataFromJson(data: string) {
        const date = moment().valueOf();
        const json = JSON.parse(data);
        let newPresets: PresetItem[] = [];

        if (Array.isArray(json)) {
            newPresets = json as unknown as PresetItem[];
        } else {
            newPresets = [json as unknown as PresetItem];
        }

        for (const preset of newPresets) {
            const presetItem = preset;
            presetItem.date = date;

            if (!presetItem.id) {
                presetItem.id = `preset-${date}`;
            }

            if (this.getPresetByName(preset.id!)) {
                if (confirm(`${preset.id} already exists. Override?`)) {
                    this.removeItem(preset.id!);
                    this.save(presetItem);
                } else {
                    presetItem.id = `${json.id}-${date}`;

                    this.save(presetItem);
                }
            } else {
                this.save(presetItem);
            }
        }
    }
}
