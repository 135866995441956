import _slice from "lodash/slice";
import _orderBy from "lodash/orderBy";
import BusinessAbstract from "./BusinessAbstract";
import ExternUserGroupRepo from "../repos/ExternUserGroupRepo";
import SessionRepo from "../repos/SessionRepo";

export default class ExternUserGroupBO extends BusinessAbstract {
  constructor() {
    super();

    this.repo = ExternUserGroupRepo.getInstance();
  }

  static getInstance() {
    if (!ExternUserGroupBO.instance) {
      ExternUserGroupBO.instance = new this();
    }

    return ExternUserGroupBO.instance;
  }

  async fetchProductStatus(externUserGroupId) {
    const response = await this.api.service("externUserGroupProductStatus", {
      externUserGroupId,
    });

    return response.externUserGroupProduct;
  }

  async fetchExternUserProductStatus(externUserGroupId) {
    const response = await this.api.service("externUserGroupProductStatus", {
      externUserGroupId,
    });

    return response;
  }

  async fetchExternUserGroupStatus(params = {}) {
    const response = await this.socket.sendServiceViaSocketWithPromise(
      "externUserGroupStatus",
      params,
    );
    if (response !== undefined && "status" in response) return response;
  }

  async fetchExternUserGroupValuation(externUserGroupId) {
    const response = await this.api.service("externUserGroupValuation", {
      externUserGroupId,
    });
    if (response.externUserGroupValuation)
      return response.externUserGroupValuation;
    return null;
  }

  async fetchExternUserGroupList(
    offset,
    count,
    params = {},
    externUserId = null,
  ) {
    return await ExternUserGroupRepo.getInstance().getListOrderedByDate(
      offset,
      count,
      params,
      externUserId,
    );
  }

  async checkPendingData(externUserId = null, params = {}) {
    return await ExternUserGroupRepo.getInstance().checkPendingData(
      externUserId,
      params,
    );
  }

  async fetchExternUserGroupListByGroupStatus(
    offset,
    count,
    groupStatus = null,
    params = {},
  ) {
    return await ExternUserGroupRepo.getInstance().getListByGroupStatus(
      offset,
      count,
      groupStatus,
      params,
    );
  }

  async fetchExternUserGroup(externUserGroupId, params = {}) {
    return await ExternUserGroupRepo.getInstance().getItem(
      externUserGroupId,
      params,
    );
  }

  async fetchValuation(params = {}) {
    return await ExternUserGroupRepo.getInstance().fetchValuation(params);
  }

  isMyExternUserGroup(externUserGroupId, externUserId = this.api.externUserId) {
    return ExternUserGroupRepo.getInstance().isMyExternUserGroup(
      externUserGroupId,
      externUserId,
    );
  }

  getExternUserGroupById(externUserGroupId) {
    return ExternUserGroupRepo.getInstance().getExternUserGroupById(
      externUserGroupId,
    );
  }

  getExternUserGroupListByGroupStatus(groupStatus) {
    return ExternUserGroupRepo.getInstance().getExternUserGroupListByGroupStatus(
      groupStatus,
    );
  }

  getExternUnserGroupIdList() {
    return ExternUserGroupRepo.getInstance().getExternUnserGroupIdList();
  }

  getExternUserGroupList() {
    return ExternUserGroupRepo.getInstance().getExternUserGroupList();
  }

  getGroupStatusByExternUserGroupId(externUserGroupId) {
    return ExternUserGroupRepo.getInstance().getGroupStatusByExternUserGroupId(
      externUserGroupId,
    );
  }

  getStatusByExternUserGroupId(externUserGroupId) {
    return ExternUserGroupRepo.getInstance().getStatusByExternUserGroupId(
      externUserGroupId,
    );
  }

  getExternUserGroupMembersByExternUserGroupId(
    externUserGroupId,
    externUserId = null,
  ) {
    return ExternUserGroupRepo.getInstance().getExternUserGroupMembersByExternUserGroupId(
      externUserGroupId,
      externUserId,
    );
  }

  getLastEditedByExternUserGroupId(externUserGroupId) {
    return ExternUserGroupRepo.getInstance().getLastEditedByExternUserGroupId(
      externUserGroupId,
    );
  }

  getUserRoleByExternUserGroupIdAndExternUserId(
    externUserGroupId,
    externUserId,
  ) {
    return ExternUserGroupRepo.getInstance().getUserRoleByExternUserGroupIdAndExternUserId(
      externUserGroupId,
      externUserId,
    );
  }

  hasMyReviewByExternUserGroupId(externUserGroupId) {
    return ExternUserGroupRepo.getInstance().hasMyReviewByExternUserGroupId(
      externUserGroupId,
    );
  }

  isChatbotByExternUserGroupId(externUserGroupId) {
    return ExternUserGroupRepo.getInstance().isChatbotByExternUserGroupId(
      externUserGroupId,
    );
  }

  getProductIdByExternUserGroupId(externUserGroupId) {
    return ExternUserGroupRepo.getInstance().getProductIdByExternUserGroupId(
      externUserGroupId,
    );
  }

  addOrUpdateExternUserGroup(externUserGroup) {
    return ExternUserGroupRepo.getInstance().addOrUpdateExternUserGroup(
      externUserGroup,
    );
  }

  getExternUserByExternUserGroupIdAndExternUserId(
    externUserGroupId,
    externUserId,
  ) {
    return ExternUserGroupRepo.getInstance().getExternUserByExternUserGroupIdAndExternUserId(
      externUserGroupId,
      externUserId,
    );
  }

  getExternUserGroupListByGroupStatusPaginated(
    offset,
    count,
    groupStatus,
    externUserGroupIdList = [],
  ) {
    return ExternUserGroupRepo.getInstance().getExternUserGroupListByGroupStatusPaginated(
      offset,
      count,
      groupStatus,
      externUserGroupIdList,
    );
  }

  setExternUserStatus(externUserStatus) {
    ExternUserGroupRepo.getInstance().setExternUserStatus(externUserStatus);
  }

  getMyExternUserGroupsIdByGroupStatus(
    groupStatus,
    userRoleToAvoid,
    externUserId,
  ) {
    return ExternUserGroupRepo.getInstance().getMyExternUserGroupsIdByGroupStatus(
      groupStatus,
      userRoleToAvoid,
      externUserId,
    );
  }

  isValidGroupStatus(status) {
    return ExternUserGroupRepo.getInstance().isValidGroupStatus(status);
  }

  async fetchAllGroupsByStatus(groupStatus) {
    const count = 20;
    let offset = 0;
    let condition = true;
    let chats;

    while (condition) {
      chats = await this.fetchExternUserGroupListByGroupStatus(
        offset,
        count,
        groupStatus,
      );
      offset += chats.length;
      condition = chats.length > 0;
    }
  }
}
