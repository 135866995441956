import { Location } from "vue-router";
import { merge } from "lodash";
import Util, {
    getUrlQueryParamsAsObject,
    urlMatcherRegex,
} from "modules/core/lib/Util";
import { LocalizationVO } from "modules/core/model/localizationVO";
import { LocalizationListResponse } from "modules/core/model/localizationListResponse";
import { LocationSearchResponse } from "modules/core/model/locationSearchResponse";
import { LocationVO } from "modules/core/model/locationVO";
import { LocationSearchRequest } from "modules/core/model/locationSearchRequest";
import { LocalizationMapResponse } from "modules/core/model/localizationMapResponse";
import { LocalizationMapRequest } from "modules/core/model/localizationMapRequest";
import Deeplinking, { AllowedActions } from "modules/core/lib/Deeplinking";
import Api from "../lib/Api";

export default class LocalizationBO {
    protected api: Api = Api.getInstance();

    static instance?: LocalizationBO;

    localeList: LocalizationVO[] = [];

    localeListMap: Record<string, string> = {};

    localeListMapByLang: Record<string, Record<string, string>> = {};

    static getInstance(): LocalizationBO {
        if (!LocalizationBO.instance) {
            LocalizationBO.instance = new this();
        }

        return LocalizationBO.instance;
    }

    async fetchLocalizationList(
        lang: string,
        useCache: number | boolean = 0,
        rowIdList: string[] = [],
    ): Promise<LocalizationListResponse> {
        return this.api.service<LocalizationListResponse>("localizationList", {
            useCache,
            language: lang ? lang.replace("-", "_") : null,
            rowIdList,
        });
    }

    async doLocalizationMap(
        data: LocalizationMapRequest,
        useCache: boolean = false,
    ): Promise<LocalizationMapResponse> {
        return this.api.service<LocalizationMapResponse>(
            "localizationMap",
            merge(data, { useCache }),
        );
    }

    async fetchLocalizationMap(rowIdList: string[]) {
        const request = await this.doLocalizationMap(
            {
                rowIdList,
            },
            true,
        );

        if (request && !request.error) {
            for (const languageCode in request.localizationMap) {
                this.localeListMapByLang[languageCode.replace("_", "-")] =
                    LocalizationBO.convertListToMap(
                        request.localizationMap[languageCode],
                    );
            }

            this.localeListMapByLang["en-GB"] = {
                ...this.localeListMapByLang["en-EN"],
            };
        }
    }

    static convertListToMap(list: LocalizationVO[]): Record<string, string> {
        const theList: Record<string, string> = {};

        if (list) {
            list.forEach((obj) => {
                if (obj.rowId) theList[obj.rowId] = obj.description!;
            });
        }

        return theList;
    }

    async setupLocalizationList(
        languageCode: string,
        localeList: string[] = [],
        cacheTime: number | boolean = 24,
    ) {
        const response = await this.fetchLocalizationList(
            languageCode,
            cacheTime,
            localeList,
        );
        if (response && response.localizationList) {
            this.localeList = response.localizationList;

            this.localeListMap = LocalizationBO.convertListToMap(
                this.localeList,
            );
        }
    }

    /*
     * @deprecate
     */
    async setupFullLocalizationList(languageCode: string) {
        await this.setupLocalizationList(languageCode);
    }

    localeText(
        name: string,
        params?: Record<string, any>,
        languageCode?: string,
        fallbackText?: string,
    ) {
        let text = "";

        if (languageCode && this.localeListMapByLang[languageCode]) {
            if (this.localeListMapByLang[languageCode][name] !== undefined) {
                text = this.localeListMapByLang[languageCode][name];
            }
        } else if (this.localeListMap[name] !== undefined) {
            text = this.localeListMap[name];
        }

        if (!text && fallbackText) {
            text = fallbackText;
        }

        if (text != null) text = Util.replaceStringParams(text, params);

        return text;
    }

    hasFullLocalizations(): boolean {
        return this.localeList.length > 0;
    }

    getLocaleList(): LocalizationVO[] {
        return this.localeList;
    }

    async parseDeeplinkingForText(
        text: string,
        resolverCallback: (route: Location, url: string) => string,
        embeddedMode: boolean = false,
    ) {
        let textToParse = text;
        const matches = text.match(urlMatcherRegex());
        const cmdMatcher = /\/(cmd|c)\/([a-zA-Z0-9]+)/;

        if (matches) {
            for (const url of matches) {
                console.log("URL", url, cmdMatcher, url.match(cmdMatcher));
                if (url.match(cmdMatcher) && url.match(/mediktor\.com\//)) {
                    const cmdMatch = new URL(url).pathname.match(cmdMatcher);

                    if (cmdMatch && cmdMatch.length > 2) {
                        const cmd = cmdMatch[2] as AllowedActions;
                        const query = getUrlQueryParamsAsObject(url);

                        const route =
                            await window.mdkApp?.deepLinkingManager.getRedirectionForCMD(
                                { command: cmd, query },
                            );
                        // const deeplinking = new Deeplinking(cmd, query);
                        // const route = await deeplinking.manageRedirections();

                        if (route) {
                            if (route.query) {
                                const stringQuery: Record<string, string> = Object.fromEntries(
                                    Object.entries(route.query).map(([key, value]) => [
                                        key,
                                        Array.isArray(value) ? value.join(',') : value ?? '',
                                    ])
                                );
                                const params = new URLSearchParams(stringQuery).toString();
                                
                                if (embeddedMode) {
                                    return `${cmdMatch[0]}?${params}`
                                }
                                textToParse = textToParse.replace(
                                    url,
                                    resolverCallback(route, url),
                                );
                                textToParse += `?${params}`;
                            }
                        }
                    }
                }
            }
        }

        return textToParse;
    }

    async parseLocalizationListDeeplinkings(
        resolverCallback: (route: Location, url: string) => string,
    ) {
        /* for (let i = 0; i < this.localeList.length; i++) {
            let item = this.localeList[i];

            if(item.description) {
                item.description = await this.parseDeeplinkingForText(item.description, resolverCallback);
            }
        }

        if(this.localeList) {
            this.localeListMap = LocalizationBO.convertListToMap(this.localeList);
        } */

        for (const rowId in this.localeListMap) {
            if (this.localeListMap[rowId]) {
                this.localeListMap[rowId] = await this.parseDeeplinkingForText(
                    this.localeListMap[rowId],
                    resolverCallback,
                );
            }
        }

        for (const languageCode in this.localeListMapByLang) {
            for (const rowId in this.localeListMapByLang[languageCode]) {
                this.localeListMapByLang[languageCode][rowId] =
                    await this.parseDeeplinkingForText(
                        this.localeListMapByLang[languageCode][rowId],
                        resolverCallback,
                    );
            }
        }
    }

    async fetchLocationSearch(
        latitude?: number,
        longitude?: number,
        text?: string,
    ): Promise<LocationVO[]> {
        const service = await this.api.service<LocationSearchResponse>(
            "locationSearch",
            {
                latitude,
                longitude,
                text,
            } as LocationSearchRequest,
        );

        if (service && service.locationList) {
            return service.locationList;
        }

        return [];
    }
}
