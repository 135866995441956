import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import AsyncComputed from "vue-async-computed";
import Quasar from "quasar";
import PortalVue from "portal-vue";
import VueDOMPurifyHTML from "vue-dompurify-html";
export default class VueManager {
    static instance?: VueManager;

    static getInstance(): VueManager {
        if (!VueManager.instance) VueManager.instance = new this();

        return VueManager.instance;
    }

    setupVueConfiguration(router: boolean = false, metas: boolean = false) {
        Vue.config.productionTip = true;

        if (router) Vue.use(VueRouter);
        if (metas) Vue.use(VueMeta);
        Vue.use(PortalVue);
        Vue.use(Quasar, {
            components: {},
            config: {
                loadingBar: {
                    skipHijack: true,
                },
            },
        });
        Vue.use(AsyncComputed);
        Vue.use(VueDOMPurifyHTML, {
            namedConfigurations: {
                plaintext: {
                    USE_PROFILES: { html: true, svg: true },
                },
            },
        });
    }
}
