import VueRouter from "vue-router";
import LocalizationBO from "modules/core/business/LocalizationBO";
import { ExternUserVO } from "modules/core/model/externUserVO";
import { AskDialRtcResponse } from "modules/core/model/askDialRtcResponse";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import BusinessAbstract from "../business/BusinessAbstract";
import eventBus from "./EventBusSingleton";
import Ui from "./Ui";
import ExternUserGroupBO from "../business/ExternUserGroupBO";
import Util from "./Util";
import ExternUserBO from "../business/ExternUserBO";
import RTCSignalBO from "../business/RTCSignalBO";
import Holder, { Application } from "./Holder";

const rtcModalCloseTimeoutTime = 20000;

export default class VideoConferenceManager extends BusinessAbstract {
    static instance?: VideoConferenceManager;

    currentVideoConferenceExternUserGroupId?: string;

    currentVideoConferenceComponent?: any; // TODO: poner tipo real

    rtcModalOpened?: boolean = false;

    private router?: VueRouter;

    setRouter(router: VueRouter) {
        this.router = router;
    }

    static getInstance(): VideoConferenceManager {
        if (!VideoConferenceManager.instance) {
            VideoConferenceManager.instance = new this();
        }

        return VideoConferenceManager.instance;
    }

    async launchVideoconferenceView(
        remoteExternUser?: ExternUserVO,
        externUserGroupId?: string,
    ) {
        const self = this;

        if (this.currentVideoConferenceExternUserGroupId) {
            if (
                this.currentVideoConferenceExternUserGroupId ===
                externUserGroupId
            ) {
                eventBus.emit(
                    "setVideconferenceInstanceMinimize",
                    externUserGroupId,
                );
            } else {
                Ui.showAlert(
                    LocalizationBO.getInstance().localeText("tmk1447"),
                );
            }

            return;
        }

        let response =
            await ExternUserGroupBO.getInstance().fetchExternUserProductStatus(
                externUserGroupId,
            );
        if (response === null) {
            return;
        }

        response = response.externUserGroupProduct;
        if (response.twilioAccessToken === undefined) {
            Ui.showAlert(LocalizationBO.getInstance().localeText("tmk1084"));

            return;
        }
        const token = response.twilioAccessToken.twilioToken;

        const component = await import("modules/video/components/video/Main");

        const mountedComponent = await Util.mountComponent(component.default, {
            appInstance: this,
            localExternUser: Application.getInstance().externUser,
            remoteExternUser,
            externUserGroupId,
            token,
            floating: true,
            onChatAction(externUserGroupId: string) {
                if (this.router) {
                    this.router.push(
                        RouteGenerator.getInstance().chat(externUserGroupId),
                    );
                }
            },
            onFinish() {
                self.closeVideoConferenceComponent();
                /* component.destroyDom();
                document.body.removeChild(component.$el);
                self.currentVideoConference = null;

                eventBus.emit("videconferenceInstance", {externUserGroupId, active: false}); */
            },
            onWindowChange(minimized: boolean) {
                eventBus.emit("videconferenceInstance", {
                    externUserGroupId,
                    active: !minimized,
                });
            },
        });
        this.currentVideoConferenceComponent = mountedComponent;
        document.body.appendChild(mountedComponent.$el);

        this.currentVideoConferenceExternUserGroupId = externUserGroupId;

        eventBus.emit("videconferenceInstance", {
            externUserGroupId,
            active: true,
        });
    }

    closeVideoConferenceComponent() {
        if (this.currentVideoConferenceExternUserGroupId) {
            const component = this.currentVideoConferenceComponent;
            // component.hangAction();
            component.videoconferenceInstance.disconnect();
            component.destroyDom();

            try {
                document.body.removeChild(component.$el);
            } catch (error) {
                console.log(error);
            }

            eventBus.emit("videconferenceInstance", {
                externUserGroupId: this.currentVideoConferenceExternUserGroupId,
                active: false,
            });

            this.currentVideoConferenceExternUserGroupId = undefined;
        }
    }

    async mountModalRtc(payload: AskDialRtcResponse) {
        const component = await import(
            "modules/video/components/video-dialer/Main"
        );

        this.rtcModalOpened = true;

        const mountedComponent = await Util.mountComponent(component.default, {
            externUserGroupId: payload.externUserGroupId,
            externUserId: payload.sourceExternUserId,
            onAction: () => {
                instance.$destroy();
                instance.$el?.parentNode?.removeChild(instance.$el);

                if (this.router && payload.externUserGroupId) {
                    this.router.push(
                        RouteGenerator.getInstance().chat(
                            payload.externUserGroupId,
                        ),
                    );
                }

                // console.log(ExternUserGroupBO.getInstance().getExternUserByExternUserGroupIdAndExternUserId(payload.externUserGroupId, payload.sourceExternUserId));
                payload.sourceExternUserId &&
                    this.launchVideoconferenceView(
                        ExternUserBO.getInstance().getExternUserById(
                            payload.sourceExternUserId,
                        ),
                        payload.externUserGroupId,
                    );
                this.rtcModalOpened = false;
            },
        });

        this.currentVideoConferenceComponent = mountedComponent;

        const instance = Ui.mountBaseModal(
            "",
            mountedComponent.$el,
            "modal",
            [],
            true,
            async () => {
                this.rtcModalOpened = false;
                await RTCSignalBO.getInstance().fetchHangUpRTC(payload);
            },
            false,
            false,
        );

        setTimeout(async () => {
            if (instance !== undefined && this.rtcModalOpened) {
                await RTCSignalBO.getInstance().fetchHangUpRTC(payload);
                this.rtcModalOpened = false;
                instance.$destroy();
                instance.$el?.parentNode?.removeChild(instance.$el);
            }
        }, rtcModalCloseTimeoutTime);
    }

    manageRtcEvents() {
        // Call
        eventBus.on("askDialRtc", async (payload: AskDialRtcResponse) => {
            if (
                payload.sourceExternUserId !== Holder.app.externUserId &&
                !this.currentVideoConferenceExternUserGroupId
            ) {
                if (!this.rtcModalOpened) {
                    await this.mountModalRtc(payload);
                }
            }
        });
        /* // coger videollamada
        eventBus.on("pickUpRtc", payload => {

        });

        // salir de la videollamada
        eventBus.on("hangUpRtc", payload => {

        }); */
    }
}
