import template from "modules/user/components/login/login.html?raw";
import "modules/user/components/login/login.scss";
import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";
import { ExternUserVO } from "modules/core/model/externUserVO";
import {
    UIButton,
    UICheckbox,
    UIGridCell,
    UIGridRow,
    UINavbar,
    UITextfield,
} from "modules/core";
import MyChartButton from "modules/user/components/login/mychart-button/MyChartButton";
import CheckerSkinEnum from "modules/core/enums/CheckerSkinEnum";
import { LoginBO } from "modules/core/business";
import app from "modules/core/config/app";
import PasswordRecovery from "../password-recovery/PasswordRecovery";
import GoogleSignIn from "./login-extern-providers/google-sign-in/GoogleSignIn";
import _merge from "lodash/merge";
import md5 from "blueimp-md5";
import AppleSignIn from "./login-extern-providers/apple-sign-in/AppleSignIn";
import mixins from "vue-typed-mixins";
import { PropType, inject } from "vue";

type OnSubmit = (
    user: ExternUserVO,
    Component: any,
    accessCode?: string,
) => void;

export default mixins(LocaleMixin, CoreComponentMixin).extend({
    template,
    components: {
        navbar: UINavbar,
        "mdk-button": UIButton,
        "control-textfield": UITextfield,
        "control-checkbox": UICheckbox,
        "password-recovery": PasswordRecovery,
        "mdk-ui-grid-cell": UIGridCell,
        "mdk-ui-grid-row": UIGridRow,
        MyChartButton,
        "mdk-google-sign-in": GoogleSignIn,
        "mdk-apple-sign-in": AppleSignIn,
    },
    props: {
        formData: {
            required: false,
            type: Object as PropType<Record<string, string>>,
            default: null,
        },
        onSubmit: {
            required: false,
            type: Function as PropType<OnSubmit>,
            default: (user: ExternUserVO | boolean, Component: any) => {},
        },
        cleanInterface: { default: true, required: false, type: Boolean },
        withMargins: { default: false, required: false, type: Boolean },
        showMyChartLogin: { default: false, required: false, type: Boolean },
        epicMode: { default: null, required: false, type: Number },
    },
    data() {
        return {
            localFormData: {
                username: "",
                password: "",
                googleUserId: "",
            } as Record<string, string>,
            currentUserCredentials: -1,
            passwordIsVisible: false,
            primaryViewIsVisible: true,
            remember: false,
            showOrganizationListElement: false,
        };
    },
    setup() {
        const avatarVisibility = inject("avatarVisibility") as boolean;

        return { avatarVisibility };
    },

    computed: {
        usernameFieldIsVisible(): boolean {
            return true;
        },

        passwordRecoveryIsVisible(): boolean {
            return true;
        },

        componentId(): string {
            return "login";
        },

        isLanguageRTL(): boolean {
            return this.$app.language !== "ar-AR";
        },

        navbarTitle(): string | null {
            if (!this.primaryViewIsVisible) {
                return this.localeText("access.recuperarContrasena");
            }

            return null;
        },

        mainTitle(): string {
            return this.localeText("tmk347");
        },

        mainTitleIsVisible(): boolean {
            return this.appGeneralConfig.enablePageHeaders;
        },

        alternativeSigninIsVisible(): boolean {
            return (
                this.googleSigninIsVisible ||
                this.appleSigninIsVisible ||
                this.myChartSigninIsVisible
            );
        },

        googleSigninIsVisible(): boolean {
            if (this.appGeneralConfig.googleSignInId) {
                return true;
            }

            return false;
        },

        appleSigninIsVisible(): boolean {
            if (
                this.appGeneralConfig.enableAppleSignIn &&
                this.appGeneralConfig.firebaseConfig
            ) {
                return true;
            }

            return false;
        },

        myChartSigninIsVisible(): boolean {
            return this.showMyChartLogin;
        },

        classes(): Record<string, boolean> {
            return {
                "mdk-login--clean-ui": this.cleanInterface,
                "mdk-login--with-margins": this.withMargins,
            };
        },

        passwordTogglerIcon(): string {
            return this.passwordIsVisible ? "visibility_off" : "visibility";
        },

        logos(): Array<string> {
            return [
                require("app/static/img/icon-ce-dark.png"),
                require("app/static/img/icon-gdpr-dark.png"),
                require("app/static/img/icon-hippa-dark.png"),
                require("app/static/img/icon-validation-dark.png"),
            ];
        },

        passwordPlaceholderText(): string {
            return this.localeText("tmk350");
        },

        submitButtonText(): string {
            return this.localeText("tmk348");
        },

        passwordAutofocus(): boolean {
            return false;
        },

        rememberText(): string {
            return this.localeText("tmk1758");
        },

        showPlaceholderAsLabel(): boolean {
            return (
                this.appGeneralConfig.checker.inputTextLabel &&
                (this.appGeneralConfig.checkerSkin ===
                    CheckerSkinEnum.ASSISTANT ||
                    (this.appGeneralConfig.checkerSkin ===
                        CheckerSkinEnum.AVATAR &&
                        this.avatarVisibility))
            );
        },

        rememberIsVisible(): boolean {
            return this.appGeneralConfig.login.keepLoggedIn;
        },

        usernamePlaceholder(): string {
            if (this.appGeneralConfig.hospitalMode) {
                return this.localeText("tmk746");
            }
            return this.localeText("mail");
        },

        minimunCharacters() {
            return this.localeText("access.passwordMinimum");
        },
    },
    methods: {
        async performLogin(
            username?: string,
            password?: string,
            googleUserId?: string,
        ) {
            this.withPreloader(async () => {
                const data: Record<string, string | number | undefined> = {
                    username,
                    password,
                    googleUserId,
                };

                if (this.remember) {
                    data.authTokenRefreshExpiresIn =
                        app.registeredUserRefreshTokenExpirationTime;
                } else {
                    data.authTokenRefreshExpiresIn =
                        app.refreshTokenExpirationTime as any;
                }

                const response = await LoginBO.getInstance().doLogin(data);

                if (response) {
                    this.onSubmit?.(response, this);
                }
            });
        },

        login() {
            this.performLogin(
                this.localFormData.username,
                md5(this.localFormData.password),
                this.localFormData.googleUserId,
            );
        },

        loginAsGuest() {
            this.performLogin();
        },

        showPassword() {
            this.passwordIsVisible = !this.passwordIsVisible;
        },

        startGoogleSignin() {
            this.catchEvent("google-sign-in", (response, component) => {
                this.onSubmit?.(response, component);
            });
        },

        startAppleSignin() {
            this.catchEvent("apple-sign-in", (response) => {
                this.onSubmit?.(response, this);
            });
        },

        openPasswordRecoveryView() {
            this.primaryViewIsVisible = false;
        },

        onNavigationBack() {
            this.primaryViewIsVisible = true;
        },

        onPasswordRecoverySubmit() {
            this.primaryViewIsVisible = true;
        },

        populateExternalData() {
            this.localFormData = _merge(this.localFormData, this.formData);
        },
    },
    mounted() {
        this.startGoogleSignin();
        this.startAppleSignin();
        this.populateExternalData();
    },
});
