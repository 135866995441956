import CoreComponent from "modules/core/mixins/CoreComponent";
import template from "./language-selector.html?raw";
import "./language-selector.scss";
import Util, { cloneRouteLocation } from "modules/core/lib/Util";
import LanguageBO from "../../../../modules/core/business/LanguageBO";
import {
  QBtn,
  QList,
  QItem,
  QItemLabel,
  QItemSection,
  QBtnDropdown,
} from "quasar";

export default {
  template,
  mixins: [CoreComponent],

  components: {
    QBtn,
    QList,
    QItem,
    QItemLabel,
    QItemSection,
    QBtnDropdown,
  },

  props: {
    color: { default: "grey-7" },
    border: { default: true },
  },

  data() {
    return {
      selectedLanguage: null,
    };
  },

  watch: {
    $route() {
      this.setCurrentLanguage();
    },
  },

  methods: {
    // handleLanguageChange(route) {
    //   this.$router.replace(route);
    // },
    setCurrentLanguage() {
      this.selectedLanguage = LanguageBO.getInstance().currentLanguage();
    },
  },

  computed: {
    isFlat() {
      return [
        ...this.commonButtonAttributes,
        {
          flat: !this.border,
        },
      ];
    },
    selectedLanguageName() {
      return LanguageBO.getInstance().currentLanguage().originName;
    },
    selectedLanguageImage() {
      if (this.selectedLanguage != null) {
        if (this.selectedLanguage.mediaFileList !== undefined) {
          return this.selectedLanguage.mediaFileList[0].mediaURL;
        }
      }

      return null;
    },
    languageList() {
      let languageOrderedListByName = [];

      LanguageBO.getInstance()
        .getLanguageList(false)
        .forEach((language) => {
          let image = null;

          if (language.mediaFileList !== undefined) {
            image = language.mediaFileList[0].mediaURL;
          }

          const route = cloneRouteLocation(this.$route);
          route.query.lang = language.languageCode.replace("_", "-");

          languageOrderedListByName.push({
            title: language.originName,
            id: language.languageCode,
            leading: `${image}&s=10`,
            route,
          });
        });

      languageOrderedListByName = languageOrderedListByName.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });

      return languageOrderedListByName;
    },
    hasLanguageList() {
      return this.languageList.length > 0;
    },
  },

  mounted() {
    this.setCurrentLanguage();
  },
};
