import UtmHandler from "modules/core/lib/UtmHandler";
import GeolocationManager from "modules/core/lib/GeolocalizationManager";
import LocationBO from "modules/core/business/LocationBO";
import App, { TokenData } from "modules/core/lib/App";

const app = App.getInstance();

const services = {
    configuration: {
        setEnvironment: App.setEnvironmentConfig,
    },

    authentication: {
        async login(token?: string) {
            return app.login(token);
        },
        async loginWithToken(token: TokenData) {
            return app.loginWithToken(token);
        },
        async removeToken() {
            return app.removeToken();
        },
        async setToken(token: string) {
            return app.setToken(token);
        },
    },
    location: {
        async search(
            search: { latitude: number; longitude: number } | { text: string },
        ) {
            const { latitude, longitude, text } = search as Partial<
                { latitude: number; longitude: number } & { text: string }
            >;
            return LocationBO.getInstance().fetchLocationSearch(
                latitude,
                longitude,
                text,
            );
        },
        getCurrentPosition() {
            return new Promise((resolve, reject) => {
                GeolocationManager.getInstance().navigatorGeolocationAction(
                    (position) => resolve(position.coords),
                    () => reject(new Error("Could not get current location.")),
                );
            });
        },
    },
    localization: {
        getLocales(ids: string[]) {
            return ids.reduce(
                (acc, current) => {
                    acc[current] = app.localeText(current);
                    return acc;
                },
                {} as Record<string, string>,
            );
        },
    },
    marketing: {
        getUTMParams() {
            return UtmHandler.getInstance().getParams();
        },
    },
    // firebase: {
    //     login(token: string) {
    //         Api.getInstance().service("login", {
    //             firebaseUserId: token
    //         });
    //     },
    // },
};

export default services;
