import template from "./icon.html?raw";
import "./icon.scss";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,
    props: {
        icon: {
            type: String,
            required: false,
            default: null,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        classes(): Record<string, boolean> {
            return {
                "mdk-ui-icon--small": this.small,
            };
        },
    },
});
