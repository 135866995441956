// @ts-ignore
// TODO: Move to TS ENUM!
import Enum from "enum";

const CategoryTypeEnum = new Enum({
    HOME_SECTION: 0,
    SERVICE: 1,
    SPECIALTY: 2,
    HISTORY_HIDDEN: 3,
    OBLIGATORY: 4,
    EXCLUSIVE: 5,
    HOSPITAL_OBLIGATORY: 6,
    HOSPITAL_EXTERNUSER: 7,
    HOSPITAL_EVALUATION: 8,
    HOSPITAL_DISPATCH: 9,
    HOSPITAL_SERVICES: 10,
    EVALUATION_STATEMENTS_CALLCENTER: 11,
    PROFILE_STATEMENTS_CALLCENTER: 12,
    PROFILE_ONLY_SAVE_STATEMENTS: 13,
    PROFILE_ADMIN_STATEMENTS_HOSPITAL: 14,
    ENABLED_ENTITIES_EXCLUSIVELY: 15,
    INPUT_VALIDATION_HIDDEN: 16,
    CUSTOM_ORDER_IN_RELATIONS: 17,
    PROFILE_STATEMENTS_ANONYMOUS: 18,
    PROFILE_ONLY_SAVE_STATEMENTS_ANONYMOUS: 19,
    SECTION_LIST_INACTIVE: 20,
});

export default CategoryTypeEnum;
