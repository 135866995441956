import template from "modules/core/components/interface/drawer/drawer.html?raw";
import "modules/core/components/interface/drawer/drawer.scss";
import { CoreComponentMixin } from "modules/core/mixins/ts";
import { UIButton } from "../index";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,
    components: {
        UIButton,
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
        appendToRoot: {
            type: Boolean,
            required: false,
            default: true,
        },
        showClose: {
            type: Boolean,
            required: false,
            default: true,
        },
        left: {
            type: Boolean,
            required: false,
            default: false,
        },
        onDismiss: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            shown: false,
            hidden: false,
        };
    },
    computed: {
        getDefinitionClassDrawer(): Record<string, boolean> {
            return {
                "mdk-ui-drawer--active": this.shown,
                "mdk-ui-drawer--hidden": this.hidden,
                "mdk-ui-drawer--left": this.left,
            };
        },
    },
    watch: {
        active() {
            this.manage();
        },
    },
    methods: {
        dismiss() {
            // @ts-ignore this is a function, but the type inferences fails when defining a default value
            this.onDismiss();
        },
        appendToBody() {
            this.$nextTick(() => {
                document.body.appendChild(this.$el);
            });
        },
        manage() {
            if (this.active) {
                document.body.style.overflow = "hidden";

                this.hidden = false;
                this.shown = true;
            } else {
                this.shown = false;
                setTimeout(() => {
                    this.hidden = true;

                    document.body.style.overflow = "auto";
                }, 800);
            }
        },
    },
    created() {
        this.manage();

        if (
            this.appendToRoot &&
            document.getElementsByClassName("mdk-ui-drawer").length === 0
        ) {
            this.appendToBody();
        }
    },
});
