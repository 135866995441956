import GenericRepo from "./GenericRepo";
import ExternUserDAO from "../dao/ExternUserDAO";
import ExternUserRemoteDAO from "../dao/ExternUserRemoteDAO";

export default class ExternUserRepo extends GenericRepo {
  constructor() {
    super();

    this.dao = new ExternUserDAO();
    this.remoteDao = new ExternUserRemoteDAO();
    this.storageName = "externUsers";
  }

  static getInstance() {
    if (!ExternUserRepo.instance) {
      ExternUserRepo.instance = new this();
    }

    return ExternUserRepo.instance;
  }

  async getExternUser(externUserId) {
    const currentItem = this.dao.findItemById(externUserId);

    if (currentItem !== undefined) {
      return currentItem;
    }

    const item = await this.remoteDao.fetchExternUser(externUserId);

    this.dao.addItem(item);

    this.updateStorage();

    return item;
  }
}
