import template from "modules/core/components/interface/select/select.html?raw";
import "modules/core/components/interface/select/select.scss";
import { MDCSelect } from "@material/select";

import vue from "vue";

export default vue.extend({
    template,
    props: {
        value: {
            type: [Array, String, Number, Object],
            required: false,
            default: null,
        },
        options: {
            type: Object,
            required: false,
            default: {},
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            selected: null as object | null,
        };
    },
    computed: {
        classes(): string[] {
            const definition: [] = [];

            return definition;
        },
    },
    methods: {
        setup() {
            this.$nextTick(() => {
                const select = new MDCSelect(this.$el);

                select.listen("MDCSelect:change", () => {
                    // select.selectedIndex
                    // select.value
                    this.$emit("input", select.value);
                });
            });
        },
        init() {
            this.selected = this.value;

            this.setup();
        },
    },
    mounted() {
        this.init();
    },
});
