import vue from "vue";
import Api from "modules/core/lib/Api";

export default vue.extend({
  data() {
    return {
      api: Api.getInstance(),
    };
  },

  computed: {
    authUserId() {
      // Compatibility
      return Api.getInstance().userId;
    },
  },

  mounted() {
    this.onLocalizationLoaded();
  },
});
