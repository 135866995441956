import template from "./list-item-timeline.html?raw";
import "./list-item-timeline.scss";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,
    props: {
        timeline: {
            type: Boolean,
            required: false,
            default: true,
        },
        timelineActive: {
            type: Boolean,

            required: false,
            default: false,
        },
        hasSubtitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        topTitle: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        classes(): Record<string, boolean> {
            return {
                "mdk-ui-list-item-timeline--no-timeline": !this.timeline,
                "mdk-ui-list-item-timeline--active": this.timelineActive,
            };
        },
    },
});
