import mitt from "mitt";


// "sendPageAnalytics", -->  // this will be implemented in analytics channel // only used onece in checker to send "PreDiagnostico" and when calling manage origin in App.ts
// "trackCustomEvents", --> // idem
// "initAnalytics", -->  // idem

type EventNames = "init" | "tracking" | "page";

type AnalyticEvent<Name extends EventNames, Payload = undefined> = {
    name: Name;
    payload: Payload;
};


type AnalyticEvents =
    | AnalyticEvent<"init", { title: string; url: string }>
    | AnalyticEvent<"tracking", { route: string; action: string, value: string, extra?: any }>
    | AnalyticEvent<"page", { title: string; url: string }>;

type Events = {
    [key in EventNames]: Extract<AnalyticEvents, { name: key }>;
};

export const emitter = mitt<Events>();

export const dispatch = (event: AnalyticEvents) => {
    emitter.emit(event.name, event);
};

export const subscribe = <T extends EventNames>(
    event: T,
    handler: Parameters<typeof emitter.on<T>>[1],
    // handler: (data: Extract<PublicEvents, { name: T }>["payload"]) => void,
) => {
    emitter.on(event, handler);
};
export const unsubscribe = <T extends EventNames>(
    event: T,
    handler: Parameters<typeof emitter.on<T>>[1],
    // handler: (data: Extract<PublicEvents, { name: T }>["payload"]) => void,
) => {
    emitter.off(event, handler);
};



