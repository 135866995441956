import _merge from "lodash/merge";
import _map from "lodash/map";
import _includes from "lodash/includes";
import moment from "moment";
import Util, { lastChars } from "modules/core/lib/Util";
import UI from "modules/core/lib/Ui";
import { MutexManager } from "modules/core/lib/Mutex";
import ErrorResponseCodeEnum from "modules/core/enums/ErrorResponseCodeEnum";
import config from "modules/core/config/app";
import { ApiServerError } from "modules/core/lib/Api";
import eventBus from "../../core/lib/EventBusSingleton";
import EnvironmentConfig from "../../core/lib/EnvironmentConfig";

const __PING__ = new Uint8Array([0x9]);
const __PONG__ = new Uint8Array([0xa]);
const MAXCURRENTOPERATIONS = 18;
const MAXSAMECURRENTOPERATIONS = 6;
const MAXTIMEOUTXOPERATION = 20000;
const API_VERSION = "4.1.4";

export default class Socket {
  get endpoint() {
    return EnvironmentConfig.getValue("APP_WS_ENDPOINT");
  }

  get authCode() {
    return EnvironmentConfig.getValue("APP_API_AUTH_CODE");
  }

  get userId() {
    return EnvironmentConfig.getValue("APP_API_USER_ID");
  }

  constructor() {
    this.app = null;
    this.reconnectionCount = 0;

    this.WebSocket = null;
    // this.socketManager = socketManager
    // this.subscriptions = {};
    this.events = {};

    this.hashMapActions = {};
    this.dispatchingActions = {};
    this.operationsNumber = {};
    this.actionOperationsQueue = [];

    this.isReconnecting = false;

    this.socketClosedManually = false; // Flag que ens avisa de que el Socket l'hem tancat nosaltres.

    this.wasDown = false;
    this.tm = null;
    this.keepAlive = false;

    // this.externUserId = this.app.externUserId;
    // this.deviceId = this.app.deviceId;
    // this.authToken = this.app.authToken;

    this.handleUiCommunication = false;
    this.onFatalError = (errorCode) => {};
    this.defaultErrorMessage = "An error has ocurred.";

    // this.apiVersion = "4.0.10";
    // this.appVersion = null;
    // this.appId = "com.teckelmedical.mediktor";
    this.language = null;
    this.latitude = null;
    this.longitude = null;
    this.deviceType = "WEB";

    this.connectionActions = [];
    this.disconnectionActions = [];
  }

  setApp(app) {
    this.app = app;
  }

  static getInstance() {
    if (!Socket.instance) {
      Socket.instance = new this();
    }

    return Socket.instance;
  }

  endpointUrl() {
    return `${this.endpoint}/backoffice/socket/Connect.Peer?userId=${this.userId}`;
  }

  /* setCredentials(authToken, externUserId, deviceId = null) {
        this.setUserCredentials(externUserId, authToken, deviceId);
    }

    setUserCredentials(externUserId, authToken, deviceId = null) {
        this.authToken = authToken;
        this.externUserId = externUserId;

        if (!Util.isNullOrEmpty(deviceId)) {
            this.deviceId = deviceId;
        }
    } */

  setLanguage(language) {
    this.language = language;
  }

  async onCloseEvent(event, onConnection = () => {}) {
    console.log("Socket close event", event);
    // if(this.socketClosed) return;

    // console.log("onCloseEvent RETRY");

    clearTimeout(this.tm);

    if (!this.socketClosedManually) this.wasDown = true;

    if (this.reconnectionCount === 8)
      this.releaseCallbacks(this.disconnectionActions);

    this.clearMapIntervals();

    if (event.reason) {
      try {
        const json = JSON.parse(event.reason);

        if (json.error) {
          // if (json.error && this.app.isLeader) {
          console.log("Socket handling error:", json.error, this);
          await this.handleAuthServerError(json.error);
        }
      } catch (error) {
        console.log("Socket event reason json parse error", event.reason);
      }
    }

    this.reconnectWebSocket(onConnection);
  }

  async handleAuthServerError(error) {
    if (error.code) {
      if (error.code === ErrorResponseCodeEnum.ERROR_CODE_AUTHTOKEN_INVALID) {
        await this.renewAuthenticationToken();
      } else if (
        error.code === ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT ||
        error.code ===
          ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT_REGISTERED_USER ||
        error.code ===
          ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT_NO_USER_REGENERATION
      ) {
        console.log("SOCKET fatal error", this);
        await this.app.handleUserFatalError(new ApiServerError(error));
      }
    }
  }

  onErrorEvent(event) {
    clearTimeout(this.tm);
    this.WebSocket.close();
  }

  onOpenEvent(event) {
    this.socketClosedManually = false;
    this.reconnectionCount = 0;
    clearTimeout(this.socketTryOuts);
    this.ping();

    if (this.wasDown) {
      console.log("onOpen was down");
      this.releaseCallbacks(this.connectionActions);
      for (const eventCallback in this.events) {
        this.events[eventCallback]("connectionOnline", true);
      }
    }

    this.wasDown = false;
    this.setMapIntervals();
  }

  onMessageEvent(event) {
    if (event.type === "message") {
      if (event.data instanceof ArrayBuffer) {
        if (event.data.byteLength === 1) {
          const buffer = new Int8Array(event.data);
          if (buffer[0] === 0xa) {
            this.pong();
          }
        }
      } else {
        const msg = JSON.parse(event.data);
        if (this.hashMapActions[msg.hash]) {
          this.handleResponse(
            msg,
            this.hashMapActions[msg.hash].resolveList,
            this.hashMapActions[msg.hash].rejectList,
          );
          this.dispatchNextOperation(msg.hash);
        } else {
          for (const eventCallback in this.events) {
            this.events[eventCallback](msg.method, msg);
          }
        }
      }
    }
  }

  clearMapIntervals() {
    for (const key of Object.keys(this.dispatchingActions)) {
      clearInterval(this.dispatchingActions[key].timer);
    }
  }

  setMapIntervals() {
    for (const key of Object.keys(this.dispatchingActions)) {
      if (!this.socketIsNullOrNotConnected())
        this.WebSocket.send(
          JSON.stringify(this.dispatchingActions[key].request),
        );
      this.dispatchingActions[key].timer = this.setTimerService(key);
    }
  }

  startWebSocket(onConnection = () => {}) {
    try {
      if (this.WebSocket !== null && this.WebSocket.readyState !== 3) return;

      this.socketClosedManually = false;

      // console.log("START -- SOCKET -- CONNECTION");

      let delay = 0;

      if (this.WebSocket === null) delay = 10000;
      else delay = this.reconnectionCount < 8 ? 4000 : 12000;

      this.WebSocket = new WebSocket(this.endpointUrl());
      this.WebSocket.binaryType = "arraybuffer";

      this.WebSocket.onclose = (event) => {
        console.log("socket Event onclose", event);
        this.onCloseEvent(event, onConnection);
      };
      this.WebSocket.onerror = (event) => {
        // console.log("socket Event onerror");
        this.onErrorEvent(event);
      };

      this.WebSocket.onopen = (event) => {
        console.info("Websocket ON");
        if (!this.socketIsNullOrNotConnected()) {
          console.log(
            "Sending token via socket...",
            lastChars(this.getToken(), 5),
          );
          this.WebSocket.send(this.authorizationTokenForServer());

          onConnection();
        }

        this.onOpenEvent(event);
      };
      this.WebSocket.onmessage = (event) => {
        this.onMessageEvent(event);
      };

      setTimeout(() => {
        if (this.WebSocket.readyState !== 1) {
          this.closeConnection();
        }
      }, delay);
    } catch (e) {
      console.log("Socket instance error:", e);
    }
  }

  getToken() {
    return this.app.authToken;
  }

  authorizationTokenForServer() {
    return JSON.stringify({ authorization: this.getToken() });
  }

  mandatoryParams() {
    const params = {
      apiVersion: API_VERSION,
      appVersion: config.appVersion,
      appId: config.appId,
      deviceType: "WEB",
      language: this.app.currentLanguageCode()?.replace("-", "_"),
      timezoneRaw: this.timezone(),
    };

    return params;
  }

  timezone() {
    return moment().utcOffset();
  }

  reconnectWebSocket(onConnection = () => {}) {
    // console.log("RECONNECT -- WEBSOCKET");
    if (this.socketClosedManually) return;

    this.reconnectionCount++;
    let delay = 5000;
    if (this.reconnectionCount > 8) delay = 15000;

    this.socketTryOuts = setTimeout(() => {
      if (this.app.appInForeground) {
        this.startWebSocket(onConnection);
      }
    }, delay);
  }

  async renewAuthenticationToken() {
    if (!MutexManager.getInstance().acquired) {
      console.log(
        "Mutex not acquired: about to renew token because we are close to token expiration",
      );

      await this.app.renewAuthenticationToken();
    } else {
      console.log(">>> Mutex is acquired (renewing token...)");
    }
  }

  async checkRemainingTimeToTokenExpiration() {
    const remainingSecondsToTokenExpiration =
      (this.app.authTokenExpirationTime - Date.now()) / 1000;

    // console.log("Checking token...", remainingSecondsToTokenExpiration);

    if (
      remainingSecondsToTokenExpiration <
      this.app.generalConfig().tokenExpirationRefreshThreshold
    ) {
      await this.renewAuthenticationToken();
    } /* else if(AuthManager.getInstance().getStoredAuthToken() && this.app.authToken !== AuthManager.getInstance().getStoredAuthToken()) {
            // @ts-ignore
            await this.app.tabLock("tokenRenewal", async (lock) => {
                this.app.authToken = AuthManager.getInstance().getStoredAuthToken();
                this.app.authTokenExpirationTime = AuthManager.getInstance().getStoredAuthTokenExpirationTime();

                EventBusSingleton.emit("userUpdate", this.app.externUser);

                console.log("Some other tab has updated token!");
                console.log("Sending token via socket... (from Socket)", lastChars(this.app.authToken, 4));

                this.WebSocket.send(this.authorizationTokenForServer());
            });
        } */
  }

  async ping() {
    if (!this.socketIsNullOrNotConnected()) {
      this.keepAlive = false;

      this.WebSocket.send(__PING__);

      if (!this.app.userFatalErrorHandlerIsProcessing) {
        // if (this.app.isLeader && !this.app.userFatalErrorHandlerIsProcessing) {
        await this.checkRemainingTimeToTokenExpiration();
      }

      this.tm = setTimeout(() => {
        if (this.keepAlive) {
          this.ping();
        } else {
          this.closeConnection();
          this.socketClosedManually = false;
          this.wasDown = true;
        }
      }, 5000);
    }
  }

  pong() {
    this.keepAlive = true;
  }

  onNewMessage(callback = () => {}) {
    const uniqueId = Util.uniqueId();
    this.events[uniqueId] = callback;

    return uniqueId;
  }

  removeListener(id) {
    delete this.events[id];
  }

  sendServiceViaSocketWithPromise(service, params = {}) {
    return new Promise((resolve, reject) => {
      this.send(service, params, resolve, reject);
    });
  }

  handleError(err) {
    if (typeof err) {
      eventBus.emit("onSocketErrorTimeout");
    }
  }

  send(
    service,
    params = {},
    callbackSuccess = () => {},
    callbackError = () => {},
    shouldBeJson = true,
    forceResponseType = null,
  ) {
    const data = _merge({ useCache: false }, this.mandatoryParams(), params);
    const hashMethod = this.createHashMethod(service, data);

    if (!this.hashMapActions[hashMethod]) {
      const request = {
        method: service,
        hash: hashMethod,
        value: data,
      };

      this.hashMapActions[hashMethod] = {
        resolveList: [callbackSuccess],
        rejectList: [callbackError],
        request,
        date: new Date().getTime(),
      };

      if (
        Object.keys(this.dispatchingActions).length < MAXCURRENTOPERATIONS &&
        !this.hasMaxNumberOperations(service)
      ) {
        this.dispatchingActions[hashMethod] = this.hashMapActions[hashMethod];

        if (!this.socketIsNullOrNotConnected()) {
          this.dispatchingActions[hashMethod].timer =
            this.setTimerService(hashMethod);
          this.dispatchingActions[hashMethod].retries = 0;

          this.WebSocket.send(JSON.stringify(request));
        }
      } else {
        this.actionOperationsQueue.push(hashMethod);
      }
    } else {
      this.hashMapActions[hashMethod].resolveList.push(callbackSuccess);
      this.hashMapActions[hashMethod].rejectList.push(callbackError);
    }
  }

  handleResponse(
    originalResponse,
    callbackSuccessList = [],
    callbackErrorList = [],
    shouldBeJson = true,
    onErrorRetry = null,
  ) {
    const self = this;
    let errorMessage = null;
    const response =
      originalResponse.value || originalResponse.data || undefined;
    let errorCode = null;
    if ("result" in originalResponse && originalResponse.result == "ok") {
      if (typeof response === "string" && shouldBeJson) {
        const msg = "Malformed response.";
        // callbackError(msg, null, false);
        _map(callbackErrorList, (callback) => callback(msg, null, false));
        errorMessage = msg;
      } else if ("error" in response) {
        const msg = this.prettyErrorMessage(response.error);
        errorCode = response.error.code;

        // callbackError(msg, response.error, this.errorIsFatal(errorCode));
        _map(callbackErrorList, (callback) =>
          callback((msg, response.error, this.errorIsFatal(errorCode))),
        );
        errorMessage = msg;
      } else {
        _map(callbackSuccessList, (callback) =>
          callback(response, originalResponse),
        );
        // callbackSuccess(response, originalResponse);
      }
    } else if (
      "result" in originalResponse &&
      originalResponse.result == "ko"
    ) {
      const msg =
        "statusText" in response ? response.statusText : response.result;
      console.log(response.result);

      // callbackError(msg, null, false);
      _map(callbackErrorList, (callback) => callback(msg, null, false));

      errorMessage = msg;
    }
    if (errorMessage != null && !this.errorIsFatal(errorCode)) {
      if (this.handleUiCommunication) {
        const actions = [];
        if (onErrorRetry != null && response.retry == true) {
          actions.push({
            label: "Retry",
            action(el, component) {
              onErrorRetry();
            },
          });
        }
        UI.showToast(
          `${errorMessage || this.defaultErrorMessage}<!--${errorCode}-->`,
        );
      }

      return { error: errorMessage };
    }
    if (this.errorIsFatal(errorCode)) {
      // console.log("Socket service error:", errorCode);

      this.onFatalError(errorCode, errorMessage);

      // this.app.onAction("fatalErrorAuthentication", errorCode, () => {});
    } else if (response === undefined) {
      // TODO: ERROR TIMEOUT (resolver de otra forma)
      _map(callbackErrorList, (callback) => callback());
    }

    return response;
  }

  setTimerService(hashMethod) {
    return setInterval(() => {
      if (this.dispatchingActions[hashMethod]) {
        if (this.dispatchingActions[hashMethod].retries < 3) {
          if (!this.socketIsNullOrNotConnected()) {
            this.dispatchingActions[hashMethod].retries++;
            this.WebSocket.send(
              JSON.stringify(this.dispatchingActions[hashMethod].request),
            );
          }
        } else {
          this.handleResponse(
            {},
            this.hashMapActions[hashMethod].resolveList,
            this.hashMapActions[hashMethod].rejectList,
            true,
          );
          this.dispatchNextOperation(hashMethod);
        }
      }
    }, MAXTIMEOUTXOPERATION);
  }

  dispatchNextOperation(hashMethod) {
    clearInterval(this.dispatchingActions[hashMethod].timer);
    clearInterval(this.hashMapActions[hashMethod].timer);

    delete this.dispatchingActions[hashMethod];
    delete this.hashMapActions[hashMethod];

    if (this.actionOperationsQueue.length > 0) {
      const service =
        this.hashMapActions[this.actionOperationsQueue[0]].request.method;
      if (!this.hasMaxNumberOperations(service)) {
        const action = this.actionOperationsQueue.shift();
        this.dispatchingActions[action] = this.hashMapActions[action];

        this.dispatchingActions[action].timer = this.setTimerService(action);
        this.dispatchingActions[action].retries = 0;

        this.WebSocket.send(
          JSON.stringify(this.dispatchingActions[action].request),
        );
      }
    }
  }

  handleTimeout(hashMethod) {
    clearInterval(this.hashMapActions[hashMethod].timer);
    delete this.hashMapActions[hashMethod];
    const { reject } = this.hashMapActions[hashMethod];
    const { request } = this.hashMapActions[hashMethod];

    return reject(new Error("timeout"));
  }

  errorIsFatal(code) {
    return _includes(["ME666", "ME667", "MW01"], code);
  }

  prettyErrorMessage(error) {
    let prettyTermError = "";

    if (error.code != null) {
      // if(error.description.length < 100) { // Para evitar mensaje demasiado largo si hay error en el server
      prettyTermError = error.description;
      // }else{
      //    prettyTermError += ` (${error.code})`;
      // }
    }

    return prettyTermError;
  }

  hasMaxNumberOperations(service) {
    let operationsNumber = 0;
    for (const hashAction in this.dispatchingActions) {
      if (this.dispatchingActions[hashAction].request.method === service)
        operationsNumber++;
    }

    return operationsNumber > MAXSAMECURRENTOPERATIONS;
  }

  createHashMethod(service, params = {}) {
    let hash = `${this.app.deviceId}:${service}`;

    switch (service) {
      case "message":
        // console.log(params.message.sourceId);
        hash = `${hash}:${params.message.messageId}`;
        break;
      case "messages":
        // console.log(params.groupId);
        hash = `${hash}:${
          "groupId" in params && params.groupId ? params.groupId : "null"
        }:${params.onlyUnread}:${Util.uniqueId()}`;
        break;
      case "relation":
        // console.log(params.externUserGroupId);
        hash = `${hash}:${params.relation.externUserGroupId}`;
        break;
      case "relations":
        // console.log("relations", params);
        // hash = hash;
        break;
      case "askDialRtc":
        // hash = hash;
        console.log("daniel Mateu Elizalde");
        break;
      case "externUserWriting":
        // console.log("externUserWriting", params.externUserGroupId);
        hash = `${hash}:${params.externUserGroupId}`;
        break;
      case "externUserGroupStatus":
        // console.log("externUserGroupStatus", params);
        hash = `${hash}:${params.externUserGroupId}`;
        break;
      case "valuation":
        // console.log("valuation", params.externUserGroupId);
        hash = `${hash}:${params.externUserGroupId}`;
        break;
      case "externUser":
        hash = `${hash}:${params.externUserId}`;
        break;
      default:
        hash = `${hash}:${Util.uniqueId()}`;
        break;
    }

    return hash;
  }

  manageSocketEvents(callback = () => {}) {
    this.events.push(callback);
  }

  socketIsNullOrNotConnected() {
    return this.WebSocket === null || this.WebSocket.readyState !== 1;
  }

  isConnected() {
    return !this.socketIsNullOrNotConnected();
  }

  closeConnection() {
    if (this.WebSocket !== null) {
      // this.WebSocket.onclose = () => {};
      this.WebSocket.close();
      this.socketClosedManually = true;
    }
  }

  manageSocketErrors(event) {
    // console.log(event)
    // console.log('on error')
  }

  onConnected(callback) {
    this.connectionActions.push(callback);
  }

  onDisconnected(callback) {
    this.disconnectionActions.push(callback);
  }

  releaseCallbacks(actions) {
    for (const action of actions) {
      action();
    }
  }
}
