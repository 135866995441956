import template from "modules/core/components/interface/card/card.html?raw";
import "modules/core/components/interface/card/card.scss";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        padding: {
            type: Boolean,
            required: false,
            default: false,
        },
        pinTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        overflow: {
            type: Boolean,
            required: false,
            default: true,
        },
        highlighted: {
            type: Boolean,
            required: false,
            default: false,
        },
        highlightedDark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        parsedTitle(): string | null {
            return this.title;
        },
        hasActions(): boolean {
            return !!this.$slots.actions?.length;
        },
        hasTitleSlot(): boolean {
            return !!this.$slots.title?.length && !this.parsedTitle;
        },
        classes(): string[] {
            const definition: string[] = [];

            if (this.padding) {
                definition.push("mdk-ui-card--with-content-padding");
            }

            if (this.overflow) {
                definition.push("mdk-ui-card--overflow");
            }

            if (this.pinTitle) {
                definition.push("mdk-ui-card--pinned");
            }

            if (this.highlighted) {
                definition.push("mdk-ui-card--highlighted");
            }

            if (this.highlightedDark) {
                definition.push("mdk-ui-card--highlightedDark");
            }

            return definition;
        },
    },
});
