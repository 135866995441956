import vue from "vue";

export default vue.extend({
    template: `<div class="mdc-layout-grid__cell" :class="classes"><slot></slot></div>`,
    props: {
        phoneColumns: {
            type: [String, Number],
            required: false,
            default: null,
        },
        tabletColumns: {
            type: [String, Number],
            required: false,
            default: null,
        },
        desktopColumns: {
            type: [String, Number],
            required: false,
            default: null,
        },
        phone: {
            type: [String, Number],
            required: false,
            default: null,
        },
        tablet: {
            type: [String, Number],
            required: false,
            default: null,
        },
        desktop: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    computed: {
        phoneColumnsValue(): string | number | null {
            return this.phone || this.phoneColumns;
        },
        tabletColumnsValue(): string | number | null {
            return this.tablet || this.tabletColumns;
        },
        desktopColumnsValue(): string | number | null {
            return this.desktop || this.desktopColumns;
        },
        classes(): string[] {
            const definition: string[] = [];

            if (this.phoneColumnsValue) {
                definition.push(
                    `mdc-layout-grid__cell--span-${this.phoneColumnsValue}-phone`,
                );
            }

            if (this.tabletColumnsValue) {
                definition.push(
                    `mdc-layout-grid__cell--span-${this.tabletColumnsValue}-tablet`,
                );
            }

            if (this.desktopColumnsValue) {
                definition.push(
                    `mdc-layout-grid__cell--span-${this.desktopColumnsValue}-desktop`,
                );
            }

            return definition;
        },
    },
});
