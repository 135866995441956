import template from "./card-header.html?raw";
import "./card-header.scss";
import { CoreComponentMixin } from "modules/core/mixins/ts";
import { QToolbar, QToolbarTitle, QBtn, QSeparator } from "quasar";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,
    components: {
        QToolbar,
        QToolbarTitle,
        QBtn,
        QSeparator,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        closable: {
            type: Boolean,
            required: false,
            default: false,
        },
        separator: {
            type: Boolean,
            required: false,
            default: false,
        },
        colored: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        classes(): string[] {
            return [
                "mdk-ui-card-header",
                ...(this.colored ? ["mdk-ui-card-header--colored"] : []),
            ];
        },
        color(): string {
            return this.colored ? "primary" : "blue-grey-9";
        },
    },
});
