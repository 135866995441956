import BusinessAbstract from "../business/BusinessAbstract";

/**
 * This class abstracts the use of external analytics with the main used methods to track pages and custom events.
 */
export default class ExternalAnalyticsManager extends BusinessAbstract {
    static instance?: ExternalAnalyticsManager;

    static getInstance(): ExternalAnalyticsManager {
        if (!ExternalAnalyticsManager.instance) {
            ExternalAnalyticsManager.instance = new this();
        }

        return ExternalAnalyticsManager.instance;
    }

    /**
     * You can use this method to initialize your analytics provider
     */
    setup() {}

    /**
     * This method is used when there is a change in the router for sending pageVIews
     */
    sendPageAnalytics(route: any, title: string | null = null) {}

    /**
     * This method is used to track custom events, like the checker questions, offerings, session results...
     */
    trackCustomEvents(
        nameRoute: string | null = null,
        eventAction: string = "",
        eventValue: string = "",
        featureName: string | null = null,
    ) {}
}
