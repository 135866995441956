import BusinessAbstract from "./BusinessAbstract";

export default class RTCSignalBO extends BusinessAbstract {
  constructor() {
    super();
  }

  static getInstance() {
    if (!RTCSignalBO.instance) {
      RTCSignalBO.instance = new this();
    }

    return RTCSignalBO.instance;
  }

  async fetchAskDialRTC(params = {}) {
    const response = await this.socket.sendServiceViaSocketWithPromise(
      "askDialRtc",
      params,
    );
    console.log(response);
    if (response !== undefined && response !== null) {
      return response;
    }
  }

  async fetchPickUpRTC(params = {}) {
    const response = await this.socket.sendServiceViaSocketWithPromise(
      "pickUpRtc",
      params,
    );
    if (response !== undefined && response !== null) {
      return response;
    }
  }

  async fetchHangUpRTC(params = {}) {
    const response = await this.socket.sendServiceViaSocketWithPromise(
      "hangUpRtc",
      params,
    );
    if (response !== undefined && response !== null) {
      return response;
    }
  }
}
