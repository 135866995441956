import template from "modules/core/components/interface/branding-loader/branding-loader.html?raw";
import "modules/core/components/interface/branding-loader/branding-loader.scss";
import imgBallsLight from "app/static/img/balls-light.gif";
import imgBallsColor from "app/static/img/balls-color.gif";

import vue from "vue";

export default vue.extend({
    template,
    props: {
        size: {
            type: Number,
            required: false,
            default: null,
        },
        light: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        imageSrc(): string {
            return this.light ? imgBallsLight : imgBallsColor;
        },
        imageStyleDefinition(): string {
            if (this.size != null) {
                return `max-width:${this.size}px;`;
            }
            return "";
        },
    },
});
