// import template from "./svg-icon.html?raw";
import "./svg-icon.scss";

import vue from "vue";

export default vue.extend({
    template: `<div class="mdk-svg-icon" v-dompurify-html="svg" :style="svgIconStyles"></div>`,
    props: {
        svg: {
            type: String,
            required: true,
            default: null,
        },
        color: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        svgIcon(): string {
            return this.svg;
        },
        svgIconStyles(): Record<string, string> {
            return { fill: this.color };
        },
    },
});
