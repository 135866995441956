import Vue, { ComponentOptions } from "vue";
import { ServerInfoResponse } from "modules/core/model/serverInfoResponse";
import BusinessAbstract from "./BusinessAbstract";
import ServerInfoBO from "./ServerInfoBO";
import Util from "../lib/Util";

export default class TermsBO extends BusinessAbstract {
    static instance?: TermsBO;

    termsComponent?: Vue;

    static getInstance(): TermsBO {
        if (!TermsBO.instance) {
            TermsBO.instance = new this();
        }

        return TermsBO.instance;
    }

    async fetchServerInfo(): Promise<ServerInfoResponse> {
        return ServerInfoBO.getInstance().fetchServerInfo();
    }

    // Commented cause unsused
    /* async checkServerInfoAndShowTermsModal(onFinish = () => {}, defaultChecked = false) {
        let serverInfo = await this.fetchServerInfo();

        if (TermsBO.legalTermsExpired(serverInfo)) {
            this.termsComponent = this.showTermsModal(onFinish, defaultChecked);
        }

        return serverInfo;
    } */

    static legalTermsExpired(serverInfo: ServerInfoResponse): boolean {
        return !!(
            serverInfo.externUser &&
            serverInfo.legalTermsDate &&
            (!serverInfo.externUser.legalTermsDate ||
                serverInfo.legalTermsDate >
                    serverInfo.externUser.legalTermsDate)
        );
    }

    /*
     * @deprecated In favor of showing the terms from layout
     */
    async showTermsModal(
        onFinish: () => void = () => {},
        defaultChecked: boolean = false,
    ): Promise<Vue> {
        const service = await import("modules/user/components/terms/Terms");

        const component = await Util.mountComponent(
            service.default as ComponentOptions<Vue>,
            {
                isChecked: defaultChecked,
                onProceed: onFinish,
            },
        );

        document.body.appendChild(component.$el);

        return component;
    }
}
