import ClientLinkBO from "modules/core/business/ClientLinkBO";
import AppStorageManager from "modules/core/lib/AppStorageManager";
import { ClientLinkVO } from "modules/core/types/misc";

export default class ClientLinkManager {
    static instance?: ClientLinkManager;

    clientLinkList: ClientLinkVO[] = [];

    static getInstance() {
        if (!ClientLinkManager.instance) {
            ClientLinkManager.instance = new this();
        }

        return ClientLinkManager.instance;
    }

    async manageClientLink(): Promise<ClientLinkVO[]> {
        const response: any =
            await ClientLinkBO.getInstance().fetchClientLinkList();

        if (response && response.clientLinkList) {
            response.clientLinkList.forEach((item: any) =>
                this.saveClientLink(item),
            );

            return response.clientLinkList;
        }

        return [];
    }

    clearClientLinkListFromStorage() {
        AppStorageManager.getInstance().clearClientLinkList();

        this.clientLinkList = [];
    }

    saveClientLink(clientLink: ClientLinkVO) {
        AppStorageManager.getInstance().saveClientLink(clientLink);

        this.clientLinkList =
            AppStorageManager.getInstance().getClientLinkList();
    }

    getClientLinkList() {
        return AppStorageManager.getInstance().getClientLinkList();
    }
}
