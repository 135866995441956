import VueRouter, { RawLocation } from "vue-router";
import _find from "lodash/find";
import _merge from "lodash/merge";
import LocalizationBO from "modules/core/business/LocalizationBO";
import app from "modules/core/config/app";
import RouteGenerator from "../../../../../modules/core/lib/RouteGenerator";

export type MenuItem = {
    id?: string;
    title?: string;
    route?: RawLocation;
    dataQaTag?: string;
    image?: string;
    svg?: string;
    icon?: string;
    divider?: boolean;
    blank?: boolean;
    menu?: boolean;
    iconButton?: boolean;
    forceIcon?: boolean;
    options?: MenuItem[];
    dataTestId?: string;
};

export default class MenuItems {
    router: VueRouter;

    itemsList: MenuItem[];

    constructor(router: VueRouter) {
        this.router = router;

        this.itemsList = [
            {
                id: "check",
                title: LocalizationBO.getInstance().localeText(
                    "evaluator.msgSubTitle",
                ),
                route: {
                    name: "startSession",
                },
            },
            {
                dataQaTag: "specialistsHomeBtn",
                id: "specialists",
                image: require("../../../../static/img/icon-chatbot.png"),
                svg: require("../../../../static/img/icon-chatbot.svg?raw"),
                title: LocalizationBO.getInstance().localeText("tmk1328"),
                dataTestId: "healthProfessionals",
                route: {
                    name: "specialists",
                    query: { categoryId: app.allSepecialistsCategoryId },
                },
            },
            {
                dataQaTag: "dictionaryHomeBtn",
                id: "dictionary",
                icon: "import_contacts",
                title: LocalizationBO.getInstance().localeText("tmk372"),
                route: { name: "glossary" },
                dataTestId: "Dictionary",
            },
            {
                dataQaTag: "loginHomeBtn",
                id: "login",
                title: LocalizationBO.getInstance().localeText(
                    "access.iniciaSesion",
                ),
                route: {
                    name: "login",
                    query: { then: this.router.currentRoute.query.then },
                },
                dataTestId: "loginSection",
            },
            {
                dataQaTag: "registerHomeBtn",
                id: "register",
                title: LocalizationBO.getInstance().localeText(
                    "access.registrate",
                ),
                route: {
                    name: "register",
                    query: { then: this.router.currentRoute.query.then },
                },
                dataTestId: "registrationSection",
            },
            {
                dataQaTag: "settingsHomeBtn",
                id: "settings",
                title: LocalizationBO.getInstance().localeText("titulo_config"),
                icon: "settings",
                route: { name: "profileSettings" },
                dataTestId: "settingsSection",
            },
            {
                dataQaTag: "profileHomeBtn",
                id: "profile",
                title: LocalizationBO.getInstance().localeText("profile"),
                icon: "person",
                route: { name: "profile" },
                dataTestId: "Profile",
            },
            {
                dataQaTag: "activityHomeBtn",
                id: "activity",
                title: LocalizationBO.getInstance().localeText("tmk267"),
                icon: "assignment",
                route: { name: "profileActivity" },
            },
            {
                dataQaTag: "logoutHomeBtn",
                id: "logout",
                title: LocalizationBO.getInstance().localeText(
                    "access.cerrarSesion",
                ),
                icon: "power_settings_new",
                route: { name: "profileLogout" },
            },
            {
                id: "corp",
                title: LocalizationBO.getInstance().localeText("tmk405"),
                route: {},
                menu: true,
            },
            {
                id: "corpBusinessSolutions",
                title: LocalizationBO.getInstance().localeText("tmk1139"),
                route: {
                    name: "corpBusinessSolutions",
                },
            },
            {
                id: "corpScientific",
                title: LocalizationBO.getInstance().localeText("tmk2731"),
                route: {
                    name: "corpScientific",
                },
            },
            {
                id: "corpSecurity",
                title: LocalizationBO.getInstance().localeText("tmk1138"),
                route: {
                    name: "corpSecurity",
                },
            },
            {
                id: "corpTeam",
                title: LocalizationBO.getInstance().localeText("tmk1173"),
                route: {
                    name: "corpTeam",
                },
            },
            {
                id: "contact",
                title: LocalizationBO.getInstance().localeText(
                    "generics.contacto",
                ),
                route: {
                    name: "contact",
                },
            },
            {
                id: "terms",
                title: LocalizationBO.getInstance().localeText(
                    "titulo_condiciones_legales",
                ),
                route: {
                    name: "legalTerms",
                },
            },
            {
                id: "privacy",
                title: LocalizationBO.getInstance().localeText(
                    "access.politicaPrivacidad",
                ),
                route: {
                    name: "privacyPolicy",
                },
            },
            {
                id: "schedule",
                icon: "event",
                title:
                    LocalizationBO.getInstance().localeText("tmk1485") ||
                    "Agenda",
                route: {
                    name: "schedule",
                },
            },
            {
                id: "schedule-timetable",
                // classes: {"mdk-header-menu__item--indent": true},
                icon: "date_range",
                title:
                    LocalizationBO.getInstance().localeText("tmk1489") ||
                    "Disponibilidad horaria",
                route: {
                    name: "schedule-timetable",
                },
            },
            {
                id: "professionalNewImpersonatedSession",
                icon: "local_hospital",
                title: `${LocalizationBO.getInstance().localeText("new_test")}`,
                route: RouteGenerator.getInstance().newImpersonatedSession(),
            },
            {
                id: "dashboard",
                icon: "dashboard",
                title:
                    LocalizationBO.getInstance().localeText("tmk1555") ||
                    "Dashboard",
                route: {
                    name: "dashboard",
                },
            },
            {
                id: "chat",
                icon: "message",
                title:
                    LocalizationBO.getInstance().localeText("tmk1557") ||
                    "Consultas",
                route: {
                    name: "chat",
                    // query: {externUserGroupId : menuComponent.externUserGroupId}
                },
            },
            {
                id: "chats",
                icon: "message",
                title:
                    LocalizationBO.getInstance().localeText("tmk1557") ||
                    "Consultas",
                route: {
                    name: "chats",
                },
            },
            {
                id: "specialistAdmin",
                icon: "people",
                title: LocalizationBO.getInstance().localeText("tmk238"),
                route: RouteGenerator.getInstance().specialistAdminList(),
            },
            /* {
                id: "hospitalSessions",
                icon: "low_priority",
                title: LocalizationBO.getInstance().localeText("tmk771"),
                route: RouteGenerator.getInstance().hospitalSessions(),
            },
            {
                id: "hospitalUsers",
                icon: "people",
                title: LocalizationBO.getInstance().localeText("tmk770"),
                route: RouteGenerator.getInstance().hospitalUsers(),
            },
            {
                id: "hospitalAdminUserList",
                icon: "people",
                title: LocalizationBO.getInstance().localeText("tmk799"),
                route: RouteGenerator.getInstance().hospitalAdminUserList(),
            },
            {
                id: "hospitalSessionHistory",
                icon: "folder",
                title: LocalizationBO.getInstance().localeText("tmk1820"),
                route: RouteGenerator.getInstance().hospitalSessionHistory(),
            } */
        ];
    }

    getItem(id: string, options: any = {}) {
        return _merge({}, _find(this.itemsList, { id }), options);
    }

    getDivider(): MenuItem {
        return {
            divider: true,
        };
    }

    getDividerBlank(): MenuItem {
        return {
            blank: true,
        };
    }
}
