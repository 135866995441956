// @ts-ignore
import { version } from "../../../../package.json";
import CheckerSkinEnum from "../enums/CheckerSkinEnum";
import type { GeneralConfig } from "../types/misc";
import CheckerProgressBarPositionEnum from "../enums/CheckerProgressBarPositionEnum";
import OfferingsImageSizeEnum from "../enums/OfferingsImageSizeEnum";
import RestartButtonPositionEnum from "../enums/RestartButtonPositionEnum";
import ActivityItemsEnum from "../enums/ActivityItemsEnum";
import { GenericRequest } from "../model/genericRequest";
import { SessionOfferingCardLayoutEnum } from "../enums/SessionOfferingCardLayoutEnum";

const defaults: GeneralConfig = {
    analyticsAppName: "Mediktor Web", // default: Mediktor Web
    appVersion: import.meta.env.VITE_APP_VERSION, // default: import.meta.env.VITE_APP_VERSION
    appVersionRev: "", // default: ""
    mainLogo: require("app/static/img/mdk_logo_mediktor.png"), // default: logoColorImg
    mainLogoDarkMode: require("app/static/img/mdk_logo_mediktor.png"), // default: logoColorImg
    defaultLanguage: "en-EN", // default: en-EN
    copyrightBrandName: "Mediktor Corp.", // default: Mediktor Corp.
    showBrandingElements: false, // default: false
    showMainCategories: true, // default: true
    allSepecialistsCategoryId: "41bc14f9-356d-4782-bc81-f19955658fdd", // default: 41bc14f9-356d-4782-bc81-f19955658fdd
    chatbotCategoryId: "41cd1f76-d20f-4e02-a8a3-c9991471f210", // default: 41cd1f76-d20f-4e02-a8a3-c9991471f210
    paymentGatewayType: "redsys", // default: redsys, alternative => stripe
    showAppMarkets: true, // Deprecated
    marketAppleApp: null,
    marketGoogleApp: null,
    blogFeedUrl: null, // Deprecated
    wpApiToken: null, // default: Fx(n2dKNMO6GxeJT5$&bVADOx0&DJC2(XextG3Q(B)4vXox)#02GAnuh75qkClvE
    showCorporateContent: false, // Deprecated
    minimalFooter: false, // Deprecated
    enableChatbot: true, // default: true
    enableSpecialistsMenuLink: false, // default: true
    enableSocket: false, // default: false
    enableGlossary: true, // default: true
    enableGlossaryDetailSessionStart: true, // default: true
    enableSymptomsInGlossary: false, // default: false
    subTitleInGlossary: null, // default: null, add tmk that you want to show
    enableDerivation: false, // default: false
    classicViewSpecialists: false, // default: false
    sessionRecommendationProductCallPhoneNumber: null, // default: null
    covidConclusionId: "11955041-4045-4759-ae71-7d4acfcb0382", // default: 11955041-4045-4759-ae71-7d4acfcb0382
    enableAccessCode: false, // default: false
    enableSpecialties: false, // default: false
    covidLogo: require("app/static/img/covid.png"), // default: covidIcon
    poweredByMediktorLogo: require("app/static/img/powered-by.png"), // default: logoPoweredBy
    poweredByMediktorLogoDarkMode: require("app/static/img/powered-by_light.png"), // default: logoPoweredByLight
    googleSignInId: null, // default: 313684631062-1vgrfcrk6r4uhve24r56sg1egav2c8fk.apps.googleusercontent.com
    chatbotId: "genericEvaluatorChatbotId", // default: genericEvaluatorChatbotId
    chatbotProductId: "9ae87bdf-c876-4b87-96a3-4bad69cdbd83", // default: 9ae87bdf-c876-4b87-96a3-4bad69cdbd83
    colorPrimary: "#03a6a6", // default: #03a6a6
    colorSecondary: "#B33A8B", // default: #B33A8B
    scheduleEnabled: false, // default: false, enables routing to scheduling features
    scheduleEventEditDaysOffset: 120, // default: 120
    prescriptionsEnabled: false, // default: false, enables to send a prescription at the chat screen for specialist users
    valuationEnabled: false, // default: false, enables specialist users to leave a valuation of the patient state when the chat is closed
    userValuationsVisibilityEnabled: true, // default: true
    dashboardEnabled: false, // default: false, enables to swich the home page by a dashboard like page
    dashboardRedirectEnabled: false, // default: false, enables auto redirect to dashboard page
    chatDirectAccessEnabled: true, // default: true, add a direct access in the side menu to chats page
    impersonatedSessionEnabled: false, // default: false
    specialistDetailLoginRequired: false, // default: false
    showUserAvailableTickets: false, // default: false
    currency: {
        symbol: "€",
        label: "EUR",
    }, // default: {symbol: "€", label: "EUR"}
    deviceType: GenericRequest.DeviceTypeEnum.WEB, // default: "WEB",
    deviceToken: null,
    autodestroyMode: false, // default: false, Activate ONLY if instance must accept anonymous users by requirement only
    mainVisualNavigationEnabled: true, // default: true
    openOfferingActionInNewWindow: false, // default: false
    isDefaultProductActionBehaviourEnabled: true, // default: true
    adddCreditToAccountEnabled: false, // default: false
    addCreditToAccountEnabled: false, // default: false
    appId: null, // default: null
    specialistAdminEnabled: false, // default: false
    specialistAdminPasswordInputEnabled: true, // default: true
    isProfileEnrichment: true, // default: false
    creditVisibleForPartner: false, // default: false
    chatbotRestartActionEnabled: true, // default: true
    isPriceSelectorInputText: false, // default: false, this swiches the add credit and product selector to a number type input text
    minValuePriceSelectorInputText: 0, // default: 0, min value for the add credit and product selector number type input text
    maxValuePriceSelectorInputText: 100000, // default: 100000, max value for the add credit and product selector number type input text , max supported is 2147483647
    adminCanEditSpecialistStatus: false, // default: false
    partnerCanEditStatus: false, // default: false
    showCookiesAlert: true, // default: true
    userMustAcceptLegalTerms: true, // default: true
    geolocationEnabled: false, // default: false
    redirectToHomeIfCmdNotFound: true, // default: true
    enableSounds: false, // default: false, enable audio for notifications, etc..
    enableProductUpgradeModal: false, // default: false , enables a notification modal with the product upgrade price difference
    forceVolatileSensitiveUserData: true, // default: true - Whether user sensitive data should be persisted in memory rather than in storage
    registeredUserAuthStorageExpirationTime: null, // default: null - Logged user authentication expiration in hours
    enableToggleDarkMode: false, // default: false  - activate the dark mode funtionality and toggle action
    forceDarkMode: false, // default: false - sets the app dark mode by default
    enableAppleSignIn: false, // default: false - show the AppleSignIn button
    firebaseConfig: null, // default: null - you can add your firebase configuration object here, if you want to use, for example AppleSignIn
    enableOfferingMetrics: false, // default: false - enabled if needed the analytics click tracking of the product action in offerings
    checkerSkin: CheckerSkinEnum.CONVERSATION, // default: "conversation", select the checker layout , "assistant" or "conversation". Hybrid = Conversation.
    registeredUserRefreshTokenExpirationTime: 31557600, // default: 259200 - How long will the refresh token last for a logged user (in seconds)
    refreshTokenExpirationTime: null, // default: null - How long will the refresh token last (in seconds)
    checkerBottomLogo: require("app/static/img/mdk_logo_gray.png"), // default checkerBottomLogo, logo in the bottom of the checker
    enableCheckerBottomLogo: false, // default false, this logo can be hidden with this property
    checkerProgressBarPosition: CheckerProgressBarPositionEnum.BOTTOM, // default "bottom", change the progressbar position in the checker
    forceHeaderVisible: false, // default false, Force to show the header in the checker flow
    enableGenerateDynamicMetas: true, // default false, Change metas dynamically
    showDevelopmentTip: true, // default:true - show a message in the footer if not in production environment
    // Components contextualization for specific configurations:
    customTmks: {},
    /* default {}, add tmk that you want to show and the language-> example:
    "tmk1757": {
            "es-ES": "Some text in spanish",
            "en-GB": "Some text in english",
    }
    */
    enableShowTmksKeys: false, // default false, show tmk keys in the app
    legalTerms: {
        title: true, // default true
        supportingImages: true, // default true
        infoSupportingImages: true, // default true
        showContentInModal: true, // default false
        checkboxCustomItems: [], // [{title: // "Your title or html here", name: // "name to verify the model", required: boolean // if this is required to continue or not, checked:boolean // force to appear checked by default}]
        checkboxCustomItemsOnly: false, // default false -> it can by use to hide deafult options and only show the checkboxCustomItems
        dataProtectionModule: false, // default false
        enableCheckPrivacyPolicy: true, // default true
    },
    hospital: {
        enableComplementaryTests: true,
        useAgeInsteadOfBirthdate: false,
        useSubPhaseInterface: false,
        enableSymptomsValidationAdvanceEditing: true,
        enableSessionDetailEditing: true,
        defaultRowsPerPage: 50,
        useGlucemyMmol: false,
        enablePatientSearch: true,
        userProfileBasicDataEdit: true,
        researchMode: false,
        femaleVitalSigns: true,
        enableMultipleActiveSessions: true,
        enablePatientEdit: true,
    },
    checker: {
        buttonBackText: false, // default false, adds a back text to the back button
        buttonContinueSquared: false, // default false, changes button layout to squared
        buttonScrollSquared: false, // default false, changes button layout to squared
        buttonScrollLeftPosition: false, // default false, changes button position to the left side during all the flow
        buttonScrollLeftPositionSessionResultOnly: false, // default false, changes button position to the left side only in the session result page
        buttonContinueText: false, // default false,  adds a continue text to the continue button
        buttonContinueDisabledStatus: false, // default false, by default the button is not visible in the disabled state.
        buttonQuestionLocationImage: require("app/static/img/mdk_illustration_map.svg?raw"), // default questionLocationImage personalize the image in the location question if needed
        counterToast: true, // default true, hides the counter toast
        avatarIcon: require("app/static/img/icon-chatbot.svg?raw"), // default mediktor's avatar, Add your own avatar image to mediktors conversation layout
        avatarIconText: "oskar", // default "oskar", Add your own avatar text to mediktors conversation layout
        inputTextLabel: false, // default false,  add a label text above the input fields
        modalConfirmation: true, // default true,  add a confirmation modal when the evaluation is closed
        enableDefaultCloseRedirection: true, // default true,  enables a redirection on close action, set it false to catch the event and add your own actions
        enableDefaultCancelRedirection: true, // default true,  enables a redirection on cancel action, set it false to catch the event and add your own actions
        enableInnerScrollOnMobile: true, // default true, enables the scroll in the inner cantainer, used as false is usefull if there are an outer footer or header or similar
        enableNavBar: true, // default true, shows the checker nav bar with the logo, report, close button etc...
        enableSessionResult: true, // default true, if false removes the session result component at the end of the assessment
        enableSessionReportValuationPositiveAlert: true,
        enableSessionReportValuationNegativeFeedback: true,
        restartButtonPosition: RestartButtonPositionEnum.HEADER,
        enableSatisfactionSurvey: true, // default true
        enableRestartButton: false, // default false, if true shows the restart assessment button
        enableRestartButtonSessionResultOnly: false, // default false, if true the button will only be shown at the session result screen
        enableSymptomsValidationAdvanceEditing: true,
        enableErrorButtonToHome: true, // default true, enables the error button to appear on the error M0
        forceHeaderTitle: false,
        enableAnimatedHeader: false,
        animatedHeaderAvailableLanguages: [
            "ar-AR",
            "de-CH",
            "de-DE",
            "en-EN",
            "en-US",
            "en-GB",
            "es-CO",
            "es-ES",
            "ca-ES",
            "el-GR",
            "es-MX",
            "fi-FI",
            "fr-BE",
            "fr-FR",
            "it-IT",
            "ja-JP",
            "ko-KR",
            "nl-BE",
            "nl-NL",
            "pt-BR",
            "pt-PT",
            "ro-RO",
            "sv-SW",
            "tr-TR",
            "zh-CN",
            "zh-HK",
        ],
        forceHomeSkin: null,
        enableHomeDisclaimer: false,
        enableHomeDisclaimerInfo: true,
        enableSessionResultNavbarInfoButton: true,
        enableActionsQuestionLineBreack: false,
        enableGiveFeedbackButton: false, // default false, enables the give feedback button on every question
    },
    home: {
        backgroundImage: require("app/static/img/doctora.png"), // default homeBackgroundImage, adds a background image to the home pages also to the dashboard page when it behaves as the home page
        greetingTextFullName: false,
    },
    offerings: {
        imageSize: OfferingsImageSizeEnum.MEDIUM, // default MEDIUM, You can change the size of the offering images SMALL,MEDIUM, LARGE and XLARGE
        moduleLayout: SessionOfferingCardLayoutEnum.SUPEREXTENDED, // default EXTENDED, You can change the layout of the offering cards EXTENDED, BASIC, SUPEREXTENDED
    },
    customSatisfactionSurvey: {
        elementId: false, // It will set an element with this custom id
        snippet: false, // Expected a function as a string
    },
    notFound: {
        image: require("app/static/img/mdk_img_notfound.png"),
    },
    maps: {
        mapLocationPrecisionDecimals: 2,
        locationMarkerColor: "#828282",
        locationMarkerActiveColor: "#0C5D68",
    },
    login: {
        bottomActions: true,
        keepLoggedIn: true,
    },
    register: {
        bottomActions: true,
        enabled: true,
    },
    activity: {
        activityItems: [
            ActivityItemsEnum.SESSIONS,
            ActivityItemsEnum.EXTERNUSERGROUPS,
            ActivityItemsEnum.PRESCRIPTIONS,
        ], // whitelist of activity items type listed in the profile activity
    },
    settings: {
        logout: true,
        enableLanguageSelector: true,
        enableImagePicker: true,
    },
    header: {
        drawer: true, // default true, enables menu in horizontal
        iconsOnly: false,
        enabled: true,
    },
    footer: {
        enabled: true,
        mini: false,
    },
    showActivePresetName: true, // TODO: move to settings.locationAction
    enableLocationSettings: true,
    enableUserDebugger: true,
    resolveLanguage: true,
    detectUserLanguage: true,
    enablePostMessage: true, // default true, enables or not when a postMessage is sent
    hospitalMode: false,
    researchMode: false,
    tokenExpirationRefreshThreshold: 60,
    routerMode: "history",
    preventDefaultInterfaceActions: false,
    languageSelectorPosition: "bottom",
    languageSelectorInDrawer: false, // Default false active language selector in drawer
    enablePageHeaders: true,
    presentLoginWhenRequiringAuthentication: true,
    dateFormat: "DD/MM/YYYY",
    basePath: "/",
    allowedPaths: null,
    enableDynamicBasePath: true,
    rareLanguageFallsBackToEnglish: false,
    pullFullLocalizationList: false,
    logoutRedirectsToHome: true,
    showCertificate: true, // Default: true, ertificate link on navbar
    interface: {
        buttonAttributes: {
            rounded: true,
            noCaps: true,
            color: "primary",
            unelevated: true,
        },
        inputAttributes: {
            rounded: true,
            outlined: true,
            dense: true,
        },
    },
    capabilities: {
        location: {
            delegateRequest: false,
            enabled: true,
        },
    },
    enableMessageToWebkit: false,
    enableGlobalFunctions: true,
    autoStart: true,
    enableRedirections: true,
    presentDefaultView: true,
    enableHomeSelector: false,
    listRowsPerPage: 50,
    userProfileBasicDataEdit: true,
    enableMiniNav: false,
    enableAvatarPoc: false,
    legalTermsTextLocaleId: "tmk1684",
    privacyPolicyTextLocaleId: "tmk1685",
    cookiesTextLocaleId: "tmk1686",
    heightUnitIsCm: false,
    utmParams: {
        campaign: "mediktor",
        source: "symptom_checker",
        medium: "app",
    },
    authTokenExpiration: undefined,
    enableBreadCrumb: true,
    showLabellingECREP: false, // default false, values accepted: false, "uk" for United Kingdom, "ch" for Switzerland. It is used to show ECREP on labelling
    enableSpecialistListing: false,
    embeddedMode: false,
};

export default defaults;
