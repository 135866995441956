import _omit from "lodash/omit";

export class Deferred {
    promise: Promise<void>;

    reject!: (value: PromiseLike<any>) => void;

    resolve!: (reason?: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

export class Mutex {
    acquired: boolean = false;

    queue: Record<number, [Deferred, () => void]> = {};

    acquire() {
        this.acquired = true;
    }

    dispatch(fn: () => Promise<any>): Promise<any> {
        if (!this.acquired) {
            return fn();
        }

        const p = new Deferred();
        const key = new Date().getUTCMilliseconds();

        this.queue[key] = [p, fn];

        return p.promise;
    }

    release() {
        this.acquired = false;

        if (Object.keys(this.queue).length > 0) {
            console.log("Releasing mutex queue...", this.queue);

            for (const key in this.queue) {
                this.queue[key][0].resolve(this.queue[key][1]());
                // eslint-disable-next-line no-prototype-builtins
                if (this.queue.hasOwnProperty(key)) {
                    this.queue = _omit(this.queue, key);
                }
            }
        }
    }

    clearQueue() {
        console.log("Clear mutex queue");
        this.queue = {};
    }
}

export class MutexManager {
    static instance?: Mutex;

    static getInstance(): Mutex {
        if (!MutexManager.instance) MutexManager.instance = new Mutex();

        return MutexManager.instance;
    }
}
