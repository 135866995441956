// @ts-ignore
// TODO: Move to TS ENUM!
import Enum from "enum";

export default new Enum({
    vitalSignsID: "08bd4a9e-f04d-43e8-9c26-ff84f959c5a0",
    noneStatementIds: {
        "1725b605-d1cf-4a92-bcbe-eebb23e76b5d":
            "b374dee8-9c0d-4228-93e5-3672b341b1e8", // allergies
        category11: "dc87ffef-8ba1-4331-985f-da7d4239fe0a", // risk
        "93634dc1-286d-4531-87a8-ec8d2b4b1c88":
            "ccae5319-ab87-4cde-834c-3a8a60d62cfc", // surgical
        "2c2f244a-1516-4d2d-b365-8750338e00ab":
            "e067ab96-de04-4f30-8ebd-8de847f56566", // conditions
        "ceb380de-60b1-4b74-846e-77df019ed673":
            "d27a1c48-3d53-4b08-8bf7-9b7983db8011", // medication
        "49618760-5487-4323-a190-027f3893d05d":
            "94616917-525b-4e88-ac4b-00028ac4f32a",
        "7cbfbd58-c1af-494b-bf02-895b4ed5053a":
            "14be094b-3b3d-46fb-9dbf-38702f421f11",
        "ebf49ca1-395b-4bc9-ae3a-b883e370efdd":
            "d748e967-dbc9-499e-8db7-1d51958df92f", // race
        "6e9a8adf-4a2a-4f1e-84c5-e6085abc9aaa":
            "d6cb3da6-e2b0-4d24-bec7-55d3083d2222", // nivel de conciencia
        // "5dabf842-563d-44b3-90e6-fe9e4dc344ff": "ed06949f-0399-4cf2-9860-59539dd2d9a3" // gender
    },
    gendersID: "4a312ded-ba57-43de-8479-53897558302d",
});
