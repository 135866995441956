// @ts-ignore
import template from "modules/core/components/interface/navbar/navbar.html?raw";
import "modules/core/components/interface/navbar/navbar.scss";
import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";
import { QBtn } from "quasar";
import { MDCTopAppBar } from "@material/top-app-bar";
import AudioController from "modules/core/lib/AudioController";

import mixins from "vue-typed-mixins";
import { inject } from "vue";
import Button from "modules/core/components/interface/button/Button";

export default mixins(CoreComponentMixin, LocaleMixin).extend({
    template,
    components: {
        "mdk-ui-button": Button,
        QBtn,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        backButtonVisible: {
            type: Boolean,
            required: false,
            default: true,
        },
        backAction: {
            type: Function,
            required: false,
            default: null,
        },
        backIcon: {
            type: String,
            required: false,
            default: "arrow_back",
        },
        backIconText: {
            type: String,
            required: false,
            default: "",
        },
        actions: {
            type: Array,
            required: false,
            default: () => [],
        },
        centerTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        flexible: {
            type: Boolean,
            required: false,
            default: false,
        },
        theme: {
            type: String,
            required: false,
            default: "",
        },
        light: {
            type: Boolean,
            required: false,
            default: true,
        },
        monocrome: {
            type: Boolean,
            required: false,
            default: false,
        },
        infoIconVisible: {
            type: Boolean,
            required: false,
            default: false,
        },
        resultIsVisible: {
            type: Boolean,
            required: false,
            default: false,
        },
        showAvatarToggle: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    setup() {
        type SetValue = (visibility: boolean) => void;

        const avatarVisibility = inject<boolean>("avatarVisibility");
        const avatarThumbnail = inject<boolean>("avatarThumbnail");
        const setVisibility = inject<SetValue>("setVisibility");

        

        return { avatarVisibility, setVisibility, avatarThumbnail };
    },

    data() {
        return {
            disableToggleAvatar: false,
        };
    },

    computed: {
        isBackButtonVisible(): boolean {
            return (
                this.backButtonVisible &&
                !this.app().generalConfig().enableMiniNav
            );
        },
        classDefinition(): Record<string, boolean> {
            return {
                "mdk-ui-navbar--light": this.light,
                "mdk-ui-navbar--monocrome": this.monocrome,
            };
        },

        getDefinitionClassIconButton(): Record<string, boolean> {
            return {
                "mdc-top-app-bar__navigation-icon material-icons":
                    this.isBackIcon,
                "mdk-ui-navbar__button-back": !this.isBackIcon,
            };
        },

        isBackIcon(): boolean {
            const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

            return !isHTML(this.backIcon);
        },

        avatarToggleVisible(): boolean {
            return !!(
                this.showAvatarToggle &&
                this.resultIsVisible !== undefined &&
                !this.resultIsVisible &&
                this.app().generalConfig().checkerSkin === "avatar" &&
                this.app()
                    .generalConfig()
                    .checker.animatedHeaderAvailableLanguages.includes(
                        this.$app.language,
                    )
            );
        },

        skin(): string {
            return this.app().generalConfig().checkerSkin;
        },

        locales(): string[] {
            return ["tmk1137", "tmk_mediktor_sources"];
        },
    },

    methods: {
        disableAvatar() {
            if (window.innerHeight < 400) {
                this.setVisibility?.(false);
                this.disableToggleAvatar = true;
            } else if (this.disableToggleAvatar) {
                this.disableToggleAvatar = false;
            }
        },

        goBack() {
            console.log("NAVBAR::goBack", this.backAction !== null);
            if (this.backAction !== null) {
                this.backAction();
            } else {
                this.goBackInHistory();
            }
        },

        goBackInHistory() {
            console.log("NAVBAR::goBackInHistory");
            this.$router.back();
            // window.history.back();
        },

        toggleAvatarVisibility(value: boolean) {
            if (this.disableToggleAvatar) {
                this.$q.notify({
                    message: this.localeText("tmk2259"),
                    badgeStyle: "opacity: 0",
                    classes: "snackbar",
                });
                this.setVisibility?.(false);
            } else {
                if (
                    AudioController.getInstance().getIsGrantedAudio() ===
                        false &&
                    value
                ) {
                    AudioController.getInstance().setIsGrantedAudio(value);
                }
                this.setVisibility?.(value);
            }
        },

        setup() {
            const topAppBar = new MDCTopAppBar(this.$el);
        },
    },

    mounted() {
        this.setup();
        this.disableAvatar();
        window.addEventListener("resize", this.disableAvatar);
    },

    destroyed() {
        window.removeEventListener("resize", this.disableAvatar);
    },
});
