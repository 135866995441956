// import notification from "../../../app/static/sounds/notification_audio.mp3";

type PredefinedSounds = "notification";
type SoundSettings = { volume?: number };

/**
 * Create an Sound element using the Sound API, works as an Sound libray with centralized Sounds
 * or creates a custom one.
 * @param selectedSound = string
 * @param setting = object , cotains Sound settings
 * @param customSound = Audio , add own Sound element
 */
export default class SoundsNotifications {
    sound?: HTMLAudioElement;

    volume: number = 1;

    customSound?: string | HTMLAudioElement;

    constructor(
        selectedSound: PredefinedSounds,
        settings: SoundSettings = {},
        customSound?: string | HTMLAudioElement,
    ) {
        if (!selectedSound && !customSound) {
            throw new Error("[SoundsNotifications] - Valid sound is missing");
        }
        this.customSound = customSound;
        this.volume = settings?.volume ?? 0.3;

        this.addSoundSettings(selectedSound);
    }

    setSound(selectedSound: PredefinedSounds) {
        switch (selectedSound) {
            case "notification":
                this.sound = new Audio(this.notificationSound());
                break;
            default:
                this.sound =
                    this.customSound instanceof Audio
                        ? this.customSound
                        : new Audio(this.customSound);
                break;
        }
    }

    addSoundSettings(selectedSound: PredefinedSounds) {
        this.setSound(selectedSound);
        this.sound && (this.sound.volume = this.volume);
    }

    playSound() {
        this.sound?.play();
    }

    pauseSound() {
        this.sound?.pause();
    }

    reloadSound() {
        this.sound?.load();
    }

    notificationSound() {
        return require("../../../app/static/sounds/notification_audio.mp3");
    }
}
