import template from "./menu.html?raw";
import CoreComponent from "modules/core/mixins/CoreComponent";
import "./menu.scss";
import UIButton from "modules/core/components/interface/button/Button";
import UIMenu from "modules/core/components/interface/menu/Menu";
import UIGridRow from "modules/core/components/interface/grid/GridRow";
import UIGridCell from "modules/core/components/interface/grid/GridCell";
import UIListItem from "modules/core/components/interface/list-item/ListItem";
import UIDrawer from "modules/core/components/interface/drawer/Drawer";
import MenuItems from "./lib/MenuItems";
import SpecialistCardOverview from "../../../../modules/specialists/components/specialist-card/SpecialistCardOverview";
import ProductBO from "modules/core/business/ProductBO";
import ProductTypeListEnum from "../../../../modules/core/enums/ProductTypeListEnum";
import PermissionProfileEnum from "../../../../modules/core/enums/PermissionProfileEnum";
import ExternUserGroupStatusEnum from "../../../../modules/chat/enums/ExternUserGroupStatusEnum";
import ExternUserGroupMemberRoleEnum from "../../../../modules/chat/enums/ExternUserGroupMemberRoleEnum";
import ExternUserGroupBO from "modules/core/business/ExternUserGroupBO";
import SoundsNotifications from "modules/core/lib/SoundsNotifications";
import SoundsManager from "modules/core/lib/SoundsManager";
import NotificationsManager from "modules/core/lib/NotificationsManager";
import { UserPermission } from "modules/core/lib/UserPermission";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import SvgIcon from "modules/core/components/interface/svg-icon/SvgIcon";
import LanguageSelector from "app/components/layout/language-selector/LanguageSelector";
import { uniqBy } from "lodash";
import { QIcon, QExpansionItem, QList, QItem, QItemSection } from "quasar";

export default {
  template,
  mixins: [CoreComponent],

  components: {
    "mdk-ui-button": UIButton,
    "mdk-ui-menu": UIMenu,
    "mdk-ui-grid-row": UIGridRow,
    "mdk-ui-grid-cell": UIGridCell,
    "mdk-ui-list-item": UIListItem,
    "mdk-ui-drawer": UIDrawer,
    SpecialistCardOverview,
    "mdk-svg-icon": SvgIcon,
    LanguageSelector,
    QIcon,
    QExpansionItem,
    QList,
    QItem,
    QItemSection,
  },

  props: {
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      drawerIsVisible: false,
      externUser: null,
      hasSpecialists: false,
      settingsModal: null,
      isUserLoggedIn: false,
      hasChats: false,
      userNotificationCount: 0,
      isUserNotificationsFirstLoad: true,
      userPermisssion: null,
    };
  },

  watch: {
    $route(to, from) {
      this.hideDrawer();
    },
  },

  methods: {
    enableWindowResizeDetection() {
      return true;
    },
    showDrawer() {
      this.drawerIsVisible = true;
    },
    hideDrawer() {
      this.drawerIsVisible = false;
    },
    manageEvents() {
      this.catchEvent("userUpdate", (externUser) => {
        this.externUser = this.app().externUser;
        this.userPermisssion = new UserPermission(this.app().externUser);
        this.isUserLoggedIn = this.app().isUserLoggedIn();
        this.isUserNotificationsFirstLoad = true;
        NotificationsManager.getInstance().resetNotifications();
        this.userNotificationCount = 0;
      });

      this.catchEvent("userUpdateNotifications", () => {
        NotificationsManager.getInstance().updateNotifications(
          this.getMyOpenExternUserGroupsId(),
        );

        if (this.enableNotificationSound(this.unreadMessagesNotifications())) {
          this.playNotificationSound();
        }

        this.userNotificationCount = this.unreadMessagesNotifications();
        this.isUserNotificationsFirstLoad = false;
      });

      this.catchEvent("message", () => {});

      this.catchEvent("refresh-drawer-links", async () => {
        await this.setHasChats();
      });
    },
    playNotificationSound() {
      const sound = new SoundsNotifications("notification", {
        volume: 0.3,
      });
      sound.playSound();
    },
    enableNotificationSound(unreadMessages) {
      const soundsManager = new SoundsManager();
      if (!soundsManager.isSoundsEnabled()) return false;
      if (
        this.$router.currentRoute.name === "chat" ||
        this.$router.currentRoute.name === "chatbot"
      )
        return false;
      else
        return (
          !this.isUserNotificationsFirstLoad &&
          unreadMessages > this.userNotificationCount &&
          this.app().generalConfig().enableSounds
        );
    },
    isDivider(link) {
      return link.divider && link.location === "secondary";
    },
    getMyOpenExternUserGroupsId() {
      const groupStatus = ExternUserGroupStatusEnum.OPEN.value;
      const userRoleToAvoid = ExternUserGroupMemberRoleEnum.OBSERVER.value;

      if (this.externUser) {
        return ExternUserGroupBO.getInstance().getMyExternUserGroupsIdByGroupStatus(
          groupStatus,
          userRoleToAvoid,
          this.externUser.externUserId,
        );
      }

      return null;
    },
    async manageReceivedMessage(payload) {
      let data = {};
      data.message = {};
      data.message = Object.assign({}, data.message, {
        messageId: payload.messageId,
        sourceId: payload.sourceId,
        destinationId: payload.destinationId,
        isReceived: true,
        receivedDate: payload.isReceived
          ? payload.receivedDate
          : new Date().getTime(),
        isRead: false,
        readDate: null,
      });
      await this.api.callServiceWithPromise("message", data);
    },
    onDrawerDismiss() {
      this.drawerIsVisible = false;
    },
    isActivity(link) {
      return link.id === "activity";
    },
    async setHasChats() {
      const getOpenChat =
        await ExternUserGroupBO.getInstance().fetchExternUserGroupListByGroupStatus(
          0,
          1,
          0,
        );
      const getClosedChat =
        await ExternUserGroupBO.getInstance().fetchExternUserGroupListByGroupStatus(
          0,
          1,
          1,
        );

      this.hasChats = getOpenChat.length > 0 || getClosedChat.length > 0;
    },
    unreadMessagesNotifications() {
      return NotificationsManager.getInstance().unreadMessagesNotifications;
    },
  },

  computed: {
    isScheduleTimetable() {
      let isScheduleTimetable = false;
      if (this.externUser && this.externUser.externUserProducts) {
        const listOfProducts = this.externUser.externUserProducts.map(
          (e) => e.productId,
        );
        if (listOfProducts.length > 0) {
          isScheduleTimetable = listOfProducts.some((productId) => {
            const product = ProductBO.getInstance().getProductById(productId);

            return product.productTypeList.includes(
              ProductTypeListEnum.DELAYED.value,
            );
          });
        }
      }

      return isScheduleTimetable;
    },
    profileHeaderIsVisible() {
      return this.isUserLoggedIn && this.externUser;
    },
    callToActionIsVisible() {
      return this.$router.currentRoute.name !== "home";
    },
    usernameDescription() {
      if (this.externUser != null) {
        return this.externUser.fullName;
      }
    },
    logoUrl() {
      if (this.isDarkMode) return this.app().generalConfig().mainLogoDarkMode;
      else return this.app().generalConfig().mainLogo;
    },
    menuItems() {
      return new MenuItems(this.$router);
    },
    drawerCustomLinks() {
      return [];
    },
    drawerHeaderIsVisible() {
      return this.profileHeaderIsVisible || this.drawerTopLinks.length > 0;
    },
    hasChatDirectAccess() {
      return (
        this.app().generalConfig().chatDirectAccessEnabled &&
        (this.hasChats || this.userNotificationCount > 0)
      );
    },
    drawerLinks() {
      let list = [];

      if (this.shouldCollapse) {
        if (this.userPermisssion && !this.$app.generalConfig().hospitalMode) {
          //list.push(this.menuItems.getItem("specialists"));
          if (this.$app.generalConfig().enableGlossary) {
            list.push(this.menuItems.getItem("dictionary"));
          }
        }
      }

      if (
        this.userPermisssion &&
        this.$app.generalConfig().hospitalMode &&
        !this.$app.generalConfig().researchMode &&
        this.$app.generalConfig().enableGlossary
      ) {
        list.push(this.menuItems.getItem("dictionary"));

        //Ported to hospital
        /*
                if (this.userPermisssion.hasPermissionForProfile(PermissionProfileEnum.ADMINISTRATOR.value)) {
                    list.push(this.menuItems.getItem("hospitalAdminUserList"));
                }
                */
      }

      if (this.userPermisssion && !this.$app.generalConfig().hospitalMode) {
        if (this.isUserLoggedIn) {
          if (
            this.app().generalConfig().dashboardEnabled &&
            this.externUser &&
            this.externUser.isPartner
          ) {
            list.push(this.menuItems.getItem("dashboard"));
            list.push(this.menuItems.getDivider());
          }

          list.push(this.menuItems.getItem("profile"));
          if (this.hasChatDirectAccess) {
            list.push(
              this.menuItems.getItem("chat", {
                badge: this.userNotificationCount,
              }),
            );
          }
          if (
            this.externUser &&
            this.externUser.isPartner &&
            this.isScheduleTimetable &&
            this.app().generalConfig().scheduleEnabled
          ) {
            list.push(this.menuItems.getItem("schedule-timetable"));
          }

          if (this.app().generalConfig().scheduleEnabled)
            list.push(this.menuItems.getItem("schedule"));
          list.push(
            this.menuItems.getItem("activity", {
              badge: !this.hasChatDirectAccess && this.userNotificationCount,
            }),
          );

          if (
            this.externUser &&
            this.externUser.permissionProfile ===
              PermissionProfileEnum.PARTNER_EXTERNAL.value &&
            this.app().generalConfig().impersonatedSessionEnabled
          ) {
            list.push(
              this.menuItems.getItem("professionalNewImpersonatedSession"),
            );
          }
        }
      }

      if (
        (this.shouldCollapse || this.isUserLoggedIn) &&
        this.settingsIsVisible
      ) {
        list.push(this.menuItems.getItem("settings"));
      }

      if (!this.app().generalConfig().hospitalMode) {
        if (
          this.$app.isUserAdmin() &&
          this.$app.generalConfig().specialistAdminEnabled
        ) {
          list.push(this.menuItems.getDivider());
          list.push(this.menuItems.getItem("specialistAdmin"));
        }
      }

      list.push(...this.drawerCustomLinks);

      if (
        this.shouldCollapse &&
        this.userPermisssion &&
        this.$app.generalConfig().enableSpecialistListing
      ) {
        list.unshift(this.menuItems.getItem("specialists"));
      }

      return list;
    },
    getCustomSecondaryLinks() {
      return [];
    },
    secondaryLinks() {
      let list = [];

      if (!this.externUser) return [];

      list.push(...this.getCustomSecondaryLinks);

      // Ported to hospital
      /*if (
                this.isUserLoggedIn &&
                this.$app.generalConfig().hospitalMode &&
                !this.$app.generalConfig().hospital.researchMode
            ) {
				list.push(this.menuItems.getItem("hospitalSessions"));
				list.push(this.menuItems.getItem("hospitalSessionHistory"));

				if (this.$app.generalConfig().hospital.enablePatientSearch) {
					list.push(this.menuItems.getItem("hospitalUsers"));
				}
            }*/

      if (!this.$app.generalConfig().hospitalMode) {
        if (this.$app.generalConfig().enableSpecialistsMenuLink) {
          list.push(this.menuItems.getItem("specialists"));
        }
        if (this.$app.generalConfig().enableGlossary) {
          list.push(this.menuItems.getItem("dictionary"));
          list.push(this.menuItems.getDivider());
        }
      }

      if (!this.isUserLoggedIn) {
        if (this.settingsIsVisible) {
          list.push(this.menuItems.getItem("settings"));
        }
      }

      if (!this.$app.generalConfig().header.drawer) {
        list.push(...this.drawerLinks);

        if (this.isUserLoggedIn) {
          list.push(this.menuItems.getDivider());
          list.push({
            id: "userIdentification",
            icon: "person",
            title: this.externUser.name,
            route: {}, //this.$app.generalConfig().hospitalMode ? RouteGenerator.getInstance().profileSettings() : RouteGenerator.getInstance().profile(),
            classes: "text-weight-bold",
          });
        }
      }

      list.push(...this.authLinks);

      if (!this.$app.generalConfig().header.drawer && this.isUserLoggedIn) {
        list.push(this.menuItems.getItem("logout"));
      }

      list = uniqBy(list, "id");

      if (this.$app.generalConfig().enableSpecialistListing) {
        list.unshift(this.menuItems.getItem("specialists"));
      }

      return list;
    },
    iconsSize() {
      return this.$app.generalConfig().header.iconsOnly ? "sm" : "xs";
    },
    settingsIsVisible() {
      return true; //return this.$route.name.match(/chatbot/);
    },
    dashboardRedirect() {
      if (!this.isUserLoggedIn) return false;

      return (
        this.app().generalConfig().dashboardRedirectEnabled &&
        this.app().generalConfig().dashboardEnabled &&
        this.externUser &&
        this.externUser.isPartner
      );
    },
    homeLink() {
      if (this.dashboardRedirect) {
        return RouteGenerator.getInstance().dashboard();
      }

      return RouteGenerator.getInstance().home();
    },
    certificateLink() {
      return RouteGenerator.getInstance().certificate();
    },
    drawerClasses() {
      return { "mdc-drawer--open": this.drawerIsVisible };
    },
    classes() {
      let definition = [];

      if (this.transparent) {
        definition.push("mdk-header-menu--transparent");
      }

      if (this.light) {
        definition.push("mdk-header-menu--light");
      }

      if (this.fixed) {
        definition.push("mdk-header-menu--fixed");
      }

      return definition;
    },
    profileTogglerIsVisible() {
      return (
        this.drawerIsEnabled && (this.isUserLoggedIn || this.shouldCollapse)
      );
    },
    userAvatarIsVisible() {
      return this.isUserLoggedIn;
    },
    shouldCollapse() {
      return this.viewportWidth < 900; //this.isTabletLayout;
    },
    drawerTopLinks() {
      let links = [];

      if (this.shouldCollapse) {
        links.push(this.menuItems.getItem("check"));
      }

      return links.filter((link) => Boolean(link.title));
    },
    authLinks() {
      let links = [];

      if (!this.isUserLoggedIn) {
        if (!this.$app.generalConfig().hospitalMode) {
          links.push(this.menuItems.getItem("login"));

          if (this.$app.generalConfig().register.enabled) {
            links.push(
              this.menuItems.getItem("register", {
                classes: {
                  filled: true,
                },
              }),
            );
          }
        }
      }

      return links;
    },
    drawerBottomLinks: function () {
      let links = [];

      links.push(...this.authLinks);

      if (this.$app.isUserLoggedIn()) {
        links.push(this.menuItems.getItem("logout"));
      }

      return links;
    },
    languageSelectorIsVisible() {
      return (
        this.$app.generalConfig().languageSelectorPosition === "top" &&
        !this.shouldCollapse
      );
    },
    languageSelectorInDrawerIsVisible() {
      return this.$app.generalConfig().languageSelectorInDrawer;
    },
    drawerIsEnabled() {
      return this.$app.generalConfig().header.drawer;
    },
    certificateTittleLink() {
      return this.localeText("tmk1932");
    },
    getDividerToHtml() {
      return this.menuItems.getDivider();
    },
    certificateIsVisible() {
      return this.$app.generalConfig().showCertificate;
    },
  },
  async created() {
    this.externUser = this.app().externUser;
    this.isUserLoggedIn = this.app().isUserLoggedIn();
    if (this.app().externUser) {
      this.userPermisssion = new UserPermission(this.app().externUser);
    }

    if (this.app().getGeneralConfigValue("chatDirectAccessEnabled")) {
      await this.setHasChats();
    }

    this.manageEvents();

    /*if(!this.$app.generalConfig().hospitalMode) {
            let response = await ExternUserBO.getInstance().fetchExternUserList({
                count: 1,
                offset: 0,
                ascending: true // TODO: si no se pasa este parametro, peta - Edu tiene que arreglarlo
            });

            if (response && response.externUserList) this.hasSpecialists = true;
        }*/
  },
};
