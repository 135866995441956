import template from "modules/core/components/interface/switch/switch.html?raw";
import "modules/core/components/interface/switch/switch.scss";
import { MDCSwitch } from "@material/switch";

import vue from "vue";

export default vue.extend({
    template,
    props: {
        onChange: {
            type: Function,
            required: true,
            default: (index: string) => {},
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        checked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            control: null as MDCSwitch | null,
        };
    },

    mounted() {
        this.setup();
    },

    methods: {
        setup() {
            this.control = new MDCSwitch(this.$refs.target as Element);
            this.control.disabled = this.disabled;
            this.control.checked = this.checked;
        },
        onTap() {
            this.onChange?.(this.control?.checked);
        },
    },

    computed: {
        classDefinition(): string[] | null {
            const definition = [];

            /* if(this.disabled){
                        definition.push("mdc-switch--disabled");
                    }

                    if(this.checked){
                        definition.push("mdc-switch--checked");
                    } */
            return null;
        },
    },
});
