// TODO: revisar typings!
// @ts-ignore
import expirePlugin from "store/plugins/expire";
import _isObject from "lodash/isObject";
import _merge from "lodash/merge";
import moment from "moment";
// @ts-ignore
import storeEngine from "store/src/store-engine";
// @ts-ignore
import json2Plugin from "store/plugins/json2";
// @ts-ignore
import localStorage from "store/storages/localStorage";
// @ts-ignore
import globalStorage from "store/storages/oldFF-globalStorage";
// @ts-ignore
import userDataStorage from "store/storages/oldIE-userDataStorage";
// @ts-ignore
import cookieStorage from "store/storages/cookieStorage";
// @ts-ignore
import sessionStorage from "store/storages/sessionStorage";
// @ts-ignore
import memoryStorage from "store/storages/memoryStorage";

import Store from "store";

type StoreTypes = "local" | "session";

export const prefix = "mdk-";

export default class Storage {
    static instance?: Storage;

    name: string;

    expires: number | null;

    store: any;

    constructor(name?: string, expires: number | null = 24 * 3 /* in hours */) {
        this.name = prefix + name;
        this.expires = expires;
        this.store = this.localStorage(); // Set localstorage by default
    }

    static getInstance(): Storage {
        if (!Storage.instance) {
            Storage.instance = new this(); // TODO: check what happens if no name is passed
        }

        return Storage.instance;
    }

    localStorage(): typeof Store {
        return storeEngine.createStore(
            [
                localStorage,
                globalStorage,
                userDataStorage,
                cookieStorage,
                sessionStorage,
                memoryStorage,
            ],
            [expirePlugin, json2Plugin],
        );
    }

    // FIXME
    sessionStorage(): any {
        return storeEngine.createStore(
            [sessionStorage, memoryStorage],
            [expirePlugin, json2Plugin],
        );
    }

    setStorageType(type: StoreTypes) {
        if (type === "local") {
            this.store = this.localStorage();
        } else if (type === "session") {
            this.store = this.sessionStorage();
        }
    }

    static getRelativeDateFromHours(timeInHours: number): number {
        return moment().add(timeInHours, "hours").valueOf();
    }

    setItem(name: string, value: any, expires?: number) {
        // return await localforage.setItem(name, value);
        if (expires) {
            this.store.set(
                name,
                value,
                Storage.getRelativeDateFromHours(expires),
            );
        } else {
            this.store.set(name, value);
        }
    }

    getItem(name: string): any {
        // return await localforage.getItem(name);
        return this.store.get(name);
    }

    exists(): boolean {
        return typeof this.getItem(this.name) !== "undefined";
    }

    save(
        dataOrName: Record<string, any> | string,
        value?: any,
        expiringHours?: number,
    ) {
        const exp = expiringHours || this.expires;
        let storageObject = this.getItem(this.name);

        if (_isObject(dataOrName)) {
            storageObject = _merge(storageObject, dataOrName);
        } else {
            if (storageObject === undefined) storageObject = {};

            storageObject[dataOrName] = value;
        }

        this.setItem(this.name, storageObject, exp || undefined);
    }

    removeByName(name: string): void | null {
        const storageObject = this.getItem(this.name);

        if (typeof storageObject === "undefined") return null;

        delete storageObject[name];

        this.save(storageObject);
    }

    clean() {
        this.store.remove(this.name);
    }

    getByName(name: string): any {
        const cookie = this.getItem(this.name);

        if (!this.exists()) return null;

        return cookie[name] !== undefined ? cookie[name] : null;
    }

    getAll(): any[] {
        return this.getItem(this.name);
    }

    clearAll() {
        return this.store.clearAll();
    }
}
