import EventBusSingleton from "modules/core/lib/EventBusSingleton";
import EnvironmentConfig from "modules/core/lib/EnvironmentConfig";
import App from "modules/core/lib/App";
import appConfig from "modules/core/config/app";
import { AllowedActions } from "modules/core/lib/Deeplinking";
import { ExternalAppConfig } from "../modules/core/types/misc";
import services from "./services";

/**
 * @deprecated
 */
export function exposeGlobalMethods(app: App) {
    if (EnvironmentConfig.getValue("APP_ENV") !== "pro") {
        try {
            window.mdkGlobal = {
                eventBus: EventBusSingleton,
                config: EnvironmentConfig,
                app,
            };
        } catch (err) {
            console.log(err);
        }
    }

    window.startApp = async () => {
        await app.start();
    };

    window.generalConfig = () => {
        app.generalConfig();
    };

    // window.startApp = async () => {
    //     await app.start();
    // };

    // @ts-ignore
    window.openView = async (
        name: AllowedActions,
        query: Record<string, any> = {},
    ) => {
        // @ts-ignore
        await app.openView(name, query);
    };

    // @ts-ignore
    window.replaceView = async (
        name: AllowedActions,
        query: Record<string, any> = {},
    ) => {
        await app.replaceView(name, query);
    };

    window.confirmSyncEvent = app.confirmSyncEvent;

    // @ts-ignore
    window.respondToSyncEvent = app.respondToSyncEvent;

    window.setAppConfig = (config: ExternalAppConfig) => {
        if (config.cssVars !== undefined) {
            app.setGlobalStyles(config.cssVars);
        }

        if (config.config !== undefined) {
            const collection = appConfig as Record<string, any>;
            const cfg = config.config as Record<string, any>;

            for (const prop in cfg) {
                if (typeof cfg[prop] === "object") {
                    const innerObject = cfg[prop] as Record<string, any>;

                    for (const innerObjectProp in innerObject) {
                        collection[prop][innerObjectProp] =
                            innerObject[innerObjectProp];
                    }
                } else {
                    collection[prop] = cfg[prop];
                }
            }
        }

        if (config.environment !== undefined) {
            app.setEnvironment(config.environment);
        }
    };

    window.shareLocation = (locations: GeolocationCoordinates[]) => {
        app.shareLocation(locations);
    };

    window.changeDarkMode = (value: boolean) => {
        app.changeDarkMode(value);
    };

    window.changeLanguage = async (language: string) => {
        await app.changeGlobalLanguage(language);
    };

    // @ts-ignore
    window.getTMKs = (ids: string[]) => {
        return ids.reduce(
            (acc, current) => {
                acc[current] = app.localeText(current);
                return acc;
            },
            {} as Record<string, string>,
        );
    };

    window.services = services;
}
