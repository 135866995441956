import mitt from "mitt";

export type EventNames =
    | "app:loaded"
    | "app:ready"
    | "app:language_changed"
    | "app:theme_changed"
    | "app:localization_requested"
    | "user:logout"
    | "user:logout_done"
    | "user:login_form_submit"
    | "user:register_form_submit"
    | "user:profile_sounds_toggle"
    | "user:profile_units_updated"
    | "user:profile_updated"
    | "page:loaded"
    | "page:redirection"
    | "auth:auth_required"
    | "auth:token_expired"
    | "auth:token_renewed"
    | "network:request_error"
    | "socket:message_received"
    | "session:offering_action"
    | "session:profile_enrichment_closed"
    | "session:from_activity_closed"
    | "session:closed"
    | "session:finish"
    | "session:location_question"
    | "chat:conversation_selected";

// type PublicEvent<Name extends EventNames, Payload = undefined> = {
//     name: Name;
//     payload: Payload;
// };
type PublicEvent<
    Name extends EventNames,
    Payload = undefined,
> = Payload extends undefined
    ? {
          name: Name;
          payload?: undefined;
      }
    : {
          name: Name;
          payload: Payload;
      };

export type PublicEvents =
    | PublicEvent<"app:loaded">
    | PublicEvent<"app:ready">
    | PublicEvent<"app:language_changed", { language: string }>
    | PublicEvent<"app:theme_changed", { theme: "light" | "dark" }>
    | PublicEvent<"app:localization_requested">
    | PublicEvent<"user:logout">
    | PublicEvent<"user:logout_done">
    | PublicEvent<"user:login_form_submit">
    | PublicEvent<"user:register_form_submit">
    | PublicEvent<"user:profile_sounds_toggle", { sounds: boolean }>
    | PublicEvent<"user:profile_units_updated", { units: string }>
    | PublicEvent<"user:profile_updated">
    | PublicEvent<"page:loaded", { name: string; url: string; title: string }>
    | PublicEvent<"page:redirection", { from: string; to: string }>
    | PublicEvent<"auth:auth_required", { route: string }>
    | PublicEvent<"auth:token_expired">
    | PublicEvent<"auth:token_renewed">
    | PublicEvent<
          "network:request_error",
          { service: string; code: string; description: string }
      >
    | PublicEvent<
          "socket:message_received",
          { chatId: string; type: string; message: string }
      >
    | PublicEvent<"session:offering_action", { id: string; url: string }>
    | PublicEvent<"session:profile_enrichment_closed">
    | PublicEvent<"session:closed">
    | PublicEvent<"session:finish", { sessionId: string; userId: string }>
    | PublicEvent<"session:location_question">
    | PublicEvent<"session:from_activity_closed">
    | PublicEvent<"chat:conversation_selected", { id: string }>;

type PublicEventChannel = {
    [key in EventNames]: Extract<PublicEvents, { name: key }>;
};

export const emitter = mitt<PublicEventChannel>();

export const dispatch = (event: PublicEvents) => {
    emitter.emit(event.name, event);
};

export const subscribe = <T extends EventNames>(
    event: T,
    handler: Parameters<typeof emitter.on<T>>[1],
) => {
    emitter.on(event, handler);
};

export const unsubscribe = <T extends EventNames>(
    event: T,
    handler: Parameters<typeof emitter.on<T>>[1],
) => {
    emitter.off(event, handler);
};

// X "appLoaded",  --> app:loaded
// X "appReady", --> app:ready
// X "pageLoaded", --> page:loaded
// X "pageRedirection", --> page:redirection
// X "logout", --> user:logout
// X "languageChange", --> app:language_changed
// X "darkModeChange", --> app:theme_changed
// X "elementAction", --> chat:conversation_selected // same as chatConversationListItemSelectChat
// X "requestError", --> network:request_error
// X "authenticationRequired", --> auth:auth_required
// X "authTokenRefreshExpired", --> auth:token_expired
// X "authTokenRenewed", --> auth:token_renewed
// "sendPageAnalytics", -->  // this will be implemented in analytics channel // only used onece in checker to send "PreDiagnostico" and when calling manage origin in App.ts
// "trackCustomEvents", --> // idem
// "initAnalytics", -->  // idem
// X "loginSubmit", --> user:login_form_submit
// X "registerSubmit", --> user:register_form_submit
// X "userProfileSoundsToggle", --> user:profile_sounds_toggle
// X "userProfileUnitsSave", --> user:profile_units_updated
// X "userProfileEditDataChange", --> user:profile_updated
// X "locationRequest", --> app:localization_requested
// X "sessionOfferingAction", --> session:offering_action
// X "message", --> socket:message_received
// X "sessionClosed", --> session:closed
// X "sessionFromActivityClosed", --> session:from_activity_closed
// X "profileEnrichmentSessionClosed", --> session:profile_enrichment_closed
// "chatConversationListItemSelectChat", --> chat:conversation_selected
