import template from "./content-header-title.html?raw";
import "./content-header-title.scss";
import SvgIcon from "../svg-icon/SvgIcon";
import UIIcon from "../icon/Icon";
import iconBullet from "app/static/img/logo-symbol.svg?raw";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

type categoryRange = 1 | 2 | 3 | 4;

export default mixins(CoreComponentMixin).extend({
    template,
    components: {
        UIIcon,
        "mdk-svg-icon": SvgIcon,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        decorator: {
            type: Boolean,
            required: false,
            default: true,
        },
        category: {
            type: Number as () => categoryRange,
            required: false,
            default: 1,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        ellipsis: {
            type: Boolean,
            required: false,
            default: false,
        },
        isRightAction: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        mainClassName(): string {
            return "mdk-ui-content-header-title";
        },
        containerClass(): Record<string, boolean> {
            const classes: Record<string, boolean> = {};
            classes[`${this.mainClassName}`] = true;

            if (this.decorator) {
                classes[`${this.mainClassName}--category1`] =
                    this.category === 1;
                classes[`${this.mainClassName}--category2`] =
                    this.category === 2;
                classes[`${this.mainClassName}--category3`] =
                    this.category === 3;
                classes[`${this.mainClassName}--category4`] =
                    this.category === 4;
            }
            if (this.ellipsis) {
                classes[`${this.mainClassName}__ellispsis`] = true;
            }

            return classes;
        },
        iconIsVisible(): boolean {
            return this.icon !== null && this.category === 1 && this.decorator;
        },
        iconBullet(): string {
            if (!this.icon && this.category === 2 && this.decorator)
                return iconBullet;
            return "";
        },
    },
});
