import vue from "vue";
import Ui from "modules/core/lib/Ui";
import Util from "../../lib/Util";

export const LayoutMixin = vue.extend({
    data() {
        return {
            layoutBreakpoint: undefined as string | undefined,
            viewportWidth: undefined as number | undefined,
        };
    },
    computed: {
        isPhoneLayout(): boolean {
            return this.layoutBreakpoint == "phone";
        },

        isTabletLayout(): boolean {
            return this.layoutBreakpoint == "tablet";
        },

        isDesktopLayout(): boolean {
            return (
                this.layoutBreakpoint == "desktop" ||
                this.layoutBreakpoint == "bigDesktop"
            );
        },

        isBigDesktopLayout(): boolean {
            return this.layoutBreakpoint == "bigDesktop";
        },

        isMobileLayout(): boolean {
            return this.isPhoneLayout || this.isTabletLayout;
        },

        isTouchDevice(): boolean {
            return Ui.isTouchDevice();
        },
    },
    methods: {
        manageBreakpoints() {
            Util.layoutBreakpoint(
                "phone",
                () => {
                    this.layoutBreakpoint = "phone";
                },
                () => {
                    this.layoutBreakpoint = undefined;
                },
            );

            Util.layoutBreakpoint(
                "tablet",
                () => {
                    this.layoutBreakpoint = "tablet";
                },
                () => {
                    this.layoutBreakpoint = "phone";
                },
            );

            Util.layoutBreakpoint(
                "desktop",
                () => {
                    this.layoutBreakpoint = "desktop";
                },
                () => {
                    this.layoutBreakpoint = "tablet";
                },
            );
            Util.layoutBreakpoint(
                "bigDesktop",
                () => {
                    this.layoutBreakpoint = "bigDesktop";
                },
                () => {
                    this.layoutBreakpoint = "desktop";
                },
            );
        },

        manageWindowSize() {
            if (!this.enableWindowResizeDetection()) return;

            window.onresize = (e: UIEvent) => {
                this.setViewportWidth();
            };
        },

        setViewportWidth() {
            this.viewportWidth = document.documentElement.clientWidth;
        },

        enableWindowResizeDetection(): boolean {
            return false;
        },
    },
    mounted() {
        this.manageBreakpoints();
        this.manageWindowSize();
        this.setViewportWidth();
    },
});
