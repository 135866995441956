import _merge from "lodash/merge";
import GenericRepo from "./GenericRepo";
import PrescriptionDAO from "../dao/PrescriptionDAO";
import PrescriptionRemoteDAO from "../dao/PrescriptionRemoteDAO";

export default class PrescriptionRepo extends GenericRepo {
  constructor() {
    super();

    this.dao = new PrescriptionDAO();
    this.remoteDao = new PrescriptionRemoteDAO();
    this.storageName = "prescriptions";
  }

  static getInstance() {
    if (!PrescriptionRepo.instance) {
      PrescriptionRepo.instance = new this();
    }

    return PrescriptionRepo.instance;
  }

  async fetch(offset, count, maxDate = null, params = {}) {
    return await this.remoteDao.fetchExternUserPrescriptionList(
      offset,
      count,
      _merge(params, { maxDate }),
    );
  }

  async fetchPending(offset, count, sinceDate, maxDate, useLastEdited = true) {
    return await this.remoteDao.fetchExternUserPrescriptionList(null, null, {
      sinceDate,
      maxDate,
      useLastEdited,
      offset,
      count,
    });
  }
}
