import _includes from "lodash/includes";
import { ExternUserVO } from "modules/core/model/externUserVO";
import PermissionProfileEnum from "modules/core/enums/PermissionProfileEnum";

// Ported from Hospital
export const hospitalUserPermissions: Record<string, number[]> = {
    "patientEditMedicalData|patientHistory|evaluator|sessionDetail|sessionDetailEditable|sessionValidate|modifyConclusions":
        [
            PermissionProfileEnum.SUPER,
            PermissionProfileEnum.PHYSICIAN,
            PermissionProfileEnum.NURSE,
        ],
    "adminUsers|adminCreateUser|adminEditUser|adminUserProfile": [
        PermissionProfileEnum.SUPER,
        PermissionProfileEnum.ADMINISTRATOR,
    ],
    "patientCreate|patientEdit|patientSearch": [
        PermissionProfileEnum.SUPER,
        PermissionProfileEnum.ADMINISTRATOR,
        PermissionProfileEnum.PHYSICIAN,
        PermissionProfileEnum.NURSE,
        PermissionProfileEnum.CASHIER,
        PermissionProfileEnum.HCA,
    ],
    "sessionsHistory|sessionCreate": [
        PermissionProfileEnum.SUPER,
        PermissionProfileEnum.PHYSICIAN,
        PermissionProfileEnum.NURSE,
        PermissionProfileEnum.CASHIER,
    ],
    dischargePatient: [
        PermissionProfileEnum.SUPER,
        PermissionProfileEnum.PHYSICIAN,
    ],
    patientProfile: [
        PermissionProfileEnum.SUPER,
        PermissionProfileEnum.ADMINISTRATOR,
        PermissionProfileEnum.PHYSICIAN,
        PermissionProfileEnum.NURSE,
        PermissionProfileEnum.CASHIER,
    ],
    history: [
        PermissionProfileEnum.SUPER,
        PermissionProfileEnum.PHYSICIAN,
        PermissionProfileEnum.NURSE,
        PermissionProfileEnum.CASHIER,
        PermissionProfileEnum.HCA,
    ],
    // "evaluator": [PermissionProfileEnum.SUPER, PermissionProfileEnum.NURSE, PermissionProfileEnum.PHYSICIAN],
    // "patientProfile": [PermissionProfileEnum.SUPER, PermissionProfileEnum.HCA, PermissionProfileEnum.CASHIER, PermissionProfileEnum.NURSE, PermissionProfileEnum.PHYSICIAN],
    // "patientHistory": [PermissionProfileEnum.SUPER, PermissionProfileEnum.NURSE, PermissionProfileEnum.PHYSICIAN],
    // "sessionDetail|sessionDetailEditable": [PermissionProfileEnum.SUPER, PermissionProfileEnum.NURSE, PermissionProfileEnum.PHYSICIAN],
    // "sessionCreate": [PermissionProfileEnum.SUPER, PermissionProfileEnum.PHYSICIAN, PermissionProfileEnum.NURSE, PermissionProfileEnum.CASHIER],
};

export class UserPermission {
    private readonly externUser: ExternUserVO;

    constructor(externUser: ExternUserVO) {
        this.externUser = externUser;
    }

    public userHospitalCanDo(actionName: string): boolean {
        if (this.externUser.permissionProfile === undefined) return false;

        const userPermission = this.externUser.permissionProfile;

        for (const actionDefinition in hospitalUserPermissions) {
            const actions = actionDefinition.split("|");
            const permissionEnums = hospitalUserPermissions[
                actionDefinition
            ].map((perm: any) => perm.value);

            if (
                _includes(actions, actionName) &&
                _includes(permissionEnums, userPermission)
            ) {
                return true;
            }
        }

        return false;
    }

    public isAdmin(): boolean {
        return this.hasPermissionForProfile(
            PermissionProfileEnum.ADMINISTRATOR.value,
        );
    }

    public hasPermissionForProfile(profile: number): boolean {
        if (this.externUser && this.externUser.permissions) {
            return ((1 << profile) & this.externUser.permissions) > 0;
        }

        return false;
    }

    public hasPermissionForProfiles(profiles: number[]): boolean {
        let finalValue = false;

        profiles.forEach(
            (profile) =>
                (finalValue =
                    finalValue || this.hasPermissionForProfile(profile)),
        );

        return finalValue;
    }

    public isHospitalUser(): boolean {
        if (this.externUser.permissionProfile === undefined) return false;

        return _includes(
            PermissionProfileEnum.HOSPITAL_USERS.value,
            this.externUser.permissionProfile,
        );
    }
}
