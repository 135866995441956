import template from "modules/core/components/interface/menu/menu.html?raw";
import "modules/core/components/interface/menu/menu.scss";
import { MDCMenu } from "@material/menu"; // @material/menu/dist/mdc.menu
import UIButton from "modules/core/components/interface/button/Button";
import CoreComponent from "modules/core/mixins/CoreComponent";

export default {
  template,
  mixins: [CoreComponent],
  data() {
    return {
      instance: null,
      selected: null,
    };
  },

  components: {
    "mdk-ui-button": UIButton,
  },

  props: {
    items: {
      type: Array, // [{id:String, name:String, leading:String}]
      required: false,
      default: () => [],
    },
    onItemTap: {
      type: Function,
      required: false,
      default: (item) => {},
    },
    defaultSelected: {
      type: String,
      required: false,
      default: null,
    },
    customMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    sameWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmationButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    addMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
    addFromBottom: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconPrimaryColor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    setup() {
      try {
        this.instance = new MDCMenu(this.$refs.menu);

        // this.instance.hoistMenuToBody();
        // this.instance.setIsHoisted(true);
        // this.instance.setAbsolutePosition(0, 36);
        this.instance.listen("MDCMenu:selected", (item) => {
          this.itemClick(this.items[item.detail.index]);
        });
        this.$el.firstElementChild.addEventListener("click", (e) => {
          this.instance.open = !this.instance.open;
        });
        /* const options = this.$refs.menu.firstElementChild.querySelectorAll('.mdc-list-item');
      for (let option of options) {
        option.addEventListener("click", event => {
          console.log(event);
          event.stopImmediatePropagation();
          // this.itemClick(event);
        });
      } */
      } catch (error) {
        console.info({ error });
      }
    },
    openMenu() {
      if (this.instance != null) {
        this.instance.open = true;
      }
    },
    itemClick(item) {
      this.selected = item.id;
      this.onItemTap(item);
    },
    itemClass(item) {
      const definition = [];

      if (item.id == this.selected) {
        definition.push("mdc-list-item--selected");
      }

      return definition;
    },
  },

  computed: {
    classDefinition() {
      const definition = {};
      definition["mdk-ui-menu--with-margin"] = this.addMargin;
      definition["mdk-ui-menu--from-bottom"] = this.addFromBottom;

      return definition;
    },
    classDefinitionMenu() {
      return {
        "mdk-ui-menu--width": this.isDesktopLayout && this.customMenu,
      };
    },
    iconClasses() {
      return {
        "mdk-ui-menu__icon--primary": this.iconPrimaryColor,
      };
    },
  },

  mounted() {
    this.setup();
    this.catchEvent("close-menu", () => {
      this.instance.open = false;
    });
    this.selected = this.defaultSelected;
  },
};
