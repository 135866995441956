import _merge from "lodash/merge";
import BusinessAbstract from "./BusinessAbstract";

export default class ClientLinkBO extends BusinessAbstract {
    static instance?: ClientLinkBO;

    static getInstance(): ClientLinkBO {
        if (!ClientLinkBO.instance) {
            ClientLinkBO.instance = new this();
        }

        return ClientLinkBO.instance;
    }

    async fetchResolveClient(params: Record<string, any> = {}): Promise<any> {
        return this.api.service("marketplace/resolveClient", params);
    }

    async fetchClientLinkInfo(params: Record<string, any> = {}): Promise<any> {
        return this.api.service("marketplace/clientLinkInfo", params);
    }

    async fetchClientLinkModify(
        params: Record<string, any> = {},
    ): Promise<any> {
        return this.api.service("marketplace/clientLinkModify", params);
    }

    async fetchClientLinkList(params: Record<string, any> = {}): Promise<any> {
        return this.api.service("marketplace/clientLinkList", params);
    }
}
