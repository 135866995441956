import Utils from "modules/core/lib/Util";
import MessagesDAO from "modules/core/dao/MessagesDAO";
import MessagesRepo from "modules/core/repos/MessagesRepo";
import BusinessAbstract from "./BusinessAbstract";

export default class MessagesBO extends BusinessAbstract {
  constructor() {
    super();
    this.dao = new MessagesDAO();
  }

  static getInstance() {
    if (!MessagesBO.instance) {
      MessagesBO.instance = new this();
    }

    return MessagesBO.instance;
  }
  // params = {}, onlyReadable = true, onlyVisibles = true, onlyUnread = false
  // offset, count, groupId, params = {}
  //

  async fetchMessages(
    offset,
    count,
    groupId = null,
    params = { onlyVisibles: true, onlyReadable: true },
  ) {
    return await MessagesRepo.getInstance().getMessageList(
      offset,
      count,
      groupId,
      params,
    );
  }

  async fetchMessage(params = {}) {
    return await MessagesRepo.getInstance().fetchMessage(params);
  }

  getUnreadMessages() {
    return MessagesRepo.getInstance().getUnreadMessages();
  }

  async fetchUnreadMessages(groupId = null, params = {}, iteration = false) {
    return await MessagesRepo.getInstance().fetchUnreadMessages(
      groupId,
      { onlyUnread: true, onlyVisibles: true, onlyReadable: true },
      true,
    );
  }

  async restartEvaluation(groupId, sourceId) {
    return await MessagesRepo.getInstance().restartEvaluation(
      groupId,
      sourceId,
    );
  }

  async sendMessage(params = {}) {
    return await MessagesRepo.getInstance().sendMessage(params);
  }

  addOrUpdateMessage(message) {
    return MessagesRepo.getInstance().addOrUpdateMessage(message);
  }

  async readMessages(messages = []) {
    return await MessagesRepo.getInstance().readMessages(messages);
  }

  async fetchExternUserWriting(externUserGroupId, isWriting) {
    return await this.socket.sendServiceViaSocketWithPromise(
      "externUserWriting",
      { externUserGroupId, isWriting },
    );
  }

  async getOrFetchLastMessage(groupId) {
    const response = await this.fetchMessages(0, 1, groupId, {
      onlyReadable: true,
      onlyVisibles: true,
      onlyUnread: false,
    });
    if (response !== undefined) return response[response.length - 1];

    return response;
  }

  /* async getOrFetchRelations(data = {}, externUserId, iteration = true) {
        let response = await this.fetchRelations(data, true, false, false);
        if (response !== undefined && "relations" in response) {
            if (iteration) {

            }
            return this.getGroupIds()
        }
    } */

  async readMessage(message = {}, isRead = false) {
    return await MessagesRepo.getInstance().readMessage(message, isRead);
  }

  getUnreadMessagesByGroupId(groupId) {
    return MessagesRepo.getInstance().getUnreadMessagesByGroupId(groupId);
  }

  async fetchLanguageCode(groupId, sourceId) {
    const data = {};
    data.message = this.createMessage(
      "LANGUAGE_CHANGE",
      sourceId,
      groupId,
      "CHATBOT_COMMAND",
      false,
    );

    return await MessagesRepo.getInstance().fetchLanguageCode(data);
  }

  getLastMessageByGroupId(groupId) {
    return MessagesRepo.getInstance().getLastMessageByGroupId(groupId);
  }

  getMessageById(messageId) {
    return MessagesRepo.getInstance().getMessageById(messageId);
  }

  addMessage(message) {
    return MessagesRepo.getInstance().addMessage(message);
  }

  async getOrFetchMessages(params = {}) {
    let messages = this.getMessageListByGroupId(
      params.groupId,
      params.offset,
      params.count,
    );
    if (messages === null || !messages.length) {
      const response = await this.fetchMessages(params);
      messages = response;
    }

    return messages;
  }

  getExternUserGroupIdsOrderedByLastMessage(externUserGroupIds = []) {
    return MessagesRepo.getInstance().getExternUserGroupIdsOrderedByLastMessage(
      externUserGroupIds,
    );
  }

  async sendRelationRequestMessage(sourceId, productId, externUser, groupId) {
    const body = {
      relation: {
        productId,
        myDescription: "Chatear con %FULLNAME%",
        externUserGroupMembers: [
          {
            externUserId: externUser.externUserId,
            externUser: {
              externUserId: externUser.externUserId,
              fullName: externUser.fullName,
              imageFace: externUser.imageFace,
            },
          },
        ],
      },
    };
    const data = {};
    data.message = this.createMessage(
      JSON.stringify(body),
      sourceId,
      groupId,
      "RELATION",
    );
    const response = await this.sendMessage(data);
    if (response !== undefined) {
      return response;
    }
  }

  async cancelSelectionExternUserList(sourceId, groupId) {
    const body = JSON.stringify({
      relation: {
        productId: null,
        myDescription: "Cancelar compra",
        externUserGroupMembers: [],
      },
    });
    const data = {};
    data.message = this.createMessage(
      body,
      sourceId,
      groupId,
      "RELATION",
      true,
    );
    const response = await this.sendMessage(data);

    if (response !== undefined) {
      return response;
    }
  }

  createMessage(body, sourceId, groupId, type = "CHAT", isVisible = true) {
    return {
      body,
      sourceId,
      groupId,
      type,
      isVisible,
      messageId: Utils.uniqueId(),
      sourceDate: new Date().getTime(),
      deliveryDate: new Date().getTime(),
      isRead: true,
      isReceived: true,
    };
  }

  getAllMyUnreadMessageCount(sourceId) {
    return this.dao.getAllMyUnreadMessageCount(sourceId);
  }

  getMessageListByGroupId(groupId, offset, count) {
    return MessagesRepo.getInstance().getMessageListByGroupId(
      groupId,
      offset,
      count,
    );
  }

  getNumberOfMessagesByGroupId(groupId) {
    return MessagesRepo.getInstance().getNumberOfMessagesByGroupId(groupId);
  }

  getLastMessageByGroupIdAndExternUserId(groupId, externUserId) {
    return this.dao.getLastMessageByGroupIdAndExternUserId(
      groupId,
      externUserId,
    );
  }
}
