import template from "modules/core/components/interface/carrousel/carrousel.html?raw";
import "modules/core/components/interface/carrousel/carrousel.scss";
import Siema from "siema";
import UI from "modules/core/lib/Ui";

import vue from "vue";

type ImageCarrousel = {
    url: string;
    copyright?: string;
};

type ReviewCarrousel = {
    description: string;
    footer: string;
};

export default vue.extend({
    template,
    components: {
        "mdk-button": () =>
            import("modules/core/components/interface/button/Button"),
    },
    props: {
        navigationButtons: {
            type: Boolean,
            required: false,
            default: true,
        },
        images: {
            type: Array,
            required: false,
            default: (): Array<ImageCarrousel> => [],
        },
        reviews: {
            type: Array,
            required: false,
            default: (): Array<ReviewCarrousel> => [],
        },
    },
    data() {
        return {
            instance: null as Siema | null,
            prevIsVisible: true,
            nextIsVisible: true,
            activeReview: 0,
        };
    },
    computed: {
        hasImages(): boolean {
            return this.images.length > 0;
        },
    },
    methods: {
        setup() {
            const self = this;
            this.$nextTick(() => {
                if (
                    typeof this.$refs.carrousel !== "undefined" &&
                    !!this.$el.parentElement
                ) {
                    (this.$el as HTMLElement).style.width =
                        `${this.$el.parentElement.offsetWidth}px`;
                    this.instance = new Siema({
                        selector: this.$refs.carrousel as HTMLElement,
                        draggable: true,
                        easing: "ease-out",
                        loop: true,
                        perPage: 1,
                        duration: 500,
                        startIndex: 0,
                        onInit() {},
                        rtl: false,
                        onChange() {
                            self.onChange();
                        },
                    });
                }
            });
            setInterval(() => {
                this.next();
            }, 4000);
        },
        destroyPlugin() {
            if (this.instance != null) {
                this.instance.destroy();
            }
        },
        onChange() {
            this.manageNavigationVisibility();
        },
        prev() {
            if (this.instance != null) {
                this.instance.prev();
            }
        },
        next() {
            if (this.instance != null) {
                this.instance.next();
            }
        },
        manageNavigationVisibility() {
            this.$nextTick(() => {
                if (this.instance != null) {
                    this.prevIsVisible =
                        this.images.length > 0 &&
                        this.instance.currentSlide > 0;
                    this.nextIsVisible =
                        this.images.length > 0 &&
                        this.instance.currentSlide < this.images.length - 1;
                    this.activeReview = this.instance.currentSlide;
                }
            });
        },
        showImage(image: ImageCarrousel) {
            const copyright = "copyright" in image ? image.copyright : null;
            UI.showImageViewer(image.url, undefined, copyright || undefined);
        },
        changeReview(index: number) {
            if (this.instance != null) {
                this.instance.goTo(index);
                this.activeReview = index;
            }
        },
        getDefinitionClass(index: number): Record<string, boolean> {
            return {
                "mdk-ui-carrousel__navigation-circles__circle--active":
                    index === this.activeReview,
            };
        },
    },
    mounted() {
        this.setup();
        this.manageNavigationVisibility();
        window.onresize = () => {
            this.destroyPlugin();
        };
    },
    destroyed() {
        this.destroyPlugin();
    },
});
