import _includes from "lodash/includes";
import localforage from "localforage";
import _findIndex from "lodash/findIndex";
import memoryStorageDriver from "localforage-memoryStorageDriver";
import app from "modules/core/config/app";
import { ClientLinkVO } from "modules/core/types/misc";
import Storage, { prefix } from "./Storage";
import SessionRepo from "../repos/SessionRepo";
import ExternUserGroupRepo from "../repos/ExternUserGroupRepo";
import ExternUserRepo from "../repos/ExternUserRepo";
import MessagesRepo from "../repos/MessagesRepo";
import PrescriptionRepo from "../repos/PrescriptionRepo";
import ValuationListRepo from "../repos/ValuationListRepo";
import { Application } from "./Holder";

export default class AppStorageManager {
    static instance?: AppStorageManager;

    suffix?: string;

    sessionStorage!: Storage;

    contentQuerySessionStorage!: Storage;

    miscStorage!: Storage;

    constructor() {
        this.setupStorages();
    }

    static getInstance(): AppStorageManager {
        if (!AppStorageManager.instance) {
            AppStorageManager.instance = new this();
        }

        return AppStorageManager.instance;
    }

    setupStorages() {
        this.sessionStorage = new Storage("app", null);
        this.sessionStorage.setStorageType("session");
        this.contentQuerySessionStorage = new Storage("contentQuery");
        this.contentQuerySessionStorage.setStorageType("session");
        this.miscStorage = new Storage(
            `misc${this.suffix ? `_${this.suffix}` : ""}`,
        );

        if (
            !Application.getInstance().isUserRegistered() &&
            Application.getInstance().generalConfig().autodestroyMode
        ) {
            this.miscStorage.setStorageType("session");
        }
    }

    getOrigin(): string {
        return this.sessionStorage?.getByName("origin");
    }

    saveOrigin(origin: string) {
        this.sessionStorage.save("origin", origin);
    }

    getDeviceId(): string {
        return this.miscStorage.getByName("deviceId");
    }

    saveDeviceId(deviceId: string) {
        this.miscStorage.save("deviceId", deviceId);
    }

    getEpicMode(): number {
        return this.miscStorage.getByName("epicMode");
    }

    saveSessionItem<T = any>(name: string, data: T) {
        this.sessionStorage.save(name, data);
    }

    getSessionItem<T = any>(name: string): T {
        return this.sessionStorage.getByName(name);
    }

    saveClientLink(clientLink: ClientLinkVO) {
        const clientLinkList = this.getClientLinkList() || [];
        const index = _findIndex(clientLinkList, {
            clientId: clientLink.clientId,
        });
        if (index < 0) clientLinkList.push(clientLink);

        this.miscStorage.save("clientLinkList", clientLinkList);
    }

    getClientLinkList(): ClientLinkVO[] {
        return this.miscStorage.getByName("clientLinkList") || [];
    }

    clearClientLinkList() {
        this.miscStorage.removeByName("clientLinkList");
    }

    saveEpicMode(epicMode: number) {
        this.miscStorage.save("epicMode", epicMode);
    }

    getDeviceSessionId(): string {
        return this.miscStorage.getByName("deviceSessionId");
    }

    saveDeviceSessionId(deviceSessionId: string) {
        this.miscStorage.save("deviceSessionId", deviceSessionId);
    }

    getCookies(): boolean {
        return this.miscStorage.getByName("cookies");
    }

    saveCookies(cookies: boolean) {
        this.miscStorage.save("cookies", cookies);
    }

    getIsSoundsEnabled(): boolean {
        return this.miscStorage.getByName("isSoundsEnabled");
    }

    saveIsSoundsEnabled(isSoundsEnabled: boolean) {
        this.miscStorage.save("isSoundsEnabled", isSoundsEnabled);
    }

    getIsLocationEnabled(): boolean {
        return this.miscStorage.getByName("isLocationEnabled");
    }

    saveIsLocationEnabled(isLocationEnabled: boolean) {
        this.miscStorage.save("isLocationEnabled", isLocationEnabled);
    }

    getIsDarkMode(): boolean {
        return this.miscStorage.getByName("isDarkMode");
    }

    saveIsDarkMode(isDarkMode: boolean) {
        this.miscStorage.save("isDarkMode", isDarkMode);
    }

    getLanguage(): string {
        return this.miscStorage.getByName("language");
    }

    saveLanguage(language: string) {
        this.miscStorage.save("language", language);
    }

    getBuildId(): string {
        return this.miscStorage.getByName("buildId");
    }

    saveBuildId(buildId: string) {
        this.miscStorage.save("buildId", buildId);
    }

    getInteractionMode(): number {
        return this.miscStorage.getByName("interactionMode");
    }

    saveInteractionMode(interactionMode: number) {
        this.miscStorage.save("interactionMode", interactionMode);
    }

    getIsAppContent(): boolean {
        return this.contentQuerySessionStorage.getByName("isAppContent");
    }

    saveIsAppContent(isAppContent: boolean) {
        this.contentQuerySessionStorage.save("isAppContent", isAppContent);
    }

    getHideHeader(): boolean {
        return this.contentQuerySessionStorage.getByName("hideHeader");
    }

    saveHideHeader(hideHeader: boolean) {
        this.contentQuerySessionStorage.save("hideHeader", hideHeader);
    }

    getHideFooter(): boolean {
        return this.contentQuerySessionStorage.getByName("hideFooter");
    }

    saveHideFooter(hideFooter: boolean) {
        this.contentQuerySessionStorage.save("hideFooter", hideFooter);
    }

    /**
     * Empties storage items except app-presets related items
     * @return {void}
     */
    emptyStorage() {
        const storageKeysToRemove: string[] = [];

        Storage.getInstance().store.each((value: any, key: string) => {
            if (!key.includes("appPresets")) {
                storageKeysToRemove.push(key);
            }
        });

        storageKeysToRemove.forEach((key) => {
            const storage = new Storage(key.replace(prefix, ""));
            storage.clean();

            console.info("Storage clean: ", key);
        });
    }

    /**
     * Empties only content related storage items
     * @return {void}
     */
    cleanContentStorage() {
        const storageKeysToRemove: string[] = [];

        Storage.getInstance().store.each((value: any, key: string) => {
            if (key.includes("request__")) {
                storageKeysToRemove.push(key);
            }
        });

        storageKeysToRemove.forEach((key) => {
            const storage = new Storage(key.replace(prefix, ""));
            storage.clean();

            console.info("Content storage clean: ", key);
        });
    }

    async setupUserStorage(name: string) {
        await localforage.defineDriver(memoryStorageDriver);

        let driver = [
            localforage.INDEXEDDB,
            localforage.WEBSQL,
            localforage.LOCALSTORAGE,
            memoryStorageDriver._driver,
        ];
        if (app.forceVolatileSensitiveUserData)
            driver = [memoryStorageDriver._driver];

        localforage.config({
            driver, // Force WebSQL; same as using setDriver()
            name,
            version: 1.0,
            storeName: "master",
        });

        await SessionRepo.getInstance().setupDriver();
        await ExternUserGroupRepo.getInstance().setupDriver();
        await ExternUserRepo.getInstance().setupDriver();
        await MessagesRepo.getInstance().setupDriver();
        await PrescriptionRepo.getInstance().setupDriver();
    }

    async whipeUserStorage() {
        await SessionRepo.getInstance().whipeData();
        await ExternUserGroupRepo.getInstance().whipeData();
        await ExternUserRepo.getInstance().whipeData();
        await MessagesRepo.getInstance().whipeData();
        await PrescriptionRepo.getInstance().whipeData();
        await ValuationListRepo.getInstance().whipeData();
    }

    // TODO: Messages are not sync. Cuando Dani se digne a hacerlo esto se quitará.
    async whipeMessagesStorage() {
        await MessagesRepo.getInstance().whipeData();
    }

    updateStateWhenStorageUpdated() {
        window.addEventListener(
            "storage",
            function () {
                console.log("storage changed!!!!", { ...localStorage });
            },
            false,
        );
    }
}
