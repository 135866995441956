import { LocationSearchResponse } from "modules/core/model/locationSearchResponse";
import { LocationSearchRequest } from "modules/core/model/locationSearchRequest";
import { LocationVO } from "modules/core/model/locationVO";
import BusinessAbstract from "./BusinessAbstract";

export default class LocationBO extends BusinessAbstract {
    static instance?: LocationBO;

    static getInstance(): LocationBO {
        if (!LocationBO.instance) {
            LocationBO.instance = new this();
        }

        return LocationBO.instance;
    }

    async fetchLocationSearch(
        latitude?: number,
        longitude?: number,
        text?: string,
    ): Promise<LocationVO[]> {
        const service = await this.api.service<LocationSearchResponse>(
            "locationSearch",
            {
                latitude,
                longitude,
                text,
            } as LocationSearchRequest,
        );

        if (service?.locationList && !service.error) {
            return service.locationList;
        }

        return [];
    }
}
