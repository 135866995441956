import Vue, { ComponentOptions } from "vue";
import app from "modules/core/config/app";
import Api from "modules/core/lib/Api";
import { LoginRequest } from "modules/core/model/loginRequest";
import { LoginResponse } from "modules/core/model/loginResponse";
import Ui from "../lib/Ui";
import Util from "../lib/Util";

export default class LoginBO {
    static instance?: LoginBO;

    authTokenExpiresIn?: number; // TODO: why this?

    static getInstance(): LoginBO {
        if (!LoginBO.instance) {
            LoginBO.instance = new this();
        }

        return LoginBO.instance;
    }

    async doLogin(
        params: LoginRequest = {},
        dispatch: boolean = true,
        useBasicAuth: boolean = false,
    ): Promise<LoginResponse> {
        const response = await Api.getInstance().service<LoginResponse>(
            "login",
            {
                ...params,
                authTokenRefreshExpiresIn: app.authTokenExpiration,
            },
            false,
            null,
            dispatch,
            useBasicAuth,
        );

        if (response && response.authTokenExpiresIn) {
            this.authTokenExpiresIn = response.authTokenExpiresIn;
        }

        return response;
    }

    async showLoginModal(
        loginComponent: ComponentOptions<Vue>,
        onLogin: (response: LoginResponse) => void = (response) => {},
        modalTitle?: string,
    ) {
        // let componentObject = await import("modules/user/components/selector/Selector");

        const instance = await Util.mountComponent(loginComponent, {
            googleSigninId: app.googleSignInId,
            onSubmit: (response: LoginResponse) => {
                // @ts-ignore
                modal.destroy();

                onLogin(response);
            },
        });
        instance.$mount();

        const modal = Ui.showModal(modalTitle || "", instance.$el);
    }
}
