// @ts-ignore
// TODO: Move to TS ENUM!
import Enum from "enum";

const AnswerSourceEnum = new Enum({
    USER: 0,
    FORMULA: 1,
    REASON: 2,
    OBLIGATORY: 3,
    AUTORESPONSE: 4,
    UNKNOW: 5,
});

export default AnswerSourceEnum;
