import _merge from "lodash/merge";
import GenericRepo from "./GenericRepo";
import ValuationListDAO from "../dao/ValuationListDAO";
import ValuationListRemoteDAO from "../dao/ValuationListRemoteDAO";

export default class ValuationListRepo extends GenericRepo {
  constructor() {
    super();
    this.dao = new ValuationListDAO();
    this.remoteDao = new ValuationListRemoteDAO();
    this.storageName = "valuations";
  }

  static getInstance() {
    if (!ValuationListRepo.instance) {
      ValuationListRepo.instance = new this();
    }

    return ValuationListRepo.instance;
  }

  async fetch(offset, count, maxDate = null, params = {}, externUserId = null) {
    return await this.remoteDao.fetchExternUserValuationList(
      offset,
      count,
      _merge(params, { maxDate, externUserId }),
    );
  }

  async fetchPending(
    offset,
    count,
    sinceDate,
    maxDate,
    useLastEdited = true,
    externUserId,
  ) {
    return await this.remoteDao.fetchExternUserValuationList(null, null, {
      sinceDate,
      maxDate,
      useLastEdited,
      offset,
      count,
      externUserId,
    });
  }
}
