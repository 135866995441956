import firebase from "firebase/app";

export default class Utils {
    static createFirebaseAppleProvider(
        locale: string,
    ): firebase.auth.OAuthProvider {
        const provider = new firebase.auth.OAuthProvider("apple.com");
        provider.addScope("email");
        provider.addScope("name");

        provider.setCustomParameters({
            locale,
        });

        return provider;
    }
}
