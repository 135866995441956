import EventBusSingleton from "modules/core/lib/EventBusSingleton";
import type App from "modules/core/lib/App";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import { ConclusionVO } from "modules/core/model/conclusionVO";
import { SessionVO } from "modules/core/model/sessionVO";

export default class GlobalEventManager {
    static instance?: GlobalEventManager;

    protected app?: App;

    static getInstance(): GlobalEventManager {
        if (!GlobalEventManager.instance) {
            GlobalEventManager.instance = new this();
        }

        return GlobalEventManager.instance;
    }

    setApp(app: App) {
        this.app = app;
    }

    listen() {
        if (!this.app) throw new Error("App is not defined!");

        EventBusSingleton.on(
            "glossaryConclusionItemActionClick",
            (conclusion: ConclusionVO) => {
                this.app?.router?.push(
                    RouteGenerator.getInstance().conclusionActions(
                        conclusion.conclusionId!,
                    ),
                );
            },
        );

        EventBusSingleton.on(
            "glossaryConclusionDetailReasonInputSubmit",
            (session: SessionVO) => {
                this.app?.router?.push(
                    RouteGenerator.getInstance().session(session.sessionId!),
                );
            },
        );
    }
}

/*
type paymentData {
    externUser: ExternUser,
    product: Product,
    productPrice: ProductPrice,
    sessionId: string
}
* */
