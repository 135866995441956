export default {
    ERROR_CODE_UNKNOWN: "ME0",
    ERROR_CODE_UNKNOWN_VALUES: "ME001",

    ERROR_CODE_NO_PERMISSIONS: "ME1",
    ERROR_CODE_NO_PERMISSIONS_REGISTERED_USER: "ME12",
    ERROR_CODE_NO_PERMISSIONS_ACCESS_ANONYMOUS_USER_INFO: "ME13",
    ERROR_CODE_VALUE_OUT_OF_DB_RANGE: "ME2",

    ERROR_CODE_LOGIN_INVALID: "ME200",
    ERROR_CODE_LOGIN_INCORRECT: "ME201",
    ERROR_CODE_USER_INVALID: "ME202",
    ERROR_CODE_USER_ALREADY_REGISTERED: "ME203",
    ERROR_CODE_EMAIL_INVALID: "ME204",
    ERROR_CODE_BIRTHDATE_INVALID: "ME205",
    ERROR_CODE_PASSWORD_INVALID: "ME206",
    ERROR_CODE_GENDER_INVALID: "ME207",
    ERROR_CODE_NAME_TOO_LONG: "ME208",

    ERROR_CODE_SESSION_REASON_TOO_LONG: "ME50",
    ERROR_CODE_SESSION_UNKNOWN_REASON: "ME5",
    ERROR_CODE_SESSION_UNKNOWN_REASON_DDX: "ME51",
    ERROR_CODE_PAYMENT_CREATE: "ME100",
    ERROR_CODE_PAYMENT_VERIFY: "ME101",
    ERROR_CODE_NO_PARTNER: "ME300",
    ERROR_CODE_NO_PRODUCT: "ME301",
    ERROR_CODE_NO_PARTNER_SPECIALTY: "ME302",
    ERROR_CODE_NO_PARTNERCODE: "ME303",
    ERROR_CODE_PARTNERCODE_INACTIVE: "ME304",
    ERROR_CODE_NO_SPECIALTY: "ME305",
    ERROR_CODE_BUSY_SPECIALTY: "ME306",
    ERROR_CODE_BUSY_PARTNER: "ME307",
    ERROR_CODE_PARTNER_INACTIVE: "ME308",
    ERROR_CODE_PARTNER_INACTIVE_PRODUCT: "ME309",
    ERROR_CODE_NO_TICKETS: "ME310",
    ERROR_CODE_NO_BUY_TO_YOURSELF: "ME311",
    ERROR_CODE_NO_CHAT: "ME4",
    ERROR_CODE_NO_EXTERNUSER: "ME401",
    ERROR_CODE_NO_EXTERNUSERPRODUCT: "ME402",
    ERROR_CODE_TIMETABLE_INVALID: "ME410",
    ERROR_CODE_TIMETABLE_MODIFICATION_INVALID: "ME411",
    ERROR_CODE_TIMETABLE_DELETE_INVALID: "ME412",
    ERROR_CODE_INVALID_VALUATION: "ME502",
    ERROR_CODE_CONSULT_TAKEN: "ME503",
    ERROR_CODE_NO_PRODUCT_COMPATIBLE_DEVICE: "ME504",
    ERROR_CODE_NO_PRODUCT_COMPATIBLE_VERSION: "ME505",

    ERROR_CODE_WIPEOUT: "ME666",
    ERROR_CODE_WIPEOUT_REGISTERED_USER: "ME665",
    ERROR_CODE_WIPEOUT_NO_USER_REGENERATION: "ME675",
    ERROR_CODE_AUTHTOKEN_INVALID: "ME667",
    ERROR_CODE_USER_NOT_REGISTERED: "ME668",
    ERROR_CODE_EXTERNUSEREXTERNUSER_ISBLOCKED: "ME669",
    ERROR_CODE_EXTERNUSERIMPERSONATION_INVALID: "ME670",

    ERROR_CODE_PARTNEREXTERNALID_INVALID: "ME700",

    ERROR_CODE_PARAMETERS_MISSING: "ME800",
    ERROR_CODE_OBJECT_ALREADY_EXISTING: "ME801",

    ERROR_CODE_DISCONNECTED_EXTERNUSER: "ME900",
    ERROR_CODE_BUSY_EXTERNUSER: "ME901",
};
