import vue from "vue";

export const RouteMixin = vue.extend({
    computed: {
        queryFromRoute(): Record<string, any> {
            return JSON.parse(JSON.stringify(this.$route.query));
        },
    },
    methods: {
        addQueryToRoute(params: Record<string, any>) {
            const query = JSON.parse(JSON.stringify(this.$route.query));

            try {
                this.$router.replace({ query: Object.assign(query, params) });
            } catch (e) {
                console.log(e);
            }
        },
        removeQueryFromRoute(name: string) {
            const query = JSON.parse(JSON.stringify(this.$route.query));
            delete query[name];

            try {
                this.$router.replace({ query });
            } catch (e) {
                console.log(e);
            }
        },
    },
});
