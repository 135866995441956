/**
 * Mediktor API
 * Description
 *
 * OpenAPI spec version: 4.0
 * Contact: info@mediktor.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GenericRequest {
    altitude?: number;
    apiVersion?: string;
    appId?: string;
    appVersion?: string;
    autoDatabaseId?: string;
    autoDatabaseSchema?: GenericRequest.AutoDatabaseSchemaEnum;
    deviceId?: string;
    deviceToken?: string;
    deviceTokenVoIp?: string;
    deviceType?: GenericRequest.DeviceTypeEnum;
    koppenCodeEnum?: GenericRequest.KoppenCodeEnumEnum;
    language?: string;
    latitude?: number;
    locationRegion?: string;
    longitude?: number;
    method?: string;
    platformVersion?: string;
    preferredLanguage?: string;
    timezone?: number;
    timezoneId?: string;
    timezoneOffset?: number;
    timezoneRaw?: number;
    version?: string;
}
export namespace GenericRequest {
    export type AutoDatabaseSchemaEnum =
        | "AUDIT"
        | "AUDIT_RUNTIME"
        | "HISTORY"
        | "LOCATION"
        | "MASTERDATA"
        | "REPORT"
        | "RESEARCH"
        | "SERVER"
        | "USER"
        | "USER_ARCHIVE";
    export const AutoDatabaseSchemaEnum = {
        AUDIT: "AUDIT" as AutoDatabaseSchemaEnum,
        AUDITRUNTIME: "AUDIT_RUNTIME" as AutoDatabaseSchemaEnum,
        HISTORY: "HISTORY" as AutoDatabaseSchemaEnum,
        LOCATION: "LOCATION" as AutoDatabaseSchemaEnum,
        MASTERDATA: "MASTERDATA" as AutoDatabaseSchemaEnum,
        REPORT: "REPORT" as AutoDatabaseSchemaEnum,
        RESEARCH: "RESEARCH" as AutoDatabaseSchemaEnum,
        SERVER: "SERVER" as AutoDatabaseSchemaEnum,
        USER: "USER" as AutoDatabaseSchemaEnum,
        USERARCHIVE: "USER_ARCHIVE" as AutoDatabaseSchemaEnum,
    };
    export type DeviceTypeEnum =
        | "AMAZON_ECHO"
        | "ANDROID"
        | "BLACKBERRY"
        | "CHATBOT"
        | "GOOGLE_ASSISTANT"
        | "IOS"
        | "IWATCH"
        | "MEDIQUO"
        | "TELEGRAM"
        | "UNKNOWN"
        | "WEB"
        | "WEB_CHROME"
        | "WINDOWS";
    export const DeviceTypeEnum = {
        AMAZONECHO: "AMAZON_ECHO" as DeviceTypeEnum,
        ANDROID: "ANDROID" as DeviceTypeEnum,
        BLACKBERRY: "BLACKBERRY" as DeviceTypeEnum,
        CHATBOT: "CHATBOT" as DeviceTypeEnum,
        GOOGLEASSISTANT: "GOOGLE_ASSISTANT" as DeviceTypeEnum,
        IOS: "IOS" as DeviceTypeEnum,
        IWATCH: "IWATCH" as DeviceTypeEnum,
        MEDIQUO: "MEDIQUO" as DeviceTypeEnum,
        TELEGRAM: "TELEGRAM" as DeviceTypeEnum,
        UNKNOWN: "UNKNOWN" as DeviceTypeEnum,
        WEB: "WEB" as DeviceTypeEnum,
        WEBCHROME: "WEB_CHROME" as DeviceTypeEnum,
        WINDOWS: "WINDOWS" as DeviceTypeEnum,
    };
    export type KoppenCodeEnumEnum =
        | "AridoCalido"
        | "AridoFrio"
        | "ContinentalConInviernoSeco"
        | "ContinentalMediterraneo"
        | "ContinentalSinEstacionSeca"
        | "Ecuatorial"
        | "HemiboralSinEstacionSeca"
        | "HemiborealMediterraneo"
        | "HemiborealSinEstacionSeca"
        | "Mediterraneo"
        | "Ocean"
        | "Oceanico"
        | "OceanicoMediterraneo"
        | "Polar"
        | "SemiAridoCalido"
        | "SemiAridoFrio"
        | "SubpolarConInviernoSeco"
        | "SubpolarConInviernoSecoFrio"
        | "SubpolarConVeranoSeco"
        | "SubpolarConVeranoSecoFrio"
        | "SubpolarOceanico"
        | "SubpolarOceanicoConInviernoSeco"
        | "SubpolarOceanicoConVeranoSeco"
        | "SubpolarSinEstacionSeca"
        | "SubpolarSinEstacionSecaFrio"
        | "SubtropicalConInviernoSeco"
        | "SubtropicalSinEstacionSeca"
        | "TempladoConInviernoSeco"
        | "TropicalConInviernoSeco"
        | "TropicalConVeranoSeco"
        | "TropicalMonzonico"
        | "Tundra";
    export const KoppenCodeEnumEnum = {
        AridoCalido: "AridoCalido" as KoppenCodeEnumEnum,
        AridoFrio: "AridoFrio" as KoppenCodeEnumEnum,
        ContinentalConInviernoSeco:
            "ContinentalConInviernoSeco" as KoppenCodeEnumEnum,
        ContinentalMediterraneo:
            "ContinentalMediterraneo" as KoppenCodeEnumEnum,
        ContinentalSinEstacionSeca:
            "ContinentalSinEstacionSeca" as KoppenCodeEnumEnum,
        Ecuatorial: "Ecuatorial" as KoppenCodeEnumEnum,
        HemiboralSinEstacionSeca:
            "HemiboralSinEstacionSeca" as KoppenCodeEnumEnum,
        HemiborealMediterraneo: "HemiborealMediterraneo" as KoppenCodeEnumEnum,
        HemiborealSinEstacionSeca:
            "HemiborealSinEstacionSeca" as KoppenCodeEnumEnum,
        Mediterraneo: "Mediterraneo" as KoppenCodeEnumEnum,
        Ocean: "Ocean" as KoppenCodeEnumEnum,
        Oceanico: "Oceanico" as KoppenCodeEnumEnum,
        OceanicoMediterraneo: "OceanicoMediterraneo" as KoppenCodeEnumEnum,
        Polar: "Polar" as KoppenCodeEnumEnum,
        SemiAridoCalido: "SemiAridoCalido" as KoppenCodeEnumEnum,
        SemiAridoFrio: "SemiAridoFrio" as KoppenCodeEnumEnum,
        SubpolarConInviernoSeco:
            "SubpolarConInviernoSeco" as KoppenCodeEnumEnum,
        SubpolarConInviernoSecoFrio:
            "SubpolarConInviernoSecoFrio" as KoppenCodeEnumEnum,
        SubpolarConVeranoSeco: "SubpolarConVeranoSeco" as KoppenCodeEnumEnum,
        SubpolarConVeranoSecoFrio:
            "SubpolarConVeranoSecoFrio" as KoppenCodeEnumEnum,
        SubpolarOceanico: "SubpolarOceanico" as KoppenCodeEnumEnum,
        SubpolarOceanicoConInviernoSeco:
            "SubpolarOceanicoConInviernoSeco" as KoppenCodeEnumEnum,
        SubpolarOceanicoConVeranoSeco:
            "SubpolarOceanicoConVeranoSeco" as KoppenCodeEnumEnum,
        SubpolarSinEstacionSeca:
            "SubpolarSinEstacionSeca" as KoppenCodeEnumEnum,
        SubpolarSinEstacionSecaFrio:
            "SubpolarSinEstacionSecaFrio" as KoppenCodeEnumEnum,
        SubtropicalConInviernoSeco:
            "SubtropicalConInviernoSeco" as KoppenCodeEnumEnum,
        SubtropicalSinEstacionSeca:
            "SubtropicalSinEstacionSeca" as KoppenCodeEnumEnum,
        TempladoConInviernoSeco:
            "TempladoConInviernoSeco" as KoppenCodeEnumEnum,
        TropicalConInviernoSeco:
            "TropicalConInviernoSeco" as KoppenCodeEnumEnum,
        TropicalConVeranoSeco: "TropicalConVeranoSeco" as KoppenCodeEnumEnum,
        TropicalMonzonico: "TropicalMonzonico" as KoppenCodeEnumEnum,
        Tundra: "Tundra" as KoppenCodeEnumEnum,
    };
}
