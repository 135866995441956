import _merge from "lodash/merge";
import { ExternUserValuationListResponse } from "modules/core/model/externUserValuationListResponse";
import { ValuationVO } from "modules/core/model/valuationVO";
import { ExternUserValuationListRequest } from "modules/core/model/externUserValuationListRequest";
import GenericRemoteDAO from "./GenericRemoteDAO";

export default class ValuationListRemoteDAO extends GenericRemoteDAO {
    async fetchExternUserValuationList(
        offset: number,
        count: number,
        params: ExternUserValuationListRequest = {},
    ): Promise<ValuationVO[]> {
        if (!params.externUserId) return [];

        const response =
            await this.api.service<ExternUserValuationListResponse>(
                "externUserValuationList",
                _merge(this.basicParamsForPagination(offset, count), params),
            );
        if (response && response.valuationList) return response.valuationList;

        return [];
    }
}
