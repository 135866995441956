import NanoEvents from "nanoevents";
import unbindAll from "nanoevents/unbind-all";

class EventBus {
    static instance?: EventBus;

    emitter: NanoEvents<any>;

    static getInstance(): EventBus {
        if (!EventBus.instance) {
            EventBus.instance = new this();
        }

        return EventBus.instance;
    }

    constructor() {
        this.emitter = new NanoEvents();
    }

    on(...args: any[]) {
        return this.emitter.on.apply(this.emitter, args as [any, any]);
    }

    onAll(callback: { (...a: any[]): void }) {
        return this.emitter.on("__all__", callback);
    }

    emit(...args: any[]) {
        if (args.at(0) === undefined) {
            throw new Error("Anonymous event!");
        }
        console.info("EVENT", args);
        this.emitter.emit.apply(this.emitter, ["__all__", ...args] as [
            string,
            any,
        ]);

        return this.emitter.emit.apply(this.emitter, args as [any, any]);
    }

    off(event: { (): void }) {
        event();
    }

    // events() {
    //    return this.emitter.events;
    // }

    removeAll() {
        unbindAll(this.emitter);
    }
}

export default EventBus.getInstance();
