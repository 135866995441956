import template from "./google-sign-in.html?raw";
import "./google-sign-in.scss";

import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";
import Utils from "./Utils";
import { ExternUserVORes } from "modules/core/model/externUserVORes";
import mixins from "vue-typed-mixins";

export default mixins(LocaleMixin, CoreComponentMixin).extend({
    template,
    computed: {
        googleLogoUrl(): string {
            return require("./img/google.png");
        },
        googleSigninId(): string | null {
            return this.appGeneralConfig.googleSignInId;
        },
    },
    methods: {
        async startGoogleSignin() {
            this.googleSigninId &&
                Utils.startGoogleSignin(
                    this.$refs.googleButton as HTMLElement,
                    this.googleSigninId,
                    (response: ExternUserVORes) => {
                        this.emitEvent("google-sign-in", response);
                    },
                );
        },
    },
    mounted() {
        this.startGoogleSignin();
    },
});
