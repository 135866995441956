// ignore-storybook
import template from "./apple-sign-in.html?raw";
import "./apple-sign-in.scss";
import { LocaleMixin, CoreComponentMixin } from "modules/core/mixins/ts";
import SvgIcon from "modules/core/components/interface/svg-icon/SvgIcon";
import FirebaseSignInBO from "modules/core/business/FirebaseSignInBO";
import firebase from "firebase/app";
import Utils from "./Utils";
import mixins from "vue-typed-mixins";

export default mixins(LocaleMixin, CoreComponentMixin).extend({
    template,
    components: {
        "mdk-svg-icon": SvgIcon,
    },
    data() {
        return {
            FirebaseInstance: null as FirebaseSignInBO | null,
        };
    },
    computed: {
        appleLogoUrl(): string {
            return require("./img/apple.svg?raw");
        },
        appleLogoColor(): string {
            return "#4A4A4A";
        },
    },
    methods: {
        startAppleSignin() {
            const provider = this.createFirebaseAppleProvider();
            this.FirebaseInstance = new FirebaseSignInBO(provider);
        },

        async startSignInProccess() {
            this.FirebaseInstance &&
                (await this.FirebaseInstance.startSignInProccess("redirect"));
        },

        formatLocale(): string {
            return this.$app.language.split("-")[0];
        },

        createFirebaseAppleProvider(): firebase.auth.OAuthProvider {
            return Utils.createFirebaseAppleProvider(this.formatLocale());
        },
    },
    async mounted() {
        this.startAppleSignin();

        this.withPreloader(async () => {
            this.FirebaseInstance &&
                (await this.FirebaseInstance.getRedirectResult());
        });
    },
});
