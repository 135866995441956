import app from "modules/core/config/app";
import App from "./App";
import Storage from "./Storage";

export default class AuthManager {
    static instance?: AuthManager;

    private authStorage?: Storage;

    private authGuestStorage?: Storage;

    private app?: App;

    static getInstance(): AuthManager {
        if (!AuthManager.instance) AuthManager.instance = new this();

        return AuthManager.instance;
    }

    setApp(app: App) {
        this.app = app;
    }

    setupAuthStorage(suffix?: string, asSession: boolean = false) {
        this.authStorage = new Storage(
            `auth${suffix ? `_${suffix}` : ""}`,
            app.registeredUserAuthStorageExpirationTime,
        );
        this.authGuestStorage = new Storage(
            `authGuest${suffix ? `_${suffix}` : ""}`,
        );

        if (asSession) {
            this.authStorage?.setStorageType("session");
            this.authGuestStorage?.setStorageType("session");
        }
    }

    resetAuthCredentialsFromStorage() {
        this.authStorage?.clean();
        this.authGuestStorage?.clean();

        console.info("Auth storage cleaned.");
    }

    getStoredExternUserId(): string {
        return this.authStorage?.getByName("externUserId");
    }

    getStoredAuthToken(): string {
        return this.authStorage?.getByName("authToken");
    }

    getStoredAuthTokenExpirationTime(): number {
        return this.authStorage?.getByName("authTokenExpirationTime");
    }

    getStoredGuestExternUserId(): string {
        return this.authGuestStorage?.getByName("externUserId");
    }

    getStoredGuestAuthToken(): string {
        return this.authGuestStorage?.getByName("authToken");
    }

    getStoredGuestAuthTokenExpirationTime(): number {
        return this.authGuestStorage?.getByName("authTokenExpirationTime");
    }

    saveExternUserIdToStorage(value: string) {
        this.authStorage?.save("externUserId", value);
    }

    saveAuthTokenToStorage(value: string) {
        this.authStorage?.save("authToken", value);
    }

    saveAuthTokenExpirationTimeToStorage(value: number) {
        this.authStorage?.save("authTokenExpirationTime", value);
    }

    saveGuestExternUserIdToStorage(value: string) {
        this.authGuestStorage?.save("externUserId", value);
    }

    saveGuestAuthTokenToStorage(value: string) {
        this.authGuestStorage?.save("authToken", value);
    }

    saveGuestAuthTokenExpirationTimeToStorage(value: number) {
        this.authGuestStorage?.save("authTokenExpirationTime", value);
    }

    hasAuthStorage(): boolean {
        return !!this.authStorage?.exists();
    }

    addCredentialsToStorage(
        authToken: string,
        externUserId: string,
        authTokenExpirationTime: number | null = null,
    ) {
        this.saveAuthTokenToStorage(authToken);
        this.saveExternUserIdToStorage(externUserId);
        // @ts-ignore
        this.saveAuthTokenExpirationTimeToStorage(authTokenExpirationTime);
    }

    addGuestCredentialsToStorage(
        authToken: string,
        externUserId: string,
        authTokenExpirationTime: number,
    ) {
        this.saveGuestAuthTokenToStorage(authToken);
        this.saveGuestExternUserIdToStorage(externUserId);
        this.saveGuestAuthTokenExpirationTimeToStorage(authTokenExpirationTime);
    }

    detectStorageUpdate() {
        /* const channel = new BroadcastChannel<MultiInstanceAuthSyncData>(this.app.deviceId);
        channel.onmessage = async info => {
            if(info.type === "renewal") {
                if (this.app.authToken !== info.data.authToken) {
                    console.log("Auth sync broadcast. Updating...", info);

                    this.app.updateCredentials(info.data.authToken, info.data.externUserId);
                    this.app.setApiUserData(info.data.externUserId, info.data.authToken);

                    this.app.externUser = info.data.externUser;

                    await this.app.updateUserRelatedInfo(true, true);
                }
            }
        }; */
        /* let done = false;
        let timeout:ReturnType<typeof setTimeout> = null;

        const handler = async () => {
            if (!done && this.getStoredAuthToken()) {
                if(timeout) clearTimeout(timeout);

                // Let's ensure there is no more updates
                timeout = setTimeout(async () => {
                    done = false;

                    if (this.getStoredAuthToken() !== this.app.authToken) {
                        //const spinner = Ui.showSpinner(EnvironmentConfig.isLocal() ? "Storage updated and token changed" : null);
                        //const spinner = Ui.appendPreloaderTo(this.app.vueApp.$el);

                        try {
                            const externUserId = this.getStoredExternUserId();
                            const authToken = this.getStoredAuthToken();
                            const deviceId = AppStorageManager.getInstance().getDeviceId();
                            //const isNewUser = externUserId !== this.app.externUserId;

                            this.app.updateCredentials(authToken, externUserId, deviceId);
                            this.app.setApiUserData(externUserId, authToken);

                            console.log("Setting new user because storage has changed...");

                            const response = await ExternUserBO.getInstance().doExternUserByResponse({
                                externUserId
                            });

                            if (response && response.externUser) {
                                this.app.externUser = response.externUser;

                                await this.app.updateUserRelatedInfo(true, true);
                            }
                        }catch(error) {
                            console.error(error);
                        }finally{
                            //spinner.remove();
                            //this.app.removeMainLoader();
                        }
                    }

                }, 3500);

                done = true;
            }
        };

        window.addEventListener("storage", handler); */
    }
}
