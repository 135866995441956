import { ServerInfoResponse } from "modules/core/model/serverInfoResponse";
import BusinessAbstract from "./BusinessAbstract";

export default class ServerInfoBO extends BusinessAbstract {
    static instance?: ServerInfoBO;

    static getInstance(): ServerInfoBO {
        if (!ServerInfoBO.instance) {
            ServerInfoBO.instance = new this();
        }

        return ServerInfoBO.instance;
    }

    async fetchServerInfo(): Promise<ServerInfoResponse> {
        return this.api.service<ServerInfoResponse>("serverInfo");
    }
}
