import { RouteConfig } from "vue-router/types/router";
import { Route } from "vue-router";
import LanguageBO from "modules/core/business/LanguageBO";
import { slugifyTextForLanguage } from "modules/core/lib/Util";
import LocalizationBO from "modules/core/business/LocalizationBO";
import RouteGenerator from "modules/core/lib/RouteGenerator";

export default (
    language: string,
    localization: LocalizationBO,
): RouteConfig[] => {
    const routeGenerator = RouteGenerator.getInstance();
    const publicLanguage = LanguageBO.convertToPublicLanguage(language);

    return [
        {
            name: "glossary__OLD",
            path: `/${publicLanguage}/dictionary`,
            redirect: routeGenerator.glossary(),
        },
        {
            name: "about__OLD",
            path: `/${publicLanguage}/${slugifyTextForLanguage(
                localization.localeText("routes.sobreMediktor"),
                language,
            )}`,
            redirect: routeGenerator.home(),
        },
        {
            name: "sessionReportDownloadPdf_Old",
            path: `/session-report-download-pdf:sessionId`,
            redirect(to: Route) {
                return RouteGenerator.getInstance().sessionReportDownloadPdf(
                    to.params.sessionId,
                );
            },
        },
        {
            name: "specialists_Old",
            path: `/${publicLanguage}/${slugifyTextForLanguage(
                localization.localeText("routes.section"),
                language,
            )}/:categoryId`,
            redirect: (to: Route) => {
                return {
                    name: "specialists",
                    query: { categoryId: to.params.categoryId },
                };
            },
        },
        {
            name: "glossaryDetail_Old",
            path: `/${publicLanguage}/${slugifyTextForLanguage(
                localization.localeText("routes.enfermedad"),
                language,
            )}/:conclusionDescription/:conclusionId`, // old: ?conclusionId
            redirect: (to: Route) => {
                return {
                    name: "glossaryDetail",
                    query: { conclusionId: to.params.conclusionId },
                };
            },
        },
        {
            name: "specialistDetail_Old",
            path: `/${publicLanguage}/${slugifyTextForLanguage(
                localization.localeText("routes.specialists"),
                language,
            )}/:externUserName/:externUserId`,
            redirect: (to: Route) => {
                return {
                    name: "specialistDetail",
                    query: { externUserId: to.params.externUserId },
                };
            },
        },
        {
            name: "chat_Old",
            path: `/${publicLanguage}/${slugifyTextForLanguage(
                localization.localeText("routes.chat"),
                language,
            )}/:externUserGroupId`,
            redirect: (to: Route) => {
                return {
                    name: "chat",
                    query: { externUserGroupId: to.params.externUserGroupId },
                };
            },
        },
        {
            name: "patientDetail_Old",
            path: `/${publicLanguage}/${slugifyTextForLanguage(
                localization.localeText("tmk547"),
                language,
            )}/:externUserId`,
            redirect: (to: Route) => {
                return {
                    name: "patientDetail",
                    query: { externUserId: to.params.externUserId },
                };
            },
        },
        {
            name: "profileMedical_Old",
            path: `/${publicLanguage}/profile/medical/:categoryId`,
            redirect: (to: Route) => {
                return {
                    name: "profileMedical",
                    query: { categoryId: to.params.categoryId },
                };
            },
        },
        {
            name: "session_Old",
            path: `/${publicLanguage}/session/:sessionId`,
            redirect: (to: Route) => {
                return {
                    name: "session",
                    query: { sessionId: to.params.sessionId },
                };
            },
        },
        {
            name: "sessionResult_Old",
            path: `/${publicLanguage}/${slugifyTextForLanguage(
                localization.localeText("routes.results"),
                language,
            )}/:sessionId`,
            redirect: (to: Route) => {
                return {
                    name: "sessionResult",
                    query: { sessionId: to.params.sessionId },
                };
            },
        },
        /* {
            name: "en-EN_Old",
            path: "/en/:path?/:path2/:path3?/:path4/:path5?",
            redirect: (to: Route) => {
                let route = routeGenerator.home();
                route.query["lang"] = "en-GB";

                debugger;

                return route;
            }
        }, */
    ];
};
