// eslint-disable-next-line max-classes-per-file
import { getUrlQueryParamsAsObject } from "modules/core/lib/Util";

type Redirection = { name?: string };

export type CMD<T extends string> = {
    command: T;
    query?: Record<string, any>;
};

export interface IDeepLinking<R extends Redirection> {
    handle(cmd: CMD<any>): Promise<R | void>;
}

export class BaseDeepLinking<
    Commands extends string,
    // Route extends Redirection,
> {
    // implements IDeepLinking<Route>
    protected command?: Commands;

    protected query?: Record<string, any>;

    // public async handle({ command, query }: CMD<Commands>) {
    //     return;
    // }

    protected setCMD({ command, query }: CMD<Commands>) {
        this.command = command;
        this.query = query || getUrlQueryParamsAsObject(window.location.href);
    }

    protected notFoundRoute() {
        return { name: "home" };
    }

    protected checkRequiredQueryParams(params: string[] = []) {
        for (const param of params) {
            if (this.query?.[param] === undefined) {
                console.error(`CMD: No ${param} provided.`);

                return false;
            }
        }

        return true;
    }

    protected getUrlParamsAsObject(url: string): Record<string, any> {
        const object: Record<string, any> = {};
        const params = new URL(url).searchParams;

        for (const p in params) {
            object[p[0]] = p[1];
        }

        return object;
    }
}

export default class DeepLinkingManager<
    // AvailableCommands extends string,
    R extends Redirection,
> {
    private handlers: IDeepLinking<R>[] = [];

    constructor(defaultHandler: IDeepLinking<R>) {
        this.handlers = [defaultHandler];
    }

    public registerRedirectHandler(handler: IDeepLinking<R>) {
        this.handlers = [handler, ...this.handlers];
    }

    public async getRedirectionForCMD({
        command,
        query,
        // }: CMD<AvailableCommands>): Promise<R | undefined> {
    }: CMD<string>): Promise<R | undefined> {
        // const redirections = await Promise.all(
        //     this.handlers.map(
        //         async (handler) =>
        //             await handler.handle({ command, query }).catch((error) => {
        //                 console.info(
        //                     "Possible Issues during cmd processing: ",
        //                     { command, query },
        //                     error,
        //                 );
        //             }),
        //     ),
        // );
        // const redirect = (redirections.filter(Boolean) as Awaited<R>[]).find(
        //     Boolean,
        // );
        // return redirect;

        for (const handler of this.handlers) {
            try {
                console.log("DeepLinkingManager::getRedirectionForCMD", {
                    command,
                    query,
                });
                const redirect = await handler.handle({ command, query });
                if (redirect) return redirect;
            } catch (error) {
                console.info(
                    "Possible Issues during cmd processing: ",
                    { command, query },
                    error,
                );
            }
        }
        return undefined;
    }
}

// export default deepLinkingManager;
