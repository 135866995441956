import md5 from "blueimp-md5";
import _merge from "lodash/merge";
import ExternUserDAO from "modules/core/dao/ExternUserListDAO";
import { ExternUserLinkListResponse } from "modules/core/model/externUserLinkListResponse";
import { ExternUserLinkVO } from "modules/core/model/externUserLinkVO";
import { ExternUserVO } from "modules/core/model/externUserVO";
import { ExternUserResponse } from "modules/core/model/externUserResponse";
import { ExternUserRequest } from "modules/core/model/externUserRequest";
import { ExternUserListRequest } from "modules/core/model/externUserListRequest";
import { ExternUserListResponse } from "modules/core/model/externUserListResponse";
import { SpecialistListSyncRequest } from "modules/core/model/specialistListSyncRequest";
import { SpecialistListSyncResponse } from "modules/core/model/specialistListSyncResponse";
import { ExternUserValidationResponse } from "modules/core/model/externUserValidationResponse";
import { ExternUserValidationRequest } from "modules/core/model/externUserValidationRequest";
import { ForgotPasswordResponse } from "modules/core/model/forgotPasswordResponse";
import { ForgotPasswordRequest } from "modules/core/model/forgotPasswordRequest";
import { RegisterResponse } from "modules/core/model/registerResponse";
import { RegisterRequest } from "modules/core/model/registerRequest";
import { LogoutResponse } from "modules/core/model/logoutResponse";
import { ExternUserPartnerRequest } from "modules/core/model/externUserPartnerRequest";
import { ExternUserPartnerResponse } from "modules/core/model/externUserPartnerResponse";
import { ExternUserProductResponse } from "modules/core/model/externUserProductResponse";
import { ExternUserProductRequest } from "modules/core/model/externUserProductRequest";
import { CheckEarlyAccessResponse } from "modules/core/model/checkEarlyAccessResponse";
import { CheckEarlyAccessRequest } from "modules/core/model/checkEarlyAccessRequest";
import { NewSubExternUserResponse } from "modules/core/model/newSubExternUserResponse";
import { NewSubExternUserRequest } from "modules/core/model/newSubExternUserRequest";
import { ReasonHistoryDeleteResponse } from "modules/core/model/reasonHistoryDeleteResponse";
import { ExternUserLanguageRequest } from "modules/core/model/externUserLanguageRequest";
import { ExternUserLanguageResponse } from "modules/core/model/externUserLanguageResponse";
import { ExternUserCategoryResponse } from "modules/core/model/externUserCategoryResponse";
import { ExternUserCategoryRequest } from "modules/core/model/externUserCategoryRequest";
import ExternUserWorkplaceDAO from "../dao/ExternUserWorkplaceDAO";
import LocalizationBO from "./LocalizationBO";
import Ui from "../lib/Ui";
import Util from "../lib/Util";
import BusinessAbstract from "./BusinessAbstract";

export default class ExternUserBO extends BusinessAbstract {
    static instance?: ExternUserBO;

    dao: ExternUserDAO;

    workplaceDAO: ExternUserWorkplaceDAO;

    constructor() {
        super();
        this.dao = new ExternUserDAO();
        this.workplaceDAO = new ExternUserWorkplaceDAO();
    }

    static getInstance(): ExternUserBO {
        if (!ExternUserBO.instance) {
            ExternUserBO.instance = new this();
        }

        return ExternUserBO.instance;
    }

    async fetchExternUserLinkList(
        externUserId: string,
    ): Promise<ExternUserLinkVO[]> {
        const externUserLinkListResponse =
            await this.api.service<ExternUserLinkListResponse>(
                "externUserLinkList",
                {
                    externUserId,
                },
            );

        if (
            externUserLinkListResponse?.externUserLinkList &&
            !externUserLinkListResponse.error
        ) {
            return externUserLinkListResponse.externUserLinkList;
        }

        return [];
    }

    async fetchExternUser(
        externUserId?: string,
        fullInfo: boolean = false,
        externUser?: ExternUserVO,
    ): Promise<ExternUserVO> {
        const data: ExternUserRequest = {
            // fullInfo,
            externUser,
        };

        if (externUserId) data.externUserId = externUserId;

        const response = await this.api.service<ExternUserResponse>(
            "externUser",
            data,
        );

        if (response && response.externUser) {
            return response.externUser;
        }

        return {};
    }

    async fetchExternUserList(
        params: ExternUserListRequest = {},
    ): Promise<ExternUserListResponse> {
        const response = await this.api.service<ExternUserListResponse>(
            "externUserList",
            params,
        );

        return response;
    }

    async doSpecialistListSync(
        params: SpecialistListSyncRequest = {},
    ): Promise<SpecialistListSyncResponse> {
        return this.api.service<SpecialistListSyncResponse>(
            "specialistListSync",
            params,
        );
    }

    /**
     * @deprecated
     */
    async fetchExternUserWithSocket(
        params: ExternUserRequest = {},
    ): Promise<ExternUserResponse> {
        const response: ExternUserResponse =
            await this.socket.sendServiceViaSocketWithPromise(
                "externUser",
                params,
            );
        if (response?.externUser) {
            this.updateOrCreateExternUser(response.externUser);

            return response;
        }

        return {};
    }

    // TODO: Please review if going back to socket is needed
    async getOrCreateExternUserById(
        externUserId: string,
    ): Promise<ExternUserVO> {
        const externUser: ExternUserVO = this.getExternUserById(externUserId);
        if (externUser === null) {
            const response = await this.doExternUser({ externUserId });
            if (response !== undefined) {
                // eventBus.emit("userUpdateNotifications", - (- response.externUser.myUnreadMessageCount));
                this.updateOrCreateExternUser(response);

                return response;
            }
        } else this.updateExternUser(externUser);

        return externUser;
    }

    updateOrCreateExternUser(externUser: ExternUserVO): string | null {
        if (externUser?.externUserId) {
            const oldData = this.getExternUserById(externUser.externUserId);
            if (oldData !== null) this.updateExternUser(externUser);
            else this.setExternUser(externUser);

            return externUser.externUserId;
        }

        return null;
    }

    async doExternUser(data: ExternUserRequest): Promise<ExternUserVO> {
        const response = await this.api.service<ExternUserResponse>(
            "externUser",
            data,
        );

        if (response && response.externUser) {
            return response.externUser;
        }

        return {};
    }

    async doExternUserByResponse(
        data: ExternUserRequest,
    ): Promise<ExternUserResponse> {
        return this.api.service<ExternUserResponse>("externUser", data);
    }

    async doExternUserValidation(
        validationId: string,
        newPassword?: string,
    ): Promise<ExternUserValidationResponse> {
        const response = await this.api.service<ExternUserValidationResponse>(
            "externUserValidation",
            {
                validationId,
                newPassword,
            } as ExternUserValidationRequest,
        );

        return response;
    }

    async doPasswordUpdate(
        externUserId: string,
        newPassword: string,
        oldPassword: string,
    ) {
        const data: ExternUserRequest = {
            externUserId,
            externUser: {
                newPassword,
                oldPassword: md5(oldPassword),
            },
        };

        return this.doExternUser(data);
    }

    async doForgotPassword(username: string): Promise<ForgotPasswordResponse> {
        const response = await this.api.service<ForgotPasswordResponse>(
            "forgotPassword",
            {
                username,
            } as ForgotPasswordRequest,
        );

        return response;
    }

    async doRegister(
        username: string,
        password: string,
        name: string,
        partnerExternalId?: string,
    ): Promise<RegisterResponse> {
        const response = await this.api.service<RegisterResponse>("register", {
            username,
            password,
            externUser: {
                name,
                partnerExternalId,
            },
        } as RegisterRequest);

        return response;
    }

    async doLogout(): Promise<LogoutResponse> {
        return this.api.service<LogoutResponse>("logout");
    }

    async doExternUserPartner(
        data: ExternUserPartnerRequest,
    ): Promise<ExternUserPartnerResponse> {
        return this.api.service<ExternUserPartnerResponse>(
            "externUserPartner",
            data,
        );
    }

    async doExternUserProduct(
        externUserId: string,
        productId: string,
        params: ExternUserProductRequest = {},
    ): Promise<ExternUserProductResponse> {
        const response = await this.api.service<ExternUserProductResponse>(
            "externUserProduct",
            _merge(
                {
                    externUserId,
                    productId,
                } as ExternUserProductRequest,
                params,
            ),
        );

        return response;
    }

    async doExternUserProduct2(
        params: ExternUserProductRequest = {},
    ): Promise<ExternUserProductResponse> {
        const response = await this.api.service<ExternUserProductResponse>(
            "externUserProduct",
            params,
        );

        return response;
    }

    // TODO: no model in swagger!
    async doExternUserImage(
        externUserId: string,
        mediaFileId: string,
        tag: string = "faceImage",
        params = {},
    ): Promise<any> {
        const response = await this.api.service(
            "externUserImage",
            _merge(
                {
                    externUserId,
                    mediaFileId,
                    tag,
                },
                params,
            ),
        );

        return response;
    }

    getExternUserById(externUserId: string): ExternUserVO {
        return this.dao.getExternUserById(externUserId);
    }

    updateExternUser(externUser: ExternUserVO) {
        this.dao.updateExternUser(externUser);
    }

    setExternUser(externUser: ExternUserVO) {
        this.dao.setExternUser(externUser);
    }

    getUserNameById(externUserId: string): string {
        return this.dao.getUserNameById(externUserId);
    }

    getExternUserList(): ExternUserVO[] {
        return this.dao.getExternUserList() as any;
    }

    async doCheckEarlyAccess(
        promotionalCode: string,
    ): Promise<CheckEarlyAccessResponse> {
        const response = await this.api.service<CheckEarlyAccessResponse>(
            "checkEarlyAccess",
            {
                promotionalCode,
            } as CheckEarlyAccessRequest,
        );

        return response;
    }

    // TODO: no typeings in swagger!
    async fetchExternUserWorkplace(externUserId: string): Promise<any> {
        const response = await this.api.service<any>("externUserWorkplace", {
            externUserId,
            useCache: 7 * 24,
        });
        const list = response.externUserWorkplace;

        this.workplaceDAO.setItems(list);

        return list;
    }

    async doNewSubExternUser(
        data: NewSubExternUserRequest,
    ): Promise<NewSubExternUserResponse> {
        return this.api.service<NewSubExternUserResponse>(
            "newSubExternUser",
            data,
        );
    }

    async doReasonHistoryDelete(): Promise<ReasonHistoryDeleteResponse> {
        return this.api.service<ReasonHistoryDeleteResponse>(
            "reasonHistoryDelete",
        );
    }

    async doExternUserLanguage(
        params: ExternUserLanguageRequest,
    ): Promise<ExternUserLanguageResponse> {
        return this.api.service<ExternUserLanguageResponse>(
            "externUserLanguage",
            params,
        );
    }

    async doExternUserCategory(
        params: ExternUserCategoryRequest,
    ): Promise<ExternUserCategoryResponse> {
        return this.api.service<ExternUserCategoryResponse>(
            "externUserCategory",
            params,
        );
    }

    async doExternUserCreate(
        data: ExternUserRequest,
    ): Promise<ExternUserResponse> {
        return this.api.service<ExternUserResponse>("externUserCreate", data);
    }
}
