import template from "./time-picker.html?raw";
import "./time-picker.scss";

import vue from "vue";

export default vue.extend({
    template,
    props: {
        value: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            selected: { m: null, h: null } as {
                m: string | null;
                h: string | null;
            },
        };
    },
    watch: {
        selected: {
            deep: true,
            handler() {
                const result =
                    this.selected.h && this.selected.m
                        ? `${this.selected.h}:${this.selected.m}`
                        : null;
                this.$emit("input", result);
            },
        },
    },
    computed: {
        hours(): Array<string> {
            const values: string[] = [];

            for (let i = 0; i <= 23; i++) {
                values.push(i.toString().padStart(2, "0"));
            }

            return values;
        },

        minutes(): Array<string> {
            return ["00", "30"];
            /* let values = [];
                let start = 0;
        
                for(let i = 0; i <= 59; i++){
                    values.push(start.toString().padStart(2, "0"));
                    start += 30;
                }
        
                return values; */
        },
    },
    mounted() {
        if (this.value && this.value.match(/\:/)) {
            this.selected.h = this.value.split(":")[0];
            this.selected.m = this.value.split(":")[1];
        }
    },
});
