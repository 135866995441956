import template from "modules/core/components/interface/list-item/list-item.html?raw";
import "modules/core/components/interface/list-item/list-item.scss";
import UIIcon from "modules/core/components/interface/icon/Icon";
import { CoreComponentMixin } from "modules/core/mixins/ts";

import mixins from "vue-typed-mixins";

export default mixins(CoreComponentMixin).extend({
    template,
    components: {
        UIIcon,
    },

    props: {
        hasIcon: {
            type: Boolean,
            required: false,
            default: false,
        },
        selected: {
            type: Boolean,
            required: false,
            default: false,
        },
        twolines: {
            type: Boolean,
            required: false,
            default: false,
        },
        showArrow: {
            type: Boolean,
            required: false,
            default: false,
        },
        iconBaseline: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: Boolean,
            required: false,
            default: false,
        },
        interactive: {
            type: Boolean,
            required: false,
            default: true,
        },
        divider: {
            type: Boolean,
            required: false,
            default: true,
        },
        ellipsis: {
            type: Boolean,
            required: false,
            default: true,
        },
        flexible: {
            type: Boolean,
            required: false,
            default: true,
        },
        tag: {
            type: String,
            required: false,
            default: "div",
        },
    },

    computed: {
        classes(): string[] {
            const definition: string[] = [];

            if (this.twolines) {
                definition.push("mdc-list--two-line mdc-list--avatar-list");
            }

            if (!this.interactive) {
                definition.push("mdc-list--non-interactive");
            }

            if (!this.divider) {
                definition.push("mdk-ui-list-item--no-divider");
            }

            if (!this.ellipsis) {
                definition.push("mdk-ui-list-item--no-ellipsis");
            }

            if (this.flexible) {
                definition.push("mdk-ui-list-item--flexible");
            }

            if (this.iconBaseline) {
                definition.push("mdk-ui-list-item--icon-baseline");
            }

            return definition;
        },

        innerClasses(): string[] {
            const definition: string[] = [];

            if (this.selected) {
                definition.push("mdc-list-item--selected");
            }

            return definition;
        },
    },
});
