import SessionBO from "modules/core/business/SessionBO";
import GenericRepo from "./GenericRepo";
import SessionDAO from "../dao/SessionDAO";
import SessionRemoteDAO from "../dao/SessionRemoteDAO";
import { Application } from "../lib/Holder";

export default class SessionRepo extends GenericRepo {
  constructor() {
    super();

    this.dao = new SessionDAO();
    this.remoteDao = new SessionRemoteDAO();
    this.storageName = "sessions";
  }

  static getInstance() {
    if (!SessionRepo.instance) {
      SessionRepo.instance = new this();
    }

    return SessionRepo.instance;
  }

  async fetch(
    offset = null,
    count = null,
    maxDate = null,
    externParams = {},
    externUserId = null,
  ) {
    const params = {
      maxDate,
      useLastEdited: false,
      offset,
      count,
      externUserId,
      ...externParams,
    };
    if (
      Application.getInstance().currentExternUserIsPartner() &&
      !externUserId
    ) {
      params.childrenRelationTypeList = [0, 3];
      params.includeTargetSessions = true;
      params.phaseList = [0, 1];
    }

    return await this.remoteDao.fetchSessionList(null, null, params);
  }

  async fetchPending(
    offset,
    count,
    sinceDate,
    maxDate,
    useLastEdited = true,
    externUserId,
  ) {
    const params = {
      sinceDate,
      maxDate,
      useLastEdited,
      offset,
      count,
      externUserId,
    };

    if (
      Application.getInstance().currentExternUserIsPartner() &&
      !externUserId
    ) {
      params.childrenRelationTypeList = [0, 3];
      params.includeTargetSessions = true;
      params.phaseList = [0, 1];
    }

    return await this.remoteDao.fetchSessionList(null, null, params);
  }

  async getOrFetchSessionInfo(sessionId) {
    const item = this.dao.getItemById(sessionId);

    if (item) return item;

    const response = await SessionBO.getInstance().doSessionInfo({
      sessionId,
      inputValidation: true,
    });

    if (response) {
      this.dao.addItem(response.session);

      return response.session;
    }

    return null;
  }
}
