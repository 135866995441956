import _isEmpty from "lodash/isEmpty";
import _merge from "lodash/merge";
import GenericRepo from "./GenericRepo";
import ExternUserGroupDAO from "../dao/ExternUserGroupDAO";
import ExternUserGroupRemoteDAO from "../dao/ExternUserGroupRemoteDAO";
import EventBusSingleton from "../lib/EventBusSingleton";
import Util from "../lib/Util";

export default class ExternUserGroupRepo extends GenericRepo {
  constructor() {
    super();

    this.dao = new ExternUserGroupDAO();
    this.remoteDao = new ExternUserGroupRemoteDAO();
    this.storageName = "externUserGroups";
  }

  static getInstance() {
    if (!ExternUserGroupRepo.instance) {
      ExternUserGroupRepo.instance = new this();
    }

    return ExternUserGroupRepo.instance;
  }

  async fetch(
    offset = null,
    count = null,
    maxDate = null,
    params = {},
    externUserId = null,
  ) {
    return await this.remoteDao.fetchExternUserGroupList(
      offset,
      count,
      _merge(params, { maxDate, externUserId }),
    );
  }

  async fetchExternUserGroup(externUserGroupId, params = {}) {
    return await this.remoteDao.fetchExternUserGroup(externUserGroupId, params);
  }

  async fetchPending(
    offset,
    count,
    sinceDate,
    maxDate,
    useLastEdited = true,
    externUserId = null,
  ) {
    return await this.remoteDao.fetchExternUserGroupList(null, null, {
      sinceDate,
      maxDate,
      useLastEdited,
      offset,
      count,
      externUserId,
    });
  }

  async fetchValuation(params = {}) {
    const response = await this.remoteDao.fetchValuation(params);

    if (response) {
      this.setHasMyReview(response.externUserGroupId);
      this.updateStorage();

      return response;
    }
  }

  async getListByGroupStatus(offset = 0, count = 15, groupStatus) {
    const items = this.getExternUserGroupListByGroupStatusPaginated(
      offset,
      count,
      groupStatus,
    );
    const fetchCount = count - items.length;

    if (fetchCount > 0) {
      const maxDate = Util.currentTimestamp();
      const itemsFromServer = await this.fetch(offset, count, null, {
        groupStatusList: [groupStatus],
      });
      if (itemsFromServer) {
        // Random TypeError: undefined is not iterable (cannot read property Symbol(Symbol.iterator))
        for (const item of itemsFromServer) {
          this.dao.addExternUserGroup(item, false);
        }
      }

      this.lastMaxDate = maxDate;

      this.updateStorage();
    }

    return this.getExternUserGroupListByGroupStatusPaginated(
      offset,
      count,
      groupStatus,
    );
  }

  async getItem(externUserGroupId = null, params = {}) {
    const storedItem = this.getExternUserGroupById(externUserGroupId);
    if (!_isEmpty(params) || storedItem === undefined) {
      const response = await this.fetchExternUserGroup(
        externUserGroupId,
        params,
      );
      if (response) {
        this.addOrUpdateExternUserGroup(response);

        return response;
      }
    }

    return storedItem;
  }

  getMyExternUserGroupsIdByGroupStatus(
    groupStatus,
    userRoleToAvoid,
    externUserId,
  ) {
    const externGroups = this.getExternUserGroupListByGroupStatus(groupStatus);

    return externGroups
      .filter((group) => {
        return (
          group.externUserGroupMembers.filter((groupMember) => {
            return (
              groupMember.externUserId === externUserId &&
              groupMember.userRole !== userRoleToAvoid
            );
          }).length > 0
        );
      })
      .map((filteredGroup) => filteredGroup.externUserGroupId);
  }

  getExternUserGroupById(externUserGroupId) {
    return this.dao.getItemById(externUserGroupId);
  }

  getExternUserGroupListByGroupStatus(groupStatus) {
    return this.dao.getExternUserGroupListByGroupStatus(groupStatus);
  }

  addExternUserGroup(externUserGroup) {
    this.dao.addItem(externUserGroup);
  }

  isMyExternUserGroup(externUserGroupId, externUserId) {
    return this.dao.isMyExternUserGroup(externUserGroupId, externUserId);
  }

  getExternUserGroupList() {
    return this.dao.getList();
  }

  getExternUnserGroupIdList() {
    return this.dao.getListIds();
  }

  getGroupStatusByExternUserGroupId(externUserGroupId) {
    return this.dao.getGroupStatusByExternUserGroupId(externUserGroupId);
  }

  getStatusByExternUserGroupId(externUserGroupId) {
    return this.dao.getStatusByExternUserGroupId(externUserGroupId);
  }

  getExternUserGroupMembersByExternUserGroupId(
    externUserGroupId,
    externUserId = null,
  ) {
    return this.dao.getExternUserGroupMembersByExternUserGroupId(
      externUserGroupId,
      externUserId,
    );
  }

  getLastEditedByExternUserGroupId(externUserGroupId) {
    return this.dao.getLastEditedByExternUserGroupId(externUserGroupId);
  }

  getUserRoleByExternUserGroupIdAndExternUserId(
    externUserGroupId,
    externUserId,
  ) {
    return this.dao.getUserRoleByExternUserGroupIdAndExternUserId(
      externUserGroupId,
      externUserId,
    );
  }

  hasMyReviewByExternUserGroupId(externUserGroupId) {
    return this.dao.hasMyReviewByExternUserGroupId(externUserGroupId);
  }

  isChatbotByExternUserGroupId(externUserGroupId) {
    return this.dao.isChatbotByExternUserGroupId(externUserGroupId);
  }

  getProductIdByExternUserGroupId(externUserGroupId) {
    return this.dao.getProductIdByExternUserGroupId(externUserGroupId);
  }

  addOrUpdateExternUserGroup(externUserGroup) {
    if (externUserGroup) {
      this.dao.addExternUserGroup(externUserGroup);
      this.updateStorage();
    }
  }

  /* addOrUpdateMessage(message) {
        if (message) {
            this.addMessage(message);
            this.updateStorage();
            EventBusSingleton.emit("message:" + message.groupId, message);
            EventBusSingleton.emit("userUpdateNotifications");

        } else console.log(message);
        return message
    } */

  getExternUserGroupListByGroupStatusPaginated(
    offset,
    count,
    groupStatus,
    externUserGroupIdList = [],
  ) {
    return this.dao.getExternUserGroupListByGroupStatusPaginated(
      offset,
      count,
      groupStatus,
      externUserGroupIdList,
    );
  }

  getExternUserByExternUserGroupIdAndExternUserId(
    externUserGroupId,
    externUserId,
  ) {
    return this.dao.getExternUserByExternUserGroupIdAndExternUserId(
      externUserGroupId,
      externUserId,
    );
  }

  setExternUserStatus(externUserStatus) {
    this.dao.setExternUserStatus(externUserStatus);
  }

  setHasMyReview(externUserGroupId) {
    this.dao.setHasMyReview(externUserGroupId);
  }

  isValidGroupStatus(status) {
    return this.dao.isValidGroupStatus(status);
  }
}
