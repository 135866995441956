import { Dark } from "quasar";
import { AppEvents } from "modules/core/lib/events";
import AppStorageManager from "./AppStorageManager";
import { Application } from "./Holder";
// import EventBusSingleton from "./EventBusSingleton";

import { dispatchPublicEvent } from "@mediktor-web/common/events/public";
export default class DarkModeManager {
    static instance?: DarkModeManager;

    darkMode: boolean = false;

    dataTheme: string = "light";

    static getInstance(): DarkModeManager {
        if (!DarkModeManager.instance) {
            DarkModeManager.instance = new this();
        }

        return DarkModeManager.instance;
    }

    initDarkMode() {
        const isDarkMode =
            Application.getInstance().generalConfig().forceDarkMode;
        if (isDarkMode) {
            Dark.set(isDarkMode);
            this.darkMode = isDarkMode;
            this.saveIsDarkModeInStorage(isDarkMode);
        }

        if (Application.getInstance().generalConfig().enableToggleDarkMode) {
            if (this.getIsDarkModeFromStorage() === null)
                this.saveIsDarkModeInStorage(false);

            this.darkMode = this.getIsDarkModeFromStorage();
            Dark.set(this.darkMode);
        }

        this.manageDataTheme();
    }

    saveIsDarkModeInStorage(isDarkMode: boolean) {
        AppStorageManager.getInstance().saveIsDarkMode(isDarkMode);
    }

    getIsDarkModeFromStorage(): boolean {
        return AppStorageManager.getInstance().getIsDarkMode();
    }

    updateIsDarkMode(isDarkMode: boolean) {
        this.darkMode = isDarkMode;
        this.saveIsDarkModeInStorage(isDarkMode);
        Dark.set(isDarkMode);
        this.manageDataTheme();

        // EventBusSingleton.emit("dark-mode-toggle", isDarkMode);
        // new AppEvents.DarkModeChange().emit(isDarkMode);
        const theme = isDarkMode ? "dark" : "light";
        dispatchPublicEvent({ name: "app:theme_changed", payload: { theme } });
    }

    manageDataTheme() {
        this.dataTheme = this.darkMode ? "dark" : "light";

        document.documentElement.setAttribute("data-theme", this.dataTheme);
    }
}
