import _merge from "lodash/merge";
import { ChatLineRequest } from "modules/core/model/chatLineRequest";
import { ChatLineResponse } from "modules/core/model/chatLineResponse";
import BusinessAbstract from "./BusinessAbstract";

export default class ChatLineBO extends BusinessAbstract {
    static instance?: ChatLineBO;

    static getInstance(): ChatLineBO {
        if (!ChatLineBO.instance) {
            ChatLineBO.instance = new this();
        }

        return ChatLineBO.instance;
    }

    async doChatLine(
        externUserId: string,
        productId: string,
        createChatLine: boolean = false,
        sessionId?: string,
    ): Promise<ChatLineResponse> {
        const options: ChatLineRequest = {
            productId,
            sessionId,
            createChatLine,
            fullInfo: true,
        };
        if (externUserId) options.externUserId = externUserId;

        return this.api.service("chatLine", options);
    }

    async doChatLineForProduct(
        productId: string,
        createChatLine: boolean = true,
        params: ChatLineRequest = {},
    ): Promise<ChatLineResponse> {
        return this.api.service(
            "chatline",
            _merge(
                {
                    productId,
                    createChatLine,
                },
                params,
            ),
        );
    }
}
