import mitt from "mitt";

type Requests = "location";

export type RequestEventNames = `request:${Requests}`;
type ResponseEventNames = `response:${Requests}`;

type RequestEvent<
    Name extends RequestEventNames,
    Payload = undefined,
> = Payload extends undefined
    ? {
          name: Name;
          payload?: undefined;
      }
    : {
          name: Name;
          payload: Payload;
      };

type ResponseEvent<Name extends ResponseEventNames, Payload> = {
    name: Name;
    payload: Payload;
};

export type RequestEvents = RequestEvent<"request:location", { type: "single-point"} | {type: "multi-point", min: number, max: number}>

type ResponseEvents = ResponseEvent<
    "response:location",
    { coordinates: [number, number][] }
>;

type ChannelEventNames = RequestEventNames | ResponseEventNames;

type RequestEventChannel = {
    [key in ChannelEventNames]: Extract<
        key extends RequestEventNames ? RequestEvents : ResponseEvents,
        { name: key }
    >;
};

export const requestEventsChannel = mitt<RequestEventChannel>();

// type ExtractRequestEventPayload<T extends RequestEventNames> = Extract<
//     RequestEvents,
//     { name: T }
// >["payload"];

type ExtractResponseEvent<T extends ResponseEventNames> = Extract<
    ResponseEvents,
    { name: T }
>;
type ExtractResponseEventPayload<T extends ResponseEventNames> =
    ExtractResponseEvent<T>["payload"];

export const dispatchRequestEvent = <T extends RequestEvents>(event: T) => {
    type TRequest = T["name"] extends `request:${infer RequestType}`
        ? RequestType
        : never;

    const responseEventName =
        `response:${event.name.split(":")[1] as T["name"]}` as Extract<
            ResponseEventNames,
            `response:${TRequest}`
        >;

    type TResponseEventName = typeof responseEventName;

    requestEventsChannel.emit(event.name, event);

    type Payload = ExtractResponseEventPayload<TResponseEventName>;

    return new Promise<Payload>((resolve) => {
        const handler = (
            response: ResponseEvents,
            // response: Extract<ResponseEvents, { name: TResponseEventName }>,
        ) => {
            requestEventsChannel.off(responseEventName, handler);

            // @ts-ignore
            resolve(response.payload);
        };

        requestEventsChannel.on(responseEventName, handler);
    });
};

export const dispatchResponseEvent = (event: ResponseEvents) => {
    // const eventName: ResponseEventNames = event.name.startsWith("request:")
    //     ? `response:${event.name.split(":")[1] as Requests}`
    //     : event.name;

    requestEventsChannel.emit(event.name, event);
};

export default {
    requestEventsChannel,
    dispatchRequestEvent,
    dispatchResponseEvent,
};
