// @ts-ignore
// TODO: Move to TS ENUM!
import Enum from "enum";

const PermissionProfileEnum = new Enum({
    SUPER: 1,
    ADMINISTRATOR: 2,
    PHYSICIAN: 3,
    NURSE: 4,
    CASHIER: 5,
    HCA: 6,
    NONE: 0,
    PARTNER_INTERNAL: 7,
    PARTNER_EXTERNAL: 8,
    RESEARCHER: 9,

    HOSPITAL_USERS: [1, 2, 3, 4, 5, 6],
});

export default PermissionProfileEnum;
