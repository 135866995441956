import AppPresets from "modules/core/lib/AppPresets";
import { EnvironmentConfigAllowedValues } from "../types/misc";

interface EnvironmentConfiguration {
    APP_API_AUTH_USERNAME: string;
    APP_API_AUTH_PASSWORD: string;
    APP_API_USER_ID: string;
    APP_API_AUTH_CODE: string;
    APP_API_ENDPOINT: string;
    APP_WS_ENDPOINT: string;
    APP_DOMAIN: string;
    APP_BASE_PATH: string;
    REDSYS_LIVE: boolean;
    REDSYS_KEY: string;
    ENABLE_ERROR_REPORTING: boolean;
    DEBUG_CAPABILITIES: string;
    FORCE_DOMAIN: string;
    RUNTIME_MODE: boolean;
    REDSYS_MERCHANT_CODE: string;
    STRIPE_KEY: string;
    SENTRY_DSN: string;
    USER_DEBUGGER: boolean;
    BUILD_TIMESTAMP: string;
}

export const environment: Partial<EnvironmentConfiguration> = {
    BUILD_TIMESTAMP: import.meta.env.VITE_APP_BUILD_TIMESTAMP,
};

console.log({ environment });

export const runtimeConfig: Partial<
    Record<EnvironmentConfigAllowedValues, string | number | boolean>
> = {
    APP_API_AUTH_CODE: undefined,
    APP_API_USER_ID: undefined,
    APP_API_ENDPOINT: undefined,
    APP_WS_ENDPOINT: undefined,
};

export default class EnvironmentConfig {
    static setConfig(config: Partial<EnvironmentConfiguration>) {
        Object.keys(config).forEach((key) => {
            environment[key] = config[key];
        });
    }

    // This method allows us to override any environment variable at runtime (without having to re-start server)
    static overrideData(name: EnvironmentConfigAllowedValues): string | null {
        // if(name === "APP_API_ENDPOINT") return "https://office.mediktor.com:443/backoffice";
        try {
            return EnvironmentConfig.applyPresets(name);
        } catch (error) {
            console.error(error);
        }

        return null;
    }

    static applyPresets(name: EnvironmentConfigAllowedValues): string | null {
        // For development purposes only
        const preset = AppPresets.getInstance().getActivePreset();

        if (preset) {
            if (
                name === "APP_API_AUTH_CODE" &&
                preset.env?.username &&
                preset.env?.password
            )
                return btoa(`${preset.env?.username}:${preset.env?.password}`);
            if (name === "APP_API_USER_ID" && preset.env?.userId)
                return preset.env?.userId;
            if (name === "APP_API_ENDPOINT" && preset.env?.endpoint)
                return preset.env?.endpoint;
            if (name === "APP_WS_ENDPOINT" && preset.env?.wsEndpoint)
                return preset.env?.wsEndpoint;
            if (name === "APP_ANALYTICS_ID" && preset.env?.analyticsId)
                return preset.env?.analyticsId;
            if (name === "STRIPE_KEY" && preset.env?.stripeId)
                return preset.env?.stripeId;
        }

        return null;
    }

    static getValue(name: EnvironmentConfigAllowedValues) {
        if (runtimeConfig) {
            if (
                name === "APP_API_AUTH_CODE" &&
                runtimeConfig.APP_API_AUTH_CODE !== undefined
            )
                return runtimeConfig.APP_API_AUTH_CODE;
            if (
                name === "APP_API_USER_ID" &&
                runtimeConfig.APP_API_USER_ID !== undefined
            )
                return runtimeConfig.APP_API_USER_ID;
            if (
                name === "APP_API_ENDPOINT" &&
                runtimeConfig.APP_API_ENDPOINT !== undefined
            )
                return runtimeConfig.APP_API_ENDPOINT;
            if (
                name === "APP_WS_ENDPOINT" &&
                runtimeConfig.APP_WS_ENDPOINT !== undefined
            )
                return runtimeConfig.APP_WS_ENDPOINT;
        }

        if (EnvironmentConfig.overrideData(name) !== null) {
            return EnvironmentConfig.overrideData(name);
        }

        const a = EnvironmentConfig.getAll();

        if (a !== undefined && a[name] !== undefined) {
            return a[name];
        }

        return null;
    }

    static getAll() {
        return environment || {};
    }

    static setValue(property: EnvironmentConfigAllowedValues, value: any) {
        environment[property] = value;
    }

    static isLocal() {
        return window.location.host.match(/localhost/);
    }
}
